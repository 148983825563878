import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { DataGrid, GridColDef, GridColumnHeaderParams, GridRowSelectionModel } from '@mui/x-data-grid';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import GridPagination from '~/components/Common/Elements/GridPagination';

import departureService from '~/services/cruises/DepartureService';

import LinkContent from './LinkContent';
import WrapDetails from './WrapDetails';
import { SearchFilters } from './constants';

type SailingResponse = {
  total: number;
  result: CruisesContract.DepartureResponse[];
};

const DEFAULT_ROWS_RESPONSE: SailingResponse = { result: [], total: 0 };

type FetchOrderDataParams = {
  page: number;
  brand: App.Brands;
  sizePerPage: number;
  searchFilters: SearchFilters;
};

interface Props {
  page: number;
  brand: App.Brands;
  sizePerPage: number;
  currentPage: number;
  searchFilters: SearchFilters;
  onPageChange: (page: number) => void;
  showCheckboxSelection?: boolean;
  onSelectionChange?: (rowSelectionModel: GridRowSelectionModel) => void;
  onClickSelectAll?: (params: GridColumnHeaderParams) => void;
}

const urlBuilder = (id: string): string => `/cruises/sailings/${id}`;

const columns: GridColDef[] = [
  {
    flex: 1.5,
    field: 'name',
    headerName: 'Name',
    renderCell: (params) =>
      LinkContent({
        title: params.row.name,
        url: urlBuilder(params.row.id),
      }),
    display: 'flex',
  },
  {
    flex: 1,
    field: 'externalId_sailingPlan',
    headerName: 'Sailing IDs',
    renderCell: (params) =>
      WrapDetails({
        title: `SailingID: ${params.row.externalId}`,
        subtitle: `SailingPlan: ${params.row.sailingPlan}`,
      }),
    display: 'flex',
  },
  {
    flex: 1,
    field: 'cruiseLineDetails',
    headerName: 'Cruise Line',
    renderCell: (params) =>
      WrapDetails({
        title: `Code: ${params.row.cruiseLineCode}`,
        subtitle: params.row.cruiseLine,
      }),
    display: 'flex',
  },
  {
    flex: 1,
    field: 'shipDetails',
    headerName: 'Ship',
    renderCell: (params) =>
      WrapDetails({
        title: `External ID: ${params.row.shipExternalId}`,
        subtitle: params.row.shipName,
      }),
    display: 'flex',
  },
  {
    flex: 1,
    field: 'departureDate',
    headerName: 'Departure Date',
    display: 'flex',
  },
  {
    flex: 1.5,
    field: 'departurePortDetails',
    headerName: 'Departure Port',
    renderCell: (params) =>
      WrapDetails({
        title: `Code: ${params.row.departurePortCode}`,
        subtitle: params.row.departurePortName,
      }),
    display: 'flex',
  },
  {
    flex: 1.5,
    field: 'returnPortDetails',
    headerName: 'Return Port',
    renderCell: (params) =>
      WrapDetails({
        title: `Code: ${params.row.returnPortCode}`,
        subtitle: params.row.returnPortName,
      }),
    display: 'flex',
  },
  {
    flex: 1,
    field: 'status_requiresApproval',
    headerName: 'Statuses',
    renderCell: (params) =>
      WrapDetails({
        title: `Status: ${params.row.status}`,
        subtitle: `Requires Approval: ${params.row.requiresApproval}`,
      }),
    display: 'flex',
  },
];

export default function SailingList(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    brand,
    page,
    currentPage,
    searchFilters,
    sizePerPage,
    onPageChange,
    showCheckboxSelection,
    onSelectionChange,
    onClickSelectAll,
  } = props;

  const [rows, setRows] = useState<SailingResponse>(DEFAULT_ROWS_RESPONSE);
  const [fetching, setFetching] = useState<Utils.FetchingState>('idle');

  const fetchData = useCallback(
    async (params: FetchOrderDataParams): Promise<void> => {
      const { brand, searchFilters } = params;

      setFetching('loading');

      let requiresApproval: boolean = undefined;
      if (searchFilters.requiresApproval === 'TRUE') requiresApproval = true;
      else if (searchFilters.requiresApproval === 'FALSE') requiresApproval = false;

      let status: CruisesContract.DepartureStatus | null = undefined;
      if (searchFilters.status === 'ACTIVE') status = 'ACTIVE';
      else if (searchFilters.status === 'INACTIVE') status = 'INACTIVE';

      let shipExternalId: number | undefined = undefined;
      if (searchFilters.shipExternalId) shipExternalId = Number(searchFilters.shipExternalId);

      const formatSailingPlan = searchFilters.sailingPlan ? `${searchFilters.sailingPlan}_n` : undefined;

      const take = params.sizePerPage;
      const skip = (params.page - 1) * params.sizePerPage;

      try {
        const fetchedPurchases = await departureService.listWithPagination({
          ...searchFilters,
          skip,
          take,
          brand: brand as CruisesContract.Brand,
          status,
          shipExternalId,
          requiresApproval,
          sailingPlan: formatSailingPlan,
        });

        setFetching('success');

        if (!fetchedPurchases || fetchedPurchases.result.length === 0) {
          enqueueSnackbar('No results found', { autoHideDuration: 5000, variant: 'warning' });
        }

        setRows({
          total: fetchedPurchases.total,
          result: fetchedPurchases.result,
        });
      } catch (error) {
        setFetching('failed');
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });

        setRows({ total: 0, result: [] });
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    fetchData({
      brand,
      sizePerPage,
      searchFilters,
      page: currentPage,
    });
  }, [currentPage, fetchData, searchFilters, sizePerPage, brand]);

  return (
    <DataGrid
      pagination
      autoHeight
      columns={columns}
      disableColumnMenu
      disableColumnFilter
      keepNonExistentRowsSelected
      rows={rows.result}
      rowCount={rows.total}
      paginationMode="server"
      disableRowSelectionOnClick
      getRowHeight={() => 'auto'}
      pageSizeOptions={[sizePerPage]}
      loading={fetching === 'loading'}
      slots={{ pagination: GridPagination }}
      paginationModel={{ page: page - 1, pageSize: sizePerPage }}
      onPaginationModelChange={({ page }) => onPageChange(page + 1)}
      checkboxSelection={showCheckboxSelection}
      onRowSelectionModelChange={onSelectionChange}
      onColumnHeaderClick={(gridColumnParams) => {
        if (gridColumnParams.field === '__check__') {
          onClickSelectAll(gridColumnParams);
        }
      }}
    />
  );
}
