import React from 'react';

import { Control, Controller } from 'react-hook-form';

import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';

type Props = {
  name: string;
  label?: string;
  control: Control<any>;
  onChange?: (value: any) => void;
};

// important: intended to be used inside react hook form
const Checkbox = ({ name, label, control, onChange }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl>
          <FormControlLabel
            control={<MuiCheckbox {...field} onChange={onChange || field.onChange} checked={!!field.value} />}
            label={label}
          />
        </FormControl>
      )}
    />
  );
};

export default Checkbox;
