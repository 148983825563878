import React, { useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';

import useToggleState from '~/hooks/useToggleState';

import { getRefundRequestsByOrderItemId, updateRefundRequest } from '~/services/OrdersService';

interface Props {
  item: App.OrderItem;
  order: App.Order;
  offer: App.Offer;
}

export function RefundRequests({ item, order, offer }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [refundRequest, setRefundRequest] = useState<Order.RefundRequest | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    isToggled: isRespondModalOpen,
    toggleOn: openRespondModal,
    toggleOff: closeRespondModal,
  } = useToggleState(false);

  const fetchRefundRequest = async (orderId: string, itemId: string) => {
    if (orderId && itemId) {
      const result = await getRefundRequestsByOrderItemId(orderId, itemId);
      setRefundRequest(result);
    }
  };

  useEffect(() => {
    fetchRefundRequest(order.id_orders, item.id);
  }, [item.id, order.id_orders]);

  const vendorEmailLog = useMemo(() => {
    if (offer.vendor_booking_email && refundRequest) {
      const emailKey = `${refundRequest.vendor_salesforce_id}:${refundRequest.id_refund_request}`;

      return `/email/sent?email=${offer.vendor_booking_email}&key=${emailKey}`;
    }
  }, [offer.vendor_booking_email, refundRequest]);

  const handleResponse = async (status: 'approved' | 'rejected') => {
    if (refundRequest) {
      const updateData = {
        status,
        op: 'update_status',
      };

      setIsLoading(true);
      try {
        await updateRefundRequest(refundRequest.id_refund_request, updateData);
        await fetchRefundRequest(order.id_orders, item.id);
        enqueueSnackbar(`Refund request ${status} successfully`, { autoHideDuration: 3000, variant: 'success' });
        closeRespondModal();
      } catch (error) {
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'warning' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const approveRefundRequest = () => {
    handleResponse('approved');
  };

  const rejectRefundRequest = () => {
    handleResponse('rejected');
  };

  return (
    <Stack direction="column" mt={1}>
      <Stack direction="row" spacing={2}>
        <Typography variant="subtitle1" fontWeight="bold">
          Refund requests outside of policy
        </Typography>
        {refundRequest && refundRequest.status === 'pending' && (
          <Button size="small" onClick={openRespondModal}>
            Respond
          </Button>
        )}
      </Stack>
      {refundRequest && <Typography>{refundRequest.status}</Typography>}
      {!refundRequest && <Typography>N/A</Typography>}
      {refundRequest && vendorEmailLog && (
        <Button href={vendorEmailLog} target="_blank">
          View refund email log
        </Button>
      )}

      <Dialog open={isRespondModalOpen} onClose={closeRespondModal}>
        <DialogTitle>Warning: Responding to Refund Request on behalf of Vendor</DialogTitle>
        <DialogContent>
          <Typography>
            You are about to respond to this refund request on behalf of the vendor. Please choose whether to approve or
            reject the request.
          </Typography>
          <Box mt={2}>
            <Typography variant="subtitle1" fontWeight="bold">
              Reason for Request:
            </Typography>
            <Typography>{refundRequest?.reason}</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={closeRespondModal} variant="text" color="primary">
            Cancel
          </Button>
          <Stack direction="row" gap={1}>
            <LoadingButton
              onClick={rejectRefundRequest}
              variant="contained"
              color="error"
              loading={isLoading}
              disabled={!refundRequest}
            >
              Reject
            </LoadingButton>
            <LoadingButton
              onClick={approveRefundRequest}
              variant="contained"
              color="primary"
              loading={isLoading}
              disabled={!refundRequest}
            >
              Approve
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
