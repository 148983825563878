import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Link, useRouteMatch } from 'react-router-dom';

import { Box, Button, Container, Stack, Tab, Tabs } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import VendorsService from '~/services/VendorsService';

import ErrorDisplay from '../../Common/ErrorDisplay';
import Spinner from '../../Common/Spinner';
import { VendorAddonOffers } from '../Addons/AddonOffers';

import PropertiesPageContainer from './PropertiesPageContainer';
import VendorOffersContainer from './VendorOffersContainer';
import VendorRatePlans from './VendorRatePlans';
import VendorRatePlansGroups from './VendorRatePlansGroupsPageContainer';
import VendorToursContainer from './VendorToursContainer';
import VendorUsersPageContainer from './VendorUsersPageContainer';

const TAB_PROPERTIES = 'properties';

const TabsMap = {
  [TAB_PROPERTIES]: {
    title: 'Properties',
    component: PropertiesPageContainer,
  },
  'rate-plans': {
    title: 'Rate Plans',
    component: VendorRatePlans,
  },
  'rate-plan-groups': {
    title: 'Rate Plan Groups',
    component: VendorRatePlansGroups,
  },
  tours: {
    title: 'Tours',
    component: VendorToursContainer,
  },
  'addon-offers': {
    title: 'Addon Offers',
    component: VendorAddonOffers,
  },
  offers: {
    title: 'Offers',
    component: VendorOffersContainer,
  },
  users: {
    title: 'Vendor users',
    component: VendorUsersPageContainer,
  },
} as const;

type TabKey = keyof typeof TabsMap;

const allTabs = Object.keys(TabsMap);

export default function VendorsDetailContainer() {
  const {
    params: { id_vendor: vendorId },
  } = useRouteMatch<{ id_vendor: string }>();

  const [vendor, setVendor] = useState<App.Vendor | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [key, setKey] = useState<TabKey>(TAB_PROPERTIES);

  const currentTab = TabsMap[key];

  const handleChange = useCallback((_: React.SyntheticEvent, newKey: TabKey) => {
    setKey(newKey);
  }, []);

  useEffect(() => {
    setLoading(true);
    setError(null);

    VendorsService.getVendorById(vendorId)
      .then((res) => {
        setVendor(res.result);
      })
      .catch(() => {
        setError("Service error. Can't load vendor details.");
      })
      .finally(() => setLoading(false));
  }, [vendorId]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Vendors | {vendor?.name || vendorId}</title>
      </Helmet>

      <PageHeader title={vendor?.name || vendorId}>
        <Stack direction="row" spacing={1}>
          <Button component={Link} variant="text" to={`/vendors/${vendorId}/inclusions`}>
            Inclusions
          </Button>
          <Button variant="text" href={`${window.configs.VENDOR_PORTAL}/${vendorId}/offers`} target="_blank">
            Vendor Portal
          </Button>
          <Button component={Link} variant="text" to={`/vendors/${vendorId}/partner-central`}>
            Partner Central
          </Button>
        </Stack>
      </PageHeader>

      {isLoading && <Spinner />}
      {error && <ErrorDisplay message={error} />}

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={key} onChange={handleChange} id="vendors">
          {allTabs
            .filter((key) => (key === 'addon-offers' ? parseInt(window.configs.MAXIMUM_IMAGE_PER_ADDON) > 0 : true))
            .map((tabKey) => (
              <Tab key={tabKey} label={TabsMap[tabKey].title} value={tabKey} />
            ))}
        </Tabs>
      </Box>

      <Box pt={4}>
        <Box component={currentTab.component} vendorName={vendor?.name} />
      </Box>
    </Container>
  );
}
