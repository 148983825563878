import React from 'react';

import { Box } from '@mui/material';

import BookingCancelledToggle from './BookingCancelledToggle';
import SurchargeToggle from './SurchargeToggle';

export default class FullRefundVariant extends React.Component {
  render() {
    return (
      <Box id="FullRefundVariant">
        {this.props.with_fee && (
          <Box id="fee">
            <label>Fee Charged to Customer:</label>
            <input
              name="fee"
              value={this.props.tmpValue('fee')}
              onChange={this.props.handleTmpChange}
              className="data-hj-whitelist"
            />
          </Box>
        )}
        {this.props.refund.has_surcharge && (
          <SurchargeToggle
            handleFlagChange={this.props.handleFlagChange}
            surchargeInfo={this.props.surchargeInfo}
            is_refunded_surcharge={this.props.refund.is_refunded_surcharge}
          />
        )}
        {!this.props.refund.is_subscription && (
          <BookingCancelledToggle
            handleFlagChange={this.props.handleFlagChange}
            mark_cancelled={this.props.refund.mark_cancelled}
          />
        )}
      </Box>
    );
  }
}
