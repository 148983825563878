import React, { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { Alert, AlertTitle, Box, Button } from '@mui/material';

import BundleAndSaveFields from '~/components/Common/Forms/OfferForm/BundleAndSaveFields';
import FlightsAccordion from '~/components/Common/Forms/OfferForm/FlightsAccordion';
import GeneralSettingsAccordion from '~/components/Common/Forms/OfferForm/GeneralSettingsAccordion';
import OfferFieldsAccordion from '~/components/Common/Forms/OfferForm/OfferFieldsAccordion';
import OfferSettingsAccordion from '~/components/Common/Forms/OfferForm/OfferSettingsAccordion';
import TagsAndMarketingAccordion from '~/components/Common/Forms/OfferForm/TagsAndMarketingAccordion';
import { Input } from '~/components/Common/Forms/fields';
import { LocalizationData } from '~/components/Offers/Edit/ContentLocalization/ContentLocalizationForm';
import OfferTemplateModal from '~/components/Offers/Edit/OfferTemplateModal';

import { IMAGES, PACKAGES } from '~/consts/offerFields';
import {
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_RENTAL,
  OFFER_TYPE_TACTICAL_AO_HOTEL,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_V2,
} from '~/consts/offerTypes';

import { copyImage } from '~/services/ImageService';
import InclusionsService from '~/services/InclusionsService';
import OffersService from '~/services/OffersService';
import ReservationService from '~/services/ReservationService';

import isE2ETestOffer from '~/utils/isE2ETestOffer';
import { reportError } from '~/utils/reportError';

import { LUX_PLUS_FINEPRINT_CONTENT_FLASH, LUX_PLUS_FINEPRINT_CONTENT_LPC } from './constants/fineprint';
import getDefaultValues from './defaultValues';
import offerFormUtils from './utils';
import offerFormSchema from './validation';

interface Props {
  confirmedCopyOptions: string;
  contentLocalization: LocalizationData[];
  offer: any;
  onAddImage: (id: string, offerId: string) => void;
  originalOfferDetails: App.Offer;
  schema: any;
  templates: App.OfferTemplate[];
  generatorContext: any;
}

const ERROR_NOTIFICATION_DURATION = 10 * 1000; // 10 seconds

const OfferForm = ({
  confirmedCopyOptions,
  contentLocalization = [],
  offer,
  onAddImage,
  originalOfferDetails,
  schema,
  templates,
  generatorContext,
}: Props) => {
  const [currTemplateType, setCurrTemplateType] = useState('');
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [fillingPropertyName, setFillingPropertyName] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { control, formState, getValues, handleSubmit, watch, setValue } = useForm({
    defaultValues: getDefaultValues(offer, schema),
    mode: 'onBlur',
    resolver: yupResolver(offerFormSchema),
  });

  const isDepositThresholdEnabled = watch('is_enable_deposit_thresholds');
  const isFlightsWarningEnabled = watch('flights_warning_enabled');
  const type = watch('type');

  const showE2EOfferWarning = isE2ETestOffer(offer.name);
  const hasLocalizedName = contentLocalization.some((item) => !!item.name);
  const hasLocalizedDescription = contentLocalization.some((item) => !!item.description);
  const hasLocalizedAdditionalDescription = contentLocalization.some((item) => !!item.additional_description);

  const filteredTemplates = useMemo(
    () =>
      templates.filter((template) => {
        const templateOfferType = template.offer_type === 'all' ? true : offer.type === template.offer_type;
        return template.template_type === currTemplateType && templateOfferType;
      }),
    [currTemplateType, offer, templates],
  );

  const isBundleAndSaveSelected = useMemo(() => type === OFFER_TYPE_BUNDLE_AND_SAVE, [type]);

  const addOfferToBundle = (offerId) => {
    const prevOfferBundles = getValues('offer_bundles');

    if (prevOfferBundles.includes(offerId)) {
      enqueueSnackbar('Offer already added to bundle', { variant: 'warning' });

      return;
    }

    setValue('offer_bundles', [...prevOfferBundles, offerId]);
  };

  const onShowTemplateModalClick = (templateType) => {
    setCurrTemplateType(templateType);
    setShowTemplateModal(true);
  };

  const copyImagesToOffer = async (images, idOffer) => {
    for (const image of images) {
      const copyImgResponse = await copyImage(image.id_cloudinary_external);

      await onAddImage(copyImgResponse.body.result.id, idOffer);
    }
  };

  const onSubmit = async (formData) => {
    // remove null values from formData
    Object.keys(formData).forEach((key) => formData[key] === null && delete formData[key]);

    const idOffer = offer.id_salesforce_external;

    if (formData.sale_unit) {
      formData.sale_unit = formData.sale_unit.trim();
    }

    try {
      if (confirmedCopyOptions.includes(IMAGES) && Array.isArray(offer.images)) {
        if (originalOfferDetails.images && originalOfferDetails.images.length > 0) {
          await offerFormUtils.deleteOfferImages(idOffer);
        }
        await copyImagesToOffer(offer.images, idOffer);
      }
      // remove null values from packages
      const offerPackages = offer.packages.map((offerPackage) =>
        Object.fromEntries(Object.entries(offerPackage).filter(([, value]) => value !== null)),
      );
      if (confirmedCopyOptions.includes(PACKAGES) && Array.isArray(offerPackages)) {
        await offerFormUtils.copyPackagesToOffer(offerPackages);
      }

      await OffersService.updateOffer(formData, idOffer);

      enqueueSnackbar('Saved successfully', { variant: 'success' });
    } catch (error) {
      if (error.message === 'Validation error') {
        error.errors.map((validationError) => enqueueSnackbar(validationError?.message, { variant: 'error' }));
      } else {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }

      reportError(error);
    }
  };

  const onSubmitError = (errors) => {
    offerFormUtils.extractErrorMessages(errors).forEach((message) => {
      enqueueSnackbar(message, {
        autoHideDuration: ERROR_NOTIFICATION_DURATION,
        variant: 'error',
      });
    });
  };

  const onTestOfferSubmit = (form) => {
    if (confirm('Are you sure? Editing test offers can break the E2E tests!')) {
      onSubmit(form);
    }
  };

  const loadTemplateToEditor = (template: App.OfferTemplate) => {
    const fieldName = offerFormUtils.labelToOfferFieldMapping(template.template_type);

    setValue(fieldName, template.content);

    setShowTemplateModal(false);
  };

  useEffect(() => {
    const thresholdDays = offer.deposit_thresholds.number_of_days;

    if (!thresholdDays && offer.type === OFFER_TYPE_TOUR) {
      setValue('deposit_thresholds.number_of_days', 105);
    }

    if (!thresholdDays && offer.type === OFFER_TYPE_TACTICAL_AO_HOTEL) {
      setValue('deposit_thresholds.number_of_days', 60);
    }
  }, [isDepositThresholdEnabled, offer, setValue]);

  useEffect(() => {
    // don't change the field on initial render,
    // can't use touched from react-hook-form because
    // it does not update on dropdown select until focus is lost
    if (!isInitialRender) {
      setValue('sale_unit', type === OFFER_TYPE_TOUR ? 'person' : 'room');
    }
  }, [type, setValue]);

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  const onFillBundlePropertyName = () => {
    const prevOfferBundles = getValues('offer_bundles');

    setFillingPropertyName(true);

    Promise.all(prevOfferBundles.map((offerId) => OffersService.getOffer(offerId))).then((results) => {
      const propetyIds = Array.from(
        new Set(results.flatMap(({ result }) => result.packages.map((p) => p.fk_property_id))).values(),
      );

      Promise.all(propetyIds.map((propetyId) => ReservationService.getProperty(propetyId))).then((results) => {
        setValue('location', results.map(({ result }) => result.name).join(' + '));
        setFillingPropertyName(false);
      });
    });
  };

  const onAppendLPFinePrint = (offerType = 'flash') => {
    const currentFinePrintValue = getValues('fine_print');
    let newValue = '';
    switch (offerType) {
      case 'flash':
        newValue = currentFinePrintValue + `\n\n${LUX_PLUS_FINEPRINT_CONTENT_FLASH}`;
        break;
      case 'lpc':
        newValue = currentFinePrintValue + `\n\n${LUX_PLUS_FINEPRINT_CONTENT_LPC}`;
        break;
    }
    setValue('fine_print', newValue);
  };

  const importOfferInclusions = async () => {
    const lowestPricePackage = offer.packages.reduce((lowest, next) => {
      if (next.cost_price < lowest.cost_price) {
        return next;
      }
      return lowest;
    }, offer.packages[0]);

    try {
      const { result } = await InclusionsService.fetchPackageInclusions(offer.id_salesforce_external);

      const packageInclusions = result[lowestPricePackage?.id_salesforce_external];

      if (!packageInclusions.length) {
        enqueueSnackbar('No inclusions found on base package, nothing to import', { variant: 'warning' });
        return;
      }
      const offerInclusions = packageInclusions.map((packageInclusion) => ({
        text: packageInclusion.inclusion?.name,
        is_highlighted: packageInclusion.style === 'highlighted',
        icon: packageInclusion.inclusion?.category?.icon,
        category: packageInclusion.inclusion?.category?.name,
      }));

      setValue('offer_inclusions', offerInclusions);
      return;
    } catch (e) {
      enqueueSnackbar(`Failed to import inclusions: ${e?.message}`, { variant: 'error' });
      return;
    }
  };

  return (
    <>
      {showE2EOfferWarning && (
        <Alert severity="error">
          <AlertTitle>This is an E2E test offer</AlertTitle>
          Changing this offer may break the E2E tests. Only do it if you know what you're doing!
        </Alert>
      )}
      <OfferTemplateModal
        show={showTemplateModal}
        hide={() => setShowTemplateModal(false)}
        templates={filteredTemplates}
        templateType={currTemplateType}
        loadTemplate={loadTemplateToEditor}
      />
      <form
        onSubmit={
          showE2EOfferWarning ? handleSubmit(onTestOfferSubmit, onSubmitError) : handleSubmit(onSubmit, onSubmitError)
        }
      >
        {isBundleAndSaveSelected && (
          <>
            <GeneralSettingsAccordion control={control} schema={schema} />
            <BundleAndSaveFields
              addOfferToBundle={addOfferToBundle}
              control={control}
              fillingPropertyName={fillingPropertyName}
              hasLocalizedDescription={hasLocalizedDescription}
              hasLocalizedName={hasLocalizedName}
              onShowTemplateModalClick={onShowTemplateModalClick}
              onFillBundlePropertyName={onFillBundlePropertyName}
              schema={schema}
              setValue={setValue}
              onAppendLPFinePrint={onAppendLPFinePrint}
              generatorContext={generatorContext}
            />
            <TagsAndMarketingAccordion control={control} schema={schema} setValue={setValue} />
            <OfferSettingsAccordion control={control} isDepositThresholdEnabled={isDepositThresholdEnabled} />
          </>
        )}
        {!isBundleAndSaveSelected && (
          <>
            <GeneralSettingsAccordion control={control} schema={schema} />
            <OfferFieldsAccordion
              control={control}
              hasLocalizedAdditionalDescription={hasLocalizedAdditionalDescription}
              hasLocalizedDescription={hasLocalizedDescription}
              hasLocalizedName={hasLocalizedName}
              isOfferTypeTour={type === OFFER_TYPE_TOUR || type === OFFER_TYPE_TOUR_V2}
              isOfferTypeRental={type === OFFER_TYPE_RENTAL}
              onShowTemplateModalClick={onShowTemplateModalClick}
              schema={schema}
              setValue={setValue}
              generatorContext={generatorContext}
              importOfferInclusions={importOfferInclusions}
              vendorId={offer.vendor_account_id}
              onAppendLPFinePrint={onAppendLPFinePrint}
            />
            <TagsAndMarketingAccordion control={control} schema={schema} setValue={setValue} />
            <OfferSettingsAccordion control={control} isDepositThresholdEnabled={isDepositThresholdEnabled} />
            {type !== OFFER_TYPE_RENTAL && (
              <FlightsAccordion control={control} isFlightsWarningEnabled={isFlightsWarningEnabled} schema={schema} />
            )}
          </>
        )}

        <Input control={control} hidden name="booking_type" />
        <Input control={control} hidden name="flexible_nights" />
        <Input control={control} hidden name="offer_inclusions_long" />
        <Input control={control} hidden name="satisfaction_survey_link" />
        <Input control={control} hidden name="vendor_booking_email" />
        <Input control={control} hidden name="what_we_like" />
        <Box className="button-container">
          <Button data-testid="edit-offer-save-btn" disabled={formState.isSubmitting} type="submit" variant="contained">
            Save
          </Button>
        </Box>
      </form>
    </>
  );
};

export default OfferForm;
