import React, { Fragment } from 'react';

import { Stack, Typography } from '@mui/material';

type Props = {
  status: string;
  bookingNumber: string;
};

export default function CruisesOrderStatus(props: Props) {
  const { status, bookingNumber } = props;

  return (
    <Stack direction="column">
      {status === 'cancelled' && (
        <Fragment>
          <Typography component="span" sx={{ textDecoration: 'line-through' }}>
            {bookingNumber}
          </Typography>
          <Typography component="span" sx={{ textDecoration: 'line-through' }}>
            {status}
          </Typography>
        </Fragment>
      )}

      {status !== 'cancelled' && (
        <Fragment>
          <Typography component="span">{bookingNumber}</Typography>
          <Typography component="span">{status}</Typography>
        </Fragment>
      )}
    </Stack>
  );
}
