import React, { useState } from 'react';

import { Box, Button, Divider } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import PageSubheader from '../../../Common/Elements/PageSubheader';
import QuickSearchToolbar from '../../../Common/Elements/QuickSearchToolbar';

const VendorsPage = (props) => {
  const [parentId, setParentId] = useState(props.parentId);
  const [properties, setProperties] = useState(props.properties);
  const [page, setPage] = useState(0);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'sfid',
      headerName: 'Vendor ID',
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'id',
      headerName: 'View',
      disableColumnMenu: true,
      sortable: false,
      minWidth: 100,
      flex: 0,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => {
        return <Button href={`/vendors/${params.row.sfid}`}>View</Button>;
      },
    },
  ];

  return (
    <Box>
      <PageSubheader title="Vendors" />
      <Divider sx={{ mb: 2, mt: 1 }} />
      <DataGrid
        className="T-vendors-chain-table"
        rows={properties || []}
        columns={columns}
        pageSizeOptions={[10]}
        paginationModel={{ pageSize: 10, page }}
        onPaginationModelChange={({ page }) => setPage(page)}
        getRowId={(row) => row.id}
        rowCount={properties.length}
        getRowHeight={() => 'auto'}
        slots={{
          pagination: GridPagination,
          toolbar: QuickSearchToolbar,
        }}
        autoHeight
        disableRowSelectionOnClick
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default VendorsPage;
