import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';
import { API } from '@luxuryescapes/lib-types';

import GridPagination from '~/components/Common/Elements/GridPagination';
import { MultiFilterState } from '~/components/Common/Forms/OrderSearchForm';

import { ITEM_TYPE_CRUISE } from '~/consts/order';

import { getPurchases } from '~/services/OrdersService';
import cruiseService, { BatchBookingsByIdsResponse } from '~/services/cruises/BookingInfoService';

import {
  customerDataFormatter,
  orderStatusFormatter,
  orderTotalFormatter,
  packageInformationFormatter,
} from '../formatters';
import cruiseOfferDetailsFormatter from '../formatters/cruiseOfferDetailsFormatter';
import orderLinkFormatter from '../formatters/orderLinkFormatter';

type OrderResponse = {
  total: number;
  result: API.Order.Order[];
};

const DEFAULT_ROWS_RESPONSE: Rows = { result: [], total: 0 };

type FetchOrderDataParams = {
  filterField: string;
  filterValue: string;
  additionalSearchParams: MultiFilterState;
  brand: App.Brands;
  customerId: string;
  sizePerPage: number;
  currentPage: number;
};

interface Props {
  page: number;
  additionalSearchParams: MultiFilterState;
  filterField: string;
  filterValue: string;
  tenant: App.Tenant;
  customerId: string;
  sizePerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const columns: GridColDef[] = [
  {
    field: 'fk_customer_id',
    headerName: 'Customer',
    sortable: false,
    flex: 3,
    renderCell: customerDataFormatter,
    display: 'flex',
  },
  {
    flex: 2,
    sortable: false,
    field: 'offer_details',
    headerName: 'Vendor/Ship Name',
    renderCell: cruiseOfferDetailsFormatter,
    display: 'flex',
  },
  {
    field: 'status',
    headerName: 'Order Status',
    flex: 2,
    sortable: false,
    renderCell: orderStatusFormatter,
    display: 'flex',
  },
  {
    field: 'vendor_booking_details',
    headerName: 'Vendor Booking',
    sortable: false,
    flex: 1,
    renderCell: packageInformationFormatter,
    display: 'flex',
  },
  { field: 'currency_code', headerName: 'Currency', flex: 1, sortable: false, display: 'flex' },
  {
    field: 'total',
    headerName: 'Order Total',
    sortable: false,
    flex: 1,
    renderCell: orderTotalFormatter,
    display: 'flex',
  },
  { field: 'utm_source', headerName: 'Source', flex: 1, sortable: false, display: 'flex' },
  { field: 'id', headerName: 'View Order', sortable: false, flex: 1, renderCell: orderLinkFormatter, display: 'flex' },
];

type Rows = {
  total: number;
  result: RowDetails[];
};

type RowDetails = (API.Order.Order & CruisesContract.BookingBatchDetailsResponse)[];

const buildRowsContent = (
  orders: API.Order.Order[],
  cruiseDetails: CruisesContract.BookingBatchDetailsResponse[],
): RowDetails[] => {
  const rows = [];

  orders.forEach((order: API.Order.Order) => {
    order.cruise_items.forEach((item) => {
      const booking = cruiseDetails.find((bookingDetails) => bookingDetails?.bookingId === item.booking_id);

      if (booking) {
        rows.push({
          ...order,
          ...booking,
        });
      } else {
        rows.push(order);
      }
    });
  });

  return rows;
};

export default function CruisesPurchaseList(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    page,
    tenant,
    filterField,
    filterValue,
    additionalSearchParams,
    customerId,
    currentPage,
    sizePerPage,
    onPageChange,
  } = props;

  const [rows, setRows] = useState<Rows>(DEFAULT_ROWS_RESPONSE);
  const [fetching, setFetching] = useState<Utils.FetchingState>('idle');

  const fetchData = useCallback(
    async (params: FetchOrderDataParams): Promise<OrderResponse> => {
      setFetching('loading');

      try {
        const fetchedPurchases = await getPurchases({
          page: params.currentPage,
          brand: params.brand,
          per_page: params.sizePerPage,
          customer_id: params.customerId,
          item_type: ITEM_TYPE_CRUISE,
          filterBy: params.filterField,
          filterValue: params.filterValue,
          additionalFilter: params.additionalSearchParams,
        });

        setFetching('success');

        if (!fetchedPurchases || fetchedPurchases.result.length === 0) {
          enqueueSnackbar('No results found', { autoHideDuration: 5000, variant: 'warning' });
        }

        return {
          total: fetchedPurchases.total,
          result: fetchedPurchases.result,
        };
      } catch (error) {
        setFetching('failed');
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });

        return { total: 0, result: [] };
      }
    },
    [enqueueSnackbar],
  );

  const fetchCruiseDetails = useCallback(
    async (orders: OrderResponse): Promise<BatchBookingsByIdsResponse> => {
      const bookingIds =
        orders.result.flatMap((order: API.Order.Order) => order.cruise_items.map((item) => item.booking_id)) ?? [];

      try {
        const response = await cruiseService.getBatchBookingsById(bookingIds);

        return {
          result: response.result,
          status: response.status,
        };
      } catch (error) {
        setFetching('failed');
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });

        return { result: [], status: 400 };
      }
    },
    [enqueueSnackbar],
  );

  const fetchDataAndBuildContent = useCallback(
    async (params: FetchOrderDataParams) => {
      const orderResponse = await fetchData(params);
      const cruiseResponse = await fetchCruiseDetails(orderResponse);
      const content = buildRowsContent(orderResponse.result, cruiseResponse.result);

      setRows({ result: content, total: orderResponse.total });
    },
    [fetchCruiseDetails, fetchData],
  );

  useEffect(() => {
    fetchDataAndBuildContent({
      customerId,
      filterField,
      filterValue,
      additionalSearchParams,
      sizePerPage,
      currentPage,
      brand: tenant.brand,
    });
  }, [
    currentPage,
    customerId,
    filterField,
    filterValue,
    additionalSearchParams,
    sizePerPage,
    tenant.brand,
    fetchDataAndBuildContent,
  ]);

  return (
    <>
      <DataGrid
        pagination
        autoHeight
        rows={rows.result}
        rowCount={rows.total}
        columns={columns}
        disableColumnMenu
        disableColumnFilter
        paginationMode="server"
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        pageSizeOptions={[sizePerPage]}
        loading={fetching === 'loading'}
        slots={{ pagination: GridPagination }}
        paginationModel={{ page: page - 1, pageSize: sizePerPage }}
        onPaginationModelChange={({ page }) => onPageChange(page + 1)}
      />
    </>
  );
}
