import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';

import { MenuItem, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import {
  createPopularDestination,
  deletePopularDestination,
  getDestinationAlertList,
} from '~/services/DestinationAlertService';

import loadScript from '~/utils/loadScript';

import AsyncAutocomplete from '../Common/Legacy/AsyncAutocomplete';

import PopularDestination from './PopularDestination';

const typesOptions = [
  {
    value: 'geocode',
    label: 'Local',
  },
  {
    value: '(regions)',
    label: 'Country/State/City',
  },
];

const GooglePlaceSearch = () => {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const autocomplete = useRef<google.maps.places.AutocompleteService>();
  const geocoder = useRef<google.maps.Geocoder>();

  const [regionInput, setRegionInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
    country: '',
    adminLevelOne: '',
    locality: '',
    sublocality: '',
    colloquialArea: '',
    lng: '',
    lat: '',
    placeId: '',
    level: '',
    isoCode: '',
  });

  const [popularList, setPopularList] = useState([]);
  const [types, setTypes] = useState('geocode');

  const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegionInput({ [name]: e.target.value });
  };

  const createDestination = async (payload) => {
    await createPopularDestination(payload);
    const response = await getDestinationAlertList(brand, regionInput.isoCode);
    setPopularList(response.result);
  };

  const deleteDestination = async (id) => {
    await deletePopularDestination(id);
    const response = await getDestinationAlertList(brand, regionInput.isoCode);
    setPopularList(response.result);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${window.configs.GOOGLE_PLACE_API_KEY}&libraries=places&language=en-AU`,
    ).then(() => {
      const googleMaps = window.google.maps;
      if (!autocomplete.current && googleMaps) {
        autocomplete.current = new googleMaps.places.AutocompleteService();
      }
      if (!geocoder.current && googleMaps) {
        geocoder.current = new googleMaps.Geocoder();
      }
    });

    getDestinationAlertList(brand, regionInput.isoCode).then((res) => setPopularList(res.result));
  }, [brand, regionInput.isoCode]);

  const getPlacePredictions = (input, callback) => {
    if (!input || !autocomplete.current) {
      return callback(null);
    }

    autocomplete.current.getPlacePredictions(
      {
        input: input,
        types: [types],
      },
      (predictions) => {
        if (!predictions) {
          return callback(null);
        }
        callback(
          predictions.map((prediction) => ({
            value: prediction.place_id,
            label: prediction.description,
            types: prediction.types,
          })),
        );
      },
    );
  };

  const callbackOnSelect = (places) => {
    const address = places[0].address_components;
    const place_id = places[0].place_id;
    const approvedLevels = ['country', 'administrative_area_level_1', 'locality', 'colloquial_area'];
    const level = approvedLevels.includes(address[0].types[0]) ? address[0].types[0] : 'locality';

    const getRegionLevelValue = (regionLevel) => address.find((item) => item.types[0] === regionLevel)?.long_name || '';
    setRegionInput({ country: getRegionLevelValue('country') });
    setRegionInput({
      adminLevelOne: getRegionLevelValue('administrative_area_level_1'),
    });
    setRegionInput({ locality: getRegionLevelValue('locality') });
    setRegionInput({ colloquialArea: getRegionLevelValue('colloquial_area') });
    setRegionInput({ sublocality: address[0].long_name });
    setRegionInput({ lng: places[0].geometry.location.toJSON().lng });
    setRegionInput({ lat: places[0].geometry.location.toJSON().lat });
    setRegionInput({ placeId: place_id });
    setRegionInput({ level });
    setRegionInput({ isoCode: places[0].address_components[0].short_name });
  };

  const onChangeWithCoords = (selected) => {
    if (selected) {
      geocoder.current.geocode({ placeId: selected.value }, callbackOnSelect);
    }
  };

  const debouncedGetPlacePredictions = debounce(getPlacePredictions, 500);
  const onSelectChange = useCallback((option) => setTypes(option), []);

  return (
    <div>
      <Grid container columnSpacing={4}>
        <Grid xs={12} lg={3}>
          <TextField
            id="type-selection"
            label="Search type"
            value={types}
            onChange={(e) => onSelectChange(e.target.value)}
            fullWidth
            select
          >
            {typesOptions.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid xs={12} lg={9}>
          <AsyncAutocomplete
            loadOptions={debouncedGetPlacePredictions}
            onChange={onChangeWithCoords}
            placeholder="Search a city, country or region"
          />
        </Grid>
      </Grid>

      <PopularDestination
        country={regionInput.country}
        adminLevelOne={regionInput.adminLevelOne}
        colloquialArea={regionInput.colloquialArea}
        locality={regionInput.locality}
        sublocality={regionInput.sublocality}
        handleChange={handleChange}
        lng={regionInput.lng}
        lat={regionInput.lat}
        onSave={createDestination}
        onRemove={deleteDestination}
        level={regionInput.level}
        placeId={regionInput.placeId}
        popularList={popularList}
      />
    </div>
  );
};

export default GooglePlaceSearch;
