import React, { Component } from 'react';

class Flag extends Component {
  render() {
    const icon = this.props.region === 'world' ? 'flag-icon-un' : 'flag-icon-' + this.props.region.toLowerCase();

    return <span className={'flag-icon ' + icon} style={this.props.style} />;
  }
}

export default Flag;
