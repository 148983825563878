const KILO = 1024;
const PREFIXED_BYTES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] as const;

/**
 * Format a number of bytes
 * @returns {string} Prefixed bytes presentaion
 * @example
 * formatByes(2048)
 * // returns "2 KB"
 */
export function formatBytes(bytes: number, decimalPoints = 2): `${number} ${(typeof PREFIXED_BYTES)[number]}` {
  if (bytes == 0) return '0 Bytes';

  const i = Math.floor(Math.log(bytes) / Math.log(KILO));

  return `${parseFloat((bytes / Math.pow(KILO, i)).toFixed(decimalPoints))} ${PREFIXED_BYTES[i]}`;
}

/**
 * Convert camel case to space-separated
 * @returns {string} Space-separated
 * @example
 * camelCaseToSpaceSeparated('camelCase')
 * // returns "camel Case"
 */
export function camelCaseToSpaceSeparated(input: string): string {
  return input?.replace(/([A-Z])/g, ' $1') ?? '';
}

/**
 * Convert string to Title Case
 * @returns {string} any text
 * @example
 * titleCase('just testing')
 * // returns "Just Testing"
 */
export function titleCase(value: string) {
  const str = value.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }

  return str.join(' ');
}

export function extractNumberFromString(id: string): number | null {
  const matches = id.match(/\d+/);
  return matches ? parseInt(matches[0], 10) : null;
}
