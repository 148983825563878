/* eslint-disable prettier/prettier */
import React from 'react';

import { useHistory } from 'react-router';

import { Alert, Box, Grid, Stack, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import { DiscountItemInfo, PromoTypesAndUnknown, getPromoProductDisplayName } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';

type PromoItemResultProps = {
  discountItem: DiscountItemInfo;
  isDevMode: boolean;
  promoType: PromoTypesAndUnknown;
  currency: string;
  meta: definitions['PromoMeta'];
};

const PromoItemResult = ({ discountItem, isDevMode, promoType, currency, meta }: PromoItemResultProps) => {
  const {
    item,
    warning,
    discountAmount,
    matchingDiscount,
    luxPlusDiscountHigher,
    doesNotQualifyWithMemberPricing,
    discountHasCombinedWithLuxPlusPricing,
  } = discountItem;
  const { poffer, reqItem } = item;
  const { discountableTotal, luxPlusPrice, offerId, itemId } = reqItem;
  const hasLuxPlusPrice = luxPlusPrice && luxPlusPrice > 0;
  const originalPrice = hasLuxPlusPrice ? luxPlusPrice : discountableTotal;

  const history = useHistory();

  const handleGridClick = (item: DiscountItemInfo) => {
    if (item.item.reqItem.categoryBK === 'subscription') return;
    history.push(`/offers/${item.item.reqItem.offerId}`);
  };

  return (
    <Box>
      <Grid
        container
        key={`${offerId}_${itemId}`}
        spacing={1}
        sx={{
          marginBottom: 1,
          border: '1px solid',
          borderColor: 'grey.400',
          borderRadius: '4px',
        }}
        title={`${JSON.stringify(discountItem, null, 4)}`}
      >
        <Grid
          item
          xs={4}
          sx={{
            padding: '4px',
            borderRight: '1px solid',
            borderColor: 'grey.400',
          }}
        >
          <Stack direction="row" gap={2}>
            <Typography sx={{ color: hasLuxPlusPrice ? 'blue' : '' }}>
              {currencyFormatter(currency, originalPrice - discountAmount)}
            </Typography>
            {discountAmount > 0 ? (
              <>
                <Typography sx={{ textDecoration: 'line-through', color: hasLuxPlusPrice ? 'blue' : '' }}>
                  {' '}
                  {currencyFormatter(currency, originalPrice)}
                </Typography>
                <Typography sx={{ color: 'green', marginLeft: 1 }}>
                  ({currencyFormatter(currency ?? 'AUD', discountAmount)})
                </Typography>
              </>
            ) : (
              <Typography>{currencyFormatter(currency, originalPrice)}</Typography>
            )}
            {isDevMode && warning && (
              <Typography variant="body2" component="div">
                {warning}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={2}>
          <Stack
            onClick={() => handleGridClick(discountItem)}
            title={JSON.stringify(
              {
                categoryBK: reqItem.categoryBK,
                subCategoryBK: reqItem.subCategoryBK,
                productBK: poffer.productBK,
                offerId: poffer.offerId,
              },
              null,
              4,
            )}
          >
            <Typography>
              {meta && getPromoProductDisplayName(meta.promo_product_types, reqItem.categoryBK, reqItem.subCategoryBK)}
            </Typography>
          </Stack>
        </Grid>

        {matchingDiscount && (
          <Grid item xs={2}>
            <details>
              <summary>
                <Typography variant="body2" component="div">
                  {promoType == 'unknown' && (
                    <>
                      <strong>Discount:</strong> {matchingDiscount.discount_value}
                    </>
                  )}
                  {promoType == 'percentage' && `${matchingDiscount.discount_value}%`}
                  {promoType == 'fixed_amount' && `${currencyFormatter(currency, matchingDiscount.discount_value)}`}
                </Typography>
                {luxPlusDiscountHigher && (
                  <Typography variant="body2" component="div">
                    <strong>luxPlusDiscountHigher:</strong>
                  </Typography>
                )}
                {discountHasCombinedWithLuxPlusPricing && (
                  <Typography variant="body2" component="div">
                    <strong>(discountHasCombinedWithLuxPlusPricing)</strong>
                  </Typography>
                )}
                {doesNotQualifyWithMemberPricing && (
                  <Typography variant="body2" component="div">
                    <strong>(doesNotQualifyWithMemberPricing)</strong>
                  </Typography>
                )}
              </summary>
              <Typography variant="body2" component="div">
                <strong>Region:</strong> {matchingDiscount.region}
              </Typography>
              <Typography variant="body2" component="div">
                <strong>Products:</strong>{' '}
                {matchingDiscount?.products && matchingDiscount?.products.length > 0 ? (
                  matchingDiscount.products.join(', ')
                ) : (
                  <Alert severity="warning">a matching discount? This is unexpected</Alert>
                )}
              </Typography>
              {matchingDiscount.min_spend && (
                <Typography variant="body2" component="div">
                  <strong>Minimum Spend:</strong> {currencyFormatter(currency, matchingDiscount.min_spend)}
                </Typography>
              )}
              {matchingDiscount.max_discount && (
                <Typography variant="body2" component="div">
                  <strong>Maximum Discount:</strong> {currencyFormatter(currency, matchingDiscount.max_discount)}
                </Typography>
              )}
              {reqItem.travellers && (
                <Typography variant="body2" component="div" title="travellers">
                  <strong>Travellers:</strong> {reqItem.travellers.firstName} {reqItem.travellers.lastName}
                </Typography>
              )}
            </details>
          </Grid>
        )}
        {matchingDiscount?.subscription_item_discount_type !== 'none' &&
          matchingDiscount?.subscription_item_discount_value > 0 && (
            <Typography variant="body2" component="div">
              <strong>Subscription Item Discount:</strong>
              {matchingDiscount.subscription_item_discount_type == 'fixed_amount'
                ? currencyFormatter(currency, matchingDiscount.subscription_item_discount_value)
                : `${matchingDiscount.subscription_item_discount_value}%`}
            </Typography>
          )}
      </Grid>
    </Box>
  );
};

export default PromoItemResult;
