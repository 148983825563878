import qs from 'qs';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';
import { API } from '@luxuryescapes/lib-types';

import { RoomTypeImage } from '~/components/Common/Forms/RoomTypeImagesForm';

import { getTimezoneOffset } from '~/services/TimeService';

import { json_headers, request } from './common';

interface ChainQueryParams {
  parent_id?: string;
  parent_name?: string;
  country?: string;
}

export default class ReservationService {
  static basePath() {
    return window.configs.API_HOST + '/api';
  }

  // Reservation
  static createReservation(object, orderId, itemId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/orders/${orderId}/items/${itemId}/reservation`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getReservationById(reservationId) {
    return request(`${this.basePath()}/hotels/reservations/${reservationId}`, {
      method: 'GET',
    });
  }

  // Properties
  static getProperties(vendorId, limit = 30, page = 1) {
    const params = {
      include_deleted: true,
      id_salesforce_external: vendorId,
      limit,
      page,
    };

    return request(`${this.basePath()}/properties?${qs.stringify(params)}`);
  }

  static getPropertiesByHotelCode(hotelCode, limit = 10, page = 1) {
    const params = {
      include_deleted: true,
      hotel_code: hotelCode,
      limit,
      page,
    };

    return request(`${this.basePath()}/properties?${qs.stringify(params)}`);
  }

  static getPropertiesByName(name, limit = 100, page = 1, includeDeleted = true) {
    const params = {
      include_deleted: includeDeleted,
      name,
      limit,
      page,
    };

    return request(`${this.basePath()}/properties?${qs.stringify(params)}`);
  }

  static getPropertiesForPartnerCentral(vendorId, limit = 10, page = 1) {
    const params = {
      include_deleted: true,
      sync_to_partner_central: true,
      id_salesforce_external: vendorId,
      limit,
      page,
    };

    return request(`${this.basePath()}/properties?${qs.stringify(params)}`, {
      method: 'GET',
      bustCache: true,
    });
  }

  static getPropertiesSchema() {
    return request(`${this.basePath()}/properties`, { method: 'OPTIONS' });
  }

  static getPropertySchema(id) {
    return request(`${this.basePath()}/properties/${id}`, {
      method: 'OPTIONS',
    });
  }

  static getPropertyTaxesAndFeesSchema(id) {
    return request(`${this.basePath()}/properties/${id}?op=taxes_and_fees`, {
      method: 'OPTIONS',
    });
  }

  static getProperty(id) {
    return request(`${this.basePath()}/properties/${id}`, {
      method: 'GET',
      bustCache: true,
    });
  }

  static getChannelPropertyData(id) {
    return request(`${this.basePath()}/properties/${id}/channel-property-data`, {
      method: 'GET',
      bustCache: true,
    });
  }

  static updateProperty(object, id) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties/${id}`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updatePropertyTaxesAndFees(object, id) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties/${id}?op=taxes_and_fees`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static createProperty(object) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deleteProperty(id) {
    return request(`${this.basePath()}/properties/${id}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  static restoreProperty(id) {
    return request(`${this.basePath()}/properties/${id}/restore`, {
      method: 'POST',
      ignoreResponse: true,
    });
  }

  static triggerHotelUpdates(id) {
    return request(`${this.basePath()}/properties/${id}/hotel-updates`, {
      method: 'POST',
    });
  }

  // Room rates
  static getRoomRates(propertyId, roomTypeId) {
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates`, {
      bustCache: true,
    });
  }

  static getRoomRateDates(propertyId, roomTypeId, roomRateId, nights = 1, numberOfAdults = 2, childrenAges = []) {
    let occupancy = `${numberOfAdults}`;
    if (childrenAges.length > 0) {
      occupancy = `${occupancy}-${childrenAges.join(',')}`;
    }
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/rates?nights=${nights}&occupancy=${occupancy}`,
    );
  }

  static getExtraGuestSurcharges(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/extra-guest-surcharges`,
      { bustCache: true },
    );
  }

  static createExtraGuestSurcharge(object, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/extra-guest-surcharges`,
      { method: 'POST', headers: json_headers, body: jsonData },
    );
  }

  static updateExtraGuestSurcharge(object, id, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/extra-guest-surcharges/${id}`,
      { method: 'PUT', headers: json_headers, body: jsonData },
    );
  }

  static deleteExtraGuestSurcharge(id, propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/extra-guest-surcharges/${id}`,
      { method: 'DELETE', ignoreResponse: true },
    );
  }

  static createRoomRate(propertyId, roomTypeId, object) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static createBatchRoomRates(propertyId, roomTypeId, object) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/batch`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deleteRoomRate(id, propertyId, roomTypeId) {
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${id}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  // Room types
  static getRoomTypesSchema(id) {
    return request(`${this.basePath()}/properties/${id}/room-types`, {
      method: 'OPTIONS',
    });
  }

  static getRoomType(propertyId, roomTypeId) {
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}`, { bustCache: true });
  }

  static createRoomType(object, propertyId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties/${propertyId}/room-types`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateRoomType(object, propertyId, roomTypeId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateRoomTypeOccupancy(object, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/occupancy`,
      { method: 'PATCH', headers: json_headers, body: jsonData },
    );
  }

  static deleteRoomType(propertyId, roomTypeId) {
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  static getPropertyReviewSchema(id) {
    return request(`${this.basePath()}/properties/${id}/reviews`, {
      method: 'OPTIONS',
    });
  }

  static createPropertyReview(object, propertyId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties/${propertyId}/reviews`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deletePropertyReview(propertyId, reviewId) {
    return request(`${this.basePath()}/properties/${propertyId}/reviews/${reviewId}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  static updatePropertyReview(object, propertyId, reviewId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties/${propertyId}/reviews/${reviewId}`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getTourReviewSchema(id) {
    return request(`${this.basePath()}/tours/${id}/reviews`, {
      method: 'OPTIONS',
    });
  }

  static createTourReview(object, tourId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/tours/${tourId}/reviews`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deleteTourReview(tourId, reviewId) {
    return request(`${this.basePath()}/tours/${tourId}/reviews/${reviewId}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  static updateTourReview(object, tourId, reviewId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/tours/${tourId}/reviews/${reviewId}`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getRoomTypeCapacities(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/capacities`,
      { method: 'GET' },
    );
  }

  static createRoomTypeCapacity(object, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/capacities`,
      { method: 'POST', headers: json_headers, body: jsonData },
    );
  }

  static updateRoomTypeCapacity(object, id, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/capacities/${id}`,
      { method: 'PATCH', headers: json_headers, body: jsonData },
    );
  }

  static deleteRoomTypeCapacity(id, propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/capacities/${id}`,
      { method: 'DELETE', ignoreResponse: true },
    );
  }

  static getRoomTypeIncludedGuests(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/included-guests`,
      { method: 'GET' },
    );
  }

  static updateRoomTypeIncludedGuests(object, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/included-guests`,
      { method: 'PATCH', headers: json_headers, body: jsonData },
    );
  }

  static createRoomTypeIncludedGuest(object, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/included-guests`,
      { method: 'POST', headers: json_headers, body: jsonData },
    );
  }

  static updateRoomTypeIncludedGuest(object, id, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/included-guests/${id}`,
      { method: 'PATCH', headers: json_headers, body: jsonData },
    );
  }

  static deleteRoomTypeIncludedGuest(id, propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/included-guests/${id}`,
      { method: 'DELETE', ignoreResponse: true },
    );
  }

  static getRatePlans(vendorId, packagedOnly?: boolean) {
    const query = [`id_salesforce_external=${vendorId}`];
    if (typeof packagedOnly !== 'undefined') {
      query.push(`packaged_only=${packagedOnly === true ? 'true' : 'false'}`);
    }
    return request(`${this.basePath()}/rate-plans?${query.join('&')}`, {
      method: 'GET',
    });
  }

  static getRatePlansGroupsSchema(vendorId) {
    return request(`${this.basePath()}/rate-plans-groups/${vendorId}`, {
      method: 'OPTIONS',
    });
  }

  static updateRatePlansGroups(vendorId, payload) {
    const jsonData = JSON.stringify(payload);
    return request(`${this.basePath()}/rate-plans-groups/${vendorId}`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getRatePlansGroups(vendorId) {
    return request(`${this.basePath()}/rate-plans-groups/${vendorId}`, {
      method: 'GET',
    });
  }

  static getRatePlansSchema() {
    return request(`${this.basePath()}/rate-plans`, { method: 'OPTIONS' });
  }

  static getRatePlan(id) {
    return request(`${this.basePath()}/rate-plans/${id}`, {
      method: 'GET',
      bustCache: true,
    });
  }

  static getRatePlanSchema(id) {
    return request(`${this.basePath()}/rate-plans/${id}`, {
      method: 'OPTIONS',
    });
  }

  static updateRatePlan(object, id) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/rate-plans/${id}`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static setDefaultRatePlan(id) {
    return request(`${this.basePath()}/rate-plans/${id}/default`, {
      method: 'PUT',
      headers: json_headers,
    });
  }

  static createRatePlan(object) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/rate-plans`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static cloneRatePlan(id) {
    return request(`${this.basePath()}/rate-plans/${id}/clone`, {
      method: 'POST',
      headers: json_headers,
    });
  }

  static deleteRatePlan(id) {
    return request(`${this.basePath()}/rate-plans/${id}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  static copyRatePlans(vendorId, fromVendorId) {
    return request(
      `${this.basePath()}/rate-plans/copy?id_salesforce_external=${vendorId}&from_id_salesforce_external=${fromVendorId}`,
      {
        method: 'POST',
        headers: json_headers,
      },
    );
  }

  static getRoomTypeImages(propertyId, roomTypeId) {
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/images`, { method: 'GET' });
  }

  static createRoomTypeImage(object, propertyId, roomTypeId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/images`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateRoomTypeImage(object, id, propertyId, roomTypeId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/images/${id}`, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateRoomTypeImages(updatePayload, propertyId, roomTypeId) {
    const jsonData = JSON.stringify(updatePayload);
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/images`, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deleteRoomTypeImage(id, propertyId, roomTypeId) {
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/images/${id}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  static replaceRoomTypeImages(images: RoomTypeImage[], propertyId, roomTypeId) {
    const jsonData = JSON.stringify(images);
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/images`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static createPropertyImages(imagesObject, propertyId) {
    const jsonData = JSON.stringify(imagesObject);
    return request(`${this.basePath()}/properties/${propertyId}/images`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updatePropertyImages(imagesObject, propertyId) {
    const jsonData = JSON.stringify(imagesObject);
    return request(`${this.basePath()}/properties/${propertyId}/images`, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getPropertyImages(propertyId) {
    return request(`${this.basePath()}/properties/${propertyId}/images`, {
      method: 'GET',
      headers: json_headers,
    });
  }

  static deletePropertyImage(propertyId, propertyImageId) {
    return request(`${this.basePath()}/properties/${propertyId}/images/${propertyImageId}`, {
      method: 'DELETE',
      headers: json_headers,
    });
  }

  static replacePropertyHighlights(highlights: Reservation.PropertyHighlight[], propertyId) {
    const jsonData = JSON.stringify(highlights);
    return request(`${this.basePath()}/properties/${propertyId}/highlights`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getAvailability(propertyId, roomTypeId, roomRateId, nights = 1) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/availability?nights=${nights}`,
      { method: 'GET' },
    );
  }

  static getAvailabilitySchema(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/availability`,
      { method: 'OPTIONS' },
    );
  }

  static patchAvailability(object, propertyId, roomTypeId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/availability`, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getBlackoutDates(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/blackout-dates`,
      { method: 'GET' },
    );
  }

  static createBlackoutDateBlock(object, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/blackout-dates`,
      { method: 'POST', headers: json_headers, body: jsonData },
    );
  }

  static deleteBlackoutDateBlock(id, propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/blackout-dates/${id}`,
      { method: 'DELETE', ignoreResponse: true },
    );
  }

  static getSurchargeDates(propertyId, roomTypeId, roomRateId, surchargeType?) {
    let url = `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/surcharge-dates`;
    if (surchargeType) {
      url += `?surcharge_type=${surchargeType}`;
    }
    return request(url, { method: 'GET' });
  }

  static getSurchargeDatesByRoomType(propertyId, roomTypeId, q = {}) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/surcharge-dates?${qs.stringify(q)}`,
      { method: 'GET' },
    );
  }

  static getSurchargeDateBlockSchema(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/surcharge-dates`,
      { method: 'OPTIONS' },
    );
  }

  static createSurchargeDateBlock(object, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/surcharge-dates`,
      { method: 'POST', headers: json_headers, body: jsonData },
    );
  }

  static updateSurchargeDateBlock(object, id, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/surcharge-dates/${id}`,
      { method: 'PATCH', headers: json_headers, body: jsonData },
    );
  }

  static deleteSurchargeDateBlock(id, propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/surcharge-dates/${id}`,
      { method: 'DELETE', ignoreResponse: true },
    );
  }

  static deleteSurchargeDateBlockByRoomRateId(propertyId, roomRateId, surchargeType) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-rates/${roomRateId}/surcharge-dates?surcharge_type=${surchargeType}`,
      {
        method: 'DELETE',
        ignoreResponse: true,
      },
    );
  }

  static deleteSurchargeDateBlockByRatePlanId(ratePlanId, surchargeType) {
    return request(`${this.basePath()}/rate-plans/${ratePlanId}/surcharge-dates?surcharge_type=${surchargeType}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  static async enquireHotelReservation(
    propertyId,
    roomTypeId,
    roomRateId,
    queryObj,
  ): Promise<API.Reservation.EnquiryRate[]> {
    const q = {
      ...queryObj,
      timezone_offset: getTimezoneOffset(),
    };

    // Remove when everything has a room rate
    if (!roomRateId) {
      return request(`${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/enquiry?${qs.stringify(q)}`);
    }

    const res: { result: API.Reservation.EnquiryRate[] } = await request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/enquiry?${qs.stringify(
        q,
      )}`,
    );

    return res.result;
  }

  // Tours
  static getTours({ page = 1, limit = 15, vendorId, retainDynamicTags = true }) {
    const queryParams = {
      page,
      limit,
      id_salesforce_external: vendorId,
      retainDynamicTags,
    };

    return request(`${this.basePath()}/tours?${qs.stringify(queryParams)}`, {
      method: 'GET',
    });
  }

  static getTourSchema() {
    return request(`${this.basePath()}/tours`, { method: 'OPTIONS' });
  }

  static getTour(id: string, retainDynamicTags = true): Promise<API.Reservation.TourResult> {
    return request(
      `${this.basePath()}/tours/${id}/?${qs.stringify({
        retainDynamicTags,
      })}`,
      {
        method: 'GET',
        bustCache: true,
      },
    );
  }

  static updateTour(object, id) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/tours/${id}`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static createTour(object) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/tours`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deleteTour(id) {
    return request(`${this.basePath()}/tours/${id}`, {
      method: 'DELETE',
      ignoreResponse: false,
    });
  }

  static async enquireTourReservation(tourId, tourOptionId, queryObj) {
    const q = {
      ...queryObj,
      timezone_offset: getTimezoneOffset(),
    };

    const res = await request(
      `${this.basePath()}/tours/${tourId}/tour-options/${tourOptionId}/enquiry?${qs.stringify(q)}`,
    );

    return res.result;
  }

  // Tour options
  static getTourOptionsSchema(id) {
    return request(`${this.basePath()}/tours/${id}/tour-options`, {
      method: 'OPTIONS',
    });
  }

  static getTourOption(tourId, tourOptionId) {
    return request(`${this.basePath()}/tours/${tourId}/tour-options/${tourOptionId}`, {
      method: 'GET',
    });
  }

  static createTourOption(object, tourId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/tours/${tourId}/tour-options`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateTourOption(object, tourId, tourOptionId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/tours/${tourId}/tour-options/${tourOptionId}`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deleteTourOption(tourId, tourOptionId) {
    return request(`${this.basePath()}/tours/${tourId}/tour-options/${tourOptionId}`, {
      method: 'DELETE',
      ignoreResponse: false,
    });
  }

  // Tour legs
  static getTourLegsSchema(id) {
    return request(`${this.basePath()}/tours/${id}/tour-legs`, {
      method: 'OPTIONS',
    });
  }

  static createTourLeg(object, tourId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/tours/${tourId}/tour-legs`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateTourLeg(object, tourId, tourOptionId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/tours/${tourId}/tour-legs/${tourOptionId}`, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deleteTourLeg(tourId, tourOptionId) {
    return request(`${this.basePath()}/tours/${tourId}/tour-legs/${tourOptionId}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  // Tour leg dates
  static getTourOptionDates(tourId, tourOptionId) {
    return request(`${this.basePath()}/tours/${tourId}/tour-options/${tourOptionId}/dates`, { method: 'GET' });
  }

  static createTourOptionDate(object, tourId, tourOptionId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/tours/${tourId}/tour-options/${tourOptionId}/dates`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static createTourOptionDates(object) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/tours/tour-options-dates`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateTourOptionDate(object, tourId, tourOptionId, tourOptionDateId) {
    const jsonData = JSON.stringify(object);
    return request(`${this.basePath()}/tours/${tourId}/tour-options/${tourOptionId}/dates/${tourOptionDateId}`, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deleteTourOptionDate(tourId, tourOptionId, tourOptionDateId) {
    return request(`${this.basePath()}/tours/${tourId}/tour-options/${tourOptionId}/dates/${tourOptionDateId}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  // Amenities
  static getAmenitiesSchema() {
    return request(`${this.basePath()}/amenities`, { method: 'OPTIONS' });
  }

  static getAmenities() {
    return request(`${this.basePath()}/amenities`, { method: 'GET' });
  }

  static getAmenity(id) {
    return request(`${this.basePath()}/amenities/${id}`, { method: 'GET' });
  }

  static searchAmenities(name, builtIn, limit, page) {
    const queryParams = {
      name,
      built_in: builtIn,
      per_page: limit,
      page,
    };

    return request(`${this.basePath()}/amenities/search?${qs.stringify(queryParams)}`);
  }

  static createAmenity(name: string, builtIn = false) {
    const jsonData = JSON.stringify({ name, built_in: builtIn.toString() });
    return request(`${this.basePath()}/amenities`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateAmenity(id, name, builtIn, groupId) {
    const jsonData = JSON.stringify({
      id,
      name,
      built_in: builtIn,
      group_id: groupId,
    });
    return request(`${this.basePath()}/amenities/${id}`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static createAmenityGroup(name: string) {
    const jsonData = JSON.stringify({ name });
    return request(`${this.basePath()}/amenity-groups`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getAmenityGroups(page = 1) {
    return request(`${this.basePath()}/amenity-groups?per_page=100&page=${page}`, { method: 'GET' });
  }

  static getAmenityGroup(id: string) {
    return request(`${this.basePath()}/amenity-groups/${id}`, { method: 'GET' });
  }

  static updateAmenityGroup(id, name, order) {
    const jsonData = JSON.stringify({ id, name, order });
    return request(`${this.basePath()}/amenity-groups/${id}`, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateAmenityGroups(groups: App.AmenityGroup[]) {
    const jsonData = JSON.stringify(groups);
    return request(`${this.basePath()}/amenity-groups`, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static removeAmenityFromGroup(amenity) {
    return this.updateAmenity(amenity.id, amenity.name, amenity.built_in, null);
  }

  static getFXRates() {
    return request(`${this.basePath()}/properties/surcharges/fx-rates`, {
      method: 'GET',
    });
  }

  static getLengthsOfStay(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/lengths-of-stay`,
      { method: 'GET' },
    );
  }

  // Room rate lead days operations
  static getRoomRateLeadDaysSchema(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/lead-days`,
      { method: 'OPTIONS' },
    );
  }

  static getRoomRateLeadDays(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/lead-days`,
      { method: 'GET' },
    );
  }

  static manageRoomRateLeadDays(object, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/lead-days`,
      { method: 'PATCH', headers: json_headers, body: jsonData },
    );
  }

  // Room rate inclusion operations
  static getRoomRateInclusionsSchema(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/inclusions`,
      { method: 'OPTIONS' },
    );
  }

  static getRoomRateInclusions(propertyId, roomTypeId, roomRateId) {
    const queryParams = {
      showLuxPlusInclusions: true,
    };

    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/inclusions?${qs.stringify(
        queryParams,
      )}`,
      { method: 'GET' },
    );
  }

  static createRoomRateInclusion(object, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/inclusions`,
      { method: 'POST', headers: json_headers, body: jsonData },
    );
  }

  static createOperationInBulk(payload, propertyId, roomTypeId, roomRateId, operation: string) {
    const jsonData = JSON.stringify(payload);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/copy/bulk?op=${operation}`,
      { method: 'POST', headers: json_headers, body: jsonData },
    );
  }

  static patchRoomRateInclusionHideValue(propertyId, roomTypeId, roomRateId, flag) {
    const jsonData = JSON.stringify({
      hide_value: flag,
    });
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/inclusions/hide-value`,
      { method: 'PATCH', headers: json_headers, body: jsonData },
    );
  }

  static updateRoomRateInclusion(object, id, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(object);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/inclusions/${id}`,
      { method: 'PATCH', headers: json_headers, body: jsonData },
    );
  }

  static updateRoomRateInclusions(payload, propertyId, roomTypeId, roomRateId) {
    const jsonData = JSON.stringify(payload);
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/inclusions/order`,
      { method: 'PATCH', headers: json_headers, body: jsonData },
    );
  }

  static deleteRoomRateInclusion(id, propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/inclusions/${id}`,
      { method: 'DELETE', ignoreResponse: true },
    );
  }

  static deleteRoomRateInclusionsBulk(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/inclusions/bulk`,
      { method: 'DELETE', headers: json_headers },
    );
  }

  static getAriLogs(propertyId, roomTypeId, roomRateId) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/ari-logs`,
      { method: 'GET' },
    );
  }

  static getAriLogsByDate(propertyId, roomTypeId, roomRateId, date) {
    return request(
      `${this.basePath()}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}/ari-logs/${date}`,
      { method: 'GET' },
    );
  }

  static triggerSnapshot(propertyId) {
    return request(`${this.basePath()}/properties/${propertyId}/snapshot`, {
      ignoreResponse: true,
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify({}),
    });
  }

  static importPropertiesData(propertyId, data) {
    return request(`${this.basePath()}/properties/${propertyId}/import`, {
      method: 'POST',
      body: data,
    });
  }

  static importProperties(data) {
    return request(`${this.basePath()}/properties/import`, {
      method: 'POST',
      body: data,
    });
  }

  static getVirtualCreditCardsHistory(reservationId) {
    return request(`${this.basePath()}/hotels/reservations/${reservationId}/vcc-history`, { method: 'GET' });
  }

  static resendVCCConfirmation(reservationId) {
    return request(`${this.basePath()}/hotels/reservations/${reservationId}/vcc-resend`, {
      method: 'POST',
      headers: json_headers,
    });
  }

  static getPropertiesByChainId(parentId: string, country: Array<string>) {
    const queryParams: ChainQueryParams = {
      parent_id: parentId,
    };

    if (country?.length !== 0) {
      queryParams.country = country?.toString();
    }

    return request(`${this.basePath()}/properties/chains?${qs.stringify(queryParams)}`, {
      method: 'GET',
    });
  }

  static getVendorsFromChainNameSearch(parentName: string) {
    const queryParams: ChainQueryParams = {
      parent_name: parentName,
    };

    return request(`${this.basePath()}/vendor/chain?${qs.stringify(queryParams)}`, {
      method: 'GET',
    });
  }

  static getChainSettings(sfid: string) {
    return request(`${this.basePath()}/properties/chains/settings/${sfid}`, {
      method: 'GET',
    });
  }

  static editChainSettingsById(data) {
    const jsonData = JSON.stringify(data);
    return request(`${this.basePath()}/properties/chains/settings/${data.sfid}`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getSalesforceSurcharges(offerId) {
    return request(`${this.basePath()}/hotels/salesforce-surcharges/offer/${offerId}`, { method: 'GET' });
  }

  static surchargeRangeOverlapCheck(data) {
    const jsonData = JSON.stringify(data);
    return request(`${this.basePath()}/properties/${data.propertyId}/surcharge-range-check`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getMetricAlertsByName(channel, date, metricName, offset, limit) {
    return request(
      `${this.basePath()}/properties/metrics/date/${date}/name/${metricName}?channel=${channel}&offset=${offset}&limit=${limit}`,
      {
        method: 'GET',
      },
    );
  }

  static getInclusionsCategories() {
    return request(`${this.basePath()}/reservations/inclusions-catalogue/categories`, {
      method: 'GET',
    });
  }

  static createImportNewRental(
    externalId: string,
    salesforceVendorId: string,
    salesforceOfferId: string,
    transactionId: string,
  ) {
    return request(`${this.basePath()}/properties/connections/rentalsunited/import`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify({ externalId, salesforceVendorId, offerId: salesforceOfferId, transactionId }),
    });
  }

  static getRentalImportStatus(transactionId: string) {
    return request(`${this.basePath()}/properties/connections/rentalsunited/import/${transactionId}/status`, {
      method: 'GET',
    });
  }

  static async getFailedReservations() {
    const resp: any = await request(`${this.basePath()}/reservations/jobs/api/queues?page=1&jobsPerPage=10`, {
      method: 'GET',
    });
    const failedQueues = Object.values(resp.queues)
      .filter((queue: any) => queue.counts.failed > 0 && queue.name.endsWith('_res')) // only failed queues of reservation jobs
      .map((queue: any) => queue.name);

    const failedReservations = await Promise.all(
      failedQueues.map(async (queue) => {
        // even though we specify active queue all returned
        const resp: any = await request(
          `${this.basePath()}/reservations/jobs/api/queues?activeQueue=${queue}&status=failed&page=1&jobsPerPage=50`,
          {
            method: 'GET',
          },
        );
        return resp.queues.find((q: any) => q.name === queue);
      }),
    );

    return failedReservations.flatMap((res: any) => res.jobs);
  }

  static getReservationLogs(reservationId: any) {
    return request(`${window.configs.API_HOST}/channels/log-by-reservation/${reservationId}`, {
      method: 'GET',
      headers: json_headers,
    });
  }

  static getReservationRequests(reservationId: any) {
    return request(`${window.configs.API_HOST}/channels/log-by-reservation/${reservationId}/requests`, {
      method: 'GET',
      headers: json_headers,
    });
  }
  static getReservationResponses(reservationId: any) {
    return request(`${window.configs.API_HOST}/channels/log-by-reservation/${reservationId}/responses`, {
      method: 'GET',
      headers: json_headers,
    });
  }

  static createPropertyMeta(propertyId: string, payload: any) {
    return request(`${this.basePath()}/properties/${propertyId}/meta-content`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static updatePropertyMeta(propertyId: string, payload: any) {
    return request(`${this.basePath()}/properties/${propertyId}/meta-content`, {
      method: 'PATCH',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static getPropertyMeta(propertyId: string) {
    return request(`${this.basePath()}/properties/${propertyId}/meta-content`, {
      method: 'GET',
    });
  }

  static clearFailedJob(channelManager: string, reservationId: string, actionName: string, resolution: string) {
    return request(
      `${this.basePath()}/reservations/jobs/api/queues/${channelManager}_res/${reservationId}-${actionName}/clean?resolution=${resolution}`,
      {
        method: 'PUT',
      },
    );
  }

  static getRoomTypesGroupsSchema(propertyId) {
    return request(`${this.basePath()}/properties/${propertyId}/room-types-groups`, {
      method: 'OPTIONS',
    });
  }

  static updateRoomTypesGroups(propertyId, payload) {
    const jsonData = JSON.stringify(payload);
    return request(`${this.basePath()}/properties/${propertyId}/room-types-groups`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getRoomTypesGroups(propertyId) {
    return request(`${this.basePath()}/properties/${propertyId}/room-types-groups`, {
      method: 'GET',
    });
  }
}
