import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { getUser } from '~/services/AgentHub/AgentService';

interface Props {
  id: string;
}

export default function UserAgencyDetails(props: Props) {
  const { id } = props;

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const fetchUserData = useCallback(
    async (id: string) => {
      try {
        setLoading(true);

        const user = await getUser(id);

        setUser(user);
      } catch (err) {
        enqueueSnackbar('Failed to load data', {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    fetchUserData(id);
  }, [fetchUserData, id]);

  const urlBuilder = () => {
    return `/users-list/agencies/${user.agencyId}`;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {user && (
        <Stack>
          <Typography>
            Agency Name: <br />
            <b>
              <Link target="_blank" rel="noreferrer" to={urlBuilder()}>
                {user.agencyName}
              </Link>
            </b>
          </Typography>
          <Typography mt={2}>
            Affiliation: <br />
            <b>{user.affiliation}</b>
          </Typography>
        </Stack>
      )}
    </>
  );
}
