import React from 'react';

import { Helmet } from 'react-helmet';

import { Box, Container, Stack } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import LedOffersService from '~/services/LedOffersService';
import OffersService from '~/services/OffersService';
import { getAddonPerformance, getOfferPerformanceForFinance } from '~/services/ReportingService';
import VendorsService from '~/services/VendorsService';

import { reportError } from '~/utils/reportError';

import ErrorPaymentsPane from './ErrorPaymentsPane';
import OverviewPane from './OverviewPane';
import VendorBookingPaymentsPane from './VendorBookingPaymentsPane';
import VendorOfferPaymentsPane from './VendorOfferPaymentsPane';
import VendorReport from './VendorReport';

export default class FinanceOfferDetailContainer extends React.Component {
  state = {
    offer_result: null,
    isLedExperience: false,
    vendor_offer_payments: null,
    offer_performance: null,
    error_payments: null,
    total_error_payments: {
      amount: 0,
      currency: 'AUD',
    },
    is_loading: true,
    error: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const offer_id = this.props.match.params.id_offer;

    this.setState({ is_loading: true, error: null });

    try {
      // Register LED offer query with index 1
      const offerData = await Promise.allSettled([
        OffersService.getOffer(offer_id, { filter: 'finance' }),
        LedOffersService.getOffer(offer_id),
      ]);

      const fulfilledPromiseResponse = offerData.find((d) => d.status === 'fulfilled');
      const offer_result = fulfilledPromiseResponse?.value;

      if (!offer_result) {
        throw new Error(`Offer ${offer_id} not found.`);
      }
      // Settled promise resolves in order of the promises registered
      // so an index of 1 here refers to LED experience/offer
      // Sadly, LED offers do not have a type set on them which would have
      // been the right way to figure this.
      const isLedExperience = offerData[1].status === 'fulfilled';
      const isAddon = offer_result.result.type === 'add-on';

      const getPerformance = isAddon ? getAddonPerformance : getOfferPerformanceForFinance;

      const data_results = await Promise.all([
        VendorsService.getPaymentsByOfferVendor(offer_id, offer_result.result.vendor_account_id),
        getPerformance(offer_id),
        VendorsService.getAllErrorPayments({ offerId: offer_id }),
      ]);

      this.setState({
        is_loading: false,
        isLedExperience,
        offer_result: offer_result,
        vendor_offer_payments: data_results[0],
        offer_performance: isAddon ? data_results[1].performance : data_results[1].result,
        error_payments: data_results[2].result,
        total_error_payments: this.totalErrorPayments(data_results[2].result),
      });
    } catch (error) {
      this.setState({ is_loading: false, error: error });
    }
  };

  totalErrorPayments = (error_payments) => {
    const errorPayments = error_payments ?? [];

    return {
      amount: errorPayments.reduce((acc, payment) => acc + parseFloat(payment.amount), 0),
      currency: errorPayments[0]?.currency ?? 'AUD',
    };
  };

  handleErrorPaymentAdded = (errorPayment) => {
    this.setState((prevState) => ({
      error_payments: [errorPayment, ...prevState.error_payments],
      total_error_payments: {
        ...prevState.total_error_payments,
        amount: prevState.total_error_payments.amount + parseFloat(errorPayment.amount),
      },
    }));
  };

  handleErrorPaymentUpdated = (errorPayment) => {
    this.setState((prevState) => {
      const updatedErrorPayments = prevState.error_payments.map((payment) =>
        payment.id_adjustment === errorPayment.id_adjustment ? errorPayment : payment,
      );

      return {
        error_payments: updatedErrorPayments,
        total_error_payments: this.totalErrorPayments(updatedErrorPayments),
      };
    });
  };

  handleErrorPaymentDeleted = (id) => {
    const error_payments = this.state.error_payments.filter((payment) => payment.id_adjustment !== id);
    this.setState({
      error_payments,
      total_error_payments: this.totalErrorPayments(error_payments),
    });
  };

  render() {
    const { match } = this.props;
    const {
      is_loading,
      error,
      offer_result,
      vendor_offer_payments,
      offer_performance,
      error_payments,
      total_error_payments,
      isLedExperience,
    } = this.state;

    if (is_loading) {
      return <Spinner />;
    }

    if (error) {
      return <ErrorDisplay message={error.message} report={reportError(error)} />;
    }

    return (
      <>
        <Helmet>
          <title>Finance | {offer_result?.result?.name || match.params.id_offer}</title>
        </Helmet>
        <Container maxWidth="xl">
          <PageHeader title={`Finance | ${offer_result?.result?.name || match.params.id_offer}`} />

          <Box mt={4}>
            <Stack direction="column" spacing={4}>
              <OverviewPane
                offer={offer_result}
                offerPayments={vendor_offer_payments}
                offerPerformance={offer_performance}
                totalErrorPayments={total_error_payments}
                isLedExperience={isLedExperience}
              />
              <ErrorPaymentsPane
                offerId={match.params.id_offer}
                vendorId={offer_result.result.vendor_account_id}
                errorPayments={error_payments}
                totalErrorPayments={total_error_payments}
                onErrorPaymentAdded={this.handleErrorPaymentAdded}
                onErrorPaymentUpdated={this.handleErrorPaymentUpdated}
                onErrorPaymentDeleted={this.handleErrorPaymentDeleted}
              />
              <div className="T-Offer-Payments">
                <VendorOfferPaymentsPane
                  idOffer={match.params.id_offer}
                  idVendor={offer_result.result.vendor_account_id}
                  data={vendor_offer_payments}
                />
              </div>
              <div className="T-Booking-Payments">
                <VendorBookingPaymentsPane
                  id_offer={match.params.id_offer}
                  id_vendor={offer_result.result.vendor_account_id}
                />
              </div>
              <div>
                <VendorReport offerId={match.params.id_offer} />
              </div>
            </Stack>
          </Box>
        </Container>
      </>
    );
  }
}
