import React from 'react';

import { Typography } from '@mui/material';

import { formattedShortDate } from '@luxuryescapes/lib-refunds/build/lib/formatDate';

import { formatDateLongMonthWithMeridiem } from '~/services/TimeService';

const dateFormatterDetailed = (date: string) =>
  date && (
    <Typography title={formatDateLongMonthWithMeridiem(new Date(date))}>
      {formattedShortDate(new Date(date))}
    </Typography>
  );

export default dateFormatterDetailed;
