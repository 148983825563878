import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Container, Paper, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

import LuxMomentsPane from '~/components/Offers/Detail/LuxMomentsPane';

import { HOTEL_OFFER_TYPES, OFFER_TYPE_HOTEL, OFFER_TYPE_RENTAL, OFFER_TYPE_TOUR } from '~/consts/offerTypes';

import { getTravellerDetailRequirements } from '~/services/TravellerService';

import OffersService from '../../../services/OffersService';
import ReservationService from '../../../services/ReservationService';
import allObjectPropertiesEmpty from '../../../utils/allObjectPropertiesEmpty';
import ErrorDisplay from '../../Common/ErrorDisplay';
import Spinner from '../../Common/Spinner';

import AddonsPane from './AddonsPane';
import BadgesPane from './BadgesPane';
import ExperiencesPane from './ExperiencesPane';
import HoverNav from './HoverNav';
import ImagesPane from './ImagesPane';
import OverviewPane from './OverviewPane';
import PackagesPane from './PackagesPane';
import PanelImagePane from './PanelImagePane';
import RoomPolicyPane from './RoomPolicyPane';
import SchedulePane from './SchedulePane';
import SearchPane from './SearchPane';
import TimelinePane from './TimelinePane';
import TravellerDetailRequirementsPane from './TravellerDetailRequirementsPane';
import VideoPane from './VideoPane';
import OfferHealthStatus from './offer-doctor/OfferHealthStatus';

function getUniquePropertyIds(packages, idField) {
  return packages
    .map((pkg) => pkg[idField])
    .filter((pkgId, index, ids) => pkgId && typeof pkgId === 'string' && ids.indexOf(pkgId) === index);
}

export default function OfferDetailContainer() {
  const [fetching, setFetching] = useState(true);
  const [offerResult, setOfferResult] = useState(null);
  const [propertiesMissingGeodata, setPropertiesMissingGeodata] = useState([]);
  const [toursMissingGeodata, setToursMissingGeodata] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [propertyIds, setPropertyIds] = useState<Array<string>>([]);
  const [travellerDetailRequirements, setTravellerDetailRequirements] = useState(null);
  const [error, setError] = useState(null);

  const { id_offer: offerId } = useParams<{
    id_offer: string;
  }>();

  useEffect(() => {
    getData(offerId);
  }, [offerId]);

  const getData = async (offerId) => {
    setFetching(true);
    setError(null);
    try {
      const offerResult = await OffersService.getOffer(offerId);
      const offer = offerResult.result;
      const isHotelOffer = HOTEL_OFFER_TYPES.includes(offer.type);
      const uniqueIds = getUniquePropertyIds(offer.packages, isHotelOffer ? 'fk_property_id' : 'fk_tour_id');
      const propertyData = [];
      const missingGeodata = [];
      let travellerDetailRequirementsResult = null;

      if (isHotelOffer) {
        for (const propertyId of uniqueIds) {
          const propertyResult = await ReservationService.getProperty(propertyId);
          propertyData.push(propertyResult.result);
          if (offer.type === OFFER_TYPE_HOTEL && allObjectPropertiesEmpty(propertyResult.result.geo_data)) {
            missingGeodata.push(propertyId);
          }
        }
      } else {
        for (const tourId of uniqueIds) {
          try {
            const tourResult = await ReservationService.getTour(tourId);
            if (allObjectPropertiesEmpty(tourResult.result.geo_data)) {
              missingGeodata.push(tourId);
            }
          } catch (e) {
            console.warn(`Error of handling tour ${tourId} geodata`, e);
          }
        }

        const { status, result } = await getTravellerDetailRequirements(offer.id_salesforce_external);

        if (status === 200) {
          travellerDetailRequirementsResult = result;
        }
      }

      setOfferResult(offerResult);
      setPropertiesMissingGeodata(isHotelOffer ? missingGeodata : []);
      setToursMissingGeodata(!isHotelOffer ? missingGeodata : []);
      setPropertyIds(uniqueIds);
      setPropertyData(propertyData);
      setTravellerDetailRequirements(travellerDetailRequirementsResult);
      setFetching(false);
    } catch (err) {
      setError(err.message);
      setFetching(false);
    }
  };

  if (fetching) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  return (
    <div className="offer-deal with-side-bar">
      <Helmet>
        <title>Offers | {offerResult.result.name}</title>
      </Helmet>
      <div className="side-bar">
        <HoverNav offerType={offerResult.result.type} />
      </div>
      <Container maxWidth="xl">
        <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="overview" className="branded-panel">
          <OverviewPane
            offer={offerResult}
            propertiesMissingGeodata={propertiesMissingGeodata}
            toursMissingGeodata={toursMissingGeodata}
            propertyData={propertyData}
          />
        </Paper>
        {/*
          <h1>Vendor Details</h1>
          FIXME: API not built ye
          <hr/>
        */}
        <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="health" className="branded-panel">
          <OfferHealthStatus offer={offerResult?.result} property={propertyData[0]} />
        </Paper>
        <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="schedule" className="branded-panel">
          <SchedulePane offer={offerResult} schedules={offerResult.result.brand_schedules} />
        </Paper>
        <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="timeline" className="branded-panel">
          <Typography component="h1" variant="h3" color={[red[600]]} sx={{ fontWeight: 300, pb: 1 }}>
            Timeline
          </Typography>
          <Accordion variant="outlined">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              Timeline
            </AccordionSummary>
            <AccordionDetails>
              <TimelinePane schedules={offerResult.result.brand_schedules} offer={offerResult} />
            </AccordionDetails>
          </Accordion>
        </Paper>
        {/*
          FIXME: API not stable yet
          <hr/>
        */}
        <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="packages" className="branded-panel">
          <PackagesPane offer={offerResult} propertyData={propertyData} />
        </Paper>
        {offerResult.result.type !== OFFER_TYPE_RENTAL && HOTEL_OFFER_TYPES.includes(offerResult.result.type) && (
          <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="roomPolicies" className="branded-panel">
            <RoomPolicyPane offer={offerResult} propertyData={propertyData} />
          </Paper>
        )}
        {offerResult.result.type !== OFFER_TYPE_RENTAL && (
          <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="images" className="branded-panel">
            <h1 className="page-header">Images &amp; Videos</h1>
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                Offer Gallery
              </AccordionSummary>
              <AccordionDetails>
                <ImagesPane offer={offerResult} />
              </AccordionDetails>
            </Accordion>
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                Panel Images
              </AccordionSummary>
              <AccordionDetails>
                <PanelImagePane offer={offerResult} />
              </AccordionDetails>
            </Accordion>
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                Video
              </AccordionSummary>
              <AccordionDetails>
                <VideoPane offer={offerResult} />
              </AccordionDetails>
            </Accordion>
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
                Badges
              </AccordionSummary>
              <AccordionDetails>
                <BadgesPane offer={offerResult} />
              </AccordionDetails>
            </Accordion>
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header">
                Lux Moments
              </AccordionSummary>
              <AccordionDetails>
                <LuxMomentsPane offer={offerResult.result} />
              </AccordionDetails>
            </Accordion>
          </Paper>
        )}
        {offerResult.result.type !== OFFER_TYPE_RENTAL && (
          <>
            <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="addons" className="branded-panel">
              <AddonsPane offer={offerResult} />
            </Paper>
          </>
        )}
        {offerResult?.result.type === OFFER_TYPE_TOUR && (
          <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="travellerDetailRequirements" className="branded-panel">
            <TravellerDetailRequirementsPane
              offerId={offerResult.result.id_salesforce_external}
              travellerDetailRequirements={travellerDetailRequirements}
            />
          </Paper>
        )}
        {offerResult?.result.type !== OFFER_TYPE_TOUR && (
          <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="experiences" className="branded-panel">
            <ExperiencesPane offer={offerResult.result} />
          </Paper>
        )}
        <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="searchOrdering" className="branded-panel">
          <SearchPane
            offerId={offerResult.result.id_salesforce_external}
            propertyIds={propertyIds}
            propertyName={propertyData[0]?.name}
          />
        </Paper>
        <Paper sx={{ my: 2, p: 2 }} variant="outlined" id="searchOrdering" className="branded-panel"></Paper>
      </Container>
    </div>
  );
}
