import React from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Container, Tab, Tabs } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import APReportsTab from './APReportsPage';
import BankFilesTab from './BankFilesPage';
import BedBankTab from './BedBankPage';
import BusinessReportsTab from './BusinessReportsPage';
import CarHireTab from './CarHirePage';
import CruisesTab from './CruisePage';
import FxRatesTab from './FxRates/FxRatesPage';
import MonthReportsTab from './MonthReportsContainer';
import MusementTab from './MusementPage';
import OfferSearchTab from './OffersSearch';
import OthersTab from './Other/OtherPageContainer';
import ToursTab from './TourPage';
import VendorPaymentsTab from './VendorPayments/VendorPaymentsPage';

const TabsMap = {
  'offer-search': {
    label: 'Offer Search',
    slug: 'offer-search',
    component: OfferSearchTab,
  },
  'business-reports': {
    label: 'Business Reports',
    slug: 'business-reports',
    component: BusinessReportsTab,
  },
  'ap-reports': {
    label: 'AP Reports',
    slug: 'ap-reports',
    component: APReportsTab,
  },
  'month-end-reports': {
    label: 'Month End Reports',
    slug: 'month-end-reports',
    component: MonthReportsTab,
  },
  'vendor-payments': {
    label: 'Vendor Payments',
    slug: 'vendor-payments',
    component: VendorPaymentsTab,
  },
  'bank-files': {
    label: 'Bank Files',
    slug: 'bank-files',
    component: BankFilesTab,
  },
  'fx-rates': {
    label: 'FX Rates',
    slug: 'fx-rates',
    component: FxRatesTab,
  },
  bedbank: {
    label: 'BedBank',
    slug: 'bedbank',
    component: BedBankTab,
  },
  musement: {
    label: 'Musement',
    slug: 'musement',
    component: MusementTab,
  },
  tours: {
    label: 'Tours',
    slug: 'tours',
    component: ToursTab,
  },
  cruises: {
    slug: 'cruises',
    label: 'Cruises',
    component: CruisesTab,
  },
  carHire: {
    slug: 'car-hire',
    label: 'Car Hire',
    component: CarHireTab,
  },
  other: {
    label: 'Other',
    slug: 'other',
    component: OthersTab,
  },
} as const;

type TabKey = keyof typeof TabsMap;

const allTabs = Object.keys(TabsMap);

export default function FinancePageContainer() {
  const { section = 'offer-search' } = useParams<{ section?: TabKey }>();

  const currentTab = TabsMap[section];

  const title = `Finance / ${currentTab.label}`;

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader title={title} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={section} variant="scrollable" scrollButtons="auto">
          {allTabs.map((tab) => (
            <Tab key={tab} value={tab} label={TabsMap[tab].label} to={`/finance/${tab}`} component={Link} />
          ))}
        </Tabs>
      </Box>

      <Box mt={4}>
        <Box component={currentTab.component} />
      </Box>
    </Container>
  );
}
