import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Box, Button, Dialog, DialogContent, MenuItem, TextField } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { PROPERTY_CHANNEL_MANAGERS } from '~/consts/reservation';

import OffersService from '~/services/OffersService';
import ReservationService from '~/services/ReservationService';

const uiSchema = {
  vendorId: { 'ui:widget': 'hidden' },
  offerId: { 'ui:widget': 'hidden' },
};

const schema: RJSFSchema = {
  title: 'Data file upload',
  type: 'object',
  required: ['hotelCode', 'channelManager'],
  properties: {
    hotelCode: { type: 'string', title: 'Hotel Code' },
    supplierId: { type: 'string', title: 'Supplier ID' },
    channelManager: {
      type: 'string',
      title: 'Channel Manager',
      enum: Object.values(PROPERTY_CHANNEL_MANAGERS),
    },
    vendorId: { type: 'string' },
    generatePackages: {
      type: 'boolean',
      title: 'create packages through upload',
    },
    offerId: { type: 'string', title: 'Offer ID' },
  },
};

interface Props {
  vendorId: string;
}

interface OfferOption {
  label: string;
  value: string;
  disabled?: boolean;
}

const fetchAllOffers = async (vendorId: string) => {
  let page = 1,
    offers = [],
    lastPage = null,
    attempts = 0;
  const limit = 100;
  const getOffers = async (page: number) => {
    return OffersService.getOffers({
      page,
      limit,
      queryString: vendorId,
      orderByScheduleDate: 1,
    });
  };

  do {
    lastPage = await getOffers(page);
    offers = [...offers, ...lastPage.result];
    page++;
    attempts++;
  } while (lastPage.result.length !== 0 && attempts < 20);

  return offers;
};

const PropertyUploadModal = (props: Props): ReactElement => {
  const { vendorId } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(false);
  const [allOffersIds, setAllOffersIds] = useState<OfferOption[]>();
  const [offersLoaded, setOffersLoaded] = useState<boolean>(false);
  const [selectedOffer, setSelectedOffer] = useState<string>('');
  const [packagesCreated, setPackagesCreated] = useState<number>(0);
  const [file, setFile] = useState<File>();

  const [formData, setFormData] = useState({
    channelManager: '',
    hotelCode: '',
    supplierId: '',
    generatePackages: false,
    offerId: '',
  });

  useEffect(() => {
    fetchAllOffers(vendorId)
      .then((data) => {
        const fetchedOffers = (data || []).map((offer) => ({
          value: offer.id_salesforce_external,
          label: `${offer.name}${offer.packages_count !== 0 ? ' [This offer already has packages]' : ''}`,
          disabled: offer.packages_count !== 0, // this means salesforce managing creation of packages for this offer
        }));

        setAllOffersIds([...fetchedOffers]);

        if (allOffersIds && allOffersIds.length === 1 && allOffersIds[0].disabled === false) {
          setFormData({ ...formData, offerId: allOffersIds[0].value });
          setSelectedOffer(allOffersIds[0].value);
        } else {
          const possibleSelections = allOffersIds?.filter((o) => o.disabled === false);
          if (possibleSelections?.length === 1) {
            setFormData({ ...formData, offerId: possibleSelections[0].value });
            setSelectedOffer(possibleSelections[0].value);
          }
        }

        setOffersLoaded(true);
      })
      .catch((err) => {
        setError('Fetching offers failed: ' + err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormChange = (e: IChangeEvent) => {
    setFormData(e.formData);
  };

  const handleOfferChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOffer(e.target.value);
    setFormData({ ...formData, offerId: e.target.value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files[0]);
  };

  const triggerValidation = useCallback(
    async (fileState, formState) => {
      if (!fileState) {
        setError('Please select file first!');
        return;
      }

      if (formState.generatePackages && !formState.offerId) {
        setError('To generate packages please select an offer first');
        return;
      }
      const selectedOffer = formState.offerId;

      setInProgress(true);
      setError(null);
      setShowConfirmationMessage(false);

      try {
        const data = new FormData();
        data.append('vendorId', vendorId);
        data.append('file', fileState);
        data.append('channelManager', formState.channelManager);
        data.append('hotelCode', formState.hotelCode);
        data.append('supplierId', formState.supplierId);

        const res = await ReservationService.importProperties(data);
        //Call offer-service for packages creation
        const property_id = res?.result?.property_id;
        if (formState.generatePackages && selectedOffer && property_id) {
          const {
            result: { totals },
          } = await OffersService.createOfferPackages({
            property_id,
            offer_id_salesforce_external: selectedOffer,
          });
          setPackagesCreated(totals?.packages || 0);
        }
        setShowConfirmationMessage(true);
      } catch (e) {
        if (e.errors) {
          setError(e.errors[0].message);
        } else {
          setError(e.message);
        }
      } finally {
        setInProgress(false);
      }
    },
    [vendorId],
  );

  const handleFormSubmit = async () => {
    await triggerValidation(file, formData);
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)} type="button" variant="contained">
        + New from Data Upload
      </Button>

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent>
          {!inProgress && (
            <Form
              validator={validator}
              schema={schema}
              uiSchema={uiSchema}
              formData={formData}
              onSubmit={handleFormSubmit}
              onChange={handleFormChange}
            >
              {formData?.generatePackages && offersLoaded && allOffersIds?.length > 0 && (
                <Box>
                  <TextField
                    label="Offer ID"
                    placeholder="Offer ID to attach packages to"
                    value={selectedOffer}
                    onChange={handleOfferChange}
                    name="offerId"
                    select
                    fullWidth
                  >
                    <MenuItem value="">Select offer</MenuItem>
                    {allOffersIds?.map((offer) => (
                      <MenuItem key={offer.value} value={offer.value} disabled={offer.disabled}>
                        {offer.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              )}

              {formData?.generatePackages && !offersLoaded && <Spinner />}

              <Box mt={2}>
                <TextField
                  label="File"
                  type="file"
                  onChange={handleFileChange}
                  inputProps={{
                    accept:
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                  }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                />
              </Box>

              <Box mt={2}>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Box>
            </Form>
          )}

          <Box>
            {error && <ErrorDisplay message={error} />}

            {showConfirmationMessage && (
              <Alert severity="success">
                <strong>Success!</strong>{' '}
                {formData?.generatePackages ? `${packagesCreated} Packages created` : 'Imported'}
              </Alert>
            )}

            {inProgress && <Spinner />}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PropertyUploadModal;
