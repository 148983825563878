import {
  CREATE_TYPE_EXPIRED_BNBL_PACKAGE,
  CREDIT_TYPE_CRUISE_OFFER_PACKAGE_CREDIT,
  CREDIT_TYPE_STORE_CREDIT,
} from '~/consts/credits';

import { json_headers, request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/';
}

function delRequest(path) {
  return request(path, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
}

function postRequest(path, data) {
  return request(path, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

export function createPayPalTransaction(data) {
  return postRequest(basePath() + 'braintree/checkout', data);
}

export async function createStripeTransaction(data) {
  return postRequest(basePath() + 'stripe/checkout', data);
}

export async function createPendingLatitudeTransaction(data) {
  return postRequest(basePath() + 'latitude/checkout/initiate', data);
}

export async function deleteAccountingMeta(order_id, meta_id) {
  return delRequest(basePath() + 'refund_meta/' + order_id + '/meta/' + meta_id);
}

export async function createLeCreditTransaction(data) {
  return postRequest(basePath() + 'buy_with_credit/checkout', data);
}

export async function createPromoTransaction(data) {
  return postRequest(basePath() + 'promo/checkout', data);
}

export async function getPayPalCredentials() {
  let response = await request(basePath() + 'braintree/credentials');
  return response;
}

export function getStripeCredentials() {
  return request(basePath() + 'stripe/credentials');
}

export function getLatitudeCredentials() {
  return request(basePath() + 'latitude/credentials');
}

export function getStripeReconciliation() {
  return request(basePath() + 'reconcile/stripe');
}

export function getStripeReconciliationOrderId() {
  return request(basePath() + 'reconcile/stripe/orderids');
}

export function getStripeReconciliationCurrencies() {
  return request(basePath() + 'reconcile/stripe/currencies');
}

export function getStripeReconciliationAmounts() {
  return request(basePath() + 'reconcile/stripe/amounts');
}

export function healthCheck() {
  return request(basePath() + 'payment/health');
}

export function getCreditTypes(id_user) {
  const result = [
    { creditType: 'Giftcard' },
    { creditType: 'Stripe Invoice' },
    { creditType: 'COVID-19 No Show' },
    {
      creditType: 'COVID-19 Cancellations',
      label: 'Tours Bonus Credits - COVID Cancellations',
    },
    { creditType: 'Cash Reversal - PayPal' },
    { creditType: 'Cash Reversal - Stripe' },
    {
      creditType: 'Chargeback - PayPal',
      label: 'Chargeback from Dispute - PayPal',
    },
    {
      creditType: 'Chargeback - Stripe',
      label: 'Chargeback from Dispute - Stripe',
    },
    { creditType: 'Marketing' },
    { creditType: 'Hotel Closure' },
    { creditType: CREATE_TYPE_EXPIRED_BNBL_PACKAGE },
    { creditType: 'Transferring Credit' },
    { creditType: 'Manual Bank Transfer' },
    { creditType: 'Reactivated Credit' },
    { creditType: 'Bulk Corporate Gift Card Purchase' },
    { creditType: 'Klarna/AfterPay/LatitudePay refund' },
    { creditType: CREDIT_TYPE_STORE_CREDIT },
    { creditType: 'People & Culture' },
    { creditType: 'LEBT issued' },
    { creditType: CREDIT_TYPE_CRUISE_OFFER_PACKAGE_CREDIT },
    { creditType: 'Other' },
  ];

  // make Test Credit type available/visible to one test account
  if (id_user === window.configs.TEST_CREDIT_TYPE_ACCOUNT_ID) {
    result.push({ creditType: 'Test Credit' });
  }

  return result;
}

export function getCredits(id_user, currency_in, brand, page) {
  if (page == undefined || page == null) {
    page = 1;
  }

  const currency = currency_in === 'undefined' || !currency_in ? 'AUD' : currency_in;
  return request(
    `${basePath()}credits/credit_transactions?id_member=${id_user}&currency=${currency}&brand=${brand}&page=${page}`,
    { method: 'GET' },
  );
}

export async function addCredit(object) {
  let jsonData = JSON.stringify(object);
  return request(basePath() + 'credits', {
    method: 'POST',
    headers: json_headers,
    body: jsonData,
  });
}

export async function changeExpiryDate(object) {
  return postRequest(basePath() + 'credits/expires', object);
}

export async function bulkCreditsReactivation(file) {
  const data = new FormData();
  data.append('csv', file);

  return request(basePath() + 'credits/bulk/reactivate', {
    method: 'POST',
    body: data,
  });
}

export function getPaymentCurrencies() {
  return request(basePath() + 'payments/currencies', { method: 'GET' });
}

export async function getPayments(id_orders) {
  let url = `${basePath()}payments?id_orders=${id_orders}`;
  return request(url);
}

export async function getMerchantFeeDetails(id_orders) {
  let url = `${basePath()}payments/v2/merchant-fees/orders/${id_orders}/details`;
  return request(url);
}

export function getRefundMeta(id_orders) {
  let url = `${basePath()}refund_meta/${id_orders}`;
  return request(url);
}

export async function getPaymentPlans(id_orders) {
  try {
    let result = await request(basePath() + `payment_plan?order_id=${id_orders}`);
    let plans = result.result.plan;
    let error_logs = [];
    for (let plan of plans) {
      let logs = await request(basePath() + `payment_errors/${plan.payment_plan_component_id}`);
      error_logs = logs.result.error_logs || [];
    }
    let out = {
      plans,
      error_logs,
    };
    return out;
  } catch (e) {
    console.warn(e);
    return { plans: [], error_logs: [] };
  }
}

export function getBraintreeReconciliation() {
  return request(basePath() + 'reconcile/braintree');
}

export function getBraintreeReconciliationOrderId() {
  return request(basePath() + 'reconcile/braintree/orderids');
}

export function getBraintreeReconciliationCurrencies() {
  return request(basePath() + 'reconcile/braintree/currencies');
}

export function getBraintreeReconciliationAmounts() {
  return request(basePath() + 'reconcile/braintree/amounts');
}

export function callDepositTriggers(action, object) {
  return postRequest(basePath() + 'stripe/deposit/trigger/' + action, object);
}

export function callInstalmentTriggers(action, object) {
  return postRequest(basePath() + 'stripe/instalment/trigger/' + action, object);
}

export function callReserveForZeroTriggers(action, object) {
  return postRequest(basePath() + 'stripe/deferred-payment/trigger/' + action, object);
}

export function getDepositDetails(orderId) {
  return request(basePath() + 'payments/deposit-details/' + orderId);
}

export function getInstalmentDetails(orderId) {
  return request(basePath() + 'payments/instalment-details/' + orderId);
}

export function getReserveForZeroDetails(orderId) {
  return request(basePath() + 'payments/deferred-payment-details/' + orderId);
}
