import qs from 'qs';

import { actions } from '@luxuryescapes/contract-svc-membership';

import featureToggle from '~/utils/featureToggle';

import { addDays } from './TimeService';
import { request } from './common';

export type UpdateCustomerMembershipSubscriptionsParams =
  actions['customerSubscriptionsUpdate']['parameters']['body']['payload'];

const BASE_PATH = `${window.configs.API_HOST}/api/membership`;

interface GetCustomerMembershipSubscriptionsParams {
  customerId: string;
  brand?: string;
}

interface CancelPreviewPeriodParams {
  customerId: string;
  op: App.MembershipPreviewOperation;
}

const getCustomerMembershipSubscriptions = ({
  brand = 'luxuryescapes',
  customerId,
}: GetCustomerMembershipSubscriptionsParams) => {
  if (!customerId || !featureToggle.availableToShow('SHOW_SUBSCRIPTIONS')) return null;

  const queryParams = qs.stringify({ brand, customerId });
  const uri = `${BASE_PATH}/customer-subscriptions?${queryParams}`;

  return request(uri, { method: 'GET' });
};

const updateCustomerMembershipSubscriptions = ({
  customerId,
  op,
  reason,
}: UpdateCustomerMembershipSubscriptionsParams) => {
  if (!customerId || !featureToggle.availableToShow('SHOW_SUBSCRIPTIONS')) return null;

  const uri = `${BASE_PATH}/customer-subscriptions?without_brand=1`;
  const bodyData = JSON.stringify({ customerId, op, reason });

  return request(uri, {
    method: 'PUT',
    body: bodyData,
    headers: { 'Content-Type': 'application/json' },
  });
};

const cancelPreviewPeriod = ({ customerId, op }: CancelPreviewPeriodParams) => {
  if (!customerId || !featureToggle.availableToShow('SHOW_SUBSCRIPTIONS')) return null;

  const uri = `${BASE_PATH}/preview-periods?without_brand=1`;
  const bodyData = JSON.stringify({ customerId, op });

  return request(uri, {
    method: 'PUT',
    body: bodyData,
    headers: { 'Content-Type': 'application/json' },
  });
};

const hasActiveLuxPlusSubscriptionRenewal = (subscriptions: App.MembershipSubscriptions) => {
  return subscriptions?.renewalStatus === 'ALLOW_RENEWAL';
};

const getLuxPlusRenewalOrExpiryDate = (subscriptions: App.MembershipSubscriptions) => {
  const hasActiveRenewal = hasActiveLuxPlusSubscriptionRenewal(subscriptions);
  const subscriptionExpiryDate = subscriptions?.expiryDate;
  return hasActiveRenewal ? addDays(1, subscriptionExpiryDate) : subscriptionExpiryDate;
};

export {
  BASE_PATH,
  getCustomerMembershipSubscriptions,
  updateCustomerMembershipSubscriptions,
  hasActiveLuxPlusSubscriptionRenewal,
  getLuxPlusRenewalOrExpiryDate,
  cancelPreviewPeriod,
};
