import React, { useEffect, useMemo, useState } from 'react';

import { DndContext, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';
import { useController, useFieldArray } from 'react-hook-form';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import InclusionsService from '../../../../services/InclusionsService';
import OffersService from '../../../../services/OffersService';

import ArrayFieldItem from './ArrayFieldItem';
import PackageInclusionsFieldItem from './PackageInclusionsFieldItem';

const COLUMNS: GridColDef[] = [
  {
    disableColumnMenu: true,
    field: 'deal_option_name',
    headerName: 'Deal option name',
    flex: 2,
    minWidth: 250,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'id_salesforce_external',
    filterable: false,
    headerName: 'Salesforce ID',
    sortable: false,
    flex: 1,
    minWidth: 200,
    display: 'flex',
  },
];

export default function PackageInclusionsField(props) {
  const { control, name, setValue, getValues, onPackageDataUpdate } = props;
  const [showHidden, setShowHidden] = React.useState(false);
  const [selectedPackageIds, setSelectedPackageIds] = useState<GridRowSelectionModel>([]);
  const [selectPackagesDialogOpen, setSelectPackagesDialogOpen] = useState(false);
  const [packages, setPackages] = useState<App.Package[]>([]);
  const [currentPackageId, setCurrentPackageId] = useState('');
  const [loading, setLoading] = useState(false);
  const [vendorId, setVendorId] = useState('');
  const [offerId, setOfferId] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const dataArray = useFieldArray({
    control,
    name,
  });
  const {
    field: { value: dataValue, onChange: onChangeArray },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  // filter out hidden inclusions before rendering in sortable
  const dataArrayFields = dataArray.fields.filter((item: App.PackageInclusion) => !item.inclusion.is_hidden);

  useEffect(() => {
    if (!isEqual(getValues(name), props.internalValue)) {
      setValue(name, props.internalValue);
      onChangeArray(props.internalValue);
    }
  }, [getValues, name, onChangeArray, setValue, props.internalValue]);

  const sortableData = useMemo<UniqueIdentifier[]>(
    () => dataArrayFields?.map((item, index) => `${item.id}-${index}`),
    [dataArrayFields],
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const oldIndex = sortableData.indexOf(active?.id);
      const newIndex = sortableData.indexOf(over?.id);
      let newArray = arrayMove(dataValue, oldIndex, newIndex);

      newArray = newArray.map((item: App.PackageInclusion, index) => ({ ...item, sort_order: index + 1 }));

      setValue(name, newArray);
      onUpdateArray(newArray);
    }
  }

  const onInputChange = (event, name, index) => {
    setValue(event.target.name, event.target.value);

    const updatedArray = updateArrayValue(name, index, event.target.value);
    onUpdateArray(updatedArray);
  };

  const updateArrayValue = (name, index, value, formData = dataValue) => {
    const newArray = [...formData];
    const updatedValue = { ...newArray[index], [name]: value };

    newArray.splice(index, 1, updatedValue);

    return newArray;
  };

  const onUpdateArray = (newArray) => onChangeArray(newArray);

  useEffect(() => {
    if (selectPackagesDialogOpen) {
      setLoading(true);
      const offerId = getValues(`${name}.0.offer_id`);
      setOfferId(offerId);
      setVendorId(getValues(`${name}.0.vendor_salesforce_id`));
      setCurrentPackageId(getValues(`${name}.0.package_id`));
      OffersService.getOffer(offerId)
        .then(({ result: offer }) => setPackages(offer.packages))
        .catch((e) =>
          enqueueSnackbar(`Failed to get offer packages: ${e?.message}. Please try again.`, { variant: 'warning' }),
        )
        .finally(() => setLoading(false));
    }
  }, [enqueueSnackbar, getValues, name, selectPackagesDialogOpen]);

  const onClose = () => {
    setSelectPackagesDialogOpen(false);
  };

  const triggerPackageDataUpdate = () => {
    if (onPackageDataUpdate) {
      onPackageDataUpdate();
    }
  };

  const copyInclusionsOrder = async () => {
    setLoading(true);
    const arrayWithSortOrder = dataValue.map((item, index) => ({
      ...item,
      sort_order: index + 1,
    }));
    try {
      await InclusionsService.copyInclusionsOrder(vendorId, offerId, {
        packages: [...selectedPackageIds, currentPackageId],
        package_inclusions: arrayWithSortOrder.map((item) => ({
          sort_order: item.sort_order,
          inclusion_id: item.inclusion_id,
        })),
      });
      triggerPackageDataUpdate();
      enqueueSnackbar(`Copy inclusions order success`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Failed to copy inclusions order to packages: ${e?.message}`, { variant: 'error' });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', p: 2 }}>
      <Typography variant="h6">Package Inclusions</Typography>
      <Divider sx={{ mb: 2 }} />
      {dataArrayFields.length === 0 && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Typography variant="overline" color="secondary" sx={{ textAlign: 'center' }}>
              Empty
            </Typography>
          </Grid>
        </Grid>
      )}
      {!!dataArrayFields.length && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DndContext onDragEnd={handleDragEnd}>
              <SortableContext items={sortableData}>
                {dataArrayFields.map((item, index) => (
                  <FormControl error={error?.[index]} key={item.id} sx={{ display: 'flex', mb: 2 }}>
                    <ArrayFieldItem itemKey={item.id} index={index}>
                      <PackageInclusionsFieldItem
                        onInputChange={onInputChange}
                        getValues={getValues}
                        control={control}
                        name={name}
                        index={index}
                      />
                    </ArrayFieldItem>
                  </FormControl>
                ))}
              </SortableContext>
            </DndContext>
          </Grid>
          {showHidden && (
            <Grid item xs={12} m={2}>
              <Divider sx={{ mb: 2 }} variant="middle">
                {' '}
                Hidden Package Inclusions
              </Divider>
              {dataValue.map((item, index) => (
                <>
                  {item.inclusion.is_hidden && (
                    <FormControl error={error?.[index]} key={item.id} sx={{ display: 'flex', mb: 2 }}>
                      <PackageInclusionsFieldItem
                        onInputChange={onInputChange}
                        getValues={getValues}
                        control={control}
                        name={name}
                        index={index}
                      />
                    </FormControl>
                  )}
                </>
              ))}
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox onChange={() => setShowHidden(!showHidden)} checked={showHidden} />}
              label="Show hidden inclusions"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              target="_blank"
              href={`/vendors/${getValues(`${name}.0.vendor_salesforce_id`)}/inclusions`}
              size="large"
              color="primary"
            >
              Vendor Inclusions
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button size="large" color="secondary" onClick={() => setSelectPackagesDialogOpen(true)}>
              Copy Inclusions Ordering
            </Button>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={selectPackagesDialogOpen}
        onClose={onClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="paper"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">Select packages to copy inclusions order to</DialogTitle>
        <Box
          sx={{
            flexGrow: 1,
            position: 'relative',
          }}
        >
          {loading && (
            <LinearProgress
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
              }}
            />
          )}
        </Box>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <DataGrid
              autoHeight
              checkboxSelection
              columns={COLUMNS}
              density="compact"
              getRowId={(row) => row.id_salesforce_external}
              isRowSelectable={({ row }: GridRowParams) => currentPackageId !== row.id_salesforce_external}
              rows={packages}
              hideFooter
              slots={{ pagination: GridPagination }}
              onRowSelectionModelChange={setSelectedPackageIds}
              rowSelectionModel={selectedPackageIds}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            pl: 3,
            pr: 3,
          }}
        >
          <Button disabled={loading} onClick={() => onClose()}>
            Close
          </Button>
          <Button disabled={!selectedPackageIds.length || loading} variant="contained" onClick={copyInclusionsOrder}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
