import React from 'react';

import { Grid, Stack, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import { getPromoProductDisplayName } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';

type PromoResponseItemProps = {
  item: definitions['Discount Response']['promo']['items'][0];
  currencyCode: string;
  handleGridClick: (item: definitions['Discount Response']['promo']['items'][0]) => void;
  meta: definitions['PromoMeta'];
};

function PromoResponseItem({ item, currencyCode, handleGridClick, meta }: PromoResponseItemProps) {
  const hasDiscount = item.discountAmount > 0;
  const isLuxPlusPrice = item.luxPlusPrice < item.discountableTotal;
  return (
    <Grid
      container
      key={`${item.offerId || item.itemId}`}
      spacing={1}
      sx={{
        marginBottom: 1,
        border: '1px solid',
        borderColor: 'grey.400',
        textWrap: 'nowrap',
      }}
      onClick={() => handleGridClick(item)}
      title={`${JSON.stringify(item, null, 4)}`}
    >
      <Grid item xs sx={{ padding: '4px', borderRight: '1px solid', borderColor: 'grey.400', flexGrow: 1 }}>
        <Stack direction="row">
          {hasDiscount && (
            <Typography component="span" marginRight={1} color={isLuxPlusPrice ? 'blue' : 'text.primary'}>
              {currencyFormatter(currencyCode, item.discountableTotal - item.discountAmount)}
            </Typography>
          )}
          <Typography
            marginRight={1}
            sx={{
              textDecoration: hasDiscount ? 'line-through' : '',
            }}
            component="span"
            color={isLuxPlusPrice ? 'blue' : 'text.primary'}
          >
            {currencyFormatter(currencyCode, item.discountableTotal)}
          </Typography>
          {hasDiscount && (
            <Typography marginRight={1} color="green" component="span">
              ({currencyFormatter(currencyCode, item.discountAmount)})
            </Typography>
          )}
        </Stack>
      </Grid>
      <Grid
        item
        xs
        sx={{ padding: '4px', borderRight: '1px solid #ccc', flexGrow: 1 }}
        title="categoryBK / subCategoryBK / reservationType - [warning]"
      >
        <Typography component="span" color="text.primary">
          {meta && getPromoProductDisplayName(meta.promo_product_types, item.categoryBK, item.subCategoryBK)}
          {item.reservationType ? ` / ${item.reservationType}` : ''}
          {item.warning && ` - ${item.warning}`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export { PromoResponseItem };
