import React, { useCallback, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import { currencyFormatter } from '~/components/Experiences/helpers';

import { applyOrderCreditItem } from '~/services/BusinessTraveller/BusinessTravellerService';
import { sendBusinessCreditsAppliedEmail } from '~/services/NotificationService';
import { getCredits } from '~/services/PaymentsService';
import { addYears, formatDateSlashes } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

import PriceSummaryField from '../OrderItem/PriceSummaryField';

interface Props {
  orderItemCredit: App.BusinessOrderItem;
  customerId: string;
  location: string;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

export default function OrderItemBusinessCreditDialog(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { orderItemCredit, customerId, location, isOpen, onClose, refetch } = props;
  const [isLoading, setLoading] = useState(false);

  const { handleSubmit } = useForm({
    mode: 'onBlur',
    values: orderItemCredit,
  });

  const handleCreditsAppliedEmailSend = useCallback(async () => {
    const customer = await UsersService.getUser(customerId);
    const credits: Result<App.CustomerCreditTransactions> = await getCredits(
      customerId,
      orderItemCredit.currencyCode,
      'luxuryescapes',
      1,
    );

    // construct the email params
    const creditsAppliedEmailPayload: App.BusinessCreditsAppliedEmail = {
      employeeId: customerId,
      employeeEmail: customer.email,
      employeeFirstName: customer.givenName ?? '', // because first name is not a required field.
      location: location,
      creditsApplied: currencyFormatter(orderItemCredit.currencyCode, orderItemCredit.creditTotal, 2),
      creditTotal: currencyFormatter(orderItemCredit.currencyCode, credits.result?.available_credit, 2),
      creditExpireDate: formatDateSlashes(addYears(1)), // Cannot use next_expiring_date from credits response as credits can be expiring within the year.
    };

    const sendEmailResponse = await sendBusinessCreditsAppliedEmail(creditsAppliedEmailPayload);
    const messageVariant = sendEmailResponse.status === 200 ? 'success' : 'error';
    enqueueSnackbar(sendEmailResponse.message, { variant: messageVariant });
  }, [customerId, enqueueSnackbar, location, orderItemCredit]);

  const handleSubmission = useMemo(
    () =>
      handleSubmit(async () => {
        setLoading(true);

        try {
          const response = await applyOrderCreditItem(orderItemCredit.orderItemId);
          if (response.result) {
            enqueueSnackbar('Credits have been marked as applied.', { variant: 'success' });

            handleCreditsAppliedEmailSend();

            refetch();
            onClose();
          } else {
            enqueueSnackbar(response.message, { variant: 'error' });
          }
        } catch (e) {
          enqueueSnackbar(String(e), { variant: 'error' });
        }

        setLoading(false);
      }),
    [enqueueSnackbar, handleCreditsAppliedEmailSend, handleSubmit, onClose, orderItemCredit.orderItemId, refetch],
  );

  return (
    <Dialog open={isOpen} onClose={isLoading ? undefined : onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmission}>
        <DialogTitle>Marks credits as applied</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Mark the credits as applied to the employee's personal Luxury Escapes account.
          </Typography>
          <Typography gutterBottom>
            Before marking the credits as applied, make sure you have applied the credits to the employee.
          </Typography>
          <Typography gutterBottom>The Business Credits Applied email will be sent.</Typography>
          <Stack direction="row" alignSelf="stretch" sx={{ pt: 2 }} spacing={1}>
            <RequestQuoteIcon />
            <PriceSummaryField
              boldPrice
              label="LEBT Credits"
              value={currencyFormatter(orderItemCredit.currencyCode, orderItemCredit.creditTotal, 2)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isLoading} type="submit" variant="contained" color="primary">
            Mark Credits Applied
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
