import React, { useEffect, useState } from 'react';

import { Button, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import ErrorDisplay from '~/components/Common/ErrorDisplay';

import { getUserQuotes } from '~/services/CartService';
import { formatDateWithClock } from '~/services/TimeService';

import QuoteAgentUser from './QuoteAgentUser';
import QuoteCartItems from './QuoteCartItems';
import QuoteEmailSent from './QuoteEmailSent';

type Props = {
  user: App.User;
};

const redirectToCheckout = (cartId: string) => {
  const url = `${window.configs.LUX_CUSTOMER_PORTAL}/checkout-restore-cart?cartId=${cartId}`;
  window.open(url, '_blank');
};

const columns: GridColDef[] = [
  {
    field: 'items',
    headerName: 'Status/Cart items',
    sortable: false,
    flex: 3.5,
    display: 'flex',
    renderCell: (params) => <QuoteCartItems items={params.row.items} />,
  },
  {
    field: 'callbackDate',
    headerName: 'Scheduled callback',
    flex: 1,
    sortable: false,
    valueGetter: (_value, row) => (row.callbackDate ? formatDateWithClock(row.callbackDate) : '-'),
    display: 'flex',
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    sortable: false,
    flex: 1,
    valueGetter: (_value, row) => formatDateWithClock(row.createdAt),
    display: 'flex',
  },
  {
    field: 'agentId',
    headerName: 'Created by',
    sortable: false,
    flex: 1,
    renderCell: (params) => <QuoteAgentUser userId={params.row.agentId} />,
    display: 'flex',
  },
  {
    field: 'action',
    headerName: 'Actions',
    sortable: false,
    flex: 1.25,
    renderCell: (params) => (
      <Stack direction="row" spacing={1}>
        <Button variant="text" size="small" onClick={() => redirectToCheckout(params.row.cartId)}>
          View quote
        </Button>
        {params.row.customerEmailDedupeKey && (
          <QuoteEmailSent customerEmailDedupeKey={params.row.customerEmailDedupeKey} />
        )}
      </Stack>
    ),
    display: 'flex',
  },
];

export default function QuotesTab({ user }: Props) {
  const userId = user.id_member;

  const [total, setTotal] = useState(0);
  const [quotes, setQuotes] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const pageSize = 10;

  useEffect(() => {
    setLoading(true);
    getUserQuotes(userId, currentPage + 1, pageSize)
      .then((res) => {
        setQuotes(res.result);
        setTotal(res.total);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [userId, currentPage, pageSize]);

  return (
    <>
      {error && <ErrorDisplay message={error} />}

      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={quotes}
        rowCount={total}
        pagination
        paginationMode="server"
        paginationModel={{ page: currentPage, pageSize }}
        onPaginationModelChange={({ page }) => setCurrentPage(page)}
        slots={{ pagination: GridPagination }}
        getRowHeight={() => 'auto'}
        sx={{ '.MuiDataGrid-cell': { paddingBlock: 2 } }}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableRowSelectionOnClick
        autoHeight
      />
    </>
  );
}
