/* Purchases/Home/PurchasesPageContainer.js */
import React from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Container, Tab, Tabs } from '@mui/material';

import CartPaneContainer from '~/components/Cart/CartPaneContainer';
import PageHeader from '~/components/Common/Elements/PageHeader';

import useQuery from '~/hooks/useQuery';

import featureToggleUtils from '~/utils/featureToggle';

import AddonsPage from './AddonsPageContainer';
import OrdersPage from './AllOrders/OrdersPageContainer';
import BedbankPage from './BedbankPageContainer';
import BundleAndSavePage from './BundleAndSavePageContainer';
import CarHirePage from './CarHire/CarHirePurchasePage';
import CruisesPage from './Cruises/CruisesPurchasePage';
import CustomOffersPage from './CustomOffers';
import FlightsPage from './FlightsPageContainer';
import GiftCardsPage from './GiftCardsPageContainer';
import InsurancesPage from './InsurancesPageContainer';
import { LuxPlusPageContainer } from './LuxPlus';
import PackagesPage from './PackagesPageContainer';
import ToursPage from './ToursPageContainer';

const TAB_ALL_ORDERS = 'allOrders';

type Tab = {
  label: string;
  pageTitle: string;
  component: React.ComponentType;
  showTab?: boolean;
};

const TABS_MAP = {
  [TAB_ALL_ORDERS]: {
    label: 'All Orders',
    pageTitle: 'Latest orders - All Types',
    component: OrdersPage,
  } as Tab,
  packages: {
    label: 'Packages',
    pageTitle: 'Latest orders',
    component: PackagesPage,
  } as Tab,
  giftCards: {
    label: 'Gift Cards',
    pageTitle: 'Latest gift cards',
    component: GiftCardsPage,
  } as Tab,
  experiences: {
    label: 'Experiences',
    pageTitle: 'Latest experiences',
    component: AddonsPage,
  } as Tab,
  flights: {
    label: 'Flights',
    pageTitle: 'Latest flights',
    component: FlightsPage,
  } as Tab,
  bedbanks: {
    label: 'Bedbanks',
    pageTitle: 'Bedbank orders',
    component: BedbankPage,
  } as Tab,
  tours: {
    label: 'Tours',
    pageTitle: 'Latest tours',
    component: ToursPage,
  } as Tab,
  packagesWithInsurance: {
    label: 'Insurance',
    pageTitle: 'Latest insurance',
    component: InsurancesPage,
  } as Tab,
  carts: {
    label: 'Carts',
    pageTitle: 'Carts',
    component: CartPaneContainer,
  } as Tab,
  cruises: {
    label: 'Cruises',
    pageTitle: 'Latest cruises',
    component: CruisesPage,
    showTab: featureToggleUtils.availableToShow('SHOW_CRUISES'),
  } as Tab,
  carHire: {
    label: 'Car Hire',
    pageTitle: 'Latest bookings',
    component: CarHirePage,
    showTab: featureToggleUtils.availableToShow('SHOW_CAR_HIRE'),
  } as Tab,
  bundle: {
    label: 'Bundle and Save',
    pageTitle: 'Bundle and Save',
    component: BundleAndSavePage,
  } as Tab,
  customOffers: {
    label: 'Custom Offers',
    pageTitle: 'Custom Offers',
    component: CustomOffersPage,
  } as Tab,
  luxPlus: {
    label: 'Lux Plus',
    pageTitle: 'Lux Plus',
    component: LuxPlusPageContainer,
  } as Tab,
} as const;

type Tabs = keyof typeof TABS_MAP;

const tabList = Object.keys(TABS_MAP);

export default function PurchasesPageContainer() {
  const { section = TAB_ALL_ORDERS } = useParams<{ section?: Tabs }>();
  const query = useQuery();
  const customerId = query.get('customer_id');
  const currentTab: Tab = TABS_MAP[section];

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{`Orders / ${currentTab.label}`}</title>
      </Helmet>

      <PageHeader title={currentTab.pageTitle} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={section} id="orders" variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
          {tabList
            .filter((tabKey) => {
              const tab: Tab = TABS_MAP[tabKey];

              if (tab.showTab !== undefined) {
                return tab.showTab;
              }

              return true;
            })
            .map((tabKey) => {
              const tab: Tab = TABS_MAP[tabKey];

              return (
                <Tab
                  key={tabKey}
                  label={tab.label}
                  value={tabKey}
                  to={`/purchases/section/${tabKey}${customerId ? `?customer_id=${customerId}` : ''}`}
                  component={Link}
                />
              );
            })}
        </Tabs>
      </Box>

      <Box pt={4}>
        <Box component={currentTab.component} />
      </Box>
    </Container>
  );
}
