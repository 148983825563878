import React from 'react';

import classNames from 'clsx';
import capitalize from 'lodash/capitalize';
import { MarkdownPreview } from 'react-marked-markdown';
import { Link } from 'react-router-dom';

import {
  DirectionsBoat as BoatIcon,
  CalendarToday as CalendarTodayIcon,
  CreditCard as CreditCardIcon,
  DateRange as DateRangeIcon,
  BeachAccess as HolidayIcon,
  Hotel as HotelIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  ReportOutlined as ReportOutlinedIcon,
  Sell as SellIcon,
  StarBorderPurple500 as StarBorderPurple500Icon,
} from '@mui/icons-material';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import currencyFormatter from '~/utils/currencyFormatter';
import { formatOrderDate } from '~/utils/order';

import ItemStatus from '../OrderItem/ItemsStatus';
import PriceSummaryField from '../OrderItem/PriceSummaryField';
import ToggleButton from '../TourList/ToggleButton';

interface Props {
  customOfferItem: App.CustomOfferOrderItem;
  item: App.CustomOfferItem;
  count: number;
  showRefundModal: ({ itemId }) => void;
}

export default function CustomOfferOrderItem({ customOfferItem, count, showRefundModal, item }: Props) {
  const [travellersOpened, setTravellersOpened] = React.useState(false);

  const classes = classNames('order-item', {
    cancelled: customOfferItem.status === 'cancelled',
  });

  return (
    <Box className={classes}>
      <Grid p={2} container>
        <Grid xs={10}>
          <Grid container>
            <Grid item>
              <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
                {count}:&nbsp;
                <Link
                  to={`/users/${customOfferItem.custom_offer.fk_customer_id}/custom-offers/${customOfferItem.fk_custom_offer_id}`}
                >
                  {item.name}
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" gap={2}>
            <div>
              <ItemStatus item={customOfferItem} />
            </div>
          </Stack>
          <Grid container spacing={2}>
            <Grid xs={4}>
              <Stack gap={1} mt={2} mb={1}>
                <Typography variant="subtitle1" fontWeight="bold">
                  General information
                </Typography>
                <Stack direction="row" spacing={1}>
                  <PersonIcon />
                  <Typography>{customOfferItem.custom_offer.customer_full_name}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <PeopleIcon />
                  <Typography>{item.travellers.length} travellers</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <CalendarTodayIcon />
                  <Typography>Vendor: {item.vendor}</Typography>
                </Stack>
                {item.vendor_reference_number && (
                  <Stack direction="row" spacing={1}>
                    <CalendarTodayIcon />
                    <Typography>Vendor Booking #: {item.vendor_reference_number}</Typography>
                  </Stack>
                )}
                <Stack direction="row" spacing={1}>
                  <DateRangeIcon />
                  <Typography className="T-ReservationDate">{formatOrderDate(item.start_date)}</Typography>
                  <Typography> - </Typography>
                  <Typography>{formatOrderDate(item.end_date)}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <HolidayIcon />
                  <Typography>Offer Type: {capitalize(item.type)}</Typography>
                </Stack>
                {item.type === 'cruise' && (
                  <>
                    <Stack direction="row" spacing={1}>
                      <BoatIcon />
                      <Typography>Ship name: {item?.ship_name}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <StarBorderPurple500Icon />
                      <Typography>Ship rate: {item?.ship_rate}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <HotelIcon />
                      <Typography>Cabin: {item?.ship_cabin}</Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            </Grid>
            <Grid xs={4}>
              <Stack gap={1} mt={2} mb={1}>
                <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                  Pricing
                </Typography>
                <Stack direction="row" spacing={1}>
                  <SellIcon />
                  <PriceSummaryField
                    boldPrice
                    label="Total sell price"
                    value={currencyFormatter(
                      customOfferItem.custom_offer.price_currency,
                      customOfferItem.custom_offer.price / 100,
                      undefined,
                      'code',
                    )}
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <CreditCardIcon />
                  <PriceSummaryField
                    boldPrice
                    label="Total cost price"
                    value={currencyFormatter(
                      customOfferItem.custom_offer.cost_price_currency,
                      customOfferItem.custom_offer.cost_price / 100,
                      undefined,
                      'code',
                    )}
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <ReportOutlinedIcon />
                  <Typography>Payment terms: {customOfferItem.custom_offer.payment_terms}</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid xs={4}></Grid>
          </Grid>
        </Grid>
        <Grid xs={2}>
          <Grid container justifyContent="flex-end" textAlign="right">
            <Stack justifyContent="end">
              <Typography mt={1} variant="h6" fontWeight="bold">
                Changes and refunds
              </Typography>
              <div>
                <Button
                  onClick={() => {
                    showRefundModal({ itemId: customOfferItem.id });
                  }}
                  sx={{
                    color: 'red',
                  }}
                  className="T-issue-refund"
                >
                  Issue refund
                </Button>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid container columns={12}>
        <Grid item xs={6}>
          <Typography variant="h5">Description</Typography>
          <MarkdownPreview value={item.description} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">Terms and Conditions</Typography>
          <MarkdownPreview value={item.service_information} />
        </Grid>
      </Grid>
      <ToggleButton
        title="Travellers"
        isOpen={travellersOpened}
        handleClick={() => setTravellersOpened(!travellersOpened)}
      >
        {item?.travellers?.map((traveller) => (
          <Stack key={`${traveller.name}${traveller.date_of_birth}`} direction="row" spacing={1} m={2}>
            <PersonIcon />
            <Typography>{traveller.name}</Typography>
            <Typography>{traveller.type}</Typography>
            <Typography>{traveller.date_of_birth}</Typography>
          </Stack>
        ))}
      </ToggleButton>
    </Box>
  );
}
