import React, { useState } from 'react';

import { Box, Button, Stack, TextField } from '@mui/material';

import ChainsSearchResults from '~/components/Vendors/Home/ChainsSearchResultsContainer';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

import { SEARCH_ID_LENGTH } from '../../Vendors/Properties/constants';
import Spinner from '../Spinner';

const ChainSearchForm = (props) => {
  const [searchQuerySubmit, setSearchQuerySubmit] = useState('');
  const [searchResult, setSearchResult] = useState<{
    result: unknown;
    total: number;
  }>();
  const [tableType, setTableType] = useState();
  const [isSearching, setSearching] = useState(false);

  const handleSearchQuery = (query) => {
    setSearchQuerySubmit(query.target.value);
  };

  const fetchChains = async () => {
    const searchQuery = searchQuerySubmit.trim();

    let searchPromise = undefined;
    let tableType = undefined;

    setSearching(true);
    setSearchResult(undefined);

    if (
      searchQuery.length === SEARCH_ID_LENGTH &&
      searchQuery.indexOf(' ') === -1 &&
      searchQuery.match(/^[0-9a-zA-Z]{18}$/)
    ) {
      searchPromise = VendorsService.getVendorById(searchQuery);
      tableType = 'id';
    } else {
      searchPromise = ReservationService.getVendorsFromChainNameSearch(searchQuery);
      tableType = 'name';
    }

    const result = await searchPromise;

    setSearchResult(result);
    setTableType(tableType);
    setSearching(false);
  };

  return (
    <>
      <form onSubmit={props.handleSubmitChain}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box flex={1}>
            <TextField
              id="ChainSearch"
              data-testid="ChainSearch"
              type="text"
              name="searchString"
              label={props.placeholderText || 'Enter Chain ID or Name'}
              placeholder={props.placeholderText || 'Enter Chain ID or Name'}
              value={searchQuerySubmit}
              onChange={handleSearchQuery}
              autoFocus
              fullWidth
            />
          </Box>

          <Button type="submit" variant="contained" className="T-search-user" onClick={fetchChains}>
            Search
          </Button>
        </Stack>
      </form>

      <Box mt={4}>
        {isSearching && <Spinner />}

        {searchResult && (
          <ChainsSearchResults data={searchResult.result} total={searchResult.total} tableType={tableType} />
        )}
      </Box>
    </>
  );
};

export default ChainSearchForm;
