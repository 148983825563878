import React, { memo, useState } from 'react';

import classNames from 'clsx';
import currencyFormatter from 'currency-formatter';

import BedIcon from '@mui/icons-material/Bed';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Box, Button, Stack, Typography } from '@mui/material';

import { Tour } from '@luxuryescapes/contract-svc-tour';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';

import { formatDateISO, formatDateShort } from '~/services/TimeService';

import { formatTourV2SnapshotRoom, toTitleCase } from '~/utils/tourV2';

import OptionalExtraWarningModal from './OptionalExtraWarningModal';
import TourItineraryModal from './TourItineraryModal';

interface Props {
  count: number;
  item: App.OrderTourItem;
  order: App.Order;
  showRefundModal: (data: { itemId: string; isAfterChangeDate?: boolean }) => void;
  tourInfo: Tour.ReservationSnapshot;
  offerUrl: string;
}

const getOrderTotal = (order: App.Order) => {
  return order.tour_items.reduce((acc, item) => acc + item.total, 0);
};

const TourOrderItem = ({ item, count, order, showRefundModal, tourInfo, offerUrl }: Props) => {
  const [travellerModalOpen, setTravellerModalOpen] = useState(false);
  const [optionalExtraWarningModalOpen, setOptionalExtraWarningModalOpen] = useState(false);
  const classes = classNames('order-item', {
    cancelled: false,
  });

  const openTravellerModal = () => {
    setTravellerModalOpen(true);
  };

  const closeTravellerModal = () => {
    setTravellerModalOpen(false);
  };
  const closeOptionalExtaWarningModal = () => {
    setOptionalExtraWarningModalOpen(false);
  };

  const getTourChangeUpToDate = () =>
    new Date(tourInfo.startDate).getTime() - 86400000 * (tourInfo.dateChangesAllowedUpTo ?? 0);
  const onRowClick = () =>
    order.tour_optional_experience_items.filter((oe) => oe.status === 'completed').length > 0
      ? setOptionalExtraWarningModalOpen(true)
      : showRefundModal({
          itemId: item.id,
          isAfterChangeDate: tourInfo.source === 'salesforce' && getTourChangeUpToDate() < new Date().getTime(),
        });
  const onOptionalExtraCancel = (experience_id) =>
    showRefundModal({
      itemId: experience_id,
      isAfterChangeDate: true,
    });

  return (
    <Box mb={2} className={classes}>
      <Stack direction="row" spacing={2} alignItems="start">
        <div className="count">{count}</div>

        <Box pt={1} flexGrow={1}>
          <div className="item-header">
            <div className="item-name">{item.season_name}</div>
            <div className="item-info">ID: {item.booking_number}</div>
          </div>

          <div className="item-reservation T-HasReservation">
            <div className="section">
              {item.travellers.map((traveller) => (
                <Stack direction="row" spacing={1} alignItems="center" key={traveller.first_name}>
                  <ListAltIcon />
                  {traveller.first_name} {traveller.last_name}
                </Stack>
              ))}
            </div>

            <Stack direction="row" spacing={1} alignItems="center" className="section">
              <CalendarMonthIcon />
              <span className="T-ReservationDate">{formatDateShort(item.start_date)}</span>
              <span>-</span>
              <span>{formatDateShort(item.end_date)}</span>
            </Stack>

            <div>
              <Stack direction="row" spacing={1} alignItems="center" className="section">
                <HomeIcon />
                <a href={offerUrl} target="_blank" rel="noreferrer">
                  {item.tour_name}
                </a>
              </Stack>

              {tourInfo.packageOption?.packageOption && (
                <Stack direction="row" spacing={1} alignItems="center" className="section">
                  <ListAltIcon />
                  <span>Package Option: {tourInfo.packageOption.packageOption}</span>
                </Stack>
              )}

              <Stack direction="row" spacing={1} alignItems="center" className="section">
                <BedIcon />
                <span>{parseRoomsToString(tourInfo?.rooms as unknown as App.OrderTourItemRooms[])}</span>
              </Stack>

              <div className="section">
                <Button onClick={openTravellerModal} variant="outlined" size="small">
                  Tour Itinerary
                </Button>
              </div>
            </div>
          </div>
          {order.tour_optional_experience_items?.length > 0 && item.optional_experiences.length > 0 && (
            <Box sx={{ margin: '5px' }}>
              <FoldableSection title="Optional Experiences" initiallyExpanded>
                {order.tour_optional_experience_items.map((experience) => (
                  <Stack key={experience.optional_experience_id} direction={'row'}>
                    <Box flexGrow={3}>
                      <Typography variant="h6">{experience.name}</Typography>

                      <Stack display="grid" columnGap={2} gridTemplateColumns="auto 1fr">
                        <Typography>Location</Typography>
                        <Typography fontWeight="bold">{experience.location}</Typography>

                        <Typography>Date</Typography>
                        <Typography fontWeight="bold">{formatDateISO(experience.date)}</Typography>

                        <Typography>Time Slot</Typography>
                        <Typography fontWeight="bold">{experience.time_slot}</Typography>

                        <Typography>Travellers</Typography>
                        <Typography fontWeight="bold">{experience.count}</Typography>

                        <Typography>Day Number</Typography>
                        <Typography fontWeight="bold">{experience.day_number}</Typography>

                        <Typography>Duration</Typography>
                        <Typography fontWeight="bold">{experience.duration} minutes</Typography>

                        <Typography>Total</Typography>
                        <Typography fontWeight="bold">
                          {currencyFormatter.format(experience.total, {
                            code: order.currency_code,
                          })}{' '}
                          (
                          {currencyFormatter.format(experience.price, {
                            code: order.currency_code,
                          })}{' '}
                          each)
                        </Typography>
                      </Stack>
                    </Box>
                    <Box flexGrow={1}>
                      {<Typography>Status: {experience.status}</Typography>}
                      {experience.status === 'completed' && (
                        <Button
                          variant="text"
                          className="T-issue-refund"
                          onClick={() => onOptionalExtraCancel(experience.id)}
                        >
                          Issue refund
                        </Button>
                      )}
                    </Box>
                  </Stack>
                ))}
              </FoldableSection>
            </Box>
          )}
        </Box>

        <Box pt={2}>
          <div className="price-info">
            <div className="price">
              Price:{' '}
              {currencyFormatter.format(getOrderTotal(order), {
                code: order.currency_code,
              })}
            </div>
          </div>
        </Box>

        <Box pt={2} className="status-column">
          <Typography>Status: {item.status}</Typography>
          {tourInfo && tourInfo.source === 'ttc' && tourInfo.status && (
            <Typography>TTC&nbsp;status:&nbsp;{tourInfo.status}</Typography>
          )}
        </Box>

        <Box pt={2} className="refund-column">
          {item.status === 'completed' && (
            <Button variant="text" className="T-issue-refund" onClick={onRowClick}>
              Issue refund
            </Button>
          )}
        </Box>
      </Stack>

      <TourItineraryModal visible={travellerModalOpen} onClose={closeTravellerModal} itinerary={item.itineraries} />
      <OptionalExtraWarningModal visible={optionalExtraWarningModalOpen} onClose={closeOptionalExtaWarningModal} />
    </Box>
  );
};

function parseRoomsToString(rooms: Array<App.OrderTourItemRooms>): string {
  if (!rooms) return '';

  return rooms
    .map((room, index) => `Room ${index + 1}: ${toTitleCase(room.roomType)} (${formatTourV2SnapshotRoom(room)})`)
    .join(', ');
}

export default memo(TourOrderItem);
