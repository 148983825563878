import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import type { SourceRoom, Suppliers } from './types';
import { generateOccupancyText, generateRoomSizeText, generateViewsText } from './util';

interface Props {
  room: SourceRoom;
  isSelected: boolean;
  supplier: Suppliers;
  onSelect: (room: SourceRoom, supplier: Suppliers) => void;
}

export default function SourceRoomElement({ room, isSelected, supplier, onSelect }: Props) {
  const description = room.description || 'N/A';
  const supplierAndExternalId = `(${supplier}) ${room.externalId}`;

  const views = generateViewsText(room);
  const roomSizeText = generateRoomSizeText(room);
  const { occupancyAge, occupancyAllowed } = generateOccupancyText(room);

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        backgroundColor: !isSelected ? 'grey.100' : undefined,
        borderColor: isSelected ? 'info.light' : undefined,
      }}
    >
      <FormControlLabel
        checked={isSelected}
        onChange={() => onSelect(room, supplier)}
        control={<Checkbox />}
        label={room.name ?? room.description}
        componentsProps={{ typography: { variant: 'h6' } }}
      />

      <Typography>
        <strong>Supplier | External ID:</strong> {supplierAndExternalId}
      </Typography>
      {!!room.hasInfo && (
        <Stack direction="column" gap={1}>
          <div>
            <Typography>
              <strong>Views:</strong> {views}
            </Typography>
            <Typography>
              <strong>Room Size:</strong> {roomSizeText}
            </Typography>
            <Typography>
              <strong>Bed Groups:</strong> {room.bedGroups?.map((x) => x.description).join(', ') || 'N/A'}
            </Typography>
            <Typography>
              <strong>Occupancy Age:</strong> {occupancyAge}
            </Typography>
            <Typography>
              <strong>Occupancy Allowed:</strong> {occupancyAllowed}
            </Typography>
            <Typography>
              <strong>Description:</strong> {description}
            </Typography>
            <Typography>
              <strong>Availability Score:</strong> {room.availabilityScore ?? 'N/A'}
            </Typography>
          </div>
          <Stack direction="column">
            {room.amenities?.length > 0 && (
              <Accordion>
                <AccordionSummary>Amenities</AccordionSummary>
                <AccordionDetails>
                  <Box component="ul" m={0}>
                    {room.amenities.map((amenity) => (
                      <li key={amenity}>{amenity}</li>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
          </Stack>
          <div>
            {room.characteristic && (
              <Typography>
                <strong>Characteristic:</strong> {room.characteristic}
              </Typography>
            )}
            {room.occupancy && (
              <Typography>
                <strong>Occupancy:</strong> {room.occupancy?.max_allowed.total}
              </Typography>
            )}
          </div>
        </Stack>
      )}
    </Paper>
  );
}
