import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router';

import { Button, FormControl, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { listAffiliations } from '~/services/AgentHub/AgentService';

import { addQuery, parseSearchString } from '~/utils/url';

import AddAffiliationModal from './AddAffiliationModal';
import AffiliationEditorModal from './AffiliationEditorModal';
import DeleteAffiliationModal, { DeleteAffiliationModalData } from './DeleteAffiliationModal';

const DEFAULT_SIZE_PER_PAGE = 10;

export default function AffiliationList() {
  const location = useLocation();

  const { push: setQueryString } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [affiliationList, setAffiliationList] = useState([]);
  const [affiliationTotalCount, setAffiliationTotalCount] = useState(0);
  const [isAddAffiliationModalOpen, setIsAddAffiliationModalOpen] = useState(false);

  const [editor, setEditor] = useState({ id: null, opened: false });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<DeleteAffiliationModalData>({
    id: null,
    name: '',
    open: false,
  });

  const [filter, setFilter] = useState({
    page: 0,
    status: null,
    order: null,
    search: null,
  });

  const openAddAffiliationModal = () => {
    setIsAddAffiliationModalOpen(true);
  };

  const closeAddAffiliationModal = () => {
    setIsAddAffiliationModalOpen(false);
  };

  const onCloseDeleteModal = ({ refetch = true }) => {
    if (refetch) {
      fetchAgencyList();
    }

    setIsDeleteModalOpen({ id: null, name: '', open: false });
  };

  const onPageChange = useCallback(
    (page: number) => {
      setQueryString(addQuery(location, { page }));
    },
    [setQueryString, location],
  );

  const onSearchChange = useCallback(
    (search: string) => setQueryString(addQuery(location, { search: search || undefined })),
    [setQueryString, location],
  );
  const onSearchChangeDebounced = debounce(onSearchChange, 500);

  useEffect(() => {
    const { status, order, page, search } = parseSearchString(location.search);
    setFilter((prev) => ({
      ...prev,
      order,
      status,
      search,
      page: parseInt((page as string) ?? '0'),
    }));
  }, [location]);

  const fetchAgencyList = useCallback(async (): Promise<void> => {
    try {
      setLoadingState('loading');
      const { search } = filter;

      const { rows, total } = await listAffiliations({
        page: filter.page,
        pageSize: DEFAULT_SIZE_PER_PAGE,
        ...(search ? { search } : {}),
      });

      setAffiliationTotalCount(total);
      setAffiliationList(rows);
      setLoadingState('success');
    } catch (e) {
      setLoadingState('failed');
      enqueueSnackbar(`Failed to load data: ${e}`, {
        variant: 'error',
      });
    }
  }, [filter, enqueueSnackbar]);

  const closeEditorModal = useCallback(() => {
    setEditor({ id: null, opened: false });
    fetchAgencyList();
  }, [setEditor, fetchAgencyList]);

  useEffect(() => {
    fetchAgencyList();
  }, [fetchAgencyList]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      { field: 'name', headerName: 'Name', flex: 1, sortable: false, display: 'flex' },
      { field: 'region', headerName: 'Region', flex: 1, sortable: false, display: 'flex' },
      {
        field: 'action',
        headerName: '',
        flex: 1,
        renderCell: (params) => {
          return (
            <>
              <Button variant="text" onClick={() => setEditor({ id: params.row.id, opened: true })}>
                Edit
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setIsDeleteModalOpen({ id: params.row.id, name: params.row.name, open: true });
                }}
              >
                Delete
              </Button>
            </>
          );
        },
        display: 'flex',
      },
    ];
  }, []);

  return (
    <>
      <Stack gap={2} display="flex" justifyContent="end">
        <FormControl fullWidth>
          <TextField
            id="search-input-label"
            data-testid="search"
            type="text"
            name="search"
            label="Search"
            placeholder="Search"
            onChange={(e) => onSearchChangeDebounced(e.target.value as string)}
            fullWidth
          />
        </FormControl>
        <Button sx={{ flex: 1, alignSelf: 'end' }} variant="contained" onClick={openAddAffiliationModal}>
          Add Affiliation
        </Button>
      </Stack>
      <Stack mt={1}>
        <DataGrid
          rows={affiliationList}
          rowCount={affiliationTotalCount}
          columns={columns}
          loading={loadingState == 'loading'}
          pagination
          paginationMode="server"
          paginationModel={{ page: filter.page, pageSize: DEFAULT_SIZE_PER_PAGE }}
          pageSizeOptions={[DEFAULT_SIZE_PER_PAGE]}
          onPaginationModelChange={({ page }) => onPageChange(page)}
          slots={{ pagination: GridPagination }}
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          autoHeight
        />
        <AffiliationEditorModal onClose={closeEditorModal} id={editor.id} opened={editor.opened} />
        <AddAffiliationModal onClose={closeAddAffiliationModal} isOpen={isAddAffiliationModalOpen} />
        <DeleteAffiliationModal isOpen={isDeleteModalOpen.open} onClose={onCloseDeleteModal} data={isDeleteModalOpen} />
      </Stack>
    </>
  );
}
