import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridEditInputCell,
  GridEditSingleSelectCell,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';

import { operations } from '@luxuryescapes/contract-svc-promo';

import {
  InternalPromoWithDiscounts,
  PromoCondition,
  PromoMeta,
  createOrUpdatePromoCondition,
  deletePromoCodeCondition,
} from '~/services/PromoService';

import dateFormatterDetailed from '~/utils/dateFormatterDetails';

import PageSubheader from '../Common/Elements/PageSubheader';
import Spinner from '../Common/Spinner';

import PromoCodeUser from './formatters/PromoCodeUser';

interface Props {
  promoCode: InternalPromoWithDiscounts;
  promoMeta: PromoMeta;
}

const addUniqueID = (condition: PromoCondition): PromoCondition => {
  return {
    ...condition,
    id: condition.id_promo_code_condition ?? uuid(),
  };
};

function PromoConditions({ promoCode, promoMeta }: Props) {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [conditions, setConditions] = useState<Array<PromoCondition>>(
    promoCode.promo_conditions.map(addUniqueID) ?? [],
  );

  const handleAddCondition = () => {
    const id = uuid();
    setConditions([
      ...conditions,
      {
        id,
        id_promo_code: promoCode?.id_promo_code,
        condition_type: promoMeta[0]?.promo_condition_types[0]?.key,
        isNew: true,
        brand: brand,
      } as unknown as PromoCondition,
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'region' },
    }));
  };

  const handleCancelCondition = useCallback(
    (params) => {
      const editedRow = conditions.find(
        (row) => row.id === params.id || row.id_promo_code_condition === params.id_promo_code_condition,
      );

      if (editedRow?.isNew) {
        setConditions(conditions.filter((row) => row.id !== params.id));
      } else {
        setRowModesModel({
          ...rowModesModel,
          [params.id_promo_code_condition]: { mode: GridRowModes.View, ignoreModifications: true },
        });
      }
    },
    [rowModesModel, setConditions, conditions],
  );

  const handleEditCondition = useCallback(
    (params: PromoCondition) => {
      setRowModesModel({
        ...rowModesModel,
        [params?.id ?? params?.id_promo_code_condition]: { mode: GridRowModes.Edit, fieldToFocus: 'region' },
      });
    },
    [rowModesModel],
  );

  const validateFields = (payload) => {
    const { max_discount, min_spend } = payload;

    if (max_discount == 0 || min_spend == 0) {
      throw new Error('Values for max discount and min spend must be greater than 0 if provided.');
    }
  };

  const handleDeleteCondition = async (params: PromoCondition) => {
    if (confirm('are you sure you want to delete this condition?')) {
      try {
        await deletePromoCodeCondition(params.id_promo_code_condition);
        enqueueSnackbar('Condition deleted successfully');
        setConditions(conditions.filter((row) => row.id_promo_code_condition !== params.id_promo_code_condition));
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    }
  };

  const handleSaveCondition = async (params: PromoCondition) => {
    setRowModesModel({
      ...rowModesModel,
      [params?.id ?? params.id_promo_code_condition]: { mode: GridRowModes.View },
    });
  };

  const processRowUpdate = async (row: PromoCondition) => {
    try {
      const payload: operations['createOrUpdatePromoCodeCondition']['parameters']['body']['payload'] = {
        promoCodeCondition: {
          id_promo_code_condition: row.id_promo_code_condition,
          condition_type: row.condition_type,
          condition_value: typeof row.condition_value === 'string' ? row.condition_value : row.condition_value ?? '',
          id_promo_code: row.id_promo_code,
        },
      };

      validateFields(payload);
      const result = await createOrUpdatePromoCondition(payload);
      if (result.status !== 200) {
        enqueueSnackbar(JSON.stringify(result.result), {
          autoHideDuration: 5000,
        });
      }
      const newCondition = result.result.promoCodeCondition;
      const newConditionArray = conditions.map((c) => {
        if (c.id_promo_code_condition === newCondition.id_promo_code_condition) {
          return addUniqueID(newCondition);
        } else if (c.id === row.id) {
          return addUniqueID(newCondition);
        }
        return c;
      });

      setConditions(newConditionArray);
      enqueueSnackbar('Condition saved successfully', { variant: 'success' });

      return addUniqueID(newCondition);
    } catch (err) {
      console.error(
        `processRowUpdate - ${row?.id ?? row.id_promo_code_condition} - error\n${err.message}\n${
          err.stack
        }\n\n ${JSON.stringify(row)}`,
      );
      enqueueSnackbar(JSON.stringify(err), {
        autoHideDuration: 5000,
      });
    }
  };

  const sharedOpts: Partial<GridColDef<PromoCondition>> = {
    editable: true,
    sortable: true,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    flex: 1,
  };

  const getColumns = (promoMeta: PromoMeta): GridColDef<PromoCondition>[] => [
    {
      ...sharedOpts,
      field: 'condition_type',
      headerName: 'Condition Type',
      type: 'singleSelect',
      valueOptions:
        promoMeta?.promo_condition_types.map((ct) => {
          return {
            value: ct.key,
            label: ct.name,
          };
        }) ?? [],
      renderEditCell: (params) => <GridEditSingleSelectCell {...params} value={params.row.condition_type} />,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
    },
    {
      ...sharedOpts,
      field: 'condition_value',
      headerName: 'Condition Value',
      renderEditCell: (params) => <GridEditInputCell {...params} required />,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
    },
    {
      ...sharedOpts,
      editable: false,
      field: 'created_at',
      headerName: 'Created At',
      renderCell: (params) => dateFormatterDetailed(params.row.created_at),
    },
    {
      ...sharedOpts,
      editable: false,
      field: 'updated_at',
      headerName: 'Updated At',
      renderCell: (params) => dateFormatterDetailed(params.row.updated_at),
    },
    {
      ...sharedOpts,
      editable: false,
      field: 'modified_by',
      headerName: 'Modified By',
      renderCell: (params) => PromoCodeUser({ userId: params.row.modified_by }),
    },
    {
      ...sharedOpts,
      sortable: false,
      editable: false,
      field: 'action',
      headerName: 'Action',
      renderCell: (params) => {
        if (rowModesModel[params.id]?.mode === GridRowModes.Edit) {
          return (
            <>
              <ButtonGroup variant="contained" size="small" disableElevation>
                <Button key={`${params.id}-save`} onClick={() => handleSaveCondition(params.row)}>
                  Save
                </Button>
                <Button key={`${params.id}-cancel`} onClick={() => handleCancelCondition(params.row)}>
                  Cancel
                </Button>
              </ButtonGroup>
              {params.row.id_promo_code_condition && (
                <ButtonGroup>
                  <Button key={`${params.id}-delete`} onClick={() => handleDeleteCondition(params.row)}>
                    Delete
                  </Button>
                </ButtonGroup>
              )}
            </>
          );
        }
        return (
          <ButtonGroup variant="text" size="small">
            <Button key={`${params.id}-edit`} onClick={() => handleEditCondition(params.row)}>
              Edit
            </Button>
          </ButtonGroup>
        );
      },
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
    },
  ];

  return (
    <Box mt={3}>
      <PageSubheader title="Conditions">
        <Stack direction="row">
          <Typography variant="h5" pr={2}></Typography>
          <Button onClick={handleAddCondition} variant="contained" size="small" startIcon={<AddIcon />}>
            Add Condition
          </Button>
        </Stack>
      </PageSubheader>
      {promoMeta ? (
        <DataGrid
          columns={getColumns(promoMeta)}
          editMode="row"
          autoHeight
          rows={conditions}
          rowModesModel={rowModesModel}
          initialState={{ pagination: { paginationModel: { pageSize: 10, page: 0 } } }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => {
            enqueueSnackbar(error.message, { autoHideDuration: 5000 });
          }}
          onRowEditStop={(params) => handleCancelCondition(params)}
          getRowHeight={() => 'auto'}
        />
      ) : (
        <Spinner />
      )}
    </Box>
  );
}

export default PromoConditions;
