import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';

import { Box, Button, Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { reportError } from '../../../utils/reportError';
import Image from '../Image';

import ImagePanel from './ImagePanel';
import ImageUploadField from './ImageUploadField';
import { buttonMessages as baseButtonMessages, buttonStates } from './states/submitButton';

const buttonMessages = { ...baseButtonMessages };
buttonMessages[buttonStates.default] = 'Save changes';

interface Image {
  id: string | number;
  publicImageId: string;
  order: number;
  title?: string;
  hidden?: boolean;
  filename?: string;
  is_hero?: boolean;
  source?: string;
}

interface Props {
  images: Image[];
  onAddImage: (cloudinaryId: string, filename: string) => any;
  onDeleteImage: (imageId: string | number) => any;
  onHideImage?: (imageId: string | number) => () => any;
  onUpdateImages: (images: Image[]) => any;
  hasHiddenToggle?: boolean;
  showSaveButton?: boolean;
}

const ImagesForm = forwardRef((props: Props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saveButtonState, setSaveButtonState] = useState(buttonStates.default);
  const [images, setImages] = useState<Image[]>([]);
  useEffect(() => {
    setImages(
      props.images.map((image) => {
        image.title = image.title || undefined;
        return image;
      }),
    );
  }, [props.images]);

  const isButtonStateSaving = saveButtonState === buttonStates.saving;
  const showSaveButton = props.showSaveButton !== undefined ? props.showSaveButton : true;

  useImperativeHandle(ref, () => ({
    addImageToList(newImages) {
      const combinedImages = images.concat(newImages);

      setImages(combinedImages);
      props.onUpdateImages(combinedImages);
    },
  }));

  const debouncedOnUpdateImages = useCallback(
    debounce((images) => {
      setImages(images);
      props.onUpdateImages(images);
    }, 1000),
    [props.onUpdateImages],
  );

  const handleChangeTitle = (imageId, newTitle) => {
    if (saveButtonState !== buttonStates.default) {
      setSaveButtonState(buttonStates.default);
    }
    const newImages = images.map((image) => {
      if (image.id === imageId) {
        image.title = newTitle;
      }
      return image;
    });
    debouncedOnUpdateImages(newImages);
  };

  const addImage = (_fieldKey, cloudinaryId, filename) => {
    props
      .onAddImage(cloudinaryId, filename)
      .then(async (response) => {
        const newImages = images.concat({
          ...response,
          publicImageId: cloudinaryId,
          title: undefined,
        });
        setImages(newImages);
        return newImages;
      })
      .then((newImages) => {
        props.onUpdateImages(newImages);
      })
      .catch((e) => {
        enqueueSnackbar('Error uploading image: ' + e.message, {
          variant: 'error',
        });
        reportError(e);
      });
  };

  const handleDeleteImage = async (imageId) => {
    await props.onDeleteImage(imageId);
    let newImages;
    setImages((images) => {
      newImages = images.filter((image) => image.id !== imageId);
      for (let i = 0; i < newImages.length; i++) {
        newImages[i].order = i + 1;
      }
      return newImages;
    });
    await props.onUpdateImages(newImages);
  };

  const handleHiddenImage = (imageId) => {
    return async () => {
      // toggle selected image
      const newImages = images.map((image) => {
        if (image.id === imageId) {
          image.hidden = !image.hidden;
        }
        return image;
      });

      // reorder images if changing to hidden
      const hiddenImages = newImages.filter((image) => image.hidden);
      const visibleImages = newImages.filter((image) => !image.hidden);
      for (let i = 0; i < newImages.length; i++) {
        if (visibleImages.includes(newImages[i])) {
          newImages[i].order = visibleImages.indexOf(newImages[i]) + 1;
        } else {
          newImages[i].order = visibleImages.length + hiddenImages.indexOf(newImages[i]) + 1;
        }
      }

      newImages.sort((a, b) => a.order - b.order);

      setImages(newImages);
      await props.onUpdateImages(newImages);
    };
  };

  const handleSaveImages = () => {
    setSaveButtonState(buttonStates.saving);
    props
      .onUpdateImages(images)
      .then(() => {
        setSaveButtonState(buttonStates.saved);
      })
      .catch(() => {
        setSaveButtonState(buttonStates.failed);
      });
  };

  const handleDragSort = ({ active, over }) => {
    if (active.id !== over.id) {
      setImages((images) => {
        const oldIndex = images.map((e) => e.id).indexOf(active.id);
        const newIndex = images.map((e) => e.id).indexOf(over.id);
        const newImageList = arrayMove(images, oldIndex, newIndex);
        let order = 1;
        for (const i in newImageList) {
          newImageList[i].order = order++;
        }
        return newImageList;
      });
      props.onUpdateImages(images);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  return (
    <Box mt={1} className="T-images-form">
      {showSaveButton && (
        <Box>
          <Button variant="contained" onClick={handleSaveImages} disabled={isButtonStateSaving}>
            {buttonMessages[saveButtonState]}
          </Button>
        </Box>
      )}

      <Grid mt={1} container spacing={2}>
        <DndContext onDragEnd={handleDragSort} sensors={sensors}>
          <SortableContext items={images}>
            {images.map((value) => (
              <ImagePanel
                {...value}
                key={value.id}
                onChangeTitle={handleChangeTitle}
                onDeleteImage={handleDeleteImage}
                onHideImage={handleHiddenImage}
                hasHiddenToggle={props.hasHiddenToggle}
              />
            ))}
          </SortableContext>
        </DndContext>

        <Grid md={4}>
          <Card
            variant="outlined"
            sx={{ height: '100%', display: 'flex', placeContent: 'center', placeItems: 'center' }}
          >
            <CardContent>
              <ImageUploadField label="image" field_key="newimage" onUpload={addImage} multiple={true} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
});

ImagesForm.displayName = 'ImagesForm';

export default ImagesForm;
