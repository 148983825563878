import React from 'react';

import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { GridCellProps } from '@mui/x-data-grid';

type Props = {
  row: GridCellProps;
};

export const TopicQualityBarChart = ({ row }: Props) => {
  const rowQualitiesSum = row.qualities.HIGH + row.qualities.LOW + row.qualities.DEFAULT;

  return (
    <ResponsiveContainer height={50} width="100%">
      <BarChart
        layout="vertical"
        margin={{ top: 20 }}
        data={[
          {
            name: 'Quality',
            high: row.qualities.HIGH / rowQualitiesSum,
            low: row.qualities.LOW / rowQualitiesSum,
            default: row.qualities.DEFAULT / rowQualitiesSum,
          },
        ]}
      >
        <XAxis type="number" hide />
        <YAxis type="category" hide />
        <Bar dataKey="high" stackId="a" fill="#90e08a" />
        <Bar dataKey="default" stackId="a" fill="#d9d7d7" />
        <Bar dataKey="low" stackId="a" fill="#ff8e8e" />
      </BarChart>
    </ResponsiveContainer>
  );
};
