import React from 'react';

import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import OfferForm from '~/components/Common/Forms/OfferForm/OfferForm';

import { OFFER_TYPE_BUNDLE_AND_SAVE, OFFER_TYPE_HOTEL, OFFER_TYPE_RENTAL } from '~/consts/offerTypes';

import OffersService from '~/services/OffersService';

import { reportError } from '~/utils/reportError';

import ContentLocalizationForm from './ContentLocalization/ContentLocalizationForm';
import CopyOfferModal from './CopyOfferModal';

class OfferEditContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCopyModalVisible: false,
      offerDetails: {},
      offerSchema: {},
      offerLocalization: {},
      offerTemplates: {},
      isReady: false,
      confirmedCopyOptions: [],
      isTemplateModalVisible: false,
      currentTab: 'general',
    };
  }

  fetchData = () => {
    const { match } = this.props;
    const { id_offer: offerId } = match.params;
    Promise.all([
      OffersService.getOfferSchema(offerId),
      OffersService.getOffer(offerId, { retain_dynamic_tags: true }),
      OffersService.getOfferLocalization(offerId),
      OffersService.getOfferTemplates(),
    ])
      .then((data) => {
        const offerSchema = data[0].patch.body.schema;
        delete offerSchema.properties['surcharge_paid_direct_to_vendor'];
        this.originalOfferDetails = data[1].result;
        this.setState({
          offerDetails: data[1].result,
          offerLocalization: data[2],
          offerTemplates: data[3].filter((template) => template.status === 'approved'),
          offerSchema,
          isReady: true,
        });
      })
      .catch((error) => {
        reportError(error);
      });
  };

  showCopyModal = () => {
    this.setState({
      isCopyModalVisible: true,
    });
  };

  showTemplateModal = () => {
    this.setState({
      isTemplateModalVisible: true,
    });
  };

  hideTemplateModal = () => {
    this.setState({
      isTemplateModalVisible: false,
    });
  };

  copyOfferData = (copiedOfferData, selectedCopyOptions) => {
    this.setState((prevState) => {
      return {
        offerDetails: Object.assign({}, prevState.offerDetails, copiedOfferData),
        isReady: true,
        confirmedCopyOptions: selectedCopyOptions,
      };
    });
    this.hideCopyModal();
  };

  onAddImage = (cloudinaryId, idOffer) => {
    return OffersService.createImage({ id_cloudinary_external: cloudinaryId }, idOffer).then((newImage) => {
      newImage.id = newImage.id_image;
      return newImage;
    });
  };

  hideCopyModal = () => {
    this.setState({
      isCopyModalVisible: false,
    });
  };

  componentDidMount = () => {
    this.fetchData();
    this.unlisten = this.props.history.listen((location) => {
      if (location.hash && this.state.currentTab !== 'general') {
        this.setState({
          currentTab: 'general',
        });
      }
    });
  };

  componentWillUnmount() {
    this.unlisten();
  }

  setAsNotReady = () => {
    this.setState({
      isReady: false,
    });
  };

  handleContentLocalizationChange = (fields) => {
    this.setState((prevState) => ({
      offerLocalization: {
        ...prevState.offerLocalization,
        localizationStrings: fields,
      },
    }));
  };

  getLocalizedContentTabExtra = () => {
    if (this.state.offerDetails.type !== OFFER_TYPE_HOTEL) return '';

    const numberOfFields = this.state.offerLocalization?.localizationStrings?.length;

    if (!numberOfFields) return `(0)`;

    return `(${numberOfFields})`;
  };

  handleTabChange = (_, newValue) => {
    this.setState({
      currentTab: newValue,
    });
  };

  render() {
    const { match } = this.props;
    const { id_offer: offerId } = match.params;
    return (
      <Container className="offer-edit" maxWidth="xl">
        <Helmet>
          <title>Offers | {this.state.offerDetails?.name || offerId} | Edit Offer</title>
        </Helmet>
        <Box mb={1}>
          <Button
            component={Link}
            href={'/offers/' + offerId}
            variant="text"
            startIcon={<ArrowBackIosNewIcon />}
            color="secondary"
            size="large"
          >
            Return to offer
          </Button>
          <Stack mt={5} mb={1} direction="row" justifyContent="space-between">
            <Typography variant="h3" component="h1" sx={{ fontWeight: 500 }}>
              Edit Offer
            </Typography>
            <IconButton onClick={this.showCopyModal} size="large" color="primary" aria-label="Copy Offer">
              <ContentCopyIcon />
            </IconButton>
          </Stack>
          <Divider />
        </Box>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <Box
              mt={8}
              sx={{
                backgroundColor: 'white',
                position: 'sticky',
                top: 0,
                zIndex: 10,
              }}
              p={1}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                aria-label="Vertical tabs example"
                sx={{ border: 0 }}
                value={false}
              >
                <Tab key="general-settings" label="General Settings" component={Link} href="#general-settings" />
                <Tab key="offer-fields" label="Offer Page" component={Link} href="#offer-fields" />
                <Tab key="tags-and-marketing" label="Tags & Marketing" component={Link} href="#tags-and-marketing" />
                <Tab key="offer-settings" label="Offer Settings" component={Link} href="#offer-settings" />
                {this.state.isReady &&
                  this.state.offerDetails?.type !== OFFER_TYPE_BUNDLE_AND_SAVE &&
                  this.state.offerDetails?.type !== OFFER_TYPE_RENTAL && (
                    <Tab key="flights" label="Flights" component={Link} href="#flights" />
                  )}
              </Tabs>
            </Box>
          </Grid>
          <Grid item md={10}>
            <Tabs orientation="horizontal" value={this.state.currentTab} onChange={this.handleTabChange}>
              <Tab key="general" label="Default Content" value="general" />
              <Tab
                key="content-localization"
                label={`Localised Content ${this.getLocalizedContentTabExtra()}`}
                value="content-localization"
              />
            </Tabs>
            <Box
              sx={{
                display: this.state.currentTab === 'general' ? 'block' : 'none',
              }}
            >
              {!this.state.isReady && (
                <Stack mt={3} alignItems="center">
                  <CircularProgress />
                </Stack>
              )}
              {this.state.isReady && (
                <Box mt={3}>
                  <OfferForm
                    offer={this.state.offerDetails}
                    schema={this.state.offerSchema}
                    templates={this.state.offerTemplates}
                    confirmedCopyOptions={this.state.confirmedCopyOptions}
                    onAddImage={this.onAddImage}
                    originalOfferDetails={this.originalOfferDetails}
                    contentLocalization={this.state.offerLocalization?.localizationStrings || []}
                    generatorContext={
                      this.state.offerDetails && {
                        name: this.state.offerDetails.name,
                        id: this.state.offerDetails.id_salesforce_external,
                        offerType: this.state.offerDetails.type,
                        description: this.state.offerDetails.description,
                        location: this.state.offerDetails.location,
                        model: 'offer',
                        getting_there: this.state.offerDetails.getting_there,
                      }
                    }
                  />
                  <CopyOfferModal
                    isCopyModalVisible={this.state.isCopyModalVisible}
                    setAsNotReady={this.setAsNotReady}
                    hideCopyModal={this.hideCopyModal}
                    copyOfferData={this.copyOfferData}
                    currentOfferStatus={this.state.offerDetails.status}
                    originalOfferDetails={this.originalOfferDetails}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: this.state.currentTab === 'content-localization' ? 'block' : 'none',
              }}
            >
              {!this.state.isReady && (
                <Stack mt={3} alignItems="center">
                  <CircularProgress />
                </Stack>
              )}
              {this.state.offerDetails.type === OFFER_TYPE_HOTEL && this.state.isReady ? (
                <ContentLocalizationForm
                  offer={this.state.offerDetails}
                  fields={this.state.offerLocalization?.localizationStrings || []}
                  onChange={this.handleContentLocalizationChange}
                />
              ) : (
                <p>Only for Lux Exclusive Hotels</p>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(OfferEditContainer);
