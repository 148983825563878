import React from 'react';

import { Stack, Typography } from '@mui/material';

interface Props {
  label: string;
  value: string;
  boldPrice?: boolean;
}

const PriceSummaryField = ({ label, value, boldPrice }: Props) => {
  return (
    <Stack flexGrow={1} direction="row" spacing={1} justifyContent="space-between">
      <Typography>{label}:</Typography>
      <Typography fontWeight={boldPrice ? 'bold' : 'normal'}>{value}</Typography>
    </Stack>
  );
};

export default PriceSummaryField;
