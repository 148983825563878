import React, { Fragment } from 'react';

import { Alert } from '@mui/material';

import { LIST_VISIBILITY } from '../../../../consts/schedule';
import OffersService from '../../../../services/OffersService';
import { reportError } from '../../../../utils/reportError';

import LEDScheduleBulkUpdateContainer from './LEDScheduleBulkUpdateContainer';
import LEDScheduleTable from './LEDScheduleTable';

interface Props {
  offerId: string;
  shouldMigrateSchedule: boolean;
}

interface State {
  isUpdated: boolean;
  isFetched: boolean;
  schedules: App.Schedule[];
  availableMarketingChannels: string[];
  defaultMarketingChannels: string[];
  isMarketingChannelsFetched: boolean;
  error?: Error;
  updateErrorMessages: string[];
}

export default class LEDSchedules extends React.Component<Props, State> {
  state: State = {
    isUpdated: false,
    isFetched: false,
    schedules: [],
    availableMarketingChannels: [],
    defaultMarketingChannels: [],
    isMarketingChannelsFetched: false,
    error: null,
    updateErrorMessages: null,
  };

  componentDidMount() {
    this.fetchOfferSchedules();
  }

  onSaveFinished = () => {
    this.setState(
      {
        isUpdated: !this.state.isUpdated,
        error: null,
        updateErrorMessages: null,
      },
      () => {
        this.fetchOfferSchedules();
      },
    );
  };

  clearErrors = () => {
    this.setState({
      error: null,
      updateErrorMessages: null,
    });
  };

  onError = (error: Error) => {
    this.setState({
      error,
    });
    reportError(error);

    // Re-fetch the offer in case the schedule data is now inconsistent between client/server
    this.fetchOfferSchedules();
  };

  onUpdateErrors = (updateErrorMessages: string[]) => {
    this.setState({
      updateErrorMessages,
    });
  };

  onDelete = (id: number) => {
    if (this.props.shouldMigrateSchedule) {
      return;
    }
    OffersService.deleteSchedule(this.props.offerId, id)
      .then(() => {
        this.onSaveFinished();
      })
      .catch((e) => {
        this.onError(e);
        reportError(e);
      });
  };

  fetchOfferSchedules = () => {
    OffersService.getOffer(this.props.offerId)
      .then((response) => {
        this.setState({
          schedules: response.result.brand_schedules,
          isFetched: true,
        });
      })
      .catch((error) => {
        this.setState({
          schedules: [],
          isFetched: false,
          error,
        });
        reportError(error);
      });
  };

  render() {
    const { offerId, shouldMigrateSchedule } = this.props;
    const { schedules, isFetched, isMarketingChannelsFetched, error, updateErrorMessages } = this.state;
    const showSchedules = isFetched;

    return (
      <div className="fp-schedule-container">
        <h3>Lifestyle Brands</h3>
        <div className="fp-schedule-inner">
          {error && <Alert severity="error">{error.message}</Alert>}
          {updateErrorMessages && (
            <Alert severity="error">
              Error updating schedules:
              <ul>
                {updateErrorMessages.map((message, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            </Alert>
          )}
          {showSchedules && (
            <Fragment>
              <LEDScheduleBulkUpdateContainer
                offerId={offerId}
                schedules={schedules}
                type={LIST_VISIBILITY}
                onOpen={this.clearErrors}
                onSaveFinished={this.onSaveFinished}
                shouldMigrateSchedule={shouldMigrateSchedule}
                onError={this.fetchOfferSchedules}
              />
              <LEDScheduleTable
                offerId={offerId}
                schedules={schedules}
                type={LIST_VISIBILITY}
                onDelete={this.onDelete}
                onSaveFinished={this.onSaveFinished}
                onError={this.onError}
                onUpdateErrors={this.onUpdateErrors}
                shouldMigrateSchedule={shouldMigrateSchedule}
              />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}
