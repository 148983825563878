import type { SourceRoom } from './types';

const sqmToSqft = (sqm: number) => {
  return Math.round(sqm * 10.7639);
};

const generateRoomSizeText = (room: SourceRoom): string => {
  const roomSizeSQM = room?.roomSize ? room?.roomSize : undefined;
  const roomSizeFT = room?.roomSize ? sqmToSqft(room?.roomSize) : undefined;

  const roomSizeText = roomSizeSQM ? `${roomSizeSQM} m² / ${roomSizeFT} ft²` : 'N/A';
  return roomSizeText;
};

const generateOccupancyText = (room: SourceRoom) => {
  const occupancyFromSourceContentMaxAdults = room?.sourceContent?.maxAdults;
  const occupancyFromSourceContentMaxChildren = room?.sourceContent?.maxChildren;
  const occupancyFromSourceContentMaxTotal = room?.sourceContent?.maxPax;

  const occupancyFromSourceContent = occupancyFromSourceContentMaxAdults
    ? `Adults: ${occupancyFromSourceContentMaxAdults}, Children: ${occupancyFromSourceContentMaxChildren}, Total: ${occupancyFromSourceContentMaxTotal}`
    : undefined;

  const occupancyAge = room?.occupancy?.age_categories
    ? `Min adult: ${room?.occupancy?.age_categories?.Adult?.minimum_age}, Min child: ${room?.occupancy?.age_categories?.Child?.minimum_age}, `
    : 'N/A';

  const occupancyAllowed = room?.occupancy?.max_allowed
    ? `Adults: ${room.occupancy.max_allowed.adults}, Children: ${room.occupancy.max_allowed.children}, Total: ${room.occupancy.max_allowed.total}`
    : occupancyFromSourceContent ?? 'N/A';
  return { occupancyAge, occupancyAllowed };
};

const generateViewsText = (room: SourceRoom) => {
  const description = room?.description ?? 'N/A';
  const doesDescriptionContainView = description.toLowerCase().includes('view');
  const views = room?.views?.join(' ') || (doesDescriptionContainView ? 'Check description for view info' : 'N/A');
  return views;
};

export { generateRoomSizeText, generateOccupancyText, generateViewsText };
