import React from 'react';

import styled from 'styled-components';

import { dateNow, formatDateShort, isAfter } from '~/services/TimeService';

import { pluralizeToString } from '~/utils/tourV2';

const PromotionList = styled.ul`
  list-style-type: disc;
  margin-left: 16px;
  & > li {
    margin-bottom: 8px;
    &.outdated {
      color: #666;
    }
  }
`;

const Promotions = (props) => {
  const now = dateNow();

  return (
    <PromotionList>
      {props.promotions.map((promotion) => (
        <li
          key={promotion.name}
          className={
            isAfter(now, promotion.period_schedule.from) && isAfter(promotion.period_schedule.to, now) ? '' : 'outdated'
          }
        >
          <div>{promotion.name}</div>
          <div>
            Travel between: {formatDateShort(promotion.travel_period.from)} -{' '}
            {formatDateShort(promotion.travel_period.to)}
          </div>
          <div>
            Sale period: {formatDateShort(promotion.period_schedule.from)} -{' '}
            {formatDateShort(promotion.period_schedule.to)}
          </div>
          <div>Min stay: {pluralizeToString('night', promotion.los)}</div>
        </li>
      ))}
    </PromotionList>
  );
};

export default Promotions;
