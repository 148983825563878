import React, { useEffect, useMemo, useState } from 'react';

import { DndContext, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { useSnackbar } from 'notistack';
import { Control, Controller, useController, useFieldArray } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { ArrayFieldItem, Checkbox, Input } from '~/components/Common/Forms/fields';

import ReservationService from '~/services/ReservationService';

interface Props {
  control: Control<any>;
  label: string;
  name: string;
  setValue: (name: string, value: unknown, config?: object) => void;
  importOfferInclusions?: () => void;
}

const OfferInclusionsSelect = ({ control, label, name, setValue, importOfferInclusions }: Props) => {
  const [inclusionsCategories, setInclusinsCategories] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ReservationService.getInclusionsCategories()
      .then(({ result }) => setInclusinsCategories(result))
      .catch((e) =>
        enqueueSnackbar(`Failed to fetch offer inclusion categories: ${e?.message}`, { variant: 'warning' }),
      );
    setLoading(false);
  }, []);

  const dataArray = useFieldArray({
    control,
    name,
  });
  const {
    field: { value: dataValue, onChange: onChangeArray },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const sortableData = useMemo<UniqueIdentifier[]>(
    () => dataArray.fields?.map((item, index) => `${item.id}-${index}`) ?? [],
    [dataArray.fields],
  );
  function handleDragEnd(event) {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const oldIndex = sortableData.indexOf(active?.id);
      const newIndex = sortableData.indexOf(over?.id);
      const newArray = arrayMove(dataValue, oldIndex, newIndex);

      setValue(name, newArray);
    }
  }

  const onInputChange = (event, name, index) => {
    setValue(event.target.name, event.target.value);
    updateValue(name, index, event.target.value);
  };

  const updateValue = (name, index, value, rest = {}) => {
    const newArray = [...dataValue];
    const updatedValue = { ...newArray[index], [name]: value, ...rest };

    newArray.splice(index, 1, updatedValue);

    onChangeArray(newArray);
  };

  const onAutocompleteChange = (value, key, index) => {
    const id = `${name}.${index}.${key}`;
    const iconId = `${name}.${index}.icon`;
    const newValue = value?.name ?? '';
    const newIcon = value?.icon ?? '';

    setValue(id, newValue);
    setValue(iconId, newIcon);
    updateValue(key, index, newValue, { icon: newIcon });
  };

  const onCheckboxChange = (event, name, index) => {
    const newArray = [...dataValue];
    const updatedValue = { ...newArray[index], [name]: event.target.checked };

    newArray.splice(index, 1, updatedValue);
    setValue(event.target.name, event.target.checked);

    onChangeArray(newArray);
  };

  const onRemove = (index: number) => {
    dataArray.remove(index);
    const newArray = [...dataValue];
    newArray.splice(index, 1);
    setValue(name, newArray);
  };

  const onImportInclusionsClick = async () => {
    setLoading(true);
    await importOfferInclusions();
    setLoading(false);
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs>
          <Typography variant="h6" mb={1}>
            {label}
          </Typography>
        </Grid>
        {importOfferInclusions && (
          <Grid item>
            <Button onClick={onImportInclusionsClick} disabled={loading}>
              Import from base package
            </Button>
          </Grid>
        )}
      </Grid>

      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext items={sortableData}>
          {dataArray.fields.map((item, index) => (
            <FormControl error={error?.[index]} key={item.id} sx={{ display: 'flex', mb: 2 }}>
              <ArrayFieldItem itemKey={item.id} index={index}>
                <Stack flexGrow={1} direction="row" spacing={2} alignItems="center">
                  <Box flexGrow={2}>
                    <Controller
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          value={field.value || ''}
                          error={!!fieldState.error}
                          fullWidth
                          label="Inclusion"
                          onChange={(event) => onInputChange(event, 'text', index)}
                          variant="outlined"
                        />
                      )}
                      name={`${name}.${index}.text`}
                      control={control}
                    />
                  </Box>

                  <Box flexGrow={1}>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          onChange={(e: any, newValue: string | null) => {
                            onAutocompleteChange(newValue, 'category', index);
                          }}
                          isOptionEqualToValue={(option, value) => option.name === value}
                          value={field.value}
                          options={inclusionsCategories?.sort((a, b) => -b.category.localeCompare(a.category))}
                          groupBy={(option) => option.category}
                          getOptionLabel={(option) => option.name || option}
                          renderInput={(params) => <TextField {...params} label="Category (Icon)" />}
                        />
                      )}
                      name={`${name}.${index}.category`}
                      control={control}
                    />
                    <Input control={control} hidden name={`${name}.${index}.icon`} />
                  </Box>

                  <Box>
                    <Checkbox
                      control={control}
                      label="Highlight"
                      name={`${name}.${index}.is_highlighted`}
                      onChange={(event) => onCheckboxChange(event, 'is_highlighted', index)}
                    />
                  </Box>

                  <Box>
                    <IconButton onClick={() => onRemove(index)} color="error">
                      <DeleteIcon fontSize="medium" />
                    </IconButton>
                  </Box>
                </Stack>
              </ArrayFieldItem>
            </FormControl>
          ))}
          {!dataArray.fields.length && (
            <Box
              sx={{
                p: 2,
                width: 1,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {!loading && <Typography variant="body2">Empty</Typography>}
              {loading && <CircularProgress />}
            </Box>
          )}
          <Box
            sx={{
              width: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              onClick={() =>
                dataArray.append({
                  text: '',
                  is_highlighted: false,
                })
              }
              color="primary"
            >
              <AddIcon />
            </IconButton>
          </Box>
        </SortableContext>
      </DndContext>
    </Stack>
  );
};

export default OfferInclusionsSelect;
