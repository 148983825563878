import React from 'react';

import { Box, Typography } from '@mui/material';

type Props = {
  title: string;
  subtitle?: string;
};

export default function WrapDetails(props: Props) {
  return (
    <Box>
      <Typography>{props.title}</Typography>
      {props.subtitle && <Typography>{props.subtitle}</Typography>}
    </Box>
  );
}
