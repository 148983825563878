import React from 'react';

import { Control } from 'react-hook-form';
import { theme } from '~/theme';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack, Typography } from '@mui/material';

import { Checkbox, Input } from '~/components/Common/Forms/fields';

interface Props {
  control: Control<any>;
  isDepositThresholdEnabled: boolean;
}

const OfferSettingsAccordion = ({ control, isDepositThresholdEnabled }: Props) => {
  return (
    <Accordion disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="offer-settings"
        sx={{
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <Typography variant="h5">Offer Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container columnSpacing={6}>
          <Grid item md={4}>
            <Checkbox control={control} name="no_index" label="Noindex (prevent search engine crawling)" />
            <Checkbox
              control={control}
              name="tour_flight_details_required"
              label="Are LE Tour Flight Details Required"
            />
            <Checkbox control={control} name="staff_discount_enabled" label="Staff discount enabled" />
            <Checkbox control={control} name="ignore_vendor_email_notifications" label="Turn off email notifications" />
            <Checkbox control={control} name="is_enable_deposit_thresholds" label="Set deposit thresholds" />
            {isDepositThresholdEnabled && (
              <Stack spacing={2} mr={4} mb={2} mt={1}>
                <Input
                  control={control}
                  fullWidth
                  label="Deposit percentage"
                  name="deposit_thresholds.percentage"
                  type="number"
                />
                <Input
                  control={control}
                  fullWidth
                  label="Number of days"
                  name="deposit_thresholds.number_of_days"
                  type="number"
                />
              </Stack>
            )}
          </Grid>
          <Grid item md={4}>
            <Checkbox control={control} name="is_search_by_name_hidden" label="Hidden by name" />
            <Checkbox control={control} name="is_search_hidden" label="Hidden by destination" />
            <Checkbox control={control} name="is_discount_pill_hidden" label="Hide discount pill" />
            <Checkbox control={control} name="is_partner_property" label="Display as Partner Property" />
            <Checkbox
              control={control}
              name="surcharge_paid_direct_to_vendor"
              label="Surcharges paid directly to vendor"
            />
            <Checkbox control={control} name="disable_best_price_guarantee" label="Disable Best Price Guarantee" />
            <Checkbox control={control} name="disable_deposit" label="Disable deposit" />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default OfferSettingsAccordion;
