import React from 'react';

import { useSnackbar } from 'notistack';

import { Button, Dialog, DialogTitle, Stack } from '@mui/material';

import { AgentHubCommissionRule, deleteCommissionRule } from '~/services/AgentHub/AgentService';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  listCommissions: () => void;
  data: AgentHubCommissionRule;
}

export default function DeleteCommissionDialog({ isOpen, onClose, data, listCommissions }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  async function handleDeleteCommission(id: string) {
    try {
      await deleteCommissionRule(id);
      onClose();
      listCommissions();
    } catch (err) {
      enqueueSnackbar(err?.message || 'Unknown error', { variant: 'error' });
    }
  }

  return (
    <Dialog open={isOpen} fullWidth onClose={onClose}>
      <DialogTitle>Delete commission</DialogTitle>
      <Stack direction={'row'} alignItems={'center'} padding={3}>
        <Stack spacing={2}>Are you sure you want to delete the commission "{data?.description}"?</Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-between'} padding={3}>
        <Button onClick={onClose} variant="outlined">
          No
        </Button>
        <Button variant="contained" onClick={() => handleDeleteCommission(data.id)}>
          Yes
        </Button>
      </Stack>
    </Dialog>
  );
}
