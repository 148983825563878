import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

export const SAILING_STATUS = ['ACTIVE', 'INACTIVE'];

export const SELECT_REQUIRES_APPROVAL = ['ALL', 'TRUE', 'FALSE'];

export const SELECT_SAILING_STATUS = ['ALL', 'ACTIVE', 'INACTIVE'];

export type DataFeedDiffs = {
  id: string;
  name: string;
  database: string | number;
  dataFeed: string | number;
};

export const DATA_FEED_DIFFS_DEFAULT: DataFeedDiffs[] = [
  {
    id: '',
    name: '',
    database: '',
    dataFeed: '',
  },
];

export type SearchFilters = {
  name?: string;
  offerId?: string;
  vendorId?: string;
  sailingExternalId?: number;
  sailingPlan?: string;
  sailingDurationMin?: number;
  sailingDurationMax?: number;
  departureDateStart?: string;
  departureDateEnd?: string;
  departurePortExternalIds?: Array<number>;
  status?: 'ALL' | 'ACTIVE' | 'INACTIVE';
  requiresApproval?: 'ALL' | 'TRUE' | 'FALSE';
  shipName?: string;
  shipExternalId?: number;
};

export const SEARCH_FILTERS_DEFAULT: SearchFilters = {
  name: '',
  offerId: '',
  vendorId: '',
  sailingExternalId: 0,
  sailingPlan: '',
  sailingDurationMin: 0,
  sailingDurationMax: 0,
  departureDateStart: '',
  departureDateEnd: '',
  departurePortExternalIds: [],
  status: 'ALL',
  requiresApproval: 'ALL',
  shipName: '',
  shipExternalId: 0,
};

export const DATA_FEED_DEFAULT: CruisesContract.DataFeedResponse = {
  id: '',
  filePath: '',
  createdAt: '',
  dataFeed: {} as CruisesContract.SailingDataFeed,
};

export const DEFAULT_SAILING_RESPONSE: CruisesContract.DepartureByIdResponse = {
  id: null,
  name: '',
  duration: 0,
  offerId: '',
  cruiseLine: '',
  returnDate: '',
  departureDate: '',
  status: 'INACTIVE',
  externalId: 0,
  sailingPlan: '',
  requiresApproval: false,
  offer: {
    id: null,
    samePorts: false,
    numberOfCities: 0,
    numberOfCountries: 0,
    name: '',
    displayName: '',
    returnPortName: '',
    departurePortName: '',
    returnPortCode: '',
    departurePortCode: '',
  },
  vendor: {
    id: '',
    name: '',
    code: '',
    externalId: 0,
    imageId: '',
  },
  ship: {
    id: '',
    name: '',
    imageId: '',
    externalId: 0,
  },
  markets: [
    {
      id: '',
      name: '',
    },
  ],
  itineraries: [
    {
      id: '',
      cruiseOfferId: '',
      portId: 0,
      dayNumber: 0,
      description: '',
      departureTime: '',
      arrivalTime: '',
      createdAt: '',
      updatedAt: '',
    },
  ],
  destination: {
    id: '',
    name: '',
    externalId: 0,
    description: '',
  },
};

export type PurchaseResponse = {
  _links: unknown;
  count: number;
  total: number;
  status: number;
  result: unknown[];
  message: string | null;
};

export const PURCHASE_RESPONSE = {
  _links: {},
  count: 0,
  total: 0,
  status: 0,
  result: [],
  message: '',
};
