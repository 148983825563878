import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button, Container, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import { AgentHubCommissionRule, getCommissionRules } from '~/services/AgentHub/AgentService';
import { formatDateSlashes } from '~/services/TimeService';

import DeleteCommissionDialog from './AgentHubCommissions/DeleteCommissionDialog';
import EditCommissionDialog from './AgentHubCommissions/EditCommissionDialog';
import AgentHubCreateCommissionForm from './AgentHubCreateCommissionForm';

const AgentCommissions = () => {
  const [commissionList, setCommissionList] = useState<Array<AgentHubCommissionRule>>([]);
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [isEditModeOpen, setIsEditModeOpen] = useState<boolean>(false);
  const [commissionData, setCommissionData] = useState<AgentHubCommissionRule>();
  const { enqueueSnackbar } = useSnackbar();

  const openEditMode = () => setIsEditModeOpen(true);
  const closeEditMode = () => setIsEditModeOpen(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const listCommissions = useCallback(() => {
    setIsListLoading(true);
    getCommissionRules()
      .then((res) => {
        if (res) {
          setCommissionList(res);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setIsListLoading(false);
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    listCommissions();
  }, [listCommissions]);

  const sharedOpts: Partial<GridColDef> = {
    editable: false,
    sortable: true,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    flex: 2,
  };

  const columns: Array<GridColDef> = [
    { ...sharedOpts, field: 'description', headerName: 'Description' },
    { ...sharedOpts, field: 'commissionType', headerName: 'Type' },
    {
      ...sharedOpts,
      field: 'commissionPercentage',
      headerName: 'Commission Percentage',
      headerAlign: 'center',
      align: 'center',
    },
    {
      ...sharedOpts,
      field: 'startDate',
      headerName: 'Start Date',
      renderCell: (params) => (params.value ? formatDateSlashes(params.value) : ''),
    },
    {
      ...sharedOpts,
      field: 'endDate',
      headerName: 'End Date',
      renderCell: (params) => (params.value ? formatDateSlashes(params.value) : ''),
    },
    {
      ...sharedOpts,
      field: 'edit',
      headerName: 'Edit',
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() => {
            openEditMode();
            setCommissionData(params.row);
          }}
        >
          <ModeEditIcon />
        </Button>
      ),
    },
    {
      ...sharedOpts,
      field: 'delete',
      flex: 1,
      sortable: false,
      headerName: 'Delete',
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              openDeleteModal();
              setCommissionData(params.row);
            }}
            variant="text"
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Agent Commissions</title>
      </Helmet>

      <Grid item xs={12}>
        <PageSubheader title="Commission rules" />

        <DataGrid
          loading={isListLoading}
          columns={columns}
          getRowId={(row) => row.id}
          rows={commissionList}
          autoHeight
          initialState={{ pagination: { paginationModel: { pageSize: 10, page: 0 } } }}
          onProcessRowUpdateError={(error) => {
            enqueueSnackbar(error.message, { autoHideDuration: 5000 });
          }}
          getRowHeight={() => 'auto'}
        />

        <AgentHubCreateCommissionForm listCommissions={listCommissions} />
      </Grid>

      <EditCommissionDialog
        isOpen={isEditModeOpen}
        onClose={closeEditMode}
        data={commissionData}
        listCommissions={listCommissions}
      />
      <DeleteCommissionDialog
        listCommissions={listCommissions}
        data={commissionData}
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
      />
    </Container>
  );
};

export default AgentCommissions;
