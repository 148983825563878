import React from 'react';

import fileDownload from 'react-file-download';
import { Link, withRouter } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button } from '@mui/material';

import { getRatePlansPDF } from '~/services/PDFService';
import ReservationService from '~/services/ReservationService';

import { reportError } from '~/utils/reportError';

import RatePlanList from '../../Common/RatePlanList';
import Spinner from '../../Common/Spinner';

import CopyRatePlansModal from './CopyRatePlansModal';

class VendorRatePlansPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.editBtnFormatter = this.editBtnFormatter.bind(this);
    this.cloneBtnFormatter = this.cloneBtnFormatter.bind(this);

    this.state = {
      ratePlans: [],
      cloning: false,
      isCopyModalVisible: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  editBtnFormatter(ratePlanId) {
    const to = `/vendors/${this.props.vendorId}/rate-plans/${ratePlanId}`;

    return (
      <Button component={Link} to={to} variant="text">
        Edit
      </Button>
    );
  }

  showCopyModal = () => {
    this.setState({
      isCopyModalVisible: true,
    });
  };

  hideCopyModal = () => {
    this.setState({
      isCopyModalVisible: false,
    });
  };

  ratePlansCopied = async () => {
    await this.fetchData();
  };

  cloneRatePlan = async (id) => {
    this.setState({ cloning: true });
    try {
      await ReservationService.cloneRatePlan(id);
      await this.fetchData();
    } catch (error) {
      reportError(error);
    }
    this.setState({ cloning: false });
  };

  cloneBtnFormatter(ratePlanId) {
    const { cloning } = this.state;

    return (
      <Button variant="text" color="error" onClick={() => this.cloneRatePlan(ratePlanId)} disabled={cloning}>
        Clone {cloning && <Spinner size={15} inline={true} />}
      </Button>
    );
  }

  async fetchData() {
    this.setState({
      fetching: true,
    });
    const { result } = await ReservationService.getRatePlans(this.props.vendorId);

    this.setState({
      ratePlans: result,
      fetching: false,
    });
  }
  createRatePlansDetails = () => {
    return this.state.ratePlans.map((eachPlan) => {
      const comm_or_markup = eachPlan.rate_type === 'net' ? eachPlan.markup : eachPlan.commission;
      return {
        name: eachPlan.name,
        type: eachPlan.rate_type,
        comm_or_markup,
      };
    });
  };

  downloadRatePlans = async () => {
    this.setState({
      ratePlansDownloading: true,
      downloadFailed: false,
    });

    try {
      const list = this.createRatePlansDetails();
      const pdf = await getRatePlansPDF({
        list,
        propertyName: this.props.vendorName,
      });
      const fileName = `LuxuryEscapes-Rate-Plans-${this.props.vendorId}.pdf`;

      await fileDownload(pdf, fileName);
    } catch (error) {
      console.warn(error);
      this.setState({
        downloadFailed: true,
        ratePlansDownloading: false,
      });
    } finally {
      this.setState({
        ratePlansDownloading: false,
      });
    }
  };

  isRatePlansListPresent = () => {
    return this.state.ratePlans && this.state.ratePlans.length > 0;
  };

  render() {
    const showRatePlansDownload = this.isRatePlansListPresent();
    const toNew = `/vendors/${this.props.vendorId}/rate-plans`;
    const { ratePlansDownloading, downloadFailed } = this.state;

    return (
      <div>
        <Box textAlign="right" mb={2}>
          <Button onClick={this.showCopyModal} variant="text">
            <ContentCopyIcon />
            <span className="sr-only">Copy Offer</span>
          </Button>

          {showRatePlansDownload && (
            <Button type="button" variant="text" onClick={this.downloadRatePlans}>
              {ratePlansDownloading && <i className="fa fa-circle-o-notch fa-spin" style={{ marginRight: '5px' }} />}
              {!ratePlansDownloading && <DownloadIcon />}
              {ratePlansDownloading && <span>Downloading Rateplans</span>}
              {!ratePlansDownloading && downloadFailed && <span>Download Failed! Try Again</span>}
            </Button>
          )}
        </Box>

        <RatePlanList
          loading={this.state.fetching}
          ratePlans={this.state.ratePlans}
          editBtnFormatter={this.editBtnFormatter}
          cloneBtnFormatter={this.cloneBtnFormatter}
        />

        <Button variant="contained" component={Link} to={toNew}>
          + New
        </Button>

        <CopyRatePlansModal
          isCopyModalVisible={this.state.isCopyModalVisible}
          hideCopyModal={this.hideCopyModal}
          vendorId={this.props.vendorId}
          ratePlansCopied={this.ratePlansCopied}
        />
      </div>
    );
  }
}

export default withRouter(VendorRatePlansPageContainer);
