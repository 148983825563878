import React from 'react';

import { useHistory } from 'react-router';

import { CorporateFare, CreditCard, GroupAdd } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';

import { definitions, operations } from '@luxuryescapes/contract-svc-promo';

import { PromoMeta, sortPromoItemByCategoryAndId } from '~/services/PromoService';

import { PromoToggleFormatter } from '../formatters/PromoToggleFormatter';

import { PromoResponseItem } from './PromoResponseItem';

type PromoOrderTableCellProps = {
  response: operations['calculateDiscount']['responses']['200']['content']['application/json'];
  meta: PromoMeta;
};

function PromoResponse({ response, meta }: PromoOrderTableCellProps) {
  const history = useHistory();

  if (!response || !response.result || !response.result.promo) {
    return null;
  }

  const { promo } = response.result;

  const { has_bin_numbers, is_corporate, items, promo_category, promo_toggles } = promo;

  const handleGridClick = (item: definitions['Discount Response']['promo']['items'][0]) => {
    if (item.categoryBK === 'subscription') return;
    history.push(`/offers/${item.offerId}`);
  };

  return (
    <Box>
      {items.sort(sortPromoItemByCategoryAndId).map((item, i) => (
        <PromoResponseItem
          key={`${item.offerId || item.itemId}_${i}`}
          item={item}
          currencyCode={promo.currency}
          handleGridClick={handleGridClick}
          meta={meta}
        />
      ))}
      <PromoToggleFormatter promoToggles={promo_toggles} promoToggleInfo={meta?.promo_toggles} />
      <Stack direction="row">
        {is_corporate && (
          <Box title={`Corporate Promo for ${promo.corporate_name}`}>
            <CorporateFare />
          </Box>
        )}
        {has_bin_numbers && (
          <Box title="On Payment, we will check this BIN number matches what is configured on this promo">
            <CreditCard />
          </Box>
        )}
        {promo_category !== 'regular' && (
          <Box title={`Promo Category: ${promo_category}`}>
            <GroupAdd />
          </Box>
        )}
      </Stack>
    </Box>
  );
}

export { PromoResponse };
