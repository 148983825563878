import React from 'react';

import PropTypes from 'prop-types';

import { ROLE_ADMIN_USER } from '~/consts/roles';

interface Props {
  requiredRoles?: string[];
}

class PermissionedComponent extends React.Component<Props> {
  static contextTypes = {
    user: PropTypes.object,
  };

  render() {
    const requiredRoles =
      this.props.requiredRoles && this.props.requiredRoles.length > 0 ? this.props.requiredRoles : [ROLE_ADMIN_USER];

    if (this.context.user) {
      for (const role of requiredRoles) {
        if (this.context.user.roles.includes(role)) {
          return this.props.children;
        }
      }
    }
    return null;
  }
}

export default PermissionedComponent;
