import React from 'react';

import { withRouter } from 'react-router-dom';

import { Box, Button, Dialog, DialogActions, Stack } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';

import ReservationService from '~/services/ReservationService';

import { reportError } from '~/utils/reportError';

const INITIAL_STATE = {
  copyVendorId: '',
  hasError: false,
  errorMessage: '',
};

class CopyRatePlansModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  hideCopyModal = () => {
    this.setState(INITIAL_STATE);
    this.props.hideCopyModal();
  };

  copyRatePlans = async (event) => {
    event.preventDefault();

    // remove old errors if any
    this.setState({
      hasError: false,
      errorMessage: '',
    });

    try {
      await ReservationService.copyRatePlans(this.props.vendorId, this.state.copyVendorId);
      await this.props.ratePlansCopied();
      this.hideCopyModal();
    } catch (error) {
      reportError(error);

      this.setState({
        hasError: true,
        errorMessage: 'An error occurred. Rate plans were not copied.',
      });
    }
  };

  handleChange = (event) => {
    this.setState({ copyVendorId: event.target.value });
  };

  render() {
    return (
      <div>
        <Dialog open={this.props.isCopyModalVisible} onClose={this.hideCopyModal}>
          <h3>Copy rate plans from another vendor ID</h3>

          <form onSubmit={this.copyRatePlans.bind(this)}>
            <Box controlId="OfferSearch">
              {this.state.hasError && <ErrorDisplay message={this.state.errorMessage} />}
              <Box className="copy-offer-form">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box flexGrow={3}>
                    <input
                      required
                      type="text"
                      ref="searchInput"
                      placeholder="Type vendor ID here"
                      value={this.state.copyVendorId}
                      onChange={this.handleChange}
                    />
                  </Box>

                  <Box flexGrow={1}>
                    <Button type="submit" variant="contained" fullWidth>
                      Copy
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </form>

          <DialogActions>
            <Button variant="text" onClick={this.hideCopyModal}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(CopyRatePlansModalContainer);
