import React from 'react';

import { useRouteMatch } from 'react-router-dom';

import VendorRatePlansPageContainer from './VendorRatePlansPageContainer';

type Props = { vendorName?: string };

export default function VendorRatePlans({ vendorName }: Props) {
  const {
    params: { id_vendor: vendorId },
  } = useRouteMatch<{ id_vendor: string }>();

  return <VendorRatePlansPageContainer vendorId={vendorId} vendorName={vendorName || vendorId} />;
}
