import React, { useState } from 'react';

import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Stack, Tab, Tabs } from '@mui/material';

import PageSubheader from '../Common/Elements/PageSubheader';

type Props = {
  items: {
    title: string;
    component: React.ElementType;
    slug?: string;
    componentProps?: Record<string, unknown>;
  }[];
  defaultItem: string;
  sort?: boolean;
};

export default function ContentWithSidebar({ items, defaultItem, sort = true }: Props) {
  const { section, report = defaultItem } = useParams<{
    section: string;
    report?: string;
  }>();

  const [current, setCurrent] = useState(report);

  const currentItem = items.find((item) => (item.slug ? item.slug === current : item.title === current));

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setCurrent(newValue);
  };

  const tabs = items.map((item) => item.title);
  if (sort) tabs.sort();

  return (
    <Stack direction="row" spacing={4}>
      <Tabs
        orientation="vertical"
        value={current}
        onChange={handleChange}
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {tabs.map((tabTitle, index) => {
          const tab = items.find((item) => item.title === tabTitle);
          return (
            <Tab
              key={tab.slug ?? index}
              label={tab.title}
              value={tab.slug ?? tab.title}
              sx={{
                alignItems: 'start',
                '&.Mui-selected': {
                  backgroundColor: 'aliceblue',
                },
              }}
              {...(tab.slug ? { component: Link, to: `/finance/${section}/${tab.slug}` } : {})}
            />
          );
        })}
      </Tabs>

      <Box flex={1}>
        {currentItem && (
          <>
            <PageSubheader title={currentItem.title} />
            <Box component={currentItem.component} {...(currentItem.componentProps ?? {})} />
          </>
        )}
      </Box>
    </Stack>
  );
}
