import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Box, Button, Typography } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';

import CustomOfferRebookingOrderBanner from '~/components/Common/CustomOfferRebookingOrderBanner';

import { titleCase } from '~/utils/stringUtils';

import dateFormatter from '../Home/formatters/dateFormatter';

import OrderBedbankItemFacilities from './BedbankList/Facilities';
import OrderBedbankItemFinePrint from './BedbankList/FinePrint';
import OrderBedbankItem from './BedbankList/List';
import Log from './OrderItem/Log';
import ResendEmail from './ResendEmail';

type Props = {
  order: App.Order;
  item: Order.BedbankItem;
  hasAllowedRefund: boolean;
  bedbankRoomsInfo: {
    [reservationRoomId: string]: {
      id: string;
      status: string;
      rebook?: App.Bedbank.ReservationRebookInfo;
    };
  };
  customerEmailToAgentState: Record<string, unknown> & { isSending: boolean };
  emailToCustomerState: Record<string, unknown> & { isSending: boolean };
  resendCustomerEmail: (offerId: string, offerType?: string) => void;
  resendCustomerEmailToAgent: (offerId: string, offerType?: string) => void;
  showRefundModal: (value: unknown) => void;
  showRebookingModal: (value: unknown) => void;
};

export default function OrderDetailBedbank(props: Props) {
  const {
    order,
    item,
    hasAllowedRefund,
    bedbankRoomsInfo,
    customerEmailToAgentState,
    emailToCustomerState,
    resendCustomerEmail,
    resendCustomerEmailToAgent,
    showRefundModal,
    showRebookingModal,
  } = props;

  const tenant = useSelector((state: App.State) => state.tenant);

  const isRebookable = useMemo(() => {
    return (item.rooms ?? []).every(
      (room) => bedbankRoomsInfo[room.id_reservation_room] && bedbankRoomsInfo[room.id_reservation_room]?.rebook,
    );
  }, [item, bedbankRoomsInfo]);

  return (
    <Box
      key={item.id}
      sx={{
        border: '1px solid',
        borderColor: 'grey.100',
        borderRadius: '8px',
        marginTop: 2,
        marginBottom: '20px',
      }}
    >
      <Box bgcolor="grey.50" p={2}>
        <Box display="grid" columnGap={2} gridTemplateColumns="auto 1fr" alignItems="center">
          <Typography color="black">ID:</Typography>
          <Typography>{item.id}</Typography>

          <Typography color="black">LE Booking Number:</Typography>
          <Typography>{item.booking_number}</Typography>

          <Typography color="black">Supplier:</Typography>
          <Typography>{titleCase(item.supplier ?? 'expedia')}</Typography>

          <Typography color="black">Supplier Booking Number:</Typography>
          <Typography>{item.supplier_booking_number ?? item.booking_number}</Typography>

          {item.parent_booking_number && (
            <>
              <Typography color="black">Parent Booking Number:</Typography>
              <Typography>{item.parent_booking_number}</Typography>
            </>
          )}
        </Box>

        {isRebookable && (
          <Box display="grid" columnGap={2} gridTemplateColumns="repeat(2, auto) 1fr" alignItems="center">
            <Typography color="black">Rebookable:</Typography>
            <Button variant="text" size="small" onClick={() => showRebookingModal({ itemId: item.id })}>
              Rebook
            </Button>
          </Box>
        )}

        {item.parent_reservation_id && (
          <Box display="grid" columnGap={2} gridTemplateColumns="repeat(2, auto) 1fr" alignItems="center">
            <Typography color="black">Rebooked:</Typography>
            <Typography>{dateFormatter(item.rebooked_at)}</Typography>
          </Box>
        )}

        <Box mt={1} display="grid" columnGap={2} gridTemplateColumns="repeat(3, auto) 1fr" alignItems="center">
          <ResendEmail
            emailType="customer"
            resendEmail={resendCustomerEmail}
            isCustomerEmailDisabled={emailToCustomerState.isSending}
            resendEmailToAgent={resendCustomerEmailToAgent}
            isAgentEmailDisabled={customerEmailToAgentState.isSending}
          />
          <div></div>
        </Box>
      </Box>

      <CustomOfferRebookingOrderBanner order={order} />

      <Box p={2}>
        <OrderBedbankItem
          order={order}
          tenant={tenant}
          showRefundModal={showRefundModal}
          hasAllowedRefund={hasAllowedRefund}
          item={item}
          bedbankRoomsInfo={bedbankRoomsInfo}
          disableInteraction={false}
        />
      </Box>

      <Box p={3}>
        {item.offer?.room?.facility_groups && (
          <OrderBedbankItemFacilities name="Room Facilities" facilityGroups={item.offer.room.facility_groups} />
        )}
        {item.offer?.facility_groups && (
          <OrderBedbankItemFacilities name="Hotel Facilities" facilityGroups={item.offer.facility_groups} />
        )}
        {item.offer?.fine_print && <OrderBedbankItemFinePrint finePrint={item.offer.fine_print} />}
        <Log orderId={item.fk_order_id} itemId={item.id} />
      </Box>
    </Box>
  );
}
