import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import Spinner from '~/components/Common/Spinner';

import { getEOI, listUserEmailLogs, resendActivationEmail, updateEOIStatus } from '~/services/AgentHub/AgentService';

import { titleCase } from '~/utils/stringUtils';

const SIZE_PER_PAGE = 4;

interface Props {
  id: string;
  onClose?: () => void;
  opened?: boolean;
}

const columns: GridColDef[] = [
  { field: 'status', headerName: 'Status', sortable: false, display: 'flex' },
  { field: 'sentAt', headerName: 'Sent At', flex: 1, sortable: false, display: 'flex' },
  { field: 'failureMessage', headerName: 'Message', flex: 1, sortable: false, display: 'flex' },
];

export default function AgencySubmissionDetail(props: Props) {
  const { id, onClose, opened = false } = props;

  const [open, setOpen] = useState(opened);
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const closeModal = useCallback(() => {
    setOpen(false);
    onClose && onClose();
  }, [setOpen, onClose]);

  const openModal = useCallback(async () => {
    if (!id) {
      closeModal();
    }

    setOpen(true);

    try {
      setLoading(true);

      const result = await getEOI(id);

      setData(result);
    } finally {
      setLoading(false);
    }
  }, [setOpen, closeModal, id]);

  async function updateSubmission(status: 'approved' | 'rejected') {
    setLoading(true);
    const updatedSubmission = await updateEOIStatus(data.id, status);
    setData(updatedSubmission);
    setLoading(false);
  }

  const [result, setResult] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 1,
  });

  const fetchEmailLogList = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const { rows, total } = await listUserEmailLogs(id, {
        type: 'activation',
        page: filter.page,
        pageSize: SIZE_PER_PAGE,
      });

      setTotal(total);
      setResult(rows);
    } catch (e) {
      setTotal(0);
      setResult([]);
    } finally {
      setLoading(false);
    }
  }, [filter, id, setTotal, setResult]);

  const fetchResendActivationEmail = useCallback(async () => {
    try {
      setLoading(true);

      await resendActivationEmail(id);

      fetchEmailLogList();
    } catch (err) {
      enqueueSnackbar('Failed to load data', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [id, setLoading, enqueueSnackbar, fetchEmailLogList]);

  const copyOnboardLink = useCallback(() => {
    navigator.clipboard.writeText(
      `${window.configs.LEAGENTHUB_CUSTOMER_PORTAL}/agent-hub/complete-account-setup?agent_id=${encodeURIComponent(
        data.customerId,
      )}&agent_email=${encodeURIComponent(data.email)}`,
    );
    enqueueSnackbar(`Copied to clipboard`, { variant: 'success' });
  }, [data]);

  useEffect(() => {
    if (open) {
      fetchEmailLogList();
    }
  }, [filter, fetchEmailLogList, open]);

  useEffect(() => {
    if (opened) {
      openModal();
    }
  }, [openModal, opened]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Agency Submission Details</DialogTitle>

        <DialogContent>
          {loading ? (
            <Spinner />
          ) : (
            data && (
              <>
                <Grid direction="column" columns={12} sx={{ minWidth: '500px' }}>
                  <Typography variant="h5">Agency</Typography>

                  <Grid item mt={1}>
                    <Typography>
                      Agency Name: <br />
                      <b>{data.agencyName}</b>
                    </Typography>
                  </Grid>

                  <Grid item mt={1}>
                    <Typography>
                      Affiliation: <br />
                      <b>{data.affiliation}</b>
                    </Typography>
                  </Grid>

                  <Typography mt={2} variant="h5">
                    User
                  </Typography>

                  <Grid item mt={1}>
                    <Typography>
                      Name: <br />
                      <b>{data.name}</b>
                    </Typography>
                  </Grid>

                  <Grid item mt={1} display="flex" direction="row">
                    <Typography>
                      Email: <br />
                      <b>{data.email}</b>
                    </Typography>
                    <Typography ml={2}>
                      Phone: <br />
                      <b>{data.phone}</b>
                    </Typography>
                  </Grid>

                  <Grid item mt={2} display="flex" justifyContent={'space-between'} direction="row">
                    <Typography>
                      Status: <br />
                      <b>{titleCase(data.status)}</b>
                    </Typography>
                  </Grid>
                  {data.status === 'pending' && (
                    <Grid item mt={2} display="flex" justifyContent={'space-between'} direction="row">
                      <Button variant="contained" onClick={() => updateSubmission('approved')}>
                        Approve
                      </Button>

                      <Button variant="contained" color="error" onClick={() => updateSubmission('rejected')}>
                        Reject
                      </Button>
                    </Grid>
                  )}

                  {data.status === 'approved' && (
                    <Grid item mt={2}>
                      <DataGrid
                        rows={result}
                        rowCount={total}
                        columns={columns}
                        loading={loading}
                        pagination
                        paginationMode="server"
                        paginationModel={{ page: filter.page - 1, pageSize: SIZE_PER_PAGE }}
                        pageSizeOptions={[SIZE_PER_PAGE]}
                        onPaginationModelChange={({ page }) => setFilter({ page: page + 1 })}
                        slots={{ pagination: GridPagination }}
                        getRowId={(row) => row.sentAt}
                        disableColumnFilter
                        disableColumnMenu
                        disableRowSelectionOnClick
                        autoHeight
                      />

                      <Grid item mt={2} display="flex" justifyContent={'space-between'} direction="row">
                        <Button variant="contained" onClick={() => fetchResendActivationEmail()}>
                          Re-send Activation Email
                        </Button>

                        {data.customerId && (
                          <Button variant="contained" color="primary" onClick={copyOnboardLink}>
                            Onboard Link
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            )
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={closeModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
