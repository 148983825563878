import { getExperienceOfferById } from '~/services/ExperiencesService';
import OffersService from '~/services/OffersService';
import PublicOfferService from '~/services/PublicOfferService';
import { UnifiedSearchList } from '~/services/SearchService';
import CruiseOfferService from '~/services/cruises/OfferService';

export interface OfferDetails {
  name: string;
  url: string;
  isSoldOut?: boolean;
  isLTS?: boolean;
  cruiseLeExclusive?: boolean;
  cruiseSpecialOffer?: boolean;
}

export async function fetchUnifiedOfferDetails(
  offer: UnifiedSearchList[0],
  region: string,
  brand: App.Brands,
): Promise<OfferDetails> {
  if (offer.vertical === 'hotel') {
    if (offer.offerType === 'bedbank_hotel') {
      const resp = await PublicOfferService.getBedbankOfferById(offer.bk, region, brand);
      return {
        name: resp.result.name,
        url: `/bedbank/properties/${offer.bk}`,
        isLTS: resp.result.promotions.length > 0,
      };
    } else {
      const resp = await OffersService.getOffer(offer.bk);
      return {
        name: resp.result.name,
        url: `/offers/${offer.bk}`,
      };
    }
  } else if (offer.offerType === 'tour') {
    const resp = await OffersService.getOffer(offer.bk);
    return {
      name: resp.result.name,
      url: `/offers/${offer.bk}`,
    };
  } else if (offer.offerType === 'tour_v2') {
    const resp = await PublicOfferService.getTourV2OfferById(offer.bk, region, brand);
    return {
      name: resp.result.name,
      url: `/tours/details/${offer.bk}`,
    };
  } else if (offer.offerType === 'cruise') {
    const resp = await CruiseOfferService.getSummaryById(offer.bk);
    return {
      name: resp.result.name,
      url: `/cruises/offer/${offer.bk}`,
      isSoldOut: !resp.result.availableDepartureDates?.length,
      cruiseLeExclusive: resp.result.leExclusive,
      cruiseSpecialOffer: resp.result.promotions.length > 0,
    };
  } else if (offer.vertical === 'experience') {
    const resp = await getExperienceOfferById({
      id: offer.bk,
      brand,
    });
    return {
      name: resp.result.title,
      url: `/experience/${offer.bk}`,
    };
  } else {
    throw new Error(`To be implemented offer type: ${offer.offerType}`);
  }
}
