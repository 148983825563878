import React, { useCallback, useEffect } from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import EditIcon from '@mui/icons-material/Edit';
import { Alert, Button, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import * as libRegions from '@luxuryescapes/lib-regions';

import { Vertical } from '~/components/Content/SearchRanking/SearchRankingPage';
import { Text } from '~/components/Experiences/components';

import SearchService from '~/services/SearchService';

interface Props {
  offerId: string;
  vertical: Vertical;
}

const ScoreTable = styled(Table)`
  tr {
    height: 100%;
  }

  td {
    height: 0;
  }

  @supports (-moz-appearance: none) {
    td {
      height: 100%;
    }
  }
`;

const ScoreRegionLine = styled.div`
  display: flex;
  margin: 8px;
`;

const RegionSelector = styled(Select)`
  display: inline-block;
  width: 250px;
`;

const regionCodes = libRegions.getRegions().map((r) => r.code);

const getTierValue = (score): number => {
  if (score === 10) return 1;
  if (score === 7.5) return 2;

  return 3;
};

const emptyScore: Partial<App.OfferScore> = {
  expectedValue: 0,
  expectedValueScore: 0,
  expectedValueWeight: 1,
  overrideScore: 10,
  overrideWeight: 0,
  overrideExpireDate: '',
  tierScore: 0,
  tierWeight: 0,
  calculatedScore: 0,
};

export default function SearchOrdering({ offerId, vertical }: Props) {
  const [error, setError] = React.useState(null);
  const [offerScores, setOfferScores] = React.useState([]);
  const [regionCode, setRegion] = React.useState(regionCodes[0]);
  const [scoresInSelectedRegion, setScoresInSelectedRegion] = React.useState<Partial<App.OfferScore>>(emptyScore);
  const [rows, setRows] = React.useState([]);

  const getScoresInRegion = useCallback(
    (region): App.OfferScore => {
      return (
        offerScores?.find((s) => s.region === region) || {
          id: offerId,
          region,
          ...emptyScore,
        }
      );
    },
    [offerId, offerScores],
  );

  const selectRegion = useCallback(
    (region) => {
      setRegion(region);
      setScoresInSelectedRegion(getScoresInRegion(region));
    },
    [getScoresInRegion],
  );

  useEffect(() => {
    async function fetchScores() {
      if (!offerId) return;

      try {
        const response = await SearchService.getHotelScore(offerId);
        const scoresForCurrentVariant = response.result.filter((score) => score.variant === 'current');
        setOfferScores(scoresForCurrentVariant);
      } catch (error) {
        setError(error);
      }
    }

    fetchScores();
  }, [offerId]);

  useEffect(() => {
    selectRegion(regionCode);
  }, [regionCode, selectRegion]);

  const reload = () => {
    location.reload();
  };

  useEffect(() => {
    const score = scoresInSelectedRegion;

    const expectedValueWeight = score.tierWeight
      ? score.expectedValueWeight * (1 - score.tierWeight)
      : score.expectedValueWeight;

    const tierScoreWeight = score.tierWeight ? score.expectedValueWeight * score.tierWeight : score.tierWeight;

    setRows([
      {
        attribute: 'Expected Value',
        value: score.expectedValue?.toFixed(3),
        score: score.expectedValueScore?.toFixed(3),
        weight: `${expectedValueWeight * 100}%`,
        calculatedScore: score.expectedValueScore * expectedValueWeight,
      },
      {
        attribute: 'Tier score',
        value: getTierValue(score.tierScore),
        score: score.tierScore,
        weight: `${tierScoreWeight * 100}%`,
        calculatedScore: score.tierScore * tierScoreWeight,
      },
      {
        attribute: 'Manual Override',
        value: 'N/A',
        score: score.overrideScore,
        weight: `${score.overrideWeight * 100}%`,
        calculatedScore: score.overrideScore * score.overrideWeight,
      },
    ]);
  }, [regionCode, scoresInSelectedRegion]);

  return (
    <div>
      <h2 className="page-header">
        Search Ordering
        <Link
          to={{
            pathname: `/edit-offers/${vertical}/${offerId}/search-orderings`,
          }}
        >
          <EditIcon className="small" />
          <span className="sr-only">Edit</span>
        </Link>
      </h2>
      {error && (
        <Alert
          severity="error"
          action={
            <Button color="inherit" variant="outlined" onClick={reload}>
              Retry
            </Button>
          }
        >
          {error.message}
        </Alert>
      )}
      {!error && (
        <>
          <ScoreRegionLine>
            <Text size={'18px'} lineHeight={'21px'} align="left">
              {'Calculated search score: ' + scoresInSelectedRegion.calculatedScore}
            </Text>
            <RegionSelector
              labelId="offer-search-ordering-region-selector-label"
              id="offer-search-ordering-region-selector"
              defaultValue={regionCode === undefined ? regionCodes[0] : regionCodes.find((code) => code === regionCode)}
              value={regionCode}
              onChange={(event: SelectChangeEvent) => selectRegion(event.target.value)}
            >
              {libRegions.getRegions().map((r) => {
                return <MenuItem key={r.code} value={r.code}>{`Region(${r.code})`}</MenuItem>;
              })}
            </RegionSelector>
          </ScoreRegionLine>
          <ScoreTable className="offer-search-ordering">
            <TableHead>
              <TableRow>
                <TableCell> Attribute </TableCell>
                <TableCell align="right">Attribute Value</TableCell>
                <TableCell align="right">Attribute Score</TableCell>
                <TableCell align="right">Weighting</TableCell>
                <TableCell align="right">Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.attribute} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.attribute}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                  <TableCell align="right">{row.score}</TableCell>
                  <TableCell align="right">{row.weight}</TableCell>
                  <TableCell align="right">{row.calculatedScore}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ScoreTable>
        </>
      )}
    </div>
  );
}
