import React from 'react';

import { Link } from 'react-router-dom';

import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import { Alert, Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { OFFER_FACING_NAMES, OFFER_TYPE_BUNDLE_AND_SAVE } from '../../../consts/offerTypes';
import { customerPortalHost, salesforceHost } from '../../../services/common';
import { withTenant } from '../../hoc';

class OfferPane extends React.Component {
  offerPage(tenant, path) {
    let tenantObject = {};
    tenantObject.value = tenant;
    const ending = !path.includes('?') ? '?preview=true' : '&preview=true';
    return customerPortalHost(tenantObject) + path + ending;
  }

  render() {
    const { tenant, name, path } = this.props;
    return (
      <li>
        <a href={this.offerPage(tenant, path)} target="_blank" rel="noreferrer">
          View On {name}
        </a>
      </li>
    );
  }
}

class OverviewPane extends React.Component {
  render() {
    const { id, offer, propertiesMissingGeodata, toursMissingGeodata, propertyData } = this.props;
    const path = `/offer/${offer.result.slug}/${offer.result.id_salesforce_external}`;
    const customerPortals = {
      'Luxury Escapes': 'lux',
      'Scoopon Travel': 'scoopon',
      'Cudo Travel': 'cudo',
      'TreatMe Travel': 'treatme',
      'Deals Travel': 'deals',
      'Kogan Travel': 'kogantravel',
      'Le Business Traveller': 'lebusinesstraveller',
      'Le Agent Hub': 'leagenthub',
    };

    return (
      <Box id={id}>
        <Typography component="h1" variant="h3" color={[grey[600]]} sx={{ fontWeight: 300, pb: 1 }}>
          Overview
          <Box>
            <IconButton href={'/edit-offers/' + offer.result.id_salesforce_external}>
              <ModeEditTwoToneIcon color="primary" fontSize="large" />
            </IconButton>
          </Box>
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Grid item sm={3} sx={{ mb: 3 }}>
          <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
            Title
          </Typography>
          <Typography variant="h4" sx={{ textTransform: 'uppercase', fontWeight: 300 }}>
            {offer.result.name}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item sm={3} sx={{ mb: 3 }}>
            <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
              Offer Type
            </Typography>
            <Typography variant="body1">{OFFER_FACING_NAMES[offer.result.type]}</Typography>
          </Grid>
          <Grid item sm={3} sx={{ mb: 3 }}>
            <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
              Vendor Id
            </Typography>
            {offer.result.type !== OFFER_TYPE_BUNDLE_AND_SAVE && (
              <Typography variant="body1">
                <Link to={`/vendors/${offer.result.vendor_account_id}`}>{offer.result.vendor_account_id}</Link>
              </Typography>
            )}
            {offer.result.type === OFFER_TYPE_BUNDLE_AND_SAVE && (
              <Typography variant="body1">
                {offer.result.offer_bundles.map((bundled_offer) => (
                  <>
                    <Link to={`/vendors/${bundled_offer.vendor_account_id}`}>{bundled_offer.vendor_account_id}</Link>
                    <br />
                  </>
                ))}
              </Typography>
            )}
          </Grid>
          <Grid item sm={3} sx={{ mb: 3 }}>
            <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
              Vendor Booking Email
            </Typography>
            <Typography variant="body1">{offer.result.vendor_booking_email}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={3} sx={{ mb: 3 }}>
            <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
              Slug: {offer.result.slug}
            </Typography>
            {Object.keys(customerPortals).map(function (name) {
              return <OfferPane key={name} tenant={customerPortals[name]} name={name} path={path} />;
            })}
            <li>
              <a href={`${salesforceHost}/${offer.result.id_salesforce_external}`} target="_blank" rel="noreferrer">
                View On Salesforce
              </a>
            </li>
          </Grid>
          <Grid item sm={3} sx={{ mb: 3 }}>
            <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
              Surcharges paid directly to vendor
            </Typography>
            <Typography variant="body1">{offer.result.surcharge_paid_direct_to_vendor ? 'Yes' : 'No'}</Typography>
          </Grid>
          {propertyData?.at(0)?.channel_managed && (
            <Grid item sm={3} sx={{ mb: 3 }}>
              <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                Channel Manager
              </Typography>
              <Typography variant="body1">{propertyData?.at(0)?.channel_manager}</Typography>
            </Grid>
          )}
        </Grid>
        {offer.result.partnerships.map((partnership) => (
          <Grid container key={partnership?.prefix}>
            <Grid item sm={3} sx={{ mb: 3 }}>
              <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                {partnership?.prefix.toUpperCase()} enabled
              </Typography>
              <Typography variant="body1">Yes</Typography>
            </Grid>
          </Grid>
        ))}
        {propertiesMissingGeodata.length > 0 && (
          <Grid container spacing={2} mb={3}>
            <Grid item sm={12}>
              {propertiesMissingGeodata.map((propertyId) => (
                <Alert severity="warning" icon={false} key={propertyId}>
                  A property for this offer is missing geolocation data.{' '}
                  <Link to={`/vendors/${offer.result.vendor_account_id}/properties/${propertyId}`}>See property.</Link>
                </Alert>
              ))}
            </Grid>
          </Grid>
        )}
        {toursMissingGeodata.length > 0 && (
          <Grid container spacing={2} mb={3}>
            <Grid item sm={12}>
              {toursMissingGeodata.map((tourId) => (
                <Alert severity="warning" icon={false} key={tourId}>
                  A tour for this offer is missing geolocation data.
                  <br />
                  It will not be shown correctly on the map view feature on the website.
                  <br />
                  <Link to={`/vendors/${offer.result.vendor_account_id}/tours/${tourId}`}>See tour.</Link>
                </Alert>
              ))}
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
}

export default withTenant(OverviewPane);
// For testing
export { OfferPane, OverviewPane };
