import React from 'react';

import { Info as InfoIcon } from '@mui/icons-material';
import { Box, Button, Collapse, Stack, Typography } from '@mui/material';

import { ConnectionCancellationPolicies } from '@luxuryescapes/lib-refunds';

import useToggleState from '~/hooks/useToggleState';

import { diffDays, isBefore } from '~/services/TimeService';

import { formatOrderDate } from '~/utils/order';

type Props = {
  isItemCancelled: boolean;
  connectionCancellationPolicies: ConnectionCancellationPolicies;
};

const buildMultiLinePolicyDetail = (details) => {
  const detailTextArray = Array.isArray(details) ? details : details.split('\n');

  return detailTextArray.map((p, i) => (
    <Typography key={i} variant="body2">
      {p}
    </Typography>
  ));
};

const ReservationInfoConnectionCancellationPolicies = ({ connectionCancellationPolicies, isItemCancelled }: Props) => {
  const { isToggled, toggle } = useToggleState(false);
  const policies = connectionCancellationPolicies.policies;

  if (!policies) {
    return <Box>N/A</Box>;
  }

  let lastDayCancellationString, lastDayCancellationDate;
  if (policies.length > 0) {
    lastDayCancellationDate = policies[0].deadline;
    lastDayCancellationString = formatOrderDate(policies[0].deadline);
  }

  const hasPolicyDate = !!lastDayCancellationString;

  const willExpireToday = diffDays(lastDayCancellationDate, new Date(), 'day') === 0;
  const isLastDayPast = isBefore(lastDayCancellationDate, new Date()) && !willExpireToday;

  let baseColor = isItemCancelled ? 'grey' : 'black';
  let detailColor = 'green';
  if (isItemCancelled) {
    detailColor = 'grey';
  } else if (isLastDayPast) {
    baseColor = 'red';
    detailColor = 'red';
  }

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        <InfoIcon fontSize="small" color="secondary" />
        <Button onClick={toggle} variant="text" size="small">
          Connection Cancellation Policy Details
        </Button>
      </Stack>

      <Collapse in={isToggled} timeout="auto" unmountOnExit>
        <Stack direction="column" spacing={1} maxWidth={250}>
          {!policies.length && <p>Non-refundable</p>}
          {policies.length > 0 &&
            connectionCancellationPolicies.description &&
            buildMultiLinePolicyDetail(connectionCancellationPolicies.description)}
        </Stack>
      </Collapse>

      {hasPolicyDate && (
        <Box>
          <Typography component="span" color={baseColor}>
            Cancellable until{' '}
          </Typography>
          <Typography fontWeight="bold" component="span" color={detailColor}>
            {lastDayCancellationString}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ReservationInfoConnectionCancellationPolicies;
