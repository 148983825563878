import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import VendorsService from '~/services/VendorsService';

import addUserData from '~/utils/addUserData';

import VendorBookingPaymentsList from '../OfferDetail/VendorBookingPaymentsList';

type Props = {
  id_offer: string;
  id_vendor: string;
  search_string: string;
};

export default function VendorBookingPaymentsListContainer({ id_offer, id_vendor, search_string }: Props) {
  const [payments, setPayments] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        setLoading(true);
        setPayments(null);

        const response = await VendorsService.getPaymentsByBookingVendor(id_offer, id_vendor, search_string);
        if (response && response.result && response.result.payments) {
          const updatedResponse = await addUserData(response.result.payments, 'fk_added_by');
          response.result.payments = updatedResponse;
        }

        setPayments(response.result);
      } catch (err) {
        enqueueSnackbar(`Failed to get booking payments: ${err.message}`, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, [id_offer, id_vendor, search_string, enqueueSnackbar]);

  return <VendorBookingPaymentsList data={payments} isLoading={isLoading} />;
}
