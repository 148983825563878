import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import CopyableField from '~/components/Common/CopyableField';
import DebugModal from '~/components/DebugModal/DebugModal';

import { DEFAULT_PAGE_SIZES } from '~/consts/filters';

import { PromoMeta } from '~/services/PromoService';
import { formatDateLongMonthWithMeridiem, formatDateShort } from '~/services/TimeService';

import GridPagination from '../../Common/Elements/GridPagination';
import Spinner from '../../Common/Spinner';
import { PromoCodeName } from '../formatters/PromoCodeNameFormatter';
import PromoCodeUser from '../formatters/PromoCodeUser';
import usePromoFailures from '../hooks/usePromoFailures';

const sharedAttrs: Pick<GridColDef, 'sortable' | 'display' | 'flex'> = {
  sortable: false,
  display: 'flex',
  flex: 1,
};

const getColumns = (): GridColDef[] => [
  {
    field: 'created_at',
    valueFormatter: (value) => formatDateShort(value),
    renderCell: (params) => (
      <Box title={formatDateLongMonthWithMeridiem(params.row.created_at)}>{formatDateShort(params.row.created_at)}</Box>
    ),
    headerName: 'Logged at',
    ...sharedAttrs,
  },
  {
    field: 'code_name',
    renderCell: (params) => PromoCodeName({ codeName: params.row.code_name }),
    headerName: 'Code',
    ...sharedAttrs,
  },
  {
    field: 'Log attempt By',
    renderCell: (params) => PromoCodeUser({ userId: params.row.user_id }),
    ...sharedAttrs,
  },
  {
    field: 'error_level',
    headerName: 'Error Level',
    ...sharedAttrs,
  },
  {
    field: 'promo_failure_error_code',
    headerName: 'Error Code',
    ...sharedAttrs,
  },
  {
    field: 'error_message',
    headerName: 'Error Msg',
    ...sharedAttrs,
    flex: 4,
  },
  {
    field: 'debug',
    headerName: 'Raw',
    renderCell: (params) => (
      <CopyableField
        value={JSON.stringify(params.row, null, 4)}
        label={<DebugModal type="generic" data={params.row ?? { data: 'None' }} />}
      />
    ),
    ...sharedAttrs,
  },
];

interface Props {
  initCodeName?: string;
  initUserId?: string;
  initPage?: number;
  initLimit?: number;
  brand: App.Brands | string;
  handleUserIdFilterSet?: (userId: string) => void;
  promoMeta: PromoMeta;
}

const DEFAULT_PAGE_SIZE = 10;

const PromoFailures = ({ initCodeName, initUserId, initLimit, initPage, brand }: Props) => {
  const { promoFailures, totalRows, isLoading, promoFailuresFilter, setPromoFailuresFilter } = usePromoFailures({
    initPage,
    initLimit: initLimit ?? DEFAULT_PAGE_SIZE,
    initCodeName,
    brand: brand.toString(),
    initUserId,
  });

  useEffect(() => {
    setPromoFailuresFilter({
      ...promoFailuresFilter,
      code_name: initCodeName,
      brand: brand as definitions['Promo Brands'],
      user_id: initUserId,
    });
  }, [initCodeName, initUserId, brand, initPage, setPromoFailuresFilter]);

  const onPageChange = async (page, pageSize) => {
    setPromoFailuresFilter({
      ...promoFailuresFilter,
      page,
      limit: pageSize,
    });
  };

  return (
    <>
      {!isLoading ? (
        <DataGrid
          columns={getColumns()}
          autoHeight
          getRowId={(row) => row.uniqueKey}
          rowCount={totalRows}
          rows={promoFailures || []}
          pagination
          paginationMode="server"
          paginationModel={{ page: promoFailuresFilter.page, pageSize: promoFailuresFilter.limit }}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
          slots={{ pagination: GridPagination }}
          pageSizeOptions={DEFAULT_PAGE_SIZES}
          getRowHeight={() => 'auto'}
        />
      ) : (
        <Spinner size={36} />
      )}
    </>
  );
};

export default PromoFailures;
