import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router';

import { Alert } from '@mui/material';

import OrderSearchForm, { MultiFilterState } from '~/components/Common/Forms/OrderSearchForm';
import Spinner from '~/components/Common/Spinner';
import { withTenant } from '~/components/hoc';

import useQuery from '~/hooks/useQuery';

import { getPurchases, multiSearch, searchPurchases } from '~/services/OrdersService';

import { addQuery, removeQuery } from '~/utils/url';

import OrdersPage from './OrdersPage';

const DEFAULT_SIZE_PER_PAGE = 10;
const SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE = 50;

interface Props {
  tenant: App.Tenant;
}

const OrdersPageContainer = ({ tenant }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [purchases, setPurchases] = useState(null);
  const [filterField, setFilterField] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [additionalSearchParams, setAdditionalSearchParams] = useState<MultiFilterState>();

  const [sizePerPage, setSizePerPage] = useState(DEFAULT_SIZE_PER_PAGE);

  const currentPage = parseInt(query.get('page')) || 1;
  const customerId = query.get('customer_id');

  const isReady = !isLoading && !error && purchases;

  const searchQuery = (field, value) => {
    setFilterField(field);
    setFilterValue(value);

    const newLocation = removeQuery(location, 'customer_id', 'page');

    history.push(newLocation);
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setPurchases(null);
    setError(null);

    try {
      let fetchedPurchases;
      if (filterField === 'universal_search_id') {
        fetchedPurchases = await searchPurchases({
          page: currentPage - 1,
          perPage: sizePerPage,
          searchId: filterValue,
        });
      } else if (filterField === 'multi_search') {
        fetchedPurchases = await multiSearch({
          page: currentPage - 1,
          perPage: sizePerPage,
          orderBy: 'created_at',
          orderDirection: 'desc',
          additionalFilter: additionalSearchParams,
        });
      } else {
        fetchedPurchases = await getPurchases({
          page: currentPage,
          per_page: sizePerPage,
          brand: tenant.brand,
          customer_id: customerId,
          filterBy: filterField,
          filterValue,
          additionalFilter: additionalSearchParams,
        });
      }
      setPurchases(fetchedPurchases);
      setIsLoading(false);
    } catch (error) {
      setError(JSON.stringify(error));
      setIsLoading(false);
    }
  }, [currentPage, customerId, filterField, filterValue, sizePerPage, additionalSearchParams, tenant.brand]);

  const onPageChange = (page) => {
    const newLocation = addQuery(location, { page });
    history.push(newLocation);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (customerId) {
      setSizePerPage(SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE);
    } else {
      setSizePerPage(DEFAULT_SIZE_PER_PAGE);
    }
  }, [customerId]);

  return (
    <div>
      <OrderSearchForm
        searchQuery={searchQuery}
        setAdditionalParams={setAdditionalSearchParams}
        bookingNumberPlaceholder="Search Orders or Booking Numbers"
        bookingIdType={'universal_search_id'}
        e2eSuffix="-universal-search"
        showMultiSearch
      />

      <>
        {isLoading && <Spinner size={36} />}

        {error && <Alert severity="warning">Failed to load data: {error}</Alert>}

        {isReady && (
          <OrdersPage
            onPageChange={onPageChange}
            page={currentPage}
            purchases={purchases.result}
            sizePerPage={sizePerPage}
            total={purchases?.total}
          />
        )}
      </>
    </div>
  );
};

export default withTenant(OrdersPageContainer);
