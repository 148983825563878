import React, { useCallback, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';

import { getRegions } from '@luxuryescapes/lib-regions';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { AgentHubAffiliation, createAffiliation } from '~/services/AgentHub/AgentService';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export default function AddAffiliationModal(props: Props) {
  const { onClose, isOpen = false } = props;
  const { enqueueSnackbar } = useSnackbar();

  const regions = useMemo(() => getRegions().map((region) => region.code), []);

  const [affiliationDetails, setAffiliationDetails] = useState<AgentHubAffiliation>(undefined);
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');

  const save = useCallback(async () => {
    try {
      setLoadingState('loading');

      if (Object.entries(affiliationDetails).some(([_, value]) => !value)) {
        setLoadingState('failed');
        enqueueSnackbar('Please fill all the fields', { variant: 'error' });
        return;
      }

      const { name, region } = affiliationDetails;
      await createAffiliation({
        name,
        region,
      });
      setLoadingState('success');
      enqueueSnackbar('Register updated successfully');
      onClose();
    } catch (err) {
      setLoadingState('failed');
      enqueueSnackbar(`There was an error creating the affiliation ${err}`, { variant: 'error' });
    }
  }, [affiliationDetails, enqueueSnackbar, onClose]);

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>Add Affiliation</DialogTitle>

        <DialogContent>
          {loadingState == 'loading' && <Spinner />}
          {loadingState != 'loading' && (
            <form onSubmit={(e) => e.preventDefault()} style={{ minWidth: '500px' }}>
              <Stack mt={2} direction="column" spacing={2}>
                <FormGroup>
                  <TextField
                    label="Name"
                    value={affiliationDetails?.name}
                    required
                    fullWidth
                    onChange={(e) => setAffiliationDetails({ ...affiliationDetails, name: e.target.value })}
                  />
                </FormGroup>

                <FormControl>
                  <InputLabel id="affiliation-region">Region</InputLabel>
                  <Select
                    labelId="affiliation-region"
                    id="affiliation-region"
                    label="Region"
                    required
                    fullWidth
                    value={affiliationDetails?.region}
                    onChange={(e) => setAffiliationDetails({ ...affiliationDetails, region: e.target.value })}
                  >
                    {regions.map((region) => (
                      <MenuItem key={region} value={region}>
                        {region}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {loadingState == 'failed' && (
                  <ErrorDisplay message="There was an error creating the affiliation" severity="error" />
                )}
              </Stack>
            </form>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant="text" onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" onClick={save}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
