import React, { useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Typography } from '@mui/material';

export default function CopyableField(props) {
  const [copied, setCopied] = useState(false);
  const { value, label, className, ...rest } = props;

  return (
    <Typography className={className} {...rest}>
      {label ?? value}
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(value).then(() => {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 2000);
          });
        }}
        size="small"
        title={'Copy ' + (label ?? value)}
        aria-label={'Copy ' + (label ?? value)}
      >
        {copied ? <CheckCircleIcon /> : <ContentCopyIcon />}
      </IconButton>
    </Typography>
  );
}
