import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import { Alert, Box, Container, Tab, Tabs } from '@mui/material';

import useCurrentUser from '~/hooks/useCurrentUser';

import { hasFullAccess, hasReadAccess } from '~/utils/lere';

import PageHeader from '../Common/Elements/PageHeader';

import LereTPFMEmailTrigger from './LereTPFMEmailTrigger';

const tabs = [{ key: 'tpfm-tab', title: 'TPFM' }];

export default function LereAdminPageContainer() {
  const { user } = useCurrentUser();

  const userHasReadAccess = hasReadAccess(user);
  const userHasFullAccess = hasFullAccess(user);
  const [lereError, setLereError] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  if (!userHasFullAccess && !userHasReadAccess) {
    return null;
  }

  return (
    <>
      <Container maxWidth="xl" sx={{ backgroundColor: 'white' }}>
        <Helmet>
          <title>LERE</title>
        </Helmet>

        <PageHeader title="LERE Admin" />

        {lereError && (
          <Alert severity="error" onClose={() => setLereError(null)}>
            {lereError.message ?? lereError.toString()}
          </Alert>
        )}

        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row' }}>
          <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)}>
            {tabs.map((tab) => (
              <Tab key={tab.key} label={tab.title} value={tab.key} />
            ))}
          </Tabs>
        </Box>

        <Box pt={4}>{activeTab === 'tpfm-tab' && <LereTPFMEmailTrigger onLereError={setLereError} />}</Box>
      </Container>
    </>
  );
}
