import React from 'react';

import type { RJSFSchema } from '@rjsf/utils';
import memoize from 'lodash/memoize';
import { Helmet } from 'react-helmet';
import { Link, RouteChildrenProps, withRouter } from 'react-router-dom';

import { Alert, Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { blue, teal } from '@mui/material/colors';

import { Order } from '@luxuryescapes/contract-svc-order';

import PageHeader from '~/components/Common/Elements/PageHeader';
import UpdateUser from '~/components/Common/UpdateUser';
import {
  hasActiveOrInactiveSubscription,
  isLuxPlusActiveSubscriber,
  isLuxPlusFreePreview,
  isLuxPlusMember,
} from '~/components/Membership/utils';
import CreatePromoForUserModal from '~/components/Users/CreatePromoForUserModal';

import TENANTS from '~/consts/tenant';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

import PermissionedComponent from '../Common/PermissionedComponent';
import UserAgencyDetails from '../LeAgentHub/components/UserAgencyDetails';
import MembershipSubscriptionDetails from '../Membership/MembershipSubscriptionDetails';
import { LUXURY_PLUS } from '../Membership/constants';
import SpeedNavigationKeyboardKey from '../System/SpeedNavigationKeyboardKey';
import UserEmailChangeTable from '../System/UserEmailChangeTable';
import UserLoginTable from '../System/UserLoginTable';
import { withTenant } from '../hoc';

import CustomOffersTab from './CustomOffers/CustomOffersTab';
import ForceLogoutModal from './ForceLogoutModal';
import GDPRRemovalModal from './GRPRRemovalModal';
import GDPRRequestModal from './GRPRRequestModal';
import QuotesTab from './Quotes/QuotesTab';
import BusinessAdminSpoofButton from './UserDetails/BusinessAdminSpoofButton';
import PartnershipButtons from './UserDetails/PartnershipButtons';
import SpoofUserBlock from './UserDetails/SpoofUserBlock';
import TenancyWarningMessage from './UserDetails/TenancyWarningMessage';
import { validUserTenantForSiteTenant } from './UserDetails/utils';

const getRegionsByCountryCode = memoize((regions) => {
  return regions.reduce((regionsByCountryCode, region) => {
    regionsByCountryCode[region.code] = region;
    return regionsByCountryCode;
  }, {});
});

type Tab = 'user-details' | 'quotes' | 'custom-offers' | 'luxplus-member-profile' | 'user-agency';

export type UserTenancy = { id: string; tenant: string }; // id is the id of the user

type Props = RouteChildrenProps & {
  user: App.User;
  tenant: App.Tenant;
  tenancy: UserTenancy[];
  schema: { put: RJSFSchema };
  regions: App.Region[];
  update: (user: App.User) => void;
  subscriptions: App.MembershipSubscriptions | null;
  currentSubscriptionOrderItem: Order.SubscriptionItem;
  currentSubscriptionOrder: App.Order;
  refreshData: () => Promise<void>;
};

type State = {
  offer_id: string;
  spoof_url: string;
  for_store: boolean;
  isCreatePromoCodeModalVisible: boolean;
  isGDPRRemovalModalVisible: boolean;
  isGDPRRequestModalVisible: boolean;
  isForceLogoutModalVisible: boolean;
  currentTab: Tab;
  tenant: App.Tenant;
};

const newCustomOfferUri = (user: App.User) => {
  return `/users/${user.id_member}/custom-offers/create`;
};

class UserDetails extends React.Component<Props, State> {
  UpdateUserForm: { submitButton: HTMLButtonElement };

  constructor(props) {
    super(props);

    const urlSearchParams = new URLSearchParams(props.location.search);
    const showLuxPlusTabInitially =
      urlSearchParams.get('view_luxplus_membership') === 'true' && isLuxPlusMember(props.subscriptions);

    this.state = {
      offer_id: '',
      spoof_url: '',
      for_store: false,
      isCreatePromoCodeModalVisible: false,
      isGDPRRemovalModalVisible: false,
      isGDPRRequestModalVisible: false,
      isForceLogoutModalVisible: false,
      currentTab: showLuxPlusTabInitially ? 'luxplus-member-profile' : 'user-details',
      tenant: props.tenant,
    };
  }

  setCurrentTab(tab: Tab) {
    if (this.state.currentTab === 'luxplus-member-profile') {
      this.clearSearchParams();
    }
    this.setState({ currentTab: tab });
  }

  buyPageUri(offer_id: string) {
    return '/offers/' + offer_id + '/buy_for/' + this.props.user.id_member;
  }

  purchasePageUri() {
    return `/purchases/section/allOrders?customer_id=${this.props.user.id_member}`;
  }

  creditsPageUri(userCountry) {
    if (!userCountry) {
      return '#';
    }

    return `/users/${this.props.user.id_member}/credits?currency=${userCountry.currencyCode}`;
  }

  promoRequestPageUri() {
    return `/marketing/promo-requests?userId=${this.props.user.id_member}&page=0&limit=100`;
  }

  referralLogPageUri() {
    return `/marketing/referralLogs?q=${this.props.user.id_member}`;
  }

  newOrderPageUri() {
    return `/users/${this.props.user.id_member}/new-order/offer-selection`;
  }

  newFlightPageUri() {
    return `/users/${this.props.user.id_member}/new-flight-order/traveller`;
  }

  newGiftCardOrderPageUri() {
    return `/users/${this.props.user.id_member}/new-gift-card-order/gift-card-selection`;
  }

  newInsuranceOrderPageUri() {
    return `/users/${this.props.user.id_member}/new-insurance-order/insurance`;
  }

  newAtolPageUri() {
    return `/users/${this.props.user.id_member}/atol`;
  }

  newSentEmailUri() {
    return `/email/sent?email=${encodeURIComponent(this.props.user.email)}`;
  }

  handleBuyOfferChange = (event) => {
    this.setState({ offer_id: event.target.value });
  };

  handleBuyOfferSubmit = (event) => {
    const { history } = this.props;

    event.preventDefault();
    history.push(this.buyPageUri(this.state.offer_id));
  };

  updateUser = () => {
    this.UpdateUserForm.submitButton.click();
  };

  gdprRemoval = () => {
    this.setState({
      isGDPRRemovalModalVisible: true,
    });
  };

  gdprRequest = () => {
    this.setState({
      isGDPRRequestModalVisible: true,
    });
  };

  hideGDPRRemovalModal = () => {
    this.setState({
      isGDPRRemovalModalVisible: false,
    });
  };

  hideGDPRRequestModal = () => {
    this.setState({
      isGDPRRequestModalVisible: false,
    });
  };

  showCreatePromoCodeModal = () => {
    this.setState({
      isCreatePromoCodeModalVisible: true,
    });
  };

  showForceLogoutModal = () => {
    this.setState({
      isForceLogoutModalVisible: true,
    });
  };

  hideCreatePromoCodeModal = () => {
    this.setState({
      isCreatePromoCodeModalVisible: false,
    });
  };

  hideForceLogoutModal = () => {
    this.setState({
      isForceLogoutModalVisible: false,
    });
  };

  currentUserTenant = (user: App.User): App.Tenant => {
    return TENANTS[user.tenant];
  };

  showSpoofUserBlock = (tenant, user, userTenancies) => {
    if (tenant.value === TENANTS.scoopon.value) {
      const scooponUserId = userTenancies.find((el) => el.tenant === TENANTS.scoopon.value)?.id;
      return user.id_member === scooponUserId;
    }

    return validUserTenantForSiteTenant(tenant.value, user.tenant);
  };

  luxPlusActiveSubscriber = isLuxPlusActiveSubscriber(this.props.subscriptions);
  luxPlusFreePreview = isLuxPlusFreePreview(this.props.subscriptions);
  luxPlusMember = isLuxPlusMember(this.props.subscriptions);

  getFullTitle = () => {
    const { user } = this.props;

    const shadowBanned = user.shadow_ban_user ? ' Shadowbanned' : '';
    const vipClient = user.vip_client ? ' (VIP Client)' : '';
    const luxPlusFreePreviewText = this.luxPlusFreePreview ? 'Free Preview ' : '';
    const luxPlusMemberText = this.luxPlusMember ? ` ${luxPlusFreePreviewText} ${LUXURY_PLUS.PROGRAM_NAME} Member` : '';
    const hasDash = shadowBanned || vipClient || this.luxPlusActiveSubscriber ? ' -' : '';

    return user.fullName + hasDash + shadowBanned + vipClient + luxPlusMemberText;
  };

  keyboardActions = [
    { id: 'Purchase', key: 'q', label: 'Purchase', action: () => this.props.history.push(this.purchasePageUri()) },
    { id: 'Create-Promo-Code', key: 'c', label: 'Create Promo Code', action: this.showCreatePromoCodeModal },
    { id: 'order', key: 'n', label: 'New Order', action: () => this.props.history.push(this.newOrderPageUri()) },
    {
      id: 'credit',
      key: 'm',
      label: 'Credits',
      action: () =>
        this.props.history.push(
          this.creditsPageUri(getRegionsByCountryCode(this.props.regions)[this.props.user.country_code]),
        ),
    },
    { id: 'flight', key: 'f', label: 'New Flight', action: () => this.props.history.push(this.newFlightPageUri()) },
    {
      id: 'giftcard',
      key: 'g',
      label: 'New Gift Card Order',
      action: () => this.props.history.push(this.newGiftCardOrderPageUri()),
    },
    { id: 'New-ATOL', key: 'a', label: 'New ATOL', action: () => this.props.history.push(this.newAtolPageUri()) },
    { id: 'Update-user', key: 's', label: 'Update User', action: this.updateUser },
    {
      id: 'custom-offer',
      key: 'o',
      label: 'New Custom Offer',
      action: () => this.props.history.push(newCustomOfferUri(this.props.user)),
    },
  ];

  handleKeyDown = (event) => {
    if (
      document.body === document.activeElement &&
      !event.ctrlKey &&
      !event.shiftKey &&
      !event.altKey &&
      !event.metaKey
    ) {
      event.preventDefault();

      const action = this.keyboardActions.find((action) => action.key === event.key);
      if (action) {
        action.action();
      }
    }
  };

  clearSearchParams() {
    this.props.history.replace({
      search: '',
    });
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    const regionsByCode = getRegionsByCountryCode(this.props.regions);
    const userCountry = regionsByCode[this.props.user.country_code];
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  findKeyById = (id) => {
    const existingAction = this.keyboardActions.find((action) => action.id === id);
    return existingAction?.key;
  };

  getNameColour = () => {
    if (this.luxPlusFreePreview) {
      return teal[500];
    } else if (this.luxPlusActiveSubscriber) {
      return blue[900];
    }
  };

  render() {
    const { user, tenant, subscriptions, tenancy } = this.props;
    const { currentTab } = this.state;
    const regionsByCode = getRegionsByCountryCode(this.props.regions);
    const userCountry = regionsByCode[this.props.user.country_code];
    const fullTitle = this.getFullTitle();

    const tabs: Record<Tab, string> = {
      'user-details': 'User Details',
      quotes: 'Quotes',
      'custom-offers': 'Custom offers & quotes',
      ...(hasActiveOrInactiveSubscription(subscriptions) && {
        'luxplus-member-profile':
          (isLuxPlusActiveSubscriber(subscriptions) ? '' : `${subscriptions.status} `) +
          `${LUXURY_PLUS.PROGRAM_NAME} Member Profile`,
      }),
      ...(tenant.value === 'leagenthub' && { 'user-agency': 'Agency' }),
    };

    const isTenantOfCurrentSite = validUserTenantForSiteTenant(tenant.value, user.tenant);
    const showSpoofActions = this.showSpoofUserBlock(tenant, user, tenancy);

    return (
      <>
        <Helmet>
          <title>Users | {this.props.user?.fullName || this.props.user.email}</title>
        </Helmet>

        <PageHeader color={this.getNameColour()} title={fullTitle} />

        <Stack direction="column" gap={2}>
          {isTenantOfCurrentSite && (
            <>
              {!userCountry && (
                <Alert severity="warning">
                  <strong>Error!</strong> There is no country set for this user. To view credits you need to add
                  country.
                </Alert>
              )}

              <Stack
                direction={{ xs: 'column', md: 'row' }}
                justifyContent={{ xs: 'start', md: 'space-between' }}
                gap={2}
              >
                <Stack direction="row" alignItems="start" flexWrap="wrap" gap={2}>
                  <Button component={Link} variant="contained" to={this.purchasePageUri()}>
                    View Purchases
                    <SpeedNavigationKeyboardKey>{this.findKeyById('Purchase')}</SpeedNavigationKeyboardKey>
                  </Button>

                  <Button
                    component={Link}
                    variant="contained"
                    to={this.creditsPageUri(userCountry)}
                    disabled={!userCountry}
                  >
                    Manage Credits
                    <SpeedNavigationKeyboardKey>{this.findKeyById('credit')}</SpeedNavigationKeyboardKey>
                  </Button>

                  <Button variant="contained" component={Link} className="T-new-order-btn" to={this.newOrderPageUri()}>
                    New Order
                    <SpeedNavigationKeyboardKey>{this.findKeyById('order')}</SpeedNavigationKeyboardKey>
                  </Button>

                  <Button variant="contained" onClick={this.showCreatePromoCodeModal}>
                    New Promo Code
                    <SpeedNavigationKeyboardKey>{this.findKeyById('Create-Promo-Code')}</SpeedNavigationKeyboardKey>
                  </Button>

                  {window.configs.OFFLINE_FLIGHT_PURCHASE_ENABLED === 'on' && (
                    <Button
                      variant="contained"
                      component={Link}
                      className="T-new-flight-btn"
                      to={this.newFlightPageUri()}
                    >
                      New Flight Order
                      <SpeedNavigationKeyboardKey>{this.findKeyById('flight')}</SpeedNavigationKeyboardKey>
                    </Button>
                  )}

                  {tenant.brand === 'luxuryescapes' && (
                    <Button variant="contained" component={Link} to={this.newGiftCardOrderPageUri()}>
                      New Gift Card Order
                      <SpeedNavigationKeyboardKey>{this.findKeyById('giftcard')}</SpeedNavigationKeyboardKey>
                    </Button>
                  )}

                  <Button variant="contained" component={Link} to={this.promoRequestPageUri()}>
                    Promo Requests
                  </Button>

                  <Button variant="contained" component={Link} to={this.referralLogPageUri()}>
                    Referral Logs
                  </Button>
                  <Button variant="contained" component={Link} to={this.newAtolPageUri()}>
                    New Atol Email
                    <SpeedNavigationKeyboardKey>{this.findKeyById('New-ATOL')}</SpeedNavigationKeyboardKey>
                  </Button>

                  <Button variant="contained" component={Link} to={this.newSentEmailUri()}>
                    View Sent Emails
                  </Button>
                </Stack>

                <Stack direction="row" alignItems="start" flexWrap="wrap" flexShrink="0" gap={2}>
                  <Button variant="contained" color="error" onClick={this.showForceLogoutModal}>
                    Force logout
                  </Button>

                  <PermissionedComponent>
                    <Button variant="contained" color="error" onClick={this.gdprRemoval}>
                      DELETE ACCOUNT
                    </Button>
                  </PermissionedComponent>

                  <Button variant="contained" color="error" onClick={this.gdprRequest}>
                    REQUEST ACCOUNT DELETION
                  </Button>
                </Stack>
              </Stack>

              <Stack direction="row" alignItems="start" flexWrap="wrap" gap={2}>
                <Button variant="contained" component={Link} to={newCustomOfferUri(user)}>
                  New custom offer
                  <SpeedNavigationKeyboardKey>{this.findKeyById('custom-offer')}</SpeedNavigationKeyboardKey>
                </Button>

                <PartnershipButtons user={user} />
              </Stack>
            </>
          )}

          <Stack direction="row" alignItems="start" flexWrap="wrap" gap={2}>
            {showSpoofActions && <SpoofUserBlock user={user} tenant={tenant} />}
            {tenant.value === 'lebusinesstraveller' && showSpoofActions && <BusinessAdminSpoofButton user={user} />}
            {!isTenantOfCurrentSite && (
              <Alert severity="warning">
                <TenancyWarningMessage tenant={tenant} userTenant={this.currentUserTenant(user)} />
              </Alert>
            )}
          </Stack>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={(_, value) => this.setCurrentTab(value)}>
              {Object.entries(tabs).map(([tab, label]) => (
                <Tab key={tab} value={tab} label={label} />
              ))}
            </Tabs>
          </Box>

          <Box>
            {currentTab === 'custom-offers' && <CustomOffersTab user={user} />}
            {currentTab === 'quotes' && <QuotesTab user={user} />}
            {currentTab === 'user-details' && (
              <>
                <UpdateUser
                  schema={this.props.schema.put}
                  brand={tenant.brand}
                  update={this.props.update}
                  user={this.props.user}
                  ref={(UpdateUserForm) => {
                    this.UpdateUserForm = UpdateUserForm;
                  }}
                />

                <dl>
                  <dt className="h6 text-muted text-uppercase">Account created</dt>
                  <dd>{formatDateOrdinalWithClock(this.props.user.created_date)}</dd>
                </dl>
                <UserLoginTable userId={this.props.user.memberId} />

                <Typography variant="body1" color="textSecondary">
                  Email changes history
                </Typography>
                <UserEmailChangeTable userId={this.props.user.memberId} />

                {this.props.user.signup_domain && (
                  <dl>
                    <dt className="h6 text-muted text-uppercase">Signup Domain</dt>
                    <dd>{this.props.user.signup_domain}</dd>
                  </dl>
                )}

                {this.props.tenancy && this.props.tenancy.length > 0 && (
                  <dl>
                    <dt className="h6 text-muted text-uppercase">Other domains that have user with same email</dt>
                    {this.props.tenancy.map((el) => (
                      <dd key={el.tenant}>{el.tenant}</dd>
                    ))}
                  </dl>
                )}
                <Box className="button-container">
                  <Button variant="contained" onClick={this.updateUser}>
                    Update
                  </Button>
                </Box>
              </>
            )}

            {currentTab === 'luxplus-member-profile' && (
              <MembershipSubscriptionDetails
                subscriptions={subscriptions}
                customerId={this.props.user.id_member}
                currentSubscriptionOrderItem={this.props.currentSubscriptionOrderItem}
                currentSubscriptionOrder={this.props.currentSubscriptionOrder}
                refreshData={this.props.refreshData}
              />
            )}

            {currentTab === 'user-agency' && (
              <>
                <UserAgencyDetails id={this.props.user.id_member}></UserAgencyDetails>
              </>
            )}
          </Box>

          <CreatePromoForUserModal
            isModalOpen={this.state.isCreatePromoCodeModalVisible}
            closeModal={this.hideCreatePromoCodeModal}
            user={this.props.user}
          />
          <GDPRRemovalModal
            isGDPRRemovalModalVisible={this.state.isGDPRRemovalModalVisible}
            hideGDPRRemovalModal={this.hideGDPRRemovalModal}
            memberId={this.props.user.id_member}
            email={this.props.user.email}
            currencyCode={userCountry?.currencyCode}
            brand={tenant.brand}
          />
          <GDPRRequestModal
            isGDPRRequestModalVisible={this.state.isGDPRRequestModalVisible}
            hideGDPRRequestModal={this.hideGDPRRequestModal}
            memberId={this.props.user.id_member}
            email={this.props.user.email}
            currencyCode={userCountry?.currencyCode}
            brand={tenant.brand}
          />
          <ForceLogoutModal
            isOpen={this.state.isForceLogoutModalVisible}
            user={this.props.user}
            onClose={this.hideForceLogoutModal}
          />
        </Stack>
      </>
    );
  }
}

export const UserDetailsComponent = UserDetails;

export default withTenant(withRouter(UserDetails));
