import React from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

import { Container, Typography } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import DebugSearch from './DebugSearch';

export default function ContentPageContainer() {
  const { id_place } = useParams<{ id_place: string }>();

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Debug Search</title>
      </Helmet>

      <PageHeader title="Debug search" />

      <Typography>
        A place to debug search results, and answer the question "why is deal X showing when I search Y"
      </Typography>

      <DebugSearch placeId={id_place} />
    </Container>
  );
}
