import React from 'react';

import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';

import { formatDateWeekDayShortMonth } from '~/services/TimeService';

export default function limitsRenderer(params: GridRenderCellParams<App.PromoData, number>) {
  return (
    <Box>
      <DefinitionTitle>Expiry date:</DefinitionTitle>
      <DefinitionText>{formatDateWeekDayShortMonth(params.row.expires_at)}</DefinitionText>

      <DefinitionTitle>Limit:</DefinitionTitle>
      <DefinitionText>{params.row.limit_per_user} (per user)</DefinitionText>
      <DefinitionText>
        {`${
          params.row?.code_limit != null && params.row.code_limit > 0
            ? `${params.row.code_limit - (params.row.usage_count ?? 0)}/${params.row.code_limit} remaining`
            : `${params.row.usage_count} used/unlimited`
        }`}
      </DefinitionText>
    </Box>
  );
}
