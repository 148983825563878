import React from 'react';

import classNames from 'clsx';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Alert, Box, Button, Grid, Typography } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import { LE_AGENT_HUB, brands } from '~/consts/brands';

import { getAllScheduledRegions } from '~/utils/getAllScheduledRegions';

import isE2ETestOffer from '../../../utils/isE2ETestOffer';

import NewLEDSchedules from './LEDSchedules';
import OldLESchedules from './LESchedulesOld/NewScheduleFrontPage';
import OldLEDSchedules from './NewScheduleByBrand';
import NewScheduleByType from './NewScheduleByType';
import NewSchedules from './common/Schedules';

const dataMigratingText = 'Updating...';
const dataMigrationText = 'Clear Schedules';

const getMigrationButtonText = (isMigratingData, migrationError) => {
  if (migrationError) {
    return migrationError;
  }
  if (isMigratingData) {
    return dataMigratingText;
  }
  return dataMigrationText;
};

export default function NewSchedulePageEdit(props) {
  const {
    offerId,
    offerName,
    schedules,
    shouldMigrateSchedule,
    migrateScheduleData,
    isMigratingData,
    migrationError,
    migrationButtonState,
  } = props;
  const brandsValue = brands.map((brand) => brand.value);
  const otherBrands = [...brands].slice(1);
  const regions = libRegions.getRegions();
  const enabledRegions = regions.filter((r) => !getAllScheduledRegions(schedules).includes(r.code));
  const showE2EOfferWarning = isE2ETestOffer(offerName);

  return (
    <Box>
      <Helmet>
        <title>Offers | {offerName} | Edit Schedule</title>
      </Helmet>
      <Grid container direction="row" alignContent="space-between" p={5}>
        <Grid item xs>
          <Button component={Link} startIcon={<ChevronLeftIcon />} to={`/offers/${offerId}`}>
            Return to Deal
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classNames(migrationButtonState)}
            onClick={migrateScheduleData}
            disabled={!shouldMigrateSchedule || isMigratingData}
          >
            {getMigrationButtonText(isMigratingData, migrationError)}
          </Button>
        </Grid>
      </Grid>
      {showE2EOfferWarning && (
        <Box p={5}>
          <Alert icon={false} variant="filled" severity="error" sx={{ p: 4 }}>
            <Typography variant="h3">This is an E2E test offer</Typography>
            <Typography variant="body1">
              Changing this offer's schedules so that it doesn't appear on the homepage may break the E2E tests. Only
              change these if you know what you're doing!
            </Typography>
          </Alert>
        </Box>
      )}
      {shouldMigrateSchedule && (
        <Grid container item xs={12} px={5}>
          <Alert severity="warning" sx={{ width: '100%' }}>
            This offer has invalid schedules that are not in the new format or missing some brands. Please clear
            schedules and re-add them to fix this.
          </Alert>
        </Grid>
      )}
      <NewScheduleByType
        schedules={schedules}
        brandsValue={brandsValue}
        offerId={offerId}
        regions={regions}
        shouldMigrateSchedule={shouldMigrateSchedule}
      />
      {window.configs.DISTRIBUTION_CONTROLS_ENABLED === 'on' ? (
        <>
          <NewSchedules brand="luxuryescapes" offerId={offerId} shouldMigrateSchedule={shouldMigrateSchedule} />
          <NewSchedules brand={LE_AGENT_HUB} offerId={offerId} shouldMigrateSchedule={shouldMigrateSchedule} />
          <NewLEDSchedules offerId={offerId} shouldMigrateSchedule={shouldMigrateSchedule} />
        </>
      ) : (
        <>
          <OldLESchedules
            offerId={offerId}
            enabledRegions={enabledRegions}
            shouldMigrateSchedule={shouldMigrateSchedule}
          />
          <OldLEDSchedules
            schedules={schedules}
            brands={otherBrands}
            offerId={offerId}
            regions={regions}
            shouldMigrateSchedule={shouldMigrateSchedule}
          />
        </>
      )}
    </Box>
  );
}
