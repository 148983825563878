import React, { useCallback, useMemo, useState } from 'react';

import { Box, Button, Link, Stack, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { getLogsByUrl } from '~/services/AuditService';
import { formatDateWithClock } from '~/services/TimeService';

function DomainCellComponent({ row }) {
  const propertyDatePath = useMemo(() => {
    return `/api/bedbank/properties/${row.id}/edit`;
  }, [row]);

  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [log, setLog] = useState<{ user_email: string; timestamp: string; diff: any }>(null);

  const getPropertyDateLog = useCallback(async () => {
    setLoading(true);
    const res = await getLogsByUrl(propertyDatePath, 0, 500);

    const results = res.result
      .filter((item) => item?.models[0]?.diff?.updated?.domain)
      .map((item) => {
        return {
          user_email: item.user_email,
          timestamp: item.timestamp,
          diff: item.models[0].diff.updated.domain,
        };
      })
      .sort((a, b) => {
        const dateA = new Date(a.timestamp);
        const dateB = new Date(b.timestamp);

        // sort descending
        if (dateA >= dateB) return -1;
        if (dateA < dateB) return 1;
      });

    if (results[0]) setLog(results[0]);

    setDataLoaded(true);
    setLoading(false);
  }, [propertyDatePath]);

  const logButtonClicked = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    getPropertyDateLog();
  }, []);

  const logReady = !loading && dataLoaded;

  return (
    <Stack direction="column" spacing={2} alignItems="center">
      <Box flex={1}>
        <Link>{row.domain}</Link>
      </Box>
      <Box>
        {loading && <Spinner size={30} />}
        {!loading && !dataLoaded && (
          <Button type="button" color="secondary" size="small" onClick={logButtonClicked}>
            Reveal Log
          </Button>
        )}
        {logReady && !log && <Typography>No logs found</Typography>}
        {logReady && log && (
          <>
            <Typography>Modified by: {log.user_email}</Typography>
            <Typography>Modified timestamp: {formatDateWithClock(log.timestamp)} </Typography>
            <Typography>
              Changed from ‘{log.diff.old}’ to ‘{log.diff.new}’
            </Typography>
          </>
        )}
      </Box>
    </Stack>
  );
}

export default function DomainCell(params) {
  return <DomainCellComponent row={params.row} />;
}
