import React from 'react';

import { Helmet } from 'react-helmet';

import { Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import CancellationModalSettingsContainer from './CancellationModalSettings/CancellationModalSettingsContainer';

const Settings = () => {
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>System | Settings</title>
      </Helmet>
      <PageHeader title="Settings" />
      <CancellationModalSettingsContainer />
    </Container>
  );
};

export default Settings;
