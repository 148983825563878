import {
  ROLE_ADMIN_USER,
  ROLE_EMPLOYEE_USER,
  ROLE_HERO_PLANNER_ADMIN,
  ROLE_HERO_PLANNER_VIEWER,
  ROLE_HOTEL_COORDINATOR,
  ROLE_LERE_ADMIN,
  ROLE_LERE_USER,
  ROLE_TOUR_COORDINATOR,
} from './roles';

export const NAVIGATION_LINKS: App.NavigationLink[] = [
  {
    href: '/purchases',
    label: 'Purchases',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_TOUR_COORDINATOR]),
  },
  {
    href: '/users-list',
    label: 'Users',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]),
  },
  {
    href: '/lebusiness',
    label: 'Business',
    requiredRoles: new Set([ROLE_EMPLOYEE_USER, ROLE_ADMIN_USER]),
  },
  {
    href: '/vendors',
    label: 'Vendors',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_HOTEL_COORDINATOR]),
  },
  {
    href: '/marketing',
    label: 'Marketing',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]),
  },
  {
    href: '/customer-communication/hero-planner/schedules',
    label: 'Hero Planner',
    requiredRoles: new Set([ROLE_HERO_PLANNER_VIEWER, ROLE_HERO_PLANNER_ADMIN]),
  },
  {
    href: '/email-schedule',
    label: 'Email Schedule',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]),
  },
  {
    href: '/Curation',
    label: 'Curation',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]),
  },
  {
    href: '/finance',
    label: 'Finance',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]),
  },
  {
    href: '/system',
    label: 'System',
    requiredRoles: new Set([ROLE_ADMIN_USER]),
  },
  {
    href: '/lere',
    label: 'LERE',
    requiredRoles: new Set([ROLE_LERE_ADMIN, ROLE_LERE_USER]),
  },
  {
    href: '/support-assistant/chats-history',
    label: 'Support Assistant',
    requiredRoles: new Set([ROLE_ADMIN_USER]),
  },
];
