import qs from 'qs';
import { context } from '~/stormpath';

import ApiError from '~/types/ApiError';

import { request } from './common';

export interface DailyFxRate {
  currencyFrom: string;
  currencyTo: string;
  effectiveDate: string;
  rate: number;
}

interface GetDailyFxRatesParams {
  date?: string | undefined;
  currency_to?: string | undefined;
  currency_from?: string | undefined;
}

function basePath() {
  return window.configs.API_HOST + '/api/';
}

function fetchData(path: string) {
  //  make sure the auth token exists before issuing a request
  // (and redirect to login if it's expired)
  context.checkTokenExpired();

  const options: RequestInit = {
    credentials: 'include',
    method: 'GET',
  };

  return fetch(path, options).then(function (response) {
    if (!response.ok) {
      if (response.status === 403) {
        throw new ApiError({
          message: "You don't have the permissions for this operation",
          name: 'PermissionError',
        });
      } else if (response.status === 500) {
        throw new ApiError({
          message: '',
          name: 'Empty data set',
        });
      } else if (response.status === 502) {
        return response.json().then(function (body) {
          throw new ApiError({
            message: body.message,
            name: 'Server error',
            status: response.status,
          });
        });
      } else {
        throw new ApiError({
          message: `Network error received: ${response.status}`,
          name: 'NetworkError',
        });
      }
    }
    if (response.status === 204) {
      throw new ApiError({
        message: 'Empty data set',
        name: 'Empty data set',
      });
    } else {
      return response.blob();
    }
  });
}

export function getOfferPerformance(offer_id) {
  const url = `${basePath()}reporting/offers/${offer_id}/performance`;
  return request(url);
}
export function getOfferPerformanceForFinance(offer_id) {
  const url = `${basePath()}reporting/offers/${offer_id}/finance-performance`;
  return request(url);
}

export function getAddonPerformance(addon_id) {
  const url = `${basePath()}reporting/addons/${addon_id}/performance`;
  return request(url);
}

export function getFXRates() {
  const url = `${basePath()}reporting/fx/monthly`;
  return request(url);
}

export async function getDailyFxRates(props: GetDailyFxRatesParams): Promise<DailyFxRate[]> {
  const url = `${basePath()}reporting/fx/daily?${qs.stringify({ ...props })}`;
  const res = await request(url);
  return res['results'];
}

export function downloadAllPurchases(start_at, end_at) {
  const queryParams = {
    start_at,
    end_at,
  };
  return fetchData(`${basePath()}reporting/orders/all?${qs.stringify(queryParams)}`);
}

type EnqueueReportParams = {
  start_at?: string;
  end_at?: string;
  completed?: boolean;
};

export async function enqueueReport(report: string, params: EnqueueReportParams) {
  return fetchData(`${basePath()}reporting/${report}?${qs.stringify(params)}`);
}

export async function queueRefundReport(start_at, end_at, offer_id, le_offer_id, paymentMethod, itemStatus) {
  const queryParams = {
    start_at,
    end_at,
    format: 'csv',
    offer_id: undefined,
    le_offer_id: undefined,
    paymentMethod: undefined,
    itemStatus: undefined,
  };

  if (offer_id) {
    queryParams.offer_id = offer_id;
  }

  if (le_offer_id) {
    queryParams.le_offer_id = le_offer_id;
  }

  if (paymentMethod) {
    queryParams.paymentMethod = paymentMethod;
  }

  if (itemStatus) {
    queryParams.itemStatus = itemStatus;
  }

  return fetchData(`${basePath()}reporting/order-refunds?${qs.stringify(queryParams)}`);
}

export function queueAllCreditMovements(start_at, end_at, credit_type, format) {
  const queryParams = {
    start_at,
    end_at,
    credit_type,
    format,
  };
  return fetchData(`${basePath()}reporting/credits/all?${qs.stringify(queryParams)}`);
}

export function queueCreditSummary(start_at, end_at, currency, format) {
  const queryParams = {
    start_at,
    end_at,
    currency,
    format,
  };
  return fetchData(`${basePath()}reporting/credits/summary?${qs.stringify(queryParams)}`);
}

export function queueDetailedBookings({ idOffer, leIdOffer, endAt }) {
  const queryParams = {
    offer_id: idOffer,
    le_offer_id: leIdOffer,
    end_at: endAt,
    format: 'csv',
  };

  return fetchData(`${basePath()}reporting/bookings?${qs.stringify(queryParams)}`);
}

export function queueSummaryBookings({ endAt, recordTypeFilters }) {
  const queryParams = {
    end_at: endAt,
    format: 'csv',
    record_type_filters: recordTypeFilters,
  };

  return fetchData(`${basePath()}reporting/bookings/summary?${qs.stringify(queryParams)}`);
}

export function queueVccBookings({ date, offerTypeFilters }) {
  const queryParams = {
    date,
    format: 'email',
    offer_types: offerTypeFilters,
  };

  return fetchData(`${basePath()}reporting/bookings/vcc?${qs.stringify(queryParams)}`);
}

export function queueNonRefundableBookings({ date }) {
  const queryParams = {
    date,
    format: 'email',
  };

  return fetchData(`${basePath()}reporting/bookings/non-refundable?${qs.stringify(queryParams)}`);
}

export function queueBreakdownNonredemptions({ idOffer, leOfferId, startAt, endAt }) {
  const queryParams = {
    offer_id: idOffer,
    le_offer_id: leOfferId,
    start_at: startAt,
    end_at: endAt,
    format: 'csv',
  };

  return fetchData(`${basePath()}reporting/nonredemption/breakdown?${qs.stringify(queryParams)}`);
}

export function queueLEDNonredemptions({ offerId, startDate, endDate }) {
  const queryParams = {
    offer_id: offerId,
    start_date: startDate,
    end_date: endDate,
    format: 'csv',
  };

  return fetchData(`${basePath()}reporting/nonredemption/led?${qs.stringify(queryParams)}`);
}

export function queueDetailedNonredemptions({ idOffer, leOfferId, startAt, endAt, brandFilters }) {
  const queryParams = {
    offer_id: idOffer,
    le_offer_id: leOfferId,
    start_at: startAt,
    end_at: endAt,
    brand_filters: brandFilters,
    format: 'csv',
  };

  return fetchData(`${basePath()}reporting/nonredemption/detailed?${qs.stringify(queryParams)}`);
}

export function queueSummaryNonredemptions({ idOffer = undefined, startAt, endAt }) {
  const queryParams = {
    offer_id: idOffer,
    start_at: startAt,
    end_at: endAt,
    format: 'csv',
  };

  return fetchData(`${basePath()}reporting/nonredemption/summary?${qs.stringify(queryParams)}`);
}

export function downloadAllPromoCodes({ startAt, endAt }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: 'csv',
  };
  return fetchData(`${basePath()}reporting/promo/codes?${qs.stringify(queryParams)}`);
}

export function emailPromoCodeSpend({ startAt, endAt }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: 'email',
  };
  return fetchData(`${basePath()}reporting/promo/spend?${qs.stringify(queryParams)}`);
}

export function enqueueTaxInvoice({ idOffer }) {
  const queryParams = {
    offer_id: idOffer,
    format: 'pdf',
  };
  return fetchData(`${basePath()}reporting/tax_invoice?${qs.stringify(queryParams)}`);
}

export function enqueueCancelledBookingPaymentsReport({ startAt, endAt }) {
  const params = {
    start_at: startAt,
    end_at: endAt,
    format: 'email',
  };

  return fetchData(`${basePath()}reporting/cancelled-booking-payments?${qs.stringify(params)}`);
}

export function downloadDailyReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
    source: 'bq',
  };
  return fetchData(`${basePath()}reporting/daily?${qs.stringify(queryParams)}`);
}

export function downloadVistaraReport({ startAt, endAt }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
  };
  return fetchData(`${basePath()}reporting/vistara?${qs.stringify(queryParams)}`);
}

export function downloadRefundReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/refunds?${qs.stringify(queryParams)}`);
}

export function downloadBedbankRefundReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/bedbank-refunds?${qs.stringify(queryParams)}`);
}

export function downloadTourRefundReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/tour-refunds?${qs.stringify(queryParams)}`);
}

export function downloadCruiseRefundReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/cruise-refunds?${qs.stringify(queryParams)}`);
}

export function downloadCruiseRefundAfterPaymentReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/cruise-refunds-after-payment?${qs.stringify(queryParams)}`);
}

export function downloadMusmentRefundReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/musement-refunds?${qs.stringify(queryParams)}`);
}

export function downloadTourPurchasesReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/bookings/tour?${qs.stringify(queryParams)}`);
}

export function downloadCruisePurchasesReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/bookings/cruise?${qs.stringify(queryParams)}`);
}

export function downloadCarHireBookingsReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/bookings/carHire?${qs.stringify(queryParams)}`);
}

export function downloadCarHirePaymentStatusReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/carHire-payment?${qs.stringify(queryParams)}`);
}

export function downloadCarHireRefundsReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/carHire-rental-refund?${qs.stringify(queryParams)}`);
}

export function downloadExperiencesOffersReport({ format = 'xlsx' }) {
  return fetchData(`${basePath()}reporting/experiences/offers?${qs.stringify({ format })}`);
}

export function downloadPromoRefundReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/promo-order-refunds?${qs.stringify(queryParams)}`);
}

export function queueBedBankBookingsReport({ startAt, endAt, format }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format,
  };
  return fetchData(`${basePath()}reporting/bookings/bedbank?${qs.stringify(queryParams)}`);
}

export function queueBedBankPayableReport({ startAt, endAt, format }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format,
  };
  return fetchData(`${basePath()}reporting/bookings/bedbankpayable?${qs.stringify(queryParams)}`);
}

export function queueMusementBookingsReport({ startAt, endAt, format }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format,
  };
  return fetchData(`${basePath()}reporting/bookings/musement?${qs.stringify(queryParams)}`);
}

export function queueMusementPayableReport({ startAt, endAt, format }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format,
  };
  return fetchData(`${basePath()}reporting/bookings/musementpayable?${qs.stringify(queryParams)}`);
}

export function queueMusementPaidReport({ startAt, endAt }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
  };
  return fetchData(`${basePath()}vendor/musement-payments?${qs.stringify(queryParams)}`);
}

export function queueTTCPayableReport({ endAt, format }) {
  const queryParams = {
    end_at: endAt,
    format,
  };
  return fetchData(`${basePath()}reporting/bookings/ttcpayable?${qs.stringify(queryParams)}`);
}

export function queueRevelexPayableReport({ endAt, format }) {
  const queryParams = { end_at: endAt, format };
  return fetchData(`${basePath()}reporting/bookings/revelexpayable?${qs.stringify(queryParams)}`);
}

export function queueInsuranceBookingsReport({ startAt, endAt, recordInsuranceProviders = ['All'], format = 'email' }) {
  const { 0: insurance_provider } = recordInsuranceProviders;
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    insurance_provider,
    format,
  };
  return fetchData(`${basePath()}reporting/bookings/insurance?${qs.stringify(queryParams)}`);
}

export function downloadAgentSalesReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/agent_sales?${qs.stringify(queryParams)}`);
}

export function queueFutureBookingsReport({ startAt }) {
  const queryParams = {
    start_at: startAt,
    format: 'email',
  };
  return fetchData(`${basePath()}reporting/futureBookings?${qs.stringify(queryParams)}`);
}

export function queueCancelledFutureBookingsReport({ startAt }) {
  const queryParams = {
    start_at: startAt,
    format: 'email',
  };
  return fetchData(`${basePath()}reporting/futureBookingsCancelled?${qs.stringify(queryParams)}`);
}

export function downloadPurchaseReport({ startAt, endAt, idOffer, completedDateBased = false }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    offer_id: idOffer,
    completed_date_based: completedDateBased,
    format: 'csv',
  };
  return fetchData(`${basePath()}reporting/purchases?${qs.stringify(queryParams)}`);
}

export function queueAllTransactions(start_at, end_at, format) {
  const queryParams = {
    start_at,
    end_at,
    format,
  };
  return fetchData(`${basePath()}reporting/transactions?${qs.stringify(queryParams)}`);
}

export function queueOutstandingDepositBalance({ startAt, endAt, format }) {
  const queryParams = {
    format: format,
    start_at: undefined,
    end_at: undefined,
  };

  if (startAt) {
    queryParams.start_at = startAt;
  }

  if (endAt) {
    queryParams.end_at = endAt;
  }

  return fetchData(`${basePath()}reporting/deposits/balances?${qs.stringify(queryParams)}`);
}

export function queueGrossDeposit({ startAt, endAt, format }) {
  const queryParams = {
    format: format,
    start_at: undefined,
    end_at: undefined,
  };

  if (startAt) {
    queryParams.start_at = startAt;
  }

  if (endAt) {
    queryParams.end_at = endAt;
  }

  return fetchData(`${basePath()}reporting/deposits/gross?${qs.stringify(queryParams)}`);
}

export function queueBalancePaid({ startAt, endAt, format }) {
  const queryParams = {
    format: format,
    start_at: undefined,
    end_at: undefined,
  };

  if (startAt) {
    queryParams.start_at = startAt;
  }

  if (endAt) {
    queryParams.end_at = endAt;
  }

  return fetchData(`${basePath()}reporting/deposits/balance-paid?${qs.stringify(queryParams)}`);
}

export function queueDepositInstalmentRefund({ startAt, endAt, format }) {
  const queryParams = {
    format: format,
    start_at: undefined,
    end_at: undefined,
  };

  if (startAt) {
    queryParams.start_at = startAt;
  }

  if (endAt) {
    queryParams.end_at = endAt;
  }

  return fetchData(`${basePath()}reporting/deposits/refund?${qs.stringify(queryParams)}`);
}

export function queueItemsPurchased({ startAt, endAt, source }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: 'email',
    source,
  };

  return fetchData(`${basePath()}reporting/items_purchased?${qs.stringify(queryParams)}`);
}

export function downloadVendorPaymentsReport({ startAt, endAt, recordTypeFilters }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: 'csv',
    record_type_filters: recordTypeFilters,
  };
  return fetchData(`${basePath()}reporting/vendor-payments?${qs.stringify(queryParams)}`);
}

export function queueLoyaltyEarn({ startAt, endAt, code, datesType }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    dates_type: undefined,
  };

  if (datesType) {
    queryParams.dates_type = datesType;
  }

  return fetchData(`${basePath()}reporting/${code}/earn?${qs.stringify(queryParams)}`);
}

export function queueLoyaltyRewardPointEarnReport({ startAt, endAt, code }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
  };
  return fetchData(`${basePath()}reporting/${code}/reward?${qs.stringify(queryParams)}`);
}

export function queueTravelVouchersSold({ startAt, endAt, idOffer, leIdOffer }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: 'csv',
    offer_id: idOffer,
    le_offer_id: leIdOffer,
  };
  return fetchData(`${basePath()}reporting/travel-vouchers-sold?${qs.stringify(queryParams)}`);
}

export function queueFinanceReconciliationReport({ startAt, endAt }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: 'xlsx',
  };
  return fetchData(`${basePath()}reporting/finance_reconciliation?${qs.stringify(queryParams)}`);
}

export function downloadStripeBalanceTransactionsReport({ startAt, endAt, account, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    account,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/stripe/balance-transactions?${qs.stringify(queryParams)}`);
}

export function downloadLatitudeFinanceRefundsReport({ startAt, endAt }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: 'csv',
  };
  return fetchData(`${basePath()}reporting/latitude/refunds?${qs.stringify(queryParams)}`);
}

export function downloadGiftCardsReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/giftcards?${qs.stringify(queryParams)}`);
}

export function downloadAddonsReport({ startAt, endAt, fileFormat }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/addons?${qs.stringify(queryParams)}`);
}

export function downloadTravellersWithMissingDetails({ fileFormat }) {
  const queryParams = {
    format: fileFormat,
  };
  return fetchData(`${basePath()}reporting/travellers-with-missing-details?${qs.stringify(queryParams)}`);
}

export function queueLiveVendorHoldback({ overpaidVendorsOnly }) {
  const queryParams = {
    overpaid_vendors_only: overpaidVendorsOnly,
    format: 'email',
  };

  return fetchData(`${basePath()}reporting/vendor-holdback/live-summary?${qs.stringify(queryParams)}`);
}

export function queueAbandonedOrders({ idOffer = undefined, startAt, endAt }) {
  const queryParams = {
    offer_id: idOffer,
    start_at: startAt,
    end_at: endAt,
    format: 'csv',
  };

  return fetchData(`${basePath()}reporting/orders/abandoned?${qs.stringify(queryParams)}`);
}

export function queueXeroTrialBalance({ startAt, endAt, fileFormat, group }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    format: fileFormat,
    group: group,
  };

  return fetchData(`${basePath()}reporting/xero-trial-balance?${qs.stringify(queryParams)}`);
}

export function queueBookingPaymentsReport({ startAt, endAt, type, format }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    type,
    format,
  };
  return fetchData(`${basePath()}reporting/booking-payments?${qs.stringify(queryParams)}`);
}

export function queueUnpaidBookings({ date, recordType }) {
  const queryParams = {
    date,
    record_type: recordType,
    format: 'email',
  };

  return fetchData(`${basePath()}reporting/bookings/unpaid?${qs.stringify(queryParams)}`);
}

export function downloadLebtCreditReport({ start_at, end_at }) {
  const queryParams = {
    start_at,
    end_at,
  };
  return fetchData(`${basePath()}reporting/business-traveller/credit-report?${qs.stringify(queryParams)}`);
}

export async function getVendorPaymentProblems({ start_at, end_at }) {
  const query_params = {
    start_at,
    end_at,
  };

  const response = await request(`${basePath()}reporting/vendorpayments/problems?${qs.stringify(query_params)}`, {
    method: 'GET',
  });
  return response;
}

export function postVendorPaymentManualAdjustment({ item_id }) {
  return request(`${basePath()}reporting/vendorpayments/manual-adjustment`, {
    method: 'POST',
    body: JSON.stringify({ item_bk: item_id }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function downloadExpiringCreditsReport({ startAt, endAt, minimumCreditsValue }) {
  const queryParams = {
    start_at: startAt,
    end_at: endAt,
    min_credits_val: minimumCreditsValue,
  };

  return fetchData(`${basePath()}reporting/credits/expiring?${qs.stringify(queryParams)}`);
}

export function downloadBusinessBookingReport({ business_id, start_at, end_at }) {
  const query_params = {
    business_id: business_id,
    start_at: start_at,
    end_at: end_at,
  };

  return fetchData(`${basePath()}reporting/business-traveller/orders?${qs.stringify(query_params)}`);
}
