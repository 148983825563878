import React from 'react';

import { Typography } from '@mui/material';

import { PromoMeta } from '~/services/PromoService';

type PromoReplacementExplainerProp = {
  promoTextReplacements: PromoMeta['promo_text_replacements'];
};

const PromoReplacementExplainer = ({ promoTextReplacements }: PromoReplacementExplainerProp) => {
  return (
    <Typography variant="caption">
      <details>
        <summary>🚧 (Promo Replacement Field) 🚧</summary>Replacements tags included in this fields will be replaced
        with the dynamic values from the promo code.
        <br />
        Replacements tags:
        <ul>
          {promoTextReplacements?.length > 0
            ? promoTextReplacements.map((ptr) => <li key={ptr.name}>{`<${ptr.name}>`}</li>)
            : '(loading)'}
        </ul>
      </details>
    </Typography>
  );
};

export { PromoReplacementExplainer };
