export const CREDIT_TYPE_COVID_NO_SHOW = 'COVID-19 No Show';
export const CREDIT_TYPE_GIFTCARD = 'Giftcard';
export const CREDIT_TYPE_STRIPE_INVOICE = 'Stripe Invoice';
export const CREDIT_TYPE_STORE_CREDIT = 'Store credit';
export const CREATE_TYPE_EXPIRED_BNBL_PACKAGE = 'Expired BNBL package';
export const CREATE_TYPE_TRANSFERRING_CREDIT = 'Transferring Credit';
export const CREATE_TYPE_REACTIVATED_CREDIT = 'Reactivated Credit';
export const CREATE_TYPE_PEOPLE_AND_CULTURE = 'People & Culture';
export const CREATE_TYPE_OTHER = 'Other';
export const CREDIT_TYPE_PAY_LATER_REFUND = 'Klarna/AfterPay/LatitudePay refund';
export const CREDIT_TYPE_CRUISE_OFFER_PACKAGE_CREDIT = 'Offer package credits';
export const STRIPE_PAYMENT_ZONES = [
  'LE (AU)',
  'LE (NZ)',
  'LE (Singapore)',
  'LE US - New York',
  'LE Flights',
  'LE Int Flights',
  'LE US - Flights',
];

export const CREDIT_EXTENSION_TYPES = [
  { creditExtensionType: 'Reducing Credit Expiry' },
  { creditExtensionType: 'Aligning Credit with previous expiry (Credit Transfer)' },
  { creditExtensionType: 'Stripe Invoice - Adjusting the expiry date to 12 months' },
  { creditExtensionType: 'Goodwill extension of 1 month' },
  { creditExtensionType: 'Offline/Manual Cancellation' },
  { creditExtensionType: 'Other' },
];

export const DEFAULT_EXPIRY_IN_MONTHS = 1;
export const PAY_LATER_DEFAULT_EXPIRY_IN_MONTHS = 12;
