import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { theme } from '~/theme';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';
import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import Spinner from '~/components/Common/Spinner';

import { HOTEL_OFFER_TYPES, OFFER_TYPE_HOTEL, OFFER_TYPE_RENTAL } from '~/consts/offerTypes';

import { getExperienceOffers, getHandpickedExperiencesForPackage } from '~/services/ExperiencesService';

import { mapSFCancellationPolicyToAdmin } from '~/utils/mapSFCancelltionPolicyToAdmin';
import { isOfferTypeHotel, isOfferTypeTour } from '~/utils/offer';

import InclusionsService from '../../../services/InclusionsService';
import OffersService from '../../../services/OffersService';
import PackagesService from '../../../services/PackagesService';
import ReservationService from '../../../services/ReservationService';
import isE2ETestOffer from '../../../utils/isE2ETestOffer';
import ErrorListDisplay from '../../Common/ErrorListDisplay';
import PackageForm from '../../Common/Forms/PackageForm';
import { ManifestPackagesModal } from '../../Common/Modals/ManifestPackagesModal';
import PackageSettingsModal from '../../Common/Modals/PackageSettingsModal';

import BulkUpdatePackageStatusModal from './BulkUpdatePackageStatusModal';
import { SyncCancellationPolicyModal } from './SyncCancellationPolicyModal/SyncCancellationPolicyModal';

const DEFAULT_RADIUS_TO_SEARCH_RENTAL_EXPERIENCES = 100;
const DEFAULT_MAX_EXPERIENCES_TO_SHOW = 2000;

function fetchPackageInclusions(offer: App.Offer, offerId: string): Promise<any> | undefined {
  return isOfferTypeHotel(offer) ? InclusionsService.fetchPackageInclusions(offerId) : undefined;
}

function fetchTours(offer: App.Offer): Promise<any> | undefined {
  return isOfferTypeTour(offer)
    ? ReservationService.getTours({
        page: 1,
        // an arbitrarily large limit to hopefully encapsulate all the results
        limit: 150,
        vendorId: offer.vendor_account_id,
      })
    : undefined;
}

async function fetchData(offerId: string) {
  const { result: offer } = await OffersService.getOffer(offerId);

  const [packageSchemaResponse, propertyList, tourList, packageInclusionsResult] = await Promise.all([
    PackagesService.getPackageSchema(offerId),
    ReservationService.getProperties(offer.vendor_account_id),
    fetchTours(offer),
    fetchPackageInclusions(offer, offerId),
  ]);

  const propertyInfo = propertyList?.count ? propertyList?.result : [];
  const options = propertyInfo.map((property) => {
    return {
      label: `${property.name} (${property.channel_manager} ${property.hotel_code ?? ''})`,
      value: property.id,
    };
  });
  const packageInclusions = packageInclusionsResult?.result ?? {};

  const { schema } = packageSchemaResponse.patch.body;

  const tourData = tourList?.count ? tourList?.result : [];

  // All packages in an offer have the same property id, so we can use the first one
  const propertyIdFromPackage = offer?.packages?.[0]?.fk_property_id;
  const packageId = propertyIdFromPackage ? propertyIdFromPackage : options[0]?.value;
  const property = propertyInfo.find((p) => p.id === packageId);
  let experiences: Experiences.Offer[] = [];
  const selectedExperiences: Map<string, string[]> = new Map();

  if (offer.type === OFFER_TYPE_RENTAL) {
    const response = await getExperienceOffers({
      curationData: true,
      limit: DEFAULT_MAX_EXPERIENCES_TO_SHOW,
      minified: true,
      filters: {
        status: ['APPROVED'],
        distance: `${DEFAULT_RADIUS_TO_SEARCH_RENTAL_EXPERIENCES}km`,
        coordinates: {
          lat: property.latitude, //-8.6682382
          lng: property.longitude, //115.1716972
        },
      },
      brand: 'luxuryescapes',
    });
    experiences = response?.result ?? [];

    const handpickedExperiences = await Promise.all(
      (offer?.packages ?? []).map((p) => {
        return getHandpickedExperiencesForPackage(p.le_package_id);
      }),
    ).then((res) => {
      const responses = res.map((r) => r.result);
      return responses.flat();
    });

    handpickedExperiences.map((exp) => {
      let exps;
      if (selectedExperiences.has(exp.packageId)) {
        exps = selectedExperiences.get(exp.packageId);
        exps.push(exp.experienceId);
      } else {
        exps = [exp.experienceId];
      }
      selectedExperiences.set(exp.packageId, exps);
    });
  }

  return {
    offer,
    propertyInfo,
    options,
    packageInclusions,
    schema,
    tourData,
    packageId,
    experiences,
    selectedExperiences,
  };
}

const sortPackages = (packages) => {
  return packages.sort((a, b) => {
    return a.deal_option_name.localeCompare(b.deal_option_name, undefined, { numeric: true, sensitivity: 'base' });
  });
};

const PackagesEditContainer = () => {
  const { id_offer: offerId } = useParams<{ id_offer: string }>();

  const [packageSchema, setPackageSchema] = useState(null);
  const [propertyData, setPropertyData] = useState<Reservation.Property[] | null>(null);
  const [tourData, setTourData] = useState<Reservation.Tour[] | null>(null);
  const [offerData, setOfferData] = useState<App.Offer | null>(null);
  const [packageData, setPackageData] = useState<Array<App.AccommodationPackage & App.TourPackage> | null>([]);
  const [loading, setLoading] = useState(true);

  const [packagesExpansionStatuses, setPackagesExpansionStatuses] = useState([]);
  const [expandedPackagesQuantity, setExpandedQuantity] = useState(0);

  const [showPropertySelect, setShowPropertySelect] = useState(false);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [settingUpdates, setSettingUpdates] = useState([]);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [saveFailed, setSaveFailed] = useState(false);
  const [allSave, toggleAllSave] = useState(false);
  const [allSaveCounter, setAllSaveCounter] = useState(-1);

  const [manifestPackagesModal, toggleManifestPackagesModal] = useState(false);
  const [manifestPackagesListener, toggleManifestPackagesListener] = useState({
    type: undefined,
    clicked: false,
  });
  const [isGeneratingPackages, setIsGeneratingPackages] = useState(false);
  const [packageInclusions, setPackageInclusions] = useState<Record<string, App.PackageInclusion[]>>({});
  const [experiences, setExperiences] = useState([]);
  const [selectedExperiences, setSelectedExperiences] = useState<Map<string, string[]>>();
  const { enqueueSnackbar } = useSnackbar();

  const autoFillOptions = useCallback((manifestType) => {
    toggleManifestPackagesListener((prevState) => ({
      type: manifestType,
      clicked: !prevState.clicked,
    }));

    toggleManifestPackagesModal(false);
  }, []);

  const onChangeProperty = (event: SelectChangeEvent) => {
    const newPropertyValue = event.target.value as string;

    setSelectedPropertyId(newPropertyValue);

    const updatedPackages = packageData.map((pkg) => {
      return {
        ...pkg,
        fk_property_id: newPropertyValue,
        fk_room_rate_id: '',
        fk_room_type_id: '',
      };
    });

    setPackageData([...updatedPackages]);
  };

  const onPackageSettingsModalSave = (updates) => {
    setPackageData((prevPackageData) => prevPackageData.map((pkg) => ({ ...pkg, ...updates })));
    setSettingUpdates(Object.keys(updates));
    setShowSettingsModal(false);
  };

  const onSinglePackageSave = (pkg) => {
    const updatedPackages = packageData.map((p) => {
      if (p.id === pkg.id) {
        return pkg;
      }
      return p;
    });

    setPackageData([...updatedPackages]);
  };

  const onPackageSaveFailed = () => {
    setSaveFailed(true);

    if (allSaveCounter != -1) {
      setAllSaveCounter((prevCounter) => prevCounter + 1);
    }
  };

  const onPackageSaveComplete = () => {
    if (allSaveCounter != -1) {
      setAllSaveCounter((prevCounter) => prevCounter + 1);
    }
  };

  const onPackageChange = () => {
    if (allSaveCounter === expandedPackagesQuantity) {
      setAllSaveCounter(-1);
    }
  };

  const toggleAllPackages = () => {
    setPackagesExpansionStatuses((prevState) => {
      return prevState.map(() => !isAllExpanded);
    });
  };

  const togglePackage = (index) => {
    setPackagesExpansionStatuses((prevState) => {
      const newState = [...prevState];

      newState[index] = !prevState[index];

      return newState;
    });
  };

  const updateInclusionsOnPackages = useCallback(async () => {
    const { result } = await InclusionsService.fetchPackageInclusions(offerId);

    setPackageInclusions(result);
  }, [offerId]);

  const refreshData = useCallback(async () => {
    setLoading(true);
    const {
      offer,
      propertyInfo,
      options,
      packageInclusions,
      schema,
      tourData,
      packageId,
      experiences,
      selectedExperiences,
    } = await fetchData(offerId);

    setPackageInclusions(packageInclusions as Record<string, App.PackageInclusion[]>);
    setPackageSchema(schema);
    setPropertyData(propertyInfo);
    setTourData(tourData);
    setOfferData(offer);
    setPackagesExpansionStatuses(Array(offer.packages.length).fill(false));
    setPackageData(offer.packages);
    setShowPropertySelect(HOTEL_OFFER_TYPES.includes(offer.type));
    setPropertyOptions(options);
    setSelectedPropertyId(packageId);
    setExperiences(experiences);
    setSelectedExperiences(selectedExperiences);
    setLoading(false);
  }, [offerId]);

  const setAllPackagesStatus = useCallback(
    async (status: 'draft' | 'hidden' | 'content-approved') => {
      try {
        enqueueSnackbar('Loading package update', { variant: 'info' });
        for (const pkg of packageData) {
          const updatedPkg = Object.entries({
            ...pkg,
            status,
          }).reduce((acc, [key, value]) => {
            if (value !== null) {
              acc[key] = value;
            }
            return acc;
          }, {});

          await PackagesService.updatePackage(updatedPkg, offerId, pkg.id_salesforce_external);
        }
        refreshData();
        enqueueSnackbar('Updated packages', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar('Failed to update package status: ' + e, { variant: 'error' });
      }
    },
    [enqueueSnackbar, offerId, packageData, refreshData],
  );

  const generatePackages = () => {
    setIsGeneratingPackages(true);
    OffersService.createOfferPackages({
      property_id: selectedPropertyId,
      offer_id_salesforce_external: offerData.id_salesforce_external,
    }).then(() => {
      refreshData();
    });
    setIsGeneratingPackages(false);
  };

  let allSaveButtonState = 'default';
  allSaveButtonState =
    allSaveCounter < expandedPackagesQuantity && allSaveCounter !== -1 ? 'saving' : allSaveButtonState;
  allSaveButtonState = allSaveCounter === expandedPackagesQuantity ? 'saved' : allSaveButtonState;

  const isAllExpanded = packagesExpansionStatuses.some((isExpanded) => isExpanded);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  useEffect(() => {
    const expandedPackages = packagesExpansionStatuses.filter((status) => status);
    setExpandedQuantity(expandedPackages.length);
  }, [packagesExpansionStatuses]);

  useMemo(() => {
    setPackageData(sortPackages(packageData));
  }, [packageData]);

  if (!offerData || loading) {
    return <Spinner />;
  }

  return (
    <Box sx={{ marginX: 5 }}>
      <Helmet>
        <title>Offers | {offerData.name} | Edit Offer Packages</title>
      </Helmet>
      <Button component={Link} to={`/offers/${offerId}`}>
        <ChevronLeftIcon /> Return to offer
      </Button>
      <Typography variant="h2" paddingTop={3}>
        Edit Offer Packages
      </Typography>
      {isE2ETestOffer(offerData.name) && (
        <Alert variant="filled" severity="warning">
          <AlertTitle>This is an E2E test offer</AlertTitle>
          Changing this offer may break the E2E tests. Only do it if you know what you're doing!
        </Alert>
      )}
      <Stack direction={'row'} sx={{ justifyContent: 'right', gap: 2 }}>
        <BulkUpdatePackageStatusModal onConfirm={setAllPackagesStatus} />
        {offerData.type === OFFER_TYPE_HOTEL && (
          <SyncCancellationPolicyModal
            packages={packageData}
            newCancellationPolicy={mapSFCancellationPolicyToAdmin(offerData.sf_cancellation_policy)}
            propertyData={propertyData}
            refreshData={refreshData}
          />
        )}
        <Button onClick={() => setShowSettingsModal(true)} startIcon={<SettingsIcon />}>
          Package settings
        </Button>
      </Stack>
      {showPropertySelect && (
        <div>
          <Typography my={2} variant="h6">
            Property&nbsp;
            <Button component={Link} to={`/vendors/${offerData.vendor_account_id}/properties/${selectedPropertyId}`}>
              (show property)
            </Button>
          </Typography>
          <Select value={selectedPropertyId} onChange={onChangeProperty}>
            {propertyOptions.map((propertyOption) => (
              <MenuItem key={propertyOption.value} value={propertyOption.value}>
                {propertyOption.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      {showPropertySelect && offerData.type !== OFFER_TYPE_HOTEL && (
        <Button
          onClick={() =>
            !offerData.internal_packages && offerData.packages.length === 0
              ? generatePackages()
              : toggleManifestPackagesModal(true)
          }
          sx={{ display: 'flex', justifyContent: 'right', height: '20px' }}
          disabled={!selectedPropertyId || isGeneratingPackages}
        >
          Generate Packages
        </Button>
      )}
      <Typography mt={4} mb={2} variant="h4">
        Edit individual packages
      </Typography>
      <PackageSettingsModal
        showSettingsModal={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        onSave={onPackageSettingsModalSave}
      />
      <ManifestPackagesModal
        onClose={() => toggleManifestPackagesModal(false)}
        onSave={autoFillOptions}
        showModal={manifestPackagesModal}
        offerType={offerData.type}
      />
      <Dialog open={saveFailed} onClose={() => setSaveFailed(false)}>
        <ErrorListDisplay messages={['Some packages not saved.']} />
      </Dialog>
      {packageData.length > 1 && (
        <div style={{ marginBottom: '12px' }}>
          <Button onClick={toggleAllPackages}>{isAllExpanded ? 'Collapse All Options' : 'Expand All Options'}</Button>
        </div>
      )}
      {packageData.map((pkg, index) => {
        return (
          <Accordion
            disableGutters
            data-cy="package-accordion"
            key={pkg.le_package_id ?? pkg.id_salesforce_external}
            expanded={packagesExpansionStatuses[index]}
            TransitionProps={{ mountOnEnter: true, unmountOnExit: false }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => {
                togglePackage(index);
              }}
              sx={{
                backgroundColor: theme.palette.grey[200],
                display: 'flex',
                justifyContent: 'space-between',
                gap: '1rem',
              }}
            >
              {pkg.deal_option_name}
            </AccordionSummary>
            <AccordionDetails>
              <PackageForm
                allPackagesData={packageData}
                allSave={allSave}
                campaignType={offerData.campaign_type}
                iterateIndex={index}
                key={pkg.le_package_id ?? pkg.id_salesforce_external}
                manifestPackagesListener={manifestPackagesListener}
                offerName={offerData.name}
                offerType={offerData.type}
                onChange={onPackageChange}
                onSaveComplete={onPackageSaveComplete}
                onSaveFailed={onPackageSaveFailed}
                updateInclusionsOnPackages={updateInclusionsOnPackages}
                packageInclusions={packageInclusions}
                packageData={pkg}
                savePackage={onSinglePackageSave}
                propertyData={propertyData}
                schema={packageSchema}
                settingUpdates={settingUpdates}
                tourData={tourData}
                vendorId={offerData.vendor_account_id}
                experiences={experiences}
                packageExperiences={selectedExperiences ? selectedExperiences.get(pkg.le_package_id) : undefined}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Box className="button-container right" sx={{ background: 'white' }}>
        <Button
          onClick={() => {
            setAllSaveCounter(0);
            toggleAllSave((prevState) => !prevState);
          }}
          variant="contained"
          disabled={allSaveButtonState === 'saving'}
        >
          {(() => {
            switch (allSaveButtonState) {
              case 'default':
                return 'Save All';
              case 'saving':
                return `Saving... ${allSaveCounter}/${expandedPackagesQuantity}`;
              case 'saved':
                return 'All Saved';
            }
          })()}
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(PackagesEditContainer);
