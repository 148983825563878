import React from 'react';

import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef, GridPaginationModel, GridRowParams, GridSortDirection } from '@mui/x-data-grid';

const RoleMap = {
  BUSINESS_ADMIN: 'Admin',
  BUSINESS_EMPLOYEE: 'Employee',
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 380, display: 'flex' },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    renderCell: (params) => `${params.row.firstName} ${params.row.lastName}`,
    display: 'flex',
  },
  { field: 'email', headerName: 'Email', width: 350, display: 'flex' },
  {
    field: 'roles',
    headerName: 'Roles',
    width: 200,
    display: 'flex',
    renderCell: (params) => `${RoleMap[params.row.roles]}`,
    sortComparator: (v1, v2) => {
      const role1 = RoleMap[v1] || '';
      const role2 = RoleMap[v2] || '';
      return role1.localeCompare(role2);
    },
  },
];

const sortModel = [
  {
    field: 'roles',
    sort: 'asc' as GridSortDirection,
  },
];

interface Props {
  onPaginationModelChange: any;
  paginationModel: GridPaginationModel;
  rows: App.BusinessEmployee[];
  total: number;
}

export default function EmployeesGrid(props: Props) {
  const { onPaginationModelChange, paginationModel, rows, total } = props;

  function handleRowClick(row) {
    const url = `/users/${row.customerId}`;

    window.open(url, '_blank');
  }

  return (
    <Grid2 xs={12}>
      <Box width="100%" height={600}>
        <DataGrid
          columns={columns}
          onPaginationModelChange={onPaginationModelChange}
          onRowClick={(row: GridRowParams<App.Business>) => handleRowClick(row.row)}
          paginationMode="server"
          paginationModel={paginationModel}
          rows={rows || []}
          rowCount={total}
          sortModel={sortModel}
        />
      </Box>
    </Grid2>
  );
}
