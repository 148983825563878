import React, { Fragment, useCallback, useState } from 'react';

import currencyFormatter from 'currency-formatter';
import { last } from 'lodash';

import { Button, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { formatDateShort } from '~/services/TimeService';

import { getAllBaggageV2 } from '~/utils/flightUtils';
import { titleCase } from '~/utils/stringUtils';

import FlightRefundModal from '../../../Refund/FlightModal';

import FlightRefundStatus from './FlightRefundStatus';
import Flights from './Flights';
import OrderFlightFareFamilyInclusions from './OrderFlightFareFamilyInclusions';

const mapPassengerPlural = {
  child: 'children',
  adult: 'adults',
  infant: 'infants',
};

const mapPassengerSingular = {
  children: 'child',
  adults: 'adult',
  infants: 'infant',
};

export const getPerPersonBreakdown = (priceBreakdown, travellers: FlightTraveller[]) => {
  const travellerCount = travellers.reduce(
    (acc, t) => {
      acc[mapPassengerPlural[t.type]] += 1;
      return acc;
    },
    {
      adults: 0,
      children: 0,
      infants: 0,
    },
  );

  return Object.keys(travellerCount).reduce((acc, travellerType) => {
    if (travellerCount[travellerType] > 0 && priceBreakdown[travellerType] !== undefined) {
      acc[travellerType] = priceBreakdown[travellerType].cost;
    }
    return acc;
  }, {});
};

export const getPerPersonBreakdownWithJourneyFares = (fares, travellers: FlightTraveller[]) => {
  const travellerCount = travellers.reduce(
    (acc, t) => {
      acc[mapPassengerPlural[t.type]] += 1;
      return acc;
    },
    {
      adults: 0,
      children: 0,
      infants: 0,
    },
  );

  const accumulatedBreakdown = fares.reduce((acc, fare) => {
    const farePrice = fare.price;
    const keys = ['adult', 'child', 'infant'];
    for (const key of keys) {
      const priceBreakdown = farePrice[key];
      if (priceBreakdown) {
        if (!(key in acc)) {
          acc[key] = {};
        }

        const accKey = acc[key];
        for (const breakdownAttribute of Object.keys(priceBreakdown)) {
          if (!accKey[breakdownAttribute]) {
            accKey[breakdownAttribute] = priceBreakdown[breakdownAttribute];
          } else {
            accKey[breakdownAttribute] += priceBreakdown[breakdownAttribute];
          }
        }
      }
    }
    return acc;
  }, {});

  return Object.keys(travellerCount).reduce((acc, travellerType) => {
    const key = mapPassengerSingular[travellerType];
    if (travellerCount[travellerType] > 0 && accumulatedBreakdown[key] !== undefined) {
      acc[travellerType] = accumulatedBreakdown[key].totalFare;
    }
    return acc;
  }, {});
};

const getBaggageCost = (traveller, baggage, journey) => {
  if (!isTravellerWithBaggage(traveller)) {
    return 0;
  }

  if (journey.provider === 'travelFusion') {
    let sum = 0;
    const baggageId = baggage.id;
    const departingCount = baggage.departing.count || 0;
    const returningCount = baggage.returning.count || 0;

    if (departingCount > 0) {
      const departingBaggageInfo = journey.departing.extras.baggage.find((item) => item.id === baggageId);
      sum += (departingBaggageInfo?.amount || 0) * departingCount;
    }
    if (returningCount > 0) {
      const returningBaggageInfo = journey.returning.extras.baggage.find((item) => item.id === baggageId);
      sum += (returningBaggageInfo?.amount || 0) * returningCount;
    }
    return sum;
  }

  const isTwoOneWayFlight = journey.viewType === 'TwoOneWays';
  const direction = journey.legNumber === 1 ? 'returning' : 'departing';
  const baggageCost = [baggage].reduce((sum, b) => {
    if (b.departing && b.departing.count > 0) {
      const departingBaggageInfo = journey.departing.extras.baggage.find(
        (item) => item.id === b.id || `${item.id}-${traveller.type}` === b.id,
      );
      sum += b.departing.count * departingBaggageInfo?.['per_' + traveller.type].cost || 0;
    }
    if (b.returning && b.returning.count > 0) {
      if (isTwoOneWayFlight && direction === 'returning') {
        const baggageInfo = journey.departing.extras.baggage.find(
          (item) => item.id === b.id || `${item.id}-${traveller.type}` === b.id,
        );
        sum += b.returning.count * baggageInfo?.['per_' + traveller.type].cost || 0;
      } else {
        const returningBaggageInfo = journey.returning?.extras?.baggage?.find(
          (item) => item.id === b.id || `${item.id}-${traveller.type}` === b.id,
        );
        sum += b.returning.count * returningBaggageInfo?.['per_' + traveller.type].cost || 0;
      }
    }
    return sum;
  }, 0);

  return baggageCost;
};

function isTravellerWithBaggage(traveller?: FlightTraveller) {
  return traveller?.extras?.baggage?.length > 0 || traveller?.baggage?.length > 0;
}

function getTravellerBaggage(traveller: FlightTraveller, direction: 'departing' | 'returning'): TravellerBaggage[] {
  return 'baggage' in traveller ? getAllBaggageV2(traveller, direction) : traveller.extras?.baggage ?? [];
}

const getBaggageTotalCost = (traveller: FlightTraveller, journey, direction: 'departing' | 'returning') => {
  if (!isTravellerWithBaggage(traveller)) {
    return 0;
  }
  const baggage = getTravellerBaggage(traveller, direction);
  return baggage.reduce((sum, baggage) => {
    return sum + getBaggageCost(traveller, baggage, journey);
  }, 0);
};

const getBaggageDetails = (traveller: FlightTraveller, baggage, direction?: 'departing' | 'returning') => {
  if (!isTravellerWithBaggage(traveller)) {
    return '';
  }
  const baggageId = baggage?.id?.replace(/-/g, '~');
  const splitedBaggageId = baggageId.split('~').length >= 2 ? baggageId.split('~')[1] : baggageId;
  const baggageCount = [baggage].reduce((sum, b) => {
    if (direction !== 'returning' && b.departing && b.departing.count > 0) {
      sum += b.departing.count;
    }
    if (direction !== 'departing' && b.returning && b.returning.count > 0) {
      sum += b.returning.count;
    }
    return sum;
  }, 0);
  return baggageCount > 0 ? `${baggageCount} x ${splitedBaggageId} Checked Bag Added` : '';
};

const mapTraveller = ({
  traveller,
  index,
  status,
  cost,
  currency,
  onIssueRefund,
  journey,
  updateTravellerRefundStatus,
  orderId,
  itemId,
  direction,
}) => {
  const baggages = getTravellerBaggage(traveller, direction);
  return (
    <Grid container key={index} mt={2} mb={2}>
      <Grid xs={1} className="type-heading">
        {index + 1}
      </Grid>
      <Grid xs={3}>
        <Stack gap={1}>
          <Typography>
            {traveller.given_name} {traveller.surname}
          </Typography>
          <Typography>{traveller.type.charAt(0).toUpperCase() + traveller.type.slice(1)}</Typography>
          {baggages.length > 0 &&
            baggages.map((baggage) => (
              <Typography key={baggage.id}>{getBaggageDetails(traveller, baggage, direction)}</Typography>
            ))}
          <Typography fontWeight="bold"> Total</Typography>
        </Stack>
      </Grid>
      <Grid xs={1}>
        <Stack gap={1}>
          <Typography>
            {currencyFormatter.format(cost, {
              code: currency,
            })}
          </Typography>
          <Typography>—</Typography>
          {baggages.length > 0 &&
            baggages.map(
              (baggage) =>
                !isNaN(getBaggageCost(traveller, baggage, journey)) && (
                  <Typography key={baggage.id}>
                    {currencyFormatter.format(getBaggageCost(traveller, baggage, journey), { code: currency })}
                  </Typography>
                ),
            )}
          <Typography fontWeight="bold">
            {currencyFormatter.format(cost + getBaggageTotalCost(traveller, journey, direction), {
              code: currency,
            })}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={2} display="flex" justifyContent="center" alignItems="center">
        {traveller.refunded_at ? (
          <Fragment>
            <Stack spacing={2}>
              <Typography>Refunded</Typography>
              <Typography>{formatDateShort(traveller.refunded_at)}</Typography>
              <Typography>
                {currencyFormatter.format(traveller.refund_amount, {
                  code: currency,
                })}
              </Typography>
            </Stack>
          </Fragment>
        ) : (
          <Typography fontWeight="bold">{status}</Typography>
        )}
      </Grid>
      <Grid xs={2} display="flex" justifyContent="center" alignItems="center">
        {!traveller.refunded_at && (
          <Button variant="contained" color="error" onClick={() => onIssueRefund(traveller.id)}>
            Issue Refund
          </Button>
        )}
      </Grid>
      <Grid xs={3} display="flex" justifyContent="center" alignItems="center">
        <FlightRefundStatus
          orderId={orderId}
          itemId={itemId}
          travellerId={traveller.id}
          travellerRefundStatus={traveller.refund_status}
          onUpdateTravellerRefundStatus={updateTravellerRefundStatus}
        />
      </Grid>
    </Grid>
  );
};

const ReturnFlight = ({ departingFlight, returningFlight }) => {
  if (
    departingFlight.arrivalAirport !== returningFlight.departureAirport ||
    departingFlight.departureAirport !== returningFlight.arrivalAirport
  ) {
    return (
      <Typography>
        {returningFlight.departureCity} ({returningFlight.departureAirport}) {'>'} {departingFlight.departureCity} (
        {departingFlight.departureAirport})
      </Typography>
    );
  }
  return null;
};

interface Props {
  refreshData: () => void;
  itemId: string;
  itemStatus: string;
  orderId: string;
  flightDetails: any;
  updateTravellerRefundStatus: (orderId: string, itemId: string, travellerId: string, status: string) => void;
  itemRefundStatus: string;
  segmentIndex?: number;
}

const OrderFlightItem = ({
  refreshData,
  itemId,
  itemStatus,
  orderId,
  flightDetails,
  updateTravellerRefundStatus,
  segmentIndex,
}: Props) => {
  const journeyFares = flightDetails.journey.journeyFares;
  const departing = journeyFares.fares[0];
  const flights = departing.flightGroups[0].flights;
  const departingFlight = flights[0];
  const arrivalFlight = flights[flights.length - 1];
  const isOneWayFlight = flightDetails.journey.journeyFares.tripType === 'OneWay';
  const isTwoOneWayFlight = flightDetails.journey.journeyFares.tripType === 'TwoOneWays';
  const isOpenJaw = flightDetails.journey.fareType === 'OpenJaw';
  const returning = journeyFares.fares[1];
  const returningFlight = returning?.flightGroups[0].flights[0];
  const returningLastFlight = last<any>(returning?.flightGroups[0].flights);
  const direction = isTwoOneWayFlight
    ? flightDetails.journey.journeyFares.legNumber > 0
      ? 'returning'
      : 'departing'
    : null;
  const fareFamily = isTwoOneWayFlight
    ? flightDetails.journey.selectedUpsellOption?.fareFamily
    : flightDetails.journey.selectedUpsellOptions?.[departing.id]?.fareFamily;

  const currency = flightDetails.currency;
  const perPersonPriceBreakdown = getPerPersonBreakdownWithJourneyFares(
    flightDetails.journey.journeyFares.fares,
    flightDetails.travellers,
  );

  const priceWithFees = currencyFormatter.format(
    !isOpenJaw || (isOpenJaw && segmentIndex > 0)
      ? parseFloat(flightDetails.price) + (flightDetails.journey?.bookingFee || 0)
      : 0,
    {
      code: currency,
    },
  );

  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundTravellerId, setRefundTravellerId] = useState();
  const [refundAmount, setRefundAmount] = useState(0);

  const closeRefundModal = () => {
    refreshData();
    setShowRefundModal(false);
    setRefundTravellerId(undefined);
    setRefundAmount(0);
  };

  const onRefundClick = useCallback(() => {
    setShowRefundModal(true);
    setRefundAmount(flightDetails.journey.atolFee);
  }, [flightDetails]);

  return (
    <div className="T-Order-Flight-Item-List">
      {(!isOpenJaw || (isOpenJaw && segmentIndex === 0)) && (
        <Typography>
          {departingFlight.departureCity} ({departingFlight.departureAirport}) {'>'} {arrivalFlight.arrivalCity} (
          {arrivalFlight.arrivalAirport})
        </Typography>
      )}

      {!isOneWayFlight && !isTwoOneWayFlight && !isOpenJaw && (
        <ReturnFlight departingFlight={departingFlight} returningFlight={returningFlight} />
      )}

      {isOpenJaw && segmentIndex > 0 && (
        <Typography>
          {returningFlight.departureCity} ({returningFlight.departureAirport}) {'>'} {returningLastFlight.arrivalCity} (
          {returningLastFlight.arrivalAirport})
        </Typography>
      )}
      <Stack direction="row" my={1} justifyContent="space-between" alignItems="center">
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography fontWeight="bold">Airline Booking Ref (PNR)</Typography>
          <Typography>{flightDetails.pnr_id}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography fontWeight="bold">Provider</Typography>
          <Typography>{departing.provider}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography fontWeight="bold">Fare Type</Typography>
          <Typography>{(isOpenJaw ? 'Open Jaw' : departing.fareType || '').toLowerCase()}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography fontWeight="bold">Fare Family</Typography>
          <Typography>{titleCase(fareFamily?.description ?? '')}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography fontWeight="bold">Airline</Typography>
          <Typography>{departing.carrierName}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography fontWeight="bold">{`Price ${flightDetails.journey?.bookingFee > 0 ? '+ fees' : ''}`}</Typography>
          <Typography>{priceWithFees}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography fontWeight="bold">Flight Item Status</Typography>
          <Typography>{itemStatus}</Typography>
        </Stack>
      </Stack>

      <Flights flightDetails={flightDetails} fareFamily={fareFamily} segmentIndex={segmentIndex} />

      {(!isOpenJaw || (isOpenJaw && segmentIndex > 0)) && (
        <>
          <Divider textAlign="left">Flight Travellers</Divider>
          {flightDetails.travellers.map((traveller, index) => {
            return mapTraveller({
              traveller,
              index,
              currency,
              status: flightDetails.state,
              cost: perPersonPriceBreakdown[mapPassengerPlural[traveller.type]],
              onIssueRefund: (travellerId) => {
                setShowRefundModal(true);
                setRefundTravellerId(travellerId);
              },
              journey: flightDetails.journey,
              updateTravellerRefundStatus,
              orderId,
              itemId,
              direction,
            });
          })}
        </>
      )}

      {flightDetails.journey.bookingFee > 0 && (
        <Grid container>
          <Grid xs={1} className="type-heading">
            {flightDetails.travellers.length + 1}
          </Grid>
          <Grid xs={4}>Booking Fee</Grid>
          <Grid xs={2} className="type-heading">
            {currencyFormatter.format(flightDetails.journey.bookingFee, {
              code: currency,
            })}
          </Grid>
          <Grid xs={3} className="type-heading">
            {flightDetails.fee_refunded_at ? (
              <Fragment>
                <Stack spacing={2}>
                  <Typography>Refunded</Typography>
                  <Typography>{formatDateShort(flightDetails.fee_refunded_at)}</Typography>
                  <Typography>
                    {currencyFormatter.format(flightDetails.journey.bookingFee, {
                      code: currency,
                    })}
                  </Typography>
                </Stack>
              </Fragment>
            ) : (
              flightDetails.status
            )}
          </Grid>
          {!flightDetails.fee_refunded_at && (
            <Grid xs={2} className="type-heading">
              <Button
                onClick={() => {
                  setShowRefundModal(true);
                  setRefundAmount(flightDetails.journey.bookingFee);
                }}
              >
                Issue Refund
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {flightDetails.journey.serviceFee > 0 && (
        <Grid container>
          <Grid xs={1} className="type-heading">
            {flightDetails.travellers.length + 1}
          </Grid>
          <Grid xs={3}>
            <Typography>Service Fee</Typography>
          </Grid>
          <Grid xs={2} className="type-heading">
            {currencyFormatter.format(flightDetails.journey.serviceFee, {
              code: currency,
            })}
          </Grid>
        </Grid>
      )}
      {flightDetails.journey.atolFee > 0 && (
        <Grid container>
          <Grid xs={1} className="type-heading">
            {flightDetails.journey.bookingFee > 0
              ? flightDetails.travellers.length + 2
              : flightDetails.travellers.length + 1}
          </Grid>
          <Grid xs={4}>
            <Typography>ATOL Fee</Typography>
          </Grid>
          <Grid xs={2} className="type-heading">
            {currencyFormatter.format(flightDetails.journey.atolFee, {
              code: currency,
            })}
          </Grid>
          <Grid xs={3} className="type-heading">
            {flightDetails.fee_refunded_at && (
              <Fragment>
                <Stack spacing={2}>
                  <Typography>Refunded</Typography>
                  <Typography>{formatDateShort(flightDetails.fee_refunded_at)}</Typography>
                  <Typography>
                    {currencyFormatter.format(flightDetails.journey.atolFee, {
                      code: currency,
                    })}
                  </Typography>
                </Stack>
              </Fragment>
            )}
            {flightDetails.fee_refunded_at && flightDetails.status}
          </Grid>
          {!flightDetails.fee_refunded_at && (
            <Grid xs={2} className="type-heading">
              <Button onClick={onRefundClick}>Issue Refund</Button>
            </Grid>
          )}
        </Grid>
      )}
      {fareFamily && <OrderFlightFareFamilyInclusions fareFamily={fareFamily} />}
      <FlightRefundModal
        show={showRefundModal}
        travellerId={refundTravellerId}
        onClose={closeRefundModal}
        refundableAmount={refundAmount}
        orderId={orderId}
        itemId={itemId}
        itemStatus={itemStatus}
        currency={currency}
      />
    </div>
  );
};

export default OrderFlightItem;
