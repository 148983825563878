export const HOME_PAGE_CAROUSEL_ITEM_COUNT = 20;

export const BASE_LIST: ListOption[] = [
  {
    label: 'Home: Hero',
    value: 'home',
    softLimit: HOME_PAGE_CAROUSEL_ITEM_COUNT,
  },
  {
    label: 'Product: Hotels',
    value: 'vertical-hotel',
    offerTypes: 'hotel,last_minute_hotel,tactical_ao_hotel,bundle_and_save,bedbank_hotel',
  },
  {
    label: 'Product: Tours',
    value: 'vertical-tour',
    offerTypes: 'tour,tour_v2',
  },
  {
    label: 'Product: Cruises',
    value: 'vertical-cruise',
    offerTypes: 'cruise,tour,hotel',
    holidayTypesScoped: 'Cruises',
  },
  {
    label: 'Product: Ultra Lux',
    value: 'vertical-ultralux',
    offerTypes: 'hotel,tactical_ao_hotel,last_minute_hotel,tour,tour_v2',
    holidayTypesScoped: 'Ultra Lux',
  },
  {
    label: 'Product: Experiences',
    value: 'vertical-experience',
    offerTypes: 'flash,always_on,channel_manager',
  },
  {
    label: 'Product: Lux Plus',
    value: 'vertical-luxplus',
  },
  {
    label: 'Home Carousel: Hotels',
    value: 'carousel-hotel',
    offerTypes: 'hotel,last_minute_hotel,tactical_ao_hotel,bundle_and_save,bedbank_hotel',
    softLimit: HOME_PAGE_CAROUSEL_ITEM_COUNT,
  },
];

export const AUSTRALIA_REGIONS: ListOption[] = [
  {
    label: 'Region: NSW',
    value: 'subregion-nsw',
    softLimit: HOME_PAGE_CAROUSEL_ITEM_COUNT,
  },
  {
    label: 'Region: VIC',
    value: 'subregion-vic',
    softLimit: HOME_PAGE_CAROUSEL_ITEM_COUNT,
  },
];

export const AUSTRALIA_LIST: ListOption[] = BASE_LIST.slice(0, 1).concat(AUSTRALIA_REGIONS).concat(BASE_LIST.slice(1));

export type ListOption = {
  label: string;
  value: string;
  offerTypes?: string;
  softLimit?: number;
  holidayTypesScoped?: string;
};
