import React, { useEffect, useState } from 'react';

import { useRouteMatch } from 'react-router-dom';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';

import PropertiesPage from './PropertiesPage';

type Props = { vendorName?: string };

export default function PropertiesPageContainer(_: Props) {
  const {
    params: { id_vendor: vendorId },
  } = useRouteMatch<{ id_vendor: string }>();

  const [properties, setProperties] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    ReservationService.getProperties(vendorId)
      .then((data) => {
        setProperties(data.result);
      })
      .catch((err) => {
        console.warn(err);
        setError("Service error. Can't load vendor properties");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [vendorId]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  return <PropertiesPage vendorId={vendorId} properties={properties} />;
}
