import featureToggle from '~/utils/featureToggle';

export const isLuxPlusMember = (subscriptions: App.MembershipSubscriptions) => {
  return (
    ['ACTIVE', 'AWAITING_RENEWAL'].includes(subscriptions?.status) &&
    featureToggle.availableToShow('SHOW_SUBSCRIPTIONS')
  );
};

export const isLuxPlusActiveSubscriber = (subscriptions: App.MembershipSubscriptions) => {
  return isLuxPlusMember(subscriptions) && subscriptions?.tier === 'base';
};

export const isLuxPlusFreePreview = (subscriptions: App.MembershipSubscriptions) => {
  return isLuxPlusMember(subscriptions) && subscriptions?.tier === 'free_preview';
};

export const hasActiveOrInactiveSubscription = (subscriptions: App.MembershipSubscriptions) => {
  return subscriptions?.status && featureToggle.availableToShow('SHOW_SUBSCRIPTIONS');
};

export const getCurrentSubscriptionPeriod = (subscriptions: App.MembershipSubscriptions) => {
  return subscriptions.subscriptionPeriods?.slice(-1)[0];
};
