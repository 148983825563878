import { History } from 'history';

type AutoCompleteActionConfig = {
  /**
   * The key should be short and unique
   * if input is required, it should end with a colon
   */
  key: string;
  /***
   * Default sort order, goes:
   * - Recently Used
   * - Direct Match with inputAllowsAction pass
   * - Direct Match with inputAllowsAction fail
   * - Default score
   */
  defaultScore?: number;
  /*
   * Lookup == Navigate directly to record via human readable identity (i.e. "u:" User via email - preferred)
   * Search == Navigate to Search page with query (i.e. "ui:" Vender Search - also cool)
   * XXX By XX: Navigate to record via identifier (i.e. "ui:"" User by id - not as cool, but still useful)
   */
  description: string;
  /*
   * Will display as hover text
   */
  subtitle?: string;
  /**
   * the action to take when the option is clicked (and inputAllowsAction returns an empty array)
   *
   * NB: Currently they are all navigation actions, but could be expanded to include other actions
   */
  action: (history: History, identifier?: string) => void;
  /**
   * - When the option is clicked, inputAllowsAction is run with the search string:
   *      -  if inputAllowsAction, this option need more/diff input, return an error.
   *      -  if inputAllowsAction return no error,
   *         then run the action callback (only navigateToUrl() is used at this sage)
   */
  inputAllowsAction: (searchString: string) => Array<string> | null;
};

type NavigateToURLParams = {
  history: History;
  url: string;
  /**
   * If the page needs a reload to update (prefer false as it can be jarring)
   * (can be an issue when navigating to the same page with different params "i.e p:e2etest -> p:e2etestall")
   */
  hardReload?: boolean;
};

const navigateToUrl = ({ history, url, hardReload = false }: NavigateToURLParams) => {
  history.push(url);
  /* Some paths need a bit of encouragement to load in the correct data. Avoid if possible */
  if (hardReload) {
    history.go(0);
  }
};

/**
 * Replace this method with a check that ensure the params are valid for the action,
 * atm, if its longer than 5 characters, we consider it valid
 */
const hackStringLengthCheck = (searchString: string): Array<string> => {
  if (searchString.length > 5) {
    return [];
  }
  return ['input too short'];
};
/**
 * The define what shortcuts are avaiaible via the 'p' hotkey menu.
 *
 * Ensure new options dont conflict with existing ones, only simple one level matches are done at the moment
 *    p:e2etest  -> /promo/codes/e2etest
 *
 * NB: this could be relatively easy expanded to consider more complex matches like:
 *        u:baduser@email.com:sb -> Shadow Ban user by Email
 *
 *
 */
const speedNavigationConfig: Array<AutoCompleteActionConfig> = [
  {
    key: 'os',
    description: 'Order Search Page',
    action: (history, id) => navigateToUrl({ history, url: '/purchases' }),
    inputAllowsAction: null,
  } /*

 [Temporarily disabled until we can fix the direct navigation to the order search page] {
    key: 'o:',
    description: 'Orders search',
    defaultScore: 90,
    action: (history, id) => navigateToUrl(history, `/purchases?q=${id}`),
    inputAllowsAction: hackStringLengthCheck,
  },*/,
  {
    key: 'oid:',
    description: 'Order by ID',
    subtitle: 'Navigate directly to order via an OrderID',
    action: (history, id) => navigateToUrl({ history, url: `/purchases/${id}` }),
    inputAllowsAction: hackStringLengthCheck,
  },
  {
    key: 'u:',
    description: 'User Lookup',
    subtitle: 'Search user by name, email, QFF, customer support code, or userID',
    defaultScore: 100,
    action: (history, id) => navigateToUrl({ history, url: `/users-list?q=${id}` }),
    inputAllowsAction: hackStringLengthCheck,
  },
  {
    key: 'p:',
    description: 'Go to Promo',
    subtitle: 'Search promo code by code_name',
    action: (history, id) => navigateToUrl({ history, url: `/promos/code/${id}`, hardReload: true }),
    inputAllowsAction: hackStringLengthCheck,
  },
  {
    key: 'pid:',
    description: 'Promo Lookup by Id',
    subtitle: 'Search promo code by Promo Id',
    action: (history, id) => navigateToUrl({ history, url: `/promos/${id}` }),
    inputAllowsAction: hackStringLengthCheck,
  },
  {
    key: 'puid:',
    description: 'User ID interacted by Promo Lookup',
    subtitle: 'Search promo code by user interactions',
    action: (history, id) => navigateToUrl({ history, url: `/marketing/search-promo?userIdInteracted=${id}` }),
    inputAllowsAction: hackStringLengthCheck,
  },
  {
    key: 'v:',
    description: 'Vender Search',
    defaultScore: 10,
    action: (history, id) => navigateToUrl({ history, url: `/vendors?q=${id}` }),
    inputAllowsAction: hackStringLengthCheck,
  },
  {
    key: 'c',
    description: 'Curation/Offer List Order',
    action: (history, id) => navigateToUrl({ history, url: `/curation/offer-list-order` }),
    inputAllowsAction: null,
  },
  {
    key: 'fos:',
    description: 'Finance Offer Search',
    action: (history, id) => navigateToUrl({ history, url: `/finance?q=${id}` }),
    inputAllowsAction: hackStringLengthCheck,
  },
  {
    key: 'sa',
    description: 'Support Assistant',
    inputAllowsAction: null,
    action: (history, id) => navigateToUrl({ history, url: `/support-assistant/chats-history` }),
  },
  {
    key: 'rl:',
    description: 'Referral Log By UserID',
    inputAllowsAction: hackStringLengthCheck,
    action: (history, id) => navigateToUrl({ history, url: `/marketing/referralLogs?q=${id}` }),
  },
  {
    key: 'er:',
    description: 'Render Email by ID',
    inputAllowsAction: hackStringLengthCheck,
    action: (history, id) => navigateToUrl({ history, url: `/email/render?id=${id}` }),
  },
  {
    key: 'ct',
    description: 'Campaign Tags',
    inputAllowsAction: null,
    action: (history, id) => navigateToUrl({ history, url: `/marketing/campaign-tags` }),
  },
  {
    key: 'r',
    description: 'Business Reports',
    inputAllowsAction: null,
    action: (history, id) => navigateToUrl({ history, url: `/finance/business-reports` }),
  },
  {
    key: 'sr',
    description: 'Search Ranking',
    inputAllowsAction: null,
    action: (history, id) => navigateToUrl({ history, url: `/curation/search-ranking` }),
  },
  {
    key: 'f',
    description: 'Flights',
    inputAllowsAction: null,
    action: (history, id) => navigateToUrl({ history, url: `/flights` }),
  },
];

export { AutoCompleteActionConfig, speedNavigationConfig };
