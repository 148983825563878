import React from 'react';

import { TableCell, TableRow } from '@mui/material';

export function SearchTourHead() {
  return (
    <TableRow>
      <TableCell> Offer </TableCell>
      <TableCell align="right">Product Type</TableCell>
      <TableCell align="right">Views</TableCell>
      <TableCell align="right">Sales Count</TableCell>
      <TableCell align="right">Sales Amount</TableCell>
      <TableCell align="right">Expected Value</TableCell>
      <TableCell align="right">Adjusted EV</TableCell>
    </TableRow>
  );
}
