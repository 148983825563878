import React from 'react';

import { Box } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import useQuery from '~/hooks/useQuery';

import { DiscountItemInfo, PromoTypesAndUnknown } from '~/services/PromoService';

import PromoItemResult from './PromoItemResult';

type PromoOrderTableCellProps = {
  discountItems: Array<DiscountItemInfo> | null;
  promo_type: PromoTypesAndUnknown;
  currency: string;
  meta: definitions['PromoMeta'];
};

const PromoItemResultTableCell = ({ discountItems, promo_type, currency, meta }: PromoOrderTableCellProps) => {
  const query = useQuery();
  const isDevMode = query.get('dev') === 'true';

  return (
    <Box sx={{ width: '100%' }}>
      {discountItems?.map((discountItem, i) => (
        <PromoItemResult
          currency={currency}
          discountItem={discountItem}
          isDevMode={isDevMode}
          promoType={promo_type}
          key={i}
          meta={meta}
        />
      ))}
    </Box>
  );
};

export { PromoItemResultTableCell };
