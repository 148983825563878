import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { PromoFailure, PromoFailureFilters, getPromoCodeFailures } from '~/services/PromoService';

type UsePromoFailuresProps = {
  initPage?: number;
  initLimit?: number;
  initCodeName?: string;
  initUserId?: string;
  brand: string;
};

export default function usePromoFailures({
  initPage,
  initLimit,
  initCodeName,
  initUserId,
  brand,
}: UsePromoFailuresProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [promoFailures, setPromoFailures] = useState<Array<PromoFailure>>([]);

  const [promoFailuresFilter, setPromoFailuresFilter] = useState<PromoFailureFilters>({
    code_name: initCodeName,
    user_id: initUserId,
    brand: brand as PromoFailureFilters['brand'],
    page: initPage ?? 0,
    limit: initLimit > 0 ? initLimit : 10,
  });
  const [totalRows, setTotalRows] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchPromoFailureData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPromoCodeFailures(promoFailuresFilter);
      if (response.status === 200) {
        setPromoFailures(response.result.promoFailures.map((pr, i) => ({ uniqueKey: i, ...pr })));
        setTotalRows(Number(response.count));
      } else {
        enqueueSnackbar(JSON.stringify(response) || 'Failed to fetch promo failures', { variant: 'error' });
      }
    } catch (err) {
      if ('errors' in err && err.errors?.length > 0) {
        err.errors.forEach((e) => {
          enqueueSnackbar(`Error: ${e.path} "${e.value}" ${e.message}`, { variant: 'error', autoHideDuration: 5000 });
        });
      } else {
        enqueueSnackbar(`Error: ${JSON.stringify(err)}`, { variant: 'error', autoHideDuration: 5000 });
      }
    } finally {
      setLoading(false);
    }
  }, [promoFailuresFilter, enqueueSnackbar]);

  useEffect(() => {
    fetchPromoFailureData();
  }, [promoFailuresFilter, fetchPromoFailureData]);

  return {
    promoFailures,
    totalRows,
    isLoading,
    promoFailuresFilter,
    setPromoFailuresFilter,
    fetchPromoFailureData,
  };
}
