import React from 'react';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { getFXRates } from '~/services/ReportingService';
import ReservationService from '~/services/ReservationService';
import { addYears, datesHaveSameDayMonthYear, formatDateISO, isAfter } from '~/services/TimeService';
import VendorService from '~/services/VendorsService';

import presentFXRates from '~/utils/presentFXRates';
import { reportError } from '~/utils/reportError';

import RoomTypeEdit from './RoomTypeEdit';

export default class RoomTypeEditContainer extends React.Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.vendorId = match.params.id_vendor;
    this.propertyId = match.params.id_property;
    this.roomTypeId = match.params.id_room_type;
    this.roomRateId = match.params.id_room_rate;

    this.state = {
      lengthOfStay: 1,
      numberOfAdults: 2,
      childrenAges: [],
      inclusions: [],
      loading: true,
      hasError: false,
      isDeletingAll: false,
      showWithoutTax: false,
    };
  }

  componentDidMount() {
    this.fetchData().then(this.setStateFromResponse.bind(this)).catch(this.handleFetchError.bind(this));
  }

  componentDidUpdate(prevProps) {
    const prevRoomRateId = prevProps.match.params.id_room_rate;
    const nextRoomRateId = this.props.match.params.id_room_rate;

    if (nextRoomRateId !== prevRoomRateId) {
      this.roomRateId = nextRoomRateId;
      this.setState({
        loading: true,
        hasError: false,
      });
      this.fetchRoomRateData()
        .then(this.setStateFromRoomRateDataResponse.bind(this))
        .catch(this.handleFetchError.bind(this));
    }
  }

  getProperty() {
    return ReservationService.getProperty(this.propertyId);
  }

  getAvailabilitySchema() {
    return ReservationService.getAvailabilitySchema(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getAvailability() {
    return ReservationService.getAvailability(
      this.propertyId,
      this.roomTypeId,
      this.roomRateId,
      this.state.lengthOfStay,
    );
  }

  getBlackoutDates() {
    return ReservationService.getBlackoutDates(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getCapacities() {
    return ReservationService.getRoomTypeCapacities(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getIncludedGuests() {
    return ReservationService.getRoomTypeIncludedGuests(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getVendor(idSalesforceExternal) {
    return VendorService.getVendorById(idSalesforceExternal);
  }

  getSurchargeDates() {
    return ReservationService.getSurchargeDates(this.propertyId, this.roomTypeId, this.roomRateId, 'vendor');
  }

  getInternalSurchargeDates() {
    return ReservationService.getSurchargeDates(this.propertyId, this.roomTypeId, this.roomRateId, 'internal');
  }

  getSurchargeSchema() {
    return ReservationService.getSurchargeDateBlockSchema(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getRoomRateInclusionsSchema() {
    return ReservationService.getRoomRateInclusionsSchema(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getRoomRateLeadDaysSchema() {
    return ReservationService.getRoomRateLeadDaysSchema(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getRoomRates() {
    return ReservationService.getRoomRates(this.propertyId, this.roomTypeId);
  }

  getRoomRateDates() {
    return ReservationService.getRoomRateDates(
      this.propertyId,
      this.roomTypeId,
      this.roomRateId,
      this.state.lengthOfStay,
      this.state.numberOfAdults,
      this.state.childrenAges,
    );
  }

  getRatePlans() {
    return ReservationService.getRatePlans(this.vendorId);
  }

  getExtraGuestSurcharges() {
    return ReservationService.getExtraGuestSurcharges(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getFXRates() {
    return getFXRates();
  }

  getRoomRateLeadDays() {
    return ReservationService.getRoomRateLeadDays(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getLengthsOfStay() {
    return ReservationService.getLengthsOfStay(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getRoomRateInclusions() {
    return ReservationService.getRoomRateInclusions(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getAriLogs() {
    return ReservationService.getAriLogs(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  async fetchRoomRateData() {
    const [
      roomRates,
      surchargeDates,
      internalSurchargeDates,
      includedGuests,
      availabilitySchema,
      availability,
      capacities,
      roomRateDates,
      extraGuestSurcharges,
      blackoutDates,
      leadDays,
      lengthsOfStay,
      inclusions,
    ] = await Promise.all([
      this.getRoomRates(),
      this.getSurchargeDates(),
      this.getInternalSurchargeDates(),
      this.getIncludedGuests(),
      this.getAvailabilitySchema(),
      this.getAvailability(),
      this.getCapacities(),
      this.getRoomRateDates(),
      this.getExtraGuestSurcharges(),
      this.getBlackoutDates(),
      this.getRoomRateLeadDays(),
      this.getLengthsOfStay(),
      this.getRoomRateInclusions(),
    ]);

    return {
      roomRates,
      surchargeDates,
      internalSurchargeDates,
      includedGuests,
      availabilitySchema,
      availability,
      capacities,
      roomRateDates,
      extraGuestSurcharges,
      blackoutDates,
      leadDays,
      lengthsOfStay,
      inclusions,
    };
  }

  setStateFromRoomRateDataResponse(responses) {
    this.setState({
      loading: false,
      ...this.setStateFromResponseRoomRates(responses.roomRates),
      ...this.setStateFromResponseRoomRateDatesPartial(responses.roomRateDates),
      availabilitySchema: responses.availabilitySchema.patch.body.schema,
      ...this.setStateFromResponseAvailabilityPartial(responses.availability),
      surchargeDates: responses.surchargeDates.result.dates,
      internalSurchargeDates: responses.internalSurchargeDates.result.dates,
      ...this.setStateFromResponseIncludedGuestsPartial(responses.includedGuests),
      ...this.setStateFromResponseCapacitiesPartial(responses.capacities),
      ...this.setStateFromResponseExtraGuestSurchargesPartial(responses.extraGuestSurcharges),
      blackoutDates: responses.blackoutDates.result.dates,
      leadDays: responses.leadDays.result.dates,
      lengthsOfStay: responses.lengthsOfStay.result.dates,
      inclusions: responses.inclusions.result.inclusions,
    });
  }

  async fetchData() {
    const property = await this.getProperty();

    const [
      roomRates,
      ratePlans,
      availabilitySchema,
      availability,
      roomRateDates,
      blackoutDates,
      surchargeSchema,
      surchargeDates,
      internalSurchargeDates,
      capacities,
      includedGuests,
      vendor,
      extraGuestSurcharges,
      foreignExchangeRates,
      leadDays,
      leadDaysSchema,
      lengthsOfStay,
      inclusions,
      inclusionsSchema,
    ] = await Promise.all([
      this.getRoomRates(),
      this.getRatePlans(),
      this.getAvailabilitySchema(),
      this.getAvailability(),
      this.getRoomRateDates(),
      this.getBlackoutDates(),
      this.getSurchargeSchema(),
      this.getSurchargeDates(),
      this.getInternalSurchargeDates(),
      this.getCapacities(),
      this.getIncludedGuests(),
      this.getVendor(property.result.id_salesforce_external),
      this.getExtraGuestSurcharges(),
      this.getFXRates(),
      this.getRoomRateLeadDays(),
      this.getRoomRateLeadDaysSchema(),
      this.getLengthsOfStay(),
      this.getRoomRateInclusions(),
      this.getRoomRateInclusionsSchema(),
    ]);

    return {
      property,
      roomRates,
      ratePlans,
      availabilitySchema,
      availability,
      roomRateDates,
      blackoutDates,
      surchargeSchema,
      surchargeDates,
      internalSurchargeDates,
      capacities,
      includedGuests,
      vendor,
      extraGuestSurcharges,
      foreignExchangeRates,
      leadDays,
      leadDaysSchema,
      lengthsOfStay,
      inclusions,
      inclusionsSchema,
    };
  }

  setStateFromResponseAvailabilityPartial(response) {
    return {
      availability: this.fillAvailabilityToYears(response.result),
    };
  }

  setStateFromResponsePropertyPartial(response) {
    const roomType = response.result.room_types.find((room_type) => room_type.id === this.roomTypeId);

    return {
      property: response.result,
      channelManaged: response.result.channel_managed,
      roomType,
    };
  }

  setStateFromResponseCapacitiesPartial(response) {
    return {
      capacities: response.result.capacities,
    };
  }

  setStateFromResponseIncludedGuestsPartial(response) {
    return {
      includedGuests: response.result.included_guests,
    };
  }

  setStateFromResponseLeadDaysPartial(response) {
    return {
      leadDays: response.result.dates,
    };
  }

  setStateFromResponseInclusionsPartial(response) {
    return {
      inclusions: response.result.inclusions,
    };
  }

  setStateFromResponseRoomRates(response) {
    return {
      roomRates: response.result,
    };
  }

  setStateFromResponseRoomRateDatesPartial(response) {
    return {
      roomRateDates: response.result,
    };
  }

  setStateFromResponseExtraGuestSurchargesPartial(response) {
    return {
      extraGuestSurcharges: response.result.surcharges,
    };
  }

  setStateFromResponseForeignExchangeRatesPartial(response) {
    return {
      foreignExchangeRates: presentFXRates(response),
    };
  }

  setStateFromResponse(responses) {
    this.setState({
      loading: false,
      ...this.setStateFromResponsePropertyPartial(responses.property),
      availabilitySchema: responses.availabilitySchema.patch.body.schema,
      ...this.setStateFromResponseAvailabilityPartial(responses.availability),
      ...this.setStateFromResponseRoomRateDatesPartial(responses.roomRateDates),
      blackoutDates: responses.blackoutDates.result.dates,
      surchargeSchema: responses.surchargeSchema.post.body.schema,
      surchargeDates: responses.surchargeDates.result.dates,
      internalSurchargeDates: responses.internalSurchargeDates.result.dates,
      ...this.setStateFromResponseCapacitiesPartial(responses.capacities),
      ...this.setStateFromResponseIncludedGuestsPartial(responses.includedGuests),
      ...this.setStateFromResponseRoomRates(responses.roomRates),
      ratePlans: responses.ratePlans.result,
      vendor: responses.vendor.result,
      ...this.setStateFromResponseExtraGuestSurchargesPartial(responses.extraGuestSurcharges),
      ...this.setStateFromResponseForeignExchangeRatesPartial(responses.foreignExchangeRates),
      ...this.setStateFromResponseLeadDaysPartial(responses.leadDays),
      leadDaysSchema: responses.leadDaysSchema.patch.body.schema,
      lengthsOfStay: responses.lengthsOfStay.result.dates,
      ...this.setStateFromResponseInclusionsPartial(responses.inclusions),
      inclusionsSchema: responses.inclusionsSchema.post.body.schema,
    });
  }

  // allow calendar to show 3 years of inventory even if it doesn't exist in
  // svc-reservation
  fillAvailabilityToYears(availability, years = 3) {
    if (availability.length === 0) {
      return [];
    }

    const endOfLastAvailability = availability[availability.length - 1].range[1];

    const endDate = formatDateISO(addYears(years));

    if (datesHaveSameDayMonthYear(endOfLastAvailability, endDate) || isAfter(endOfLastAvailability, endDate)) {
      return availability;
    }

    const startDate = formatDateISO(addYears(1, endOfLastAvailability));

    return [
      ...availability,
      {
        ...availability[0],
        count: 0,
        total: 0,
        range: [startDate, endDate],
      },
    ];
  }

  handleFetchError(error) {
    this.setState({
      hasError: true,
      error,
    });
  }

  onUpdateAvailability(availability) {
    this.setState({
      availability,
    });
  }

  onUpdateBlackoutDates(blackoutDates) {
    this.setState({
      blackoutDates,
    });
  }

  deleteBlackoutDateBlock(id) {
    ReservationService.deleteBlackoutDateBlock(id, this.propertyId, this.roomTypeId, this.roomRateId);
    this.onUpdateBlackoutDates(this.state.blackoutDates.filter((block) => block.id !== id));
  }

  onInclusionsOrderUpdate(payload) {
    ReservationService.updateRoomRateInclusions(payload, this.propertyId, this.roomTypeId, this.roomRateId);
  }

  onUpdateSurchargeDates(surchargeDates) {
    this.setState({
      surchargeDates,
    });
  }

  onUpdateInternalSurchargeDates(internalSurchargeDates) {
    this.setState({
      internalSurchargeDates,
    });
  }

  deleteSurchargeDateBlock(id) {
    ReservationService.deleteSurchargeDateBlock(id, this.propertyId, this.roomTypeId, this.roomRateId);
    this.onUpdateSurchargeDates(this.state.surchargeDates.filter((block) => block.id !== id));
  }

  deleteSurchargeDateBlockByRoomRateId(surchargeType) {
    ReservationService.deleteSurchargeDateBlockByRoomRateId(this.propertyId, this.roomRateId, surchargeType);
    this.onUpdateSurchargeDates(this.state.surchargeDates.filter((block) => block.surcharge_type !== surchargeType));
  }

  deleteSurchargeDateBlockByRatePlanId(ratePlanId, surchargeType) {
    ReservationService.deleteSurchargeDateBlockByRatePlanId(ratePlanId, surchargeType);
    this.onUpdateSurchargeDates(this.state.surchargeDates.filter((block) => block.surcharge_type !== surchargeType));
  }

  deleteInternalSurchargeDateBlock(id) {
    ReservationService.deleteSurchargeDateBlock(id, this.propertyId, this.roomTypeId, this.roomRateId);
    this.onUpdateInternalSurchargeDates(this.state.internalSurchargeDates.filter((block) => block.id !== id));
  }

  onUpdateRoomTypeCapacities() {
    Promise.all([this.getProperty(), this.getCapacities()]).then((response) =>
      this.setState({
        ...this.setStateFromResponsePropertyPartial(response[0]),
        ...this.setStateFromResponseCapacitiesPartial(response[1]),
      }),
    );
  }

  onUpdateRoomTypeIncludedGuests() {
    Promise.all([this.getProperty(), this.getIncludedGuests()]).then((response) =>
      this.setState({
        ...this.setStateFromResponsePropertyPartial(response[0]),
        ...this.setStateFromResponseIncludedGuestsPartial(response[1]),
      }),
    );
  }

  onUpdateRoomRateInclusions() {
    Promise.all([this.getProperty(), this.getRoomRateInclusions()]).then((response) =>
      this.setState({
        ...this.setStateFromResponsePropertyPartial(response[0]),
        ...this.setStateFromResponseInclusionsPartial(response[1]),
      }),
    );
  }

  onUpdateRoomRateLeadDays() {
    this.getRoomRateLeadDays().then((response) => this.setState(this.setStateFromResponseLeadDaysPartial(response)));
  }

  onRoomRateExtraGuestSurchargeUpdated() {
    Promise.all([this.getProperty(), this.getExtraGuestSurcharges()]).then((response) =>
      this.setState({
        ...this.setStateFromResponsePropertyPartial(response[0]),
        ...this.setStateFromResponseExtraGuestSurchargesPartial(response[1]),
      }),
    );
  }

  async onUpdateRoomRates() {
    const response = await this.getRoomRates();
    this.setState(this.setStateFromResponseRoomRates(response));
  }

  deleteRoomTypeCapacity(id) {
    ReservationService.deleteRoomTypeCapacity(id, this.propertyId, this.roomTypeId, this.roomRateId);

    this.onUpdateRoomTypeCapacities();
  }

  deleteRoomTypeIncludedGuest(id) {
    ReservationService.deleteRoomTypeIncludedGuest(id, this.propertyId, this.roomTypeId, this.roomRateId);

    this.onUpdateRoomTypeIncludedGuests();
  }

  deleteRoomRateInclusion(id) {
    ReservationService.deleteRoomRateInclusion(id, this.propertyId, this.roomTypeId, this.roomRateId);

    this.onUpdateRoomRateInclusions();
  }

  deleteRoomRate(id) {
    this.setState({
      roomRates: this.state.roomRates.filter((x) => x.id !== id),
    });

    const update = async () => {
      await ReservationService.deleteRoomRate(id, this.propertyId, this.roomTypeId);
      await this.onUpdateRoomRates();
    };

    return update();
  }

  deleteExtraGuestSurcharge(id) {
    this.setState({
      extraGuestSurcharges: this.state.extraGuestSurcharges.filter((x) => x.id !== id),
    });

    const update = async () => {
      await ReservationService.deleteExtraGuestSurcharge(id, this.propertyId, this.roomTypeId, this.roomRateId);
      await this.onRoomRateExtraGuestSurchargeUpdated();
    };

    return update();
  }

  handleDeleteAllBlackoutDates = () => {
    this.setState({ isDeletingAll: true });

    this.doDeleteAllRoomRateLevel(
      this.state.blackoutDates,
      ReservationService.deleteBlackoutDateBlock.bind(ReservationService),
    ).then(() => {
      this.setState({ isDeletingAll: false });
      this.onUpdateBlackoutDates([]);
    });
  };

  handleDeleteAllCapacity = () => {
    this.setState({ isDeletingAll: true });

    this.doDeleteAll(this.state.capacities, ReservationService.deleteRoomTypeCapacity.bind(ReservationService)).then(
      () => {
        this.setState({ isDeletingAll: false });
        this.onUpdateRoomTypeCapacities();
      },
    );
  };

  handleDeleteAllIncludedGuests = () => {
    this.setState({ isDeletingAll: true });

    this.doDeleteAll(
      this.state.includedGuests,
      ReservationService.deleteRoomTypeIncludedGuest.bind(ReservationService),
    ).then(() => {
      this.setState({ isDeletingAll: false });
      this.onUpdateRoomTypeIncludedGuests();
    });
  };

  handleDeleteAllInclusions = () => {
    this.setState({ isDeletingAll: true });
    try {
      ReservationService.deleteRoomRateInclusionsBulk(this.propertyId, this.roomTypeId, this.roomRateId);
    } catch (e) {
      this.handleFetchError(e);
    } finally {
      this.setState({ isDeletingAll: false });
      this.onUpdateRoomRateInclusions();
    }
  };

  doDeleteAll = async (items, doFunc) => {
    const propertyId = this.propertyId,
      roomTypeId = this.roomTypeId,
      roomRateId = this.roomRateId;

    if (items.length == 0) {
      return;
    }

    const ids = items.map((item) => item.id);

    // split for 10 requests in a batch
    const chunk_size = 10;
    for (let i = 0, j = ids.length; i < j; i += 1) {
      const chunk = ids.slice(i, i + chunk_size);
      try {
        await Promise.all(chunk.map((id) => doFunc(id, propertyId, roomTypeId, roomRateId)));
      } catch (e) {
        this.handleFetchError(e);
      }
    }
  };

  doDeleteAllRoomRateLevel = async (items, doFunc) => {
    const propertyId = this.propertyId,
      roomTypeId = this.roomTypeId,
      roomRateId = this.roomRateId;

    if (items.length == 0) {
      return;
    }

    const ids = items.map((item) => item.id);

    // split for 10 requests in a batch
    const chunk_size = 10;
    for (let i = 0, j = ids.length; i < j; i += 1) {
      const chunk = ids.slice(i, i + chunk_size);
      try {
        await Promise.all(chunk.map((id) => doFunc(id, propertyId, roomTypeId, roomRateId)));
      } catch (e) {
        this.handleFetchError(e);
      }
    }
  };

  changeAmountInCalendar = (e) => {
    this.setState({ showWithoutTax: e.target.checked, loading: true }, async () => {
      try {
        this.setState({ loading: false });
      } catch (e) {
        this.handleFetchError(e);
      }
    });
  };

  getRoomRateDatesByOptions = (lengthOfStay, numberOfAdults, childrenAges) => {
    this.setState({ loading: true, lengthOfStay, numberOfAdults, childrenAges }, async () => {
      try {
        const [availability, roomRateDates] = await Promise.all([this.getAvailability(), this.getRoomRateDates()]);
        this.setState({
          loading: false,
          ...this.setStateFromResponseRoomRateDatesPartial(roomRateDates),
          ...this.setStateFromResponseAvailabilityPartial(availability),
        });
      } catch (e) {
        this.handleFetchError(e);
      } finally {
        this.setState({
          loading: false,
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    if (this.state.hasError) {
      return <ErrorDisplay message={this.state.error.message} report={reportError(this.state.error)} />;
    }

    const roomRate = this.state.roomRates.find((roomRate) => roomRate.id === this.roomRateId);

    return (
      <RoomTypeEdit
        roomType={this.state.roomType}
        vendorId={this.vendorId}
        vendor={this.state.vendor}
        channelManaged={this.state.channelManaged}
        isDeletingAll={this.state.isDeletingAll}
        foreignExchangeRates={this.state.foreignExchangeRates}
        numberOfAdults={this.state.numberOfAdults}
        childrenAges={this.state.childrenAges}
        // Property
        propertyId={this.propertyId}
        property={this.state.property}
        propertyRoomTypes={this.state.property.room_types}
        // Room Rates
        roomRate={roomRate}
        roomRates={this.state.roomRates}
        // Room Rates Dates
        roomRateDates={this.state.roomRateDates}
        getRoomRateDatesByOptions={this.getRoomRateDatesByOptions}
        // Rate Plans
        ratePlans={this.state.ratePlans}
        deleteRoomRate={this.deleteRoomRate.bind(this)}
        onUpdateRoomRates={this.onUpdateRoomRates.bind(this)}
        // Availability
        onUpdateAvailability={this.onUpdateAvailability.bind(this)}
        availabilitySchema={this.state.availabilitySchema}
        availability={this.state.availability}
        // Length of stay
        lengthOfStay={this.state.lengthOfStay}
        lengthsOfStay={this.state.lengthsOfStay}
        // Lead Days
        leadDays={this.state.leadDays}
        leadDaysSchema={this.state.leadDaysSchema}
        onUpdateRoomRateLeadDays={this.onUpdateRoomRateLeadDays.bind(this)}
        // Blackout dates
        blackoutDates={this.state.blackoutDates}
        deleteBlackoutDateBlock={this.deleteBlackoutDateBlock.bind(this)}
        onUpdateBlackoutDates={this.onUpdateBlackoutDates.bind(this)}
        onDeleteAllBlackoutDates={this.handleDeleteAllBlackoutDates}
        // Surcharge dates
        surchargeSchema={this.state.surchargeSchema}
        surchargeDates={this.state.surchargeDates}
        deleteSurchargeDateBlock={this.deleteSurchargeDateBlock.bind(this)}
        deleteSurchargeDateBlockByRoomRateId={this.deleteSurchargeDateBlockByRoomRateId.bind(this)}
        deleteSurchargeDateBlockByRatePlanId={this.deleteSurchargeDateBlockByRatePlanId.bind(this)}
        onUpdateSurchargeDates={this.onUpdateSurchargeDates.bind(this)}
        // Internal Surcharge dates
        internalSurchargeDates={this.state.internalSurchargeDates}
        deleteInternalSurchargeDateBlock={this.deleteInternalSurchargeDateBlock.bind(this)}
        onUpdateInternalSurchargeDates={this.onUpdateInternalSurchargeDates.bind(this)}
        // Extra Guest Surcharges
        extraGuestSurcharges={this.state.extraGuestSurcharges}
        onRoomRateExtraGuestSurchargeUpdated={this.onRoomRateExtraGuestSurchargeUpdated.bind(this)}
        deleteExtraGuestSurcharge={this.deleteExtraGuestSurcharge.bind(this)}
        // Inclusions
        inclusions={this.state.inclusions}
        inclusionsSchema={this.state.inclusionsSchema}
        onDeleteAllInclusions={this.handleDeleteAllInclusions.bind(this)}
        onUpdateRoomRateInclusions={this.onUpdateRoomRateInclusions.bind(this)}
        deleteInclusion={this.deleteRoomRateInclusion.bind(this)}
        onInclusionsOrderUpdate={this.onInclusionsOrderUpdate.bind(this)}
        // Room Type Capacity
        capacities={this.state.capacities}
        deleteRoomTypeCapacity={this.deleteRoomTypeCapacity.bind(this)}
        onDeleteAllCapacity={this.handleDeleteAllCapacity}
        onUpdateRoomTypeCapacities={this.onUpdateRoomTypeCapacities.bind(this)}
        // Included Guest
        includedGuests={this.state.includedGuests}
        //Without tax
        changeAmountInCalendar={this.changeAmountInCalendar}
        showWithoutTax={this.state.showWithoutTax}
        deleteRoomTypeIncludedGuest={this.deleteRoomTypeIncludedGuest.bind(this)}
        onUpdateRoomTypeIncludedGuests={this.onUpdateRoomTypeIncludedGuests.bind(this)}
        onDeleteAllIncludedGuests={this.handleDeleteAllIncludedGuests}
      />
    );
  }
}
