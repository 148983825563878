import React, { ComponentProps, Fragment, useCallback } from 'react';

import { FormProps } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema, RegistryWidgetsType, StrictRJSFSchema, TemplatesType } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import styled from 'styled-components';

import { Box, Button, CircularProgress } from '@mui/material';

import ArrayItemCopyAndReplaceModal from './ArrayItemCopyAndReplaceModal';
import BreadcrumbsLegendTemplate from './BreadcrumbsLegendTemplate';
import ComboBoxWidget from './ComboBoxWidget';
import ErrorsAlertTemplate from './ErrorsAlertTemplate';
import ExtendedArrayFieldTemplate from './ExtendedArrayFieldTemplate';
import ExtendedObjectFieldTemplate from './ExtendedObjectFieldTemplate';
import FloatingSubmitButtonTemplate from './FloatingSubmitButtonTemplate';
import ImagePreviewModal from './ImagePreviewModal';
import ImageUploadWidget from './ImageUploadWidget';
import LELocationSearchWidget from './LELocationSearchWidget';
import MarkdownEditorWidget from './MarkdownEditorWidget';
import ParentSiblingCopyModal from './ParentSiblingArrayCopyModal';
import TourCategoriesComboBoxWidget from './TourCategoriesComboBoxWidget';
import useExtendedRJSForm, { ExtendedRJSFormContext } from './useExtendedRJSForm';

const WIDGETS: RegistryWidgetsType = {
  'le-location-search': LELocationSearchWidget,
  'markdown-editor': MarkdownEditorWidget,
  'image-upload': ImageUploadWidget,
  'combo-box': ComboBoxWidget,
  'tour-categories-combo-box': TourCategoriesComboBoxWidget,
};

const TEMPLATES: Partial<TemplatesType<unknown, RJSFSchema, ExtendedRJSFormContext>> = {
  ButtonTemplates: {
    SubmitButton: FloatingSubmitButtonTemplate,
    AddButton: (props) => (
      <Button {...props} variant="contained" color="primary">
        Add
      </Button>
    ),
    RemoveButton: (props) => (
      <Button {...props} variant="contained" color="error">
        Remove
      </Button>
    ),
    MoveUpButton: (props) => (
      <Button {...props} variant="contained" color="info">
        Move Up
      </Button>
    ),
    MoveDownButton: (props) => (
      <Button {...props} variant="contained" color="info">
        Move Down
      </Button>
    ),
    CopyButton: (props) => (
      <Button {...props} variant="contained" color="info">
        Copy
      </Button>
    ),
  },
  TitleFieldTemplate: BreadcrumbsLegendTemplate,
  ArrayFieldTemplate: ExtendedArrayFieldTemplate,
  ObjectFieldTemplate: ExtendedObjectFieldTemplate,
  ErrorListTemplate: ErrorsAlertTemplate,
};

// This custom styling is here only as a reset.
const StyledForm = styled(Form)`
  legend {
    border: unset;
  }

  textarea {
    resize: vertical;
  }

  .form-group {
    width: 100%;
    margin: unset;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props<T = any, S extends StrictRJSFSchema = RJSFSchema>
  extends Pick<
    FormProps<T, S, ExtendedRJSFormContext>,
    'id' | 'schema' | 'uiSchema' | 'formData' | 'onSubmit' | 'customValidate' | 'disabled'
  > {
  busy?: boolean;
  hideSubmitButton?: boolean;
}

export default function TourDetailsForm<T, S>(props: Props<T, S>) {
  const {
    id,
    busy,
    customValidate,
    disabled,
    formData: initialFormData,
    onSubmit,
    schema,
    uiSchema,
    hideSubmitButton = false,
  } = props;
  const {
    formContext,
    formData,
    setFormData,
    parentSiblingArrayCopyDefinition,
    handleParentSiblingArrayCopy,
    handleParentSiblingArrayCopyDismiss,
    arrayItemCopyDefinition,
    handleArrayItemCopy,
    handleArrayItemCopyDismiss,
    imagePreviewDefinition,
    handleImagePreviewDismiss,
  } = useExtendedRJSForm(initialFormData);

  const handleChange = useCallback<ComponentProps<typeof Form>['onChange']>(
    (e) => {
      setFormData(e.formData);
    },
    [setFormData],
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <StyledForm
        id={id}
        formContext={formContext}
        disabled={disabled || busy}
        validator={validator}
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        widgets={WIDGETS}
        templates={TEMPLATES}
        onSubmit={onSubmit}
        onChange={handleChange}
        customValidate={customValidate}
      >
        {hideSubmitButton && <Fragment />}
      </StyledForm>

      {!!parentSiblingArrayCopyDefinition && (
        <ParentSiblingCopyModal
          formData={formData}
          copyDefinition={parentSiblingArrayCopyDefinition}
          onCopyAndAdd={handleParentSiblingArrayCopy}
          onDismiss={handleParentSiblingArrayCopyDismiss}
        />
      )}

      {!!arrayItemCopyDefinition && (
        <ArrayItemCopyAndReplaceModal
          formData={formData}
          copyDefinition={arrayItemCopyDefinition}
          onCopyAndReplace={handleArrayItemCopy}
          onDismiss={handleArrayItemCopyDismiss}
        />
      )}

      {!!imagePreviewDefinition && (
        <ImagePreviewModal imagePreviewDefinition={imagePreviewDefinition} onDismiss={handleImagePreviewDismiss} />
      )}

      {busy && (
        <Box position="sticky" p={2} left="0" bottom="0">
          <CircularProgress variant="indeterminate" color="secondary" />
        </Box>
      )}
    </Box>
  );
}
