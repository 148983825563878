import React, { useCallback, useEffect, useState } from 'react';

import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Button, Stack, Typography } from '@mui/material';

import { currencyFormatter } from '~/components/Experiences/helpers';

import useToggleState from '~/hooks/useToggleState';

import { getOrderCreditItem } from '~/services/BusinessTraveller/BusinessTravellerService';
import { formatDateNumeralMonths, isBefore } from '~/services/TimeService';

import PriceSummaryField from '../OrderItem/PriceSummaryField';

import OrderItemBusinessCreditDialog from './OrderItemBusinessCreditDialog';

export const CREDIT_STATUSES_THAT_CAN_BE_APPLIED = new Set(['pending_trip_completion', 'pending_application']);

interface Props {
  orderItemId: string;
  customerId: string;
  location: string;
}

type OrderItemFetch =
  | {
      status: 'pending';
    }
  | {
      status: 'success';
      data: App.BusinessOrderItem;
    }
  | {
      status: 'failed';
      error: string;
    };

export default function OrderItemBusinessCredit(props: Props) {
  const { orderItemId, location, customerId } = props;
  const [orderItemCredit, setOrderitemCredit] = useState<OrderItemFetch>({ status: 'pending' });
  const { isToggled: isModalOpen, toggleOn: openModal, toggleOff: closeModal } = useToggleState();

  const fetchOrderItemCredit = useCallback(async () => {
    try {
      const response = await getOrderCreditItem(orderItemId);
      if (response.result) {
        setOrderitemCredit({ status: 'success', data: response.result });
      } else {
        setOrderitemCredit({ status: 'failed', error: response.message });
      }
    } catch (e) {
      if (e.status === 404) {
        setOrderitemCredit({ status: 'failed', error: 'No LEBT credits for this item.' });
      } else {
        setOrderitemCredit({ status: 'failed', error: String(e.message ?? e) });
      }
    }
  }, [orderItemId]);

  useEffect(() => {
    fetchOrderItemCredit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack mt={1} mb={1} direction="column">
      <Typography variant="subtitle1" fontWeight="bold">
        LEBT Credits
      </Typography>
      {orderItemCredit.status === 'pending' && <Typography>loading</Typography>}
      {orderItemCredit.status === 'failed' && <Typography>{orderItemCredit.error}</Typography>}
      {orderItemCredit.status === 'success' && (
        <Stack direction="column" alignItems="start">
          <Stack direction="row" alignSelf="stretch" spacing={1}>
            <RequestQuoteIcon />
            <PriceSummaryField
              boldPrice
              label="LEBT Credits"
              value={currencyFormatter(orderItemCredit.data.currencyCode, orderItemCredit.data.creditTotal, 2)}
            />
          </Stack>
          {orderItemCredit.data.creditStatus === 'cancelled' && (
            <Typography variant="body2">Credits have been cancelled</Typography>
          )}
          {orderItemCredit.data.creditStatus === 'applied' && (
            <Typography variant="body2">Credits have already been applied</Typography>
          )}
          {CREDIT_STATUSES_THAT_CAN_BE_APPLIED.has(orderItemCredit.data.creditStatus) && (
            <>
              <Typography variant="body2">
                Credits available from {formatDateNumeralMonths(orderItemCredit.data.creditAvailableFromDate)}
              </Typography>
              {orderItemCredit.data.creditTotal > 0 &&
                isBefore(orderItemCredit.data.creditAvailableFromDate, new Date()) && (
                  <Stack mt={1}>
                    <Button variant="text" onClick={openModal}>
                      Mark Credits Applied
                    </Button>
                    <OrderItemBusinessCreditDialog
                      orderItemCredit={orderItemCredit.data}
                      customerId={customerId}
                      location={location}
                      isOpen={isModalOpen}
                      onClose={closeModal}
                      refetch={fetchOrderItemCredit}
                    />
                  </Stack>
                )}
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
}
