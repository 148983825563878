import React, { MouseEvent } from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import bedbankService from '~/services/BedbankService';

import { isAdmin } from '~/utils/adminPermission';

import BedbankPropertiesEditForm from './Form';
import { OverviewPane } from './OverviewPane';

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

interface State {
  isAdmin: boolean;
  fetching: boolean;
  scanningRatesData: boolean;
  syncRates: boolean;
  shouldScanRatesPopupBeOpen: boolean;
  scanRatesData?: IAllRatesWithExpediaPromotions;
  result?: App.Bedbank.PropertyEdit;
  error?: any;
}

interface IPropertyResponse {
  status: number;
  message: string;
  result: App.Bedbank.PropertyEdit;
}

interface IAllRatesWithExpediaPromotionsRoomRate {
  rateId: string;
  isNew: boolean;
  isDeleted: boolean;
  isChanged: boolean;
  isRestricted: boolean;
  facilities: string[];
  promotions: string[];
}

interface IAllRatesWithExpediaPromotionsRoom {
  roomId: string;
  roomName: string;
  rates: Array<IAllRatesWithExpediaPromotionsRoomRate>;
}

interface IAllRatesWithExpediaPromotions {
  lastScan: string;
  state: 'scanning' | 'completed' | 'pending';
  rooms: {
    hotelPackage: IAllRatesWithExpediaPromotionsRoom[];
    hotelOnly: IAllRatesWithExpediaPromotionsRoom[];
  };
}

interface IScanResponse {
  status: number;
  message: string;
  result?: IAllRatesWithExpediaPromotions;
}

interface ISyncResponse {
  status: number;
  message: string;
  result?: {
    id: string;
    state: string;
  };
}

const RoomRatesStatuses: {
  [name: string]: {
    label: string;
    color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  };
} = {
  new: {
    label: 'new',
    color: 'success',
  },
  deleted: {
    label: 'deleted',
    color: 'error',
  },
  changed: {
    label: 'changed',
    color: 'warning',
  },
  restricted: {
    label: 'restricted',
    color: 'info',
  },
};

function getRoomRatesStatus(rate: IAllRatesWithExpediaPromotionsRoomRate) {
  if (rate.isNew) {
    return RoomRatesStatuses.new;
  } else if (rate.isDeleted) {
    return RoomRatesStatuses.deleted;
  } else if (rate.isChanged) {
    return RoomRatesStatuses.changed;
  } else if (rate.isRestricted) {
    return RoomRatesStatuses.restricted;
  }

  return null;
}

export default class BedbankPropertiesEditPage extends React.Component<Props, State> {
  static contextTypes = {
    user: PropTypes.object,
  };

  constructor(props: Props, context) {
    super(props);
    this.state = {
      isAdmin: isAdmin(context.user),
      fetching: true,
      scanningRatesData: false,
      syncRates: true,
      shouldScanRatesPopupBeOpen: false,
      scanRatesData: null,
      result: null,
      error: null,
    };
  }

  componentDidMount = async () => {
    await this.fetchData(this.props.match.params.id);
    await this.syncRatesData();
  };

  fetchData = async (id: string) => {
    this.setState({ fetching: true, error: null }, async () => {
      try {
        const response: IPropertyResponse = await bedbankService.getPropertyEdit(id);

        this.setState({
          fetching: false,
          result: response.result,
        });
      } catch (err) {
        this.setState({ fetching: false, error: err.message });
      }
    });
  };

  scanRatesPopupClose = () => {
    this.setState({
      shouldScanRatesPopupBeOpen: false,
    });
  };

  setProperty = (property: App.Bedbank.PropertyEdit) => {
    this.setState({
      result: property,
    });
  };

  syncRatesData = async () => {
    try {
      const response: ISyncResponse = await bedbankService.getRatesSync(this.props.match.params.id);

      const syncRatesData = response.result?.state;

      if (!syncRatesData) {
        this.setState({
          syncRates: false,
        });
      }

      if (syncRatesData) {
        setTimeout(() => this.syncRatesData(), 3000);
      }
    } catch (err) {
      this.setState({
        syncRates: false,
      });
    }
  };

  scanRatesData = async () => {
    try {
      const response: IScanResponse = await bedbankService.getRatesScan(this.props.match.params.id);

      const scanningRatesData = !(response.result?.state === 'completed' || !response.result?.state);

      if (!scanningRatesData) {
        this.setState({
          scanningRatesData: false,
          scanRatesData: response.result,
        });
      }

      if (scanningRatesData) {
        setTimeout(() => this.scanRatesData(), 3000);
      }
    } catch (err) {
      this.setState({
        scanRatesData: null,
        scanningRatesData: false,
      });
    }
  };

  fetchScanRatesData = async (event: MouseEvent) => {
    event.preventDefault();
    this.setState(
      {
        shouldScanRatesPopupBeOpen: true,
        scanningRatesData: true,
      },
      this.scanRatesData,
    );
  };

  postScanRatesData = async (event: MouseEvent) => {
    event.preventDefault();
    this.setState(
      {
        scanningRatesData: true,
      },
      async () => {
        try {
          await bedbankService.postRatesScan(this.props.match.params.id);
          this.scanRatesData();
        } catch (err) {
          this.setState({ scanningRatesData: false });
        }
      },
    );
  };

  postSyncRatesData = async (event: MouseEvent) => {
    event.preventDefault();
    this.setState(
      {
        syncRates: true,
      },
      async () => {
        try {
          await bedbankService.postRatesSync(this.props.match.params.id);
          setTimeout(() => this.syncRatesData(), 5000);
        } catch (err) {
          this.setState({ syncRates: false });
        }
      },
    );
  };

  getUniqueRoomIds = (hotelOnly, hotelPackage): string[] => {
    return Array.from(new Set([...hotelOnly, ...hotelPackage].map((room) => room.roomId)));
  };

  rateItem = (id: string, title: string, rates: IAllRatesWithExpediaPromotionsRoomRate[]) => (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={id} id={id}>
        {title}
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {rates.map((rate) => (
            <Box
              key={rate.rateId}
              sx={{
                p: 1,
                mb: 1,
                border: 1,
                borderRadius: '12px',
              }}
            >
              {getRoomRatesStatus(rate) && (
                <Chip
                  label={getRoomRatesStatus(rate).label}
                  color={getRoomRatesStatus(rate).color}
                  sx={{
                    position: 'absolute',
                    right: '20px',
                    mt: '-5px',
                  }}
                />
              )}
              <div>ID: {rate.rateId}</div>
              <div>Promo: {rate.promotions[0] || 'None'}</div>
              <div>
                All inclusions:
                <ul>
                  {rate.facilities.map((facility) => (
                    <li key={facility}>{facility}</li>
                  ))}
                </ul>
              </div>
              {this.getSpecialDescriptions(rate.rateId) && (
                <div>
                  <p>Special Extra Description:</p>
                  <div dangerouslySetInnerHTML={{ __html: this.getSpecialDescriptions(rate.rateId) }}></div>
                </div>
              )}
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  getSpecialDescriptions = (rateId: string) => {
    const { rates } = this.state.result;
    const rate = rates.find((rate) => rate.extId === rateId);
    return rate?.specialOfferDescription ?? null;
  };

  roomRateItems = (data: IAllRatesWithExpediaPromotionsRoom) => {
    const withPromos = data?.rates.filter((rate) => rate.promotions.length > 0);
    const withoutPromos = data?.rates.filter((rate) => !rate.promotions.length);
    return (
      <Box>
        {data ? (
          <>
            <Box>Room ID: {data.roomId}</Box>
            <div>
              <b>{data.roomName}</b>
            </div>
            <Box>
              Rates:
              {this.rateItem(`${data.roomId}-with-promo`, `with promos (${withPromos.length})`, withPromos)}
              {this.rateItem(`${data.roomId}-without-promo`, `without promos (${withoutPromos.length})`, withoutPromos)}
            </Box>
          </>
        ) : (
          'Not available'
        )}
      </Box>
    );
  };

  render() {
    const { fetching, error, result } = this.state;

    if (fetching) {
      return <Spinner />;
    }

    if (error) {
      return <ErrorDisplay message={error} />;
    }

    const hotelPackage = this.state.scanRatesData?.rooms?.hotelPackage ?? [];
    const hotelOnly = this.state.scanRatesData?.rooms?.hotelOnly ?? [];
    const roomIds = this.getUniqueRoomIds(hotelOnly, hotelPackage);

    return (
      <div className="offer-deal">
        <Helmet>
          <title>Property | {result?.name || ''}</title>
        </Helmet>

        <Container maxWidth="xl">
          <OverviewPane
            property={result}
            syncRates={this.state.syncRates}
            fetchScanRatesData={this.fetchScanRatesData}
            postSyncRatesData={this.postSyncRatesData}
          />

          <BedbankPropertiesEditForm property={result} setProperty={this.setProperty} isAdmin={this.state.isAdmin} />
        </Container>

        <Dialog
          open={this.state.shouldScanRatesPopupBeOpen}
          onClose={this.scanRatesPopupClose}
          maxWidth={'md'}
          fullWidth
        >
          <DialogTitle>Rates</DialogTitle>
          <DialogContent>
            {this.state.scanningRatesData && <Spinner size={48} />}

            {!this.state.scanningRatesData && (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    textAlign: 'center',
                  }}
                >
                  <h2>Hotel Only</h2>
                  <h2>Hotel Package</h2>
                </Box>

                {roomIds.map((roomId) => (
                  <>
                    <Divider sx={{ mb: 2 }} />
                    <Box
                      key={roomId}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        mb: 2,
                        justifyContent: 'space-evenly',
                      }}
                    >
                      {this.roomRateItems(hotelOnly.find((room) => room.roomId === roomId))}
                      {this.roomRateItems(hotelPackage.find((room) => room.roomId === roomId))}
                    </Box>
                  </>
                ))}
              </Box>
            )}

            {!this.state.scanningRatesData && hotelPackage.length + hotelOnly.length === 0 && (
              <DialogContentText variant="h5">No results</DialogContentText>
            )}
          </DialogContent>

          <DialogActions>
            <Button variant="text" onClick={this.postScanRatesData} disabled={this.state.scanningRatesData}>
              Scan rates
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
