import React, { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

import {
  cancelPreviewPeriod,
  getLuxPlusRenewalOrExpiryDate,
  hasActiveLuxPlusSubscriptionRenewal,
  updateCustomerMembershipSubscriptions,
} from '~/services/MembershipService';
import { formatDateSlashes } from '~/services/TimeService';

import ErrorDisplay from '../Common/ErrorDisplay';
import NewRefundModal from '../Refund/NewModal';

import { isLuxPlusActiveSubscriber } from './utils';

interface Props {
  subscriptions: App.MembershipSubscriptions;
  customerId: string;
  currentSubscriptionOrderItem: Order.SubscriptionItem;
  currentSubscriptionOrder: App.Order;
  refreshData: () => Promise<void>;
}

const cancelRenewalReasons = [
  'The annual fee is too expensive',
  'I don’t travel frequently enough',
  'The benefits are not meeting my expectations',
  'I don’t want to be automatically charged',
];

export default function MembershipSubscriptionDetails(props: Props) {
  const { subscriptions, customerId, currentSubscriptionOrderItem, currentSubscriptionOrder, refreshData } = props;
  const hasActiveRenewal = hasActiveLuxPlusSubscriptionRenewal(subscriptions);
  const renewalOrExpiryDate = getLuxPlusRenewalOrExpiryDate(subscriptions);
  const joiningFeeItem = currentSubscriptionOrder?.subscription_items?.find((item) => item.sub_type === 'joining_fee');
  const isActivePreviewSubscription = subscriptions.tier === 'free_preview' && subscriptions.status === 'ACTIVE';

  const [itemToRefund, setItemToRefund] = useState<Order.SubscriptionItem>(null);
  const [openRefundWarningModal, setOpenRefundWarningModal] = useState<boolean>(false);
  const [openRefundModal, setOpenRefundModal] = useState<boolean>(false);
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] = useState<boolean>(false);
  const [openResumeSubscriptionModal, setOpenResumeSubscriptionModal] = useState<boolean>(false);
  const [openCancelPreviewPeriodModal, setOpenCancelPreviewPeriodModal] = useState<boolean>(false);
  const [error, setError] = useState<Error>(null);
  const [reason, setReason] = useState<string | undefined>(undefined);
  const [explanation, setExplanation] = useState<string | undefined>(undefined);

  const handleCancelMembershipToggle = () => {
    setItemToRefund(() => currentSubscriptionOrderItem);
    setOpenRefundModal((prevState) => !prevState);
  };

  const handleRefundJoiningFeeToggle = () => {
    setItemToRefund(() => joiningFeeItem);
    setOpenRefundModal((prevState) => !prevState);
  };

  const handleCancelMembershipWarningToggle = () => {
    setOpenRefundWarningModal((prevState) => !prevState);
  };

  const handleCancelSubscriptionToggle = () => {
    if (error) setError(null);
    setOpenCancelSubscriptionModal((prevState) => !prevState);
    setReason(undefined);
    setExplanation(undefined);
  };

  const handleResumeSubscriptionToggle = () => {
    if (error) setError(null);
    setOpenResumeSubscriptionModal((prevState) => !prevState);
  };

  const handleCancelPreviewToggle = () => {
    if (error) setError(null);
    setOpenCancelPreviewPeriodModal((prevState) => !prevState);
  };

  const handleProceedCancelMembership = () => {
    handleCancelMembershipWarningToggle();
    handleCancelMembershipToggle();
  };

  const handleCancelPreviewPeriod = () => {
    try {
      cancelPreviewPeriod({ customerId: customerId, op: 'cancel' });
      refreshData();
    } catch (error) {
      setError(error);
    } finally {
      setOpenCancelPreviewPeriodModal(false);
    }
  };

  const handleUpdateMembershipRenewal = () => {
    try {
      const op = hasActiveRenewal ? 'pause' : 'resume';
      updateCustomerMembershipSubscriptions({
        customerId,
        op,
        reason:
          op === 'pause'
            ? {
                category: reason,
                explanation: reason === 'Other' ? explanation : undefined,
              }
            : undefined,
      });
      refreshData();
    } catch (error) {
      setError(error);
    } finally {
      setOpenCancelSubscriptionModal(false);
      setOpenResumeSubscriptionModal(false);
    }
  };

  const benefitColumns: GridColDef[] = [
    { field: 'itemType', headerName: 'Item type', width: 200, display: 'flex' },
    { field: 'type', headerName: 'Benefit type', width: 200, display: 'flex' },
    { field: 'subtype', headerName: 'Benefit sub-type', width: 200, display: 'flex' },
    {
      field: 'orderId',
      headerName: 'Link to order',
      width: 120,
      renderCell: (params) => (
        <Button target="_blank" href={`/purchases/${params.value}`}>
          View Order
        </Button>
      ),
      display: 'flex',
    },
  ];

  const redeemedBenefits = subscriptions.subscriptionPeriods.flatMap((period) => period.redeemedBenefits);

  return (
    <>
      <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: '20px' }}>
        <Grid item container spacing={{ xs: 2, md: 4 }}>
          {subscriptions?.status === 'ACTIVE' && (
            <Grid item container spacing={{ xs: 2, md: 4 }}>
              <Grid item>
                <Typography variant="h6">Status</Typography>
                <Typography variant="h6" color="green">
                  {subscriptions?.status}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">Member since</Typography>
                <Typography variant="subtitle1">{formatDateSlashes(subscriptions?.memberSince)}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">Subscription {hasActiveRenewal ? 'renewal' : 'expiry'} date</Typography>
                <Typography variant="subtitle1">{formatDateSlashes(renewalOrExpiryDate)}</Typography>
              </Grid>
            </Grid>
          )}
          {subscriptions?.status !== 'ACTIVE' && (
            <Grid item container spacing={{ xs: 2, md: 4 }}>
              <Grid item>
                <Typography variant="h6">Status</Typography>
                <Typography variant="h6" color="error">
                  {subscriptions?.status}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Button target="_blank" href={`/purchases/${currentSubscriptionOrder?.id}`}>
            View subscription purchase order
          </Button>
        </Grid>
        <Grid item container spacing={{ xs: 1 }} justifyContent="end">
          <Grid item>
            {isActivePreviewSubscription && (
              <Button variant="text" color="error" onClick={handleCancelPreviewToggle}>
                Cancel preview period
              </Button>
            )}
          </Grid>
          <Grid item>
            {joiningFeeItem &&
              (joiningFeeItem.status === 'cancelled' ? (
                <Button variant="text" color="secondary">
                  Joining fee refunded
                </Button>
              ) : (
                <Button variant="text" color="error" onClick={handleRefundJoiningFeeToggle}>
                  Refund joining fee
                </Button>
              ))}
          </Grid>
          <Grid item>
            {subscriptions.status === 'EXPIRED' && (
              <Button variant="text" color="secondary">
                Subscription expired
              </Button>
            )}
            {subscriptions.status !== 'EXPIRED' &&
              subscriptions.status !== 'CONFIRMED_AWAITING_PAYMENT' &&
              currentSubscriptionOrderItem &&
              (currentSubscriptionOrderItem.status === 'cancelled' ? (
                <Button variant="text" color="secondary">
                  Subscription refunded
                </Button>
              ) : (
                <Button variant="text" color="error" onClick={handleCancelMembershipWarningToggle}>
                  Cancel & refund subscription
                </Button>
              ))}
          </Grid>
          {isLuxPlusActiveSubscriber(subscriptions) && (
            <Grid item>
              {hasActiveRenewal ? (
                <Button variant="text" color="warning" onClick={handleCancelSubscriptionToggle}>
                  Cancel subscription renewal
                </Button>
              ) : (
                <Button variant="text" color="warning" onClick={handleResumeSubscriptionToggle}>
                  Resume subscription renewal
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">Redeemed benefits</Typography>
        <DataGrid
          autoHeight
          rows={redeemedBenefits}
          columns={benefitColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
        />
      </Box>

      <Dialog
        open={openRefundWarningModal}
        onClose={handleCancelMembershipWarningToggle}
        aria-labelledby="alert-dialog-title-cancel-membership"
        aria-describedby="alert-dialog-description-cancel-membership"
      >
        <DialogTitle id="alert-dialog-title-cancel-membership">Cancel membership</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-cancel-membership">
            Warning, by cancelling this membership this customer will be refunded their subscription amount and no
            longer have access to benefits
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCancelMembershipWarningToggle} autoFocus>
            No, don’t refund
          </Button>
          <Button variant="contained" onClick={handleProceedCancelMembership}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <NewRefundModal
        show={openRefundModal}
        onHide={handleCancelMembershipToggle}
        refreshData={refreshData}
        order={currentSubscriptionOrder}
        itemId={itemToRefund?.id}
        automatic={false}
        itemType="subscription"
        isAfterChangeDate={false}
      />

      {error && <ErrorDisplay message={error.message} />}

      <Dialog
        open={openCancelSubscriptionModal}
        onClose={handleCancelSubscriptionToggle}
        aria-labelledby="alert-dialog-title-cancel-renewal"
        aria-describedby="alert-dialog-description-cancel-renewal"
      >
        <DialogTitle id={`alert-dialog-title-cancel-renewal`}>Cancel subscription renewal</DialogTitle>
        <DialogContent>
          <Box>
            <DialogContentText id="alert-dialog-description-cancel-renewal">
              Warning, by cancelling the subscription this customer will not be refunded and their subscription will not
              be renewed but still have access to benefits.
            </DialogContentText>
            <DialogContentText id="alert-dialog-description-cancel-renewal-2">
              Main reason for cancelling:
            </DialogContentText>
            <FormControl>
              <RadioGroup>
                {cancelRenewalReasons.map((reason) => (
                  <FormControlLabel
                    value={reason}
                    control={<Radio />}
                    label={reason}
                    onChange={() => setReason(reason)}
                  />
                ))}
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label="Other (please specify)"
                  onChange={() => setReason('Other')}
                />
                <TextField
                  disabled={reason !== 'Other'}
                  fullWidth
                  name="comments"
                  multiline={true}
                  rows={2}
                  onChange={(e) => setExplanation(e.target.value)}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCancelSubscriptionToggle} autoFocus>
            Cancel
          </Button>
          <Button disabled={!reason} variant="contained" onClick={handleUpdateMembershipRenewal}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openResumeSubscriptionModal}
        onClose={handleResumeSubscriptionToggle}
        aria-labelledby="alert-dialog-title-cancel-renewal"
        aria-describedby="alert-dialog-description-cancel-renewal"
      >
        <DialogTitle id={`alert-dialog-title-${hasActiveRenewal ? 'cancel' : 'resume'}-renewal`}>
          Resume the subscription renewal
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-cancel-renewal">
            Warning, by resuming the subscription this customer will be charged and their subscription will be renewed
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleResumeSubscriptionToggle} autoFocus>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleUpdateMembershipRenewal}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCancelPreviewPeriodModal}
        onClose={handleCancelPreviewToggle}
        aria-labelledby="alert-dialog-title-cancel-preview"
        aria-describedby="alert-dialog-description-cancel-preview"
      >
        <DialogTitle id={`alert-dialog-title-cancel-preview`}>Cancel the free preview period</DialogTitle>
        <DialogActions>
          <Button variant="text" onClick={handleCancelPreviewToggle} autoFocus>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCancelPreviewPeriod}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
