import React, { useCallback, useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import { Redirect } from 'react-router';

import { Box, Link, MenuItem, Stack, TextField } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import AsyncAutocomplete from '~/components/Common/Legacy/AsyncAutocomplete';

import { debug as debugSearch } from '~/services/DestinationAlertService';

import loadScript from '~/utils/loadScript';

import SearchMap from './SearchMap';

export default function DebugSearch({ placeId }) {
  const autocomplete = useRef<google.maps.places.AutocompleteService>();
  const geocoder = useRef<google.maps.Geocoder>();

  const [types, setTypes] = useState('(regions)');
  const [loaded, setLoaded] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<google.maps.GeocoderResult>();
  const [placeDebug, setPlaceDebug] = useState<{ placeId: string; place: unknown }>();

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${window.configs.GOOGLE_PLACE_API_KEY}&libraries=places&language=en-AU`,
    ).then(() => {
      const googleMaps = window.google.maps;
      if (!autocomplete.current && googleMaps) {
        autocomplete.current = new googleMaps.places.AutocompleteService();
      }
      if (!geocoder.current && googleMaps) {
        geocoder.current = new googleMaps.Geocoder();
      }
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (!selectedPlace) {
        return;
      }
      const response = await debugSearch(selectedPlace.place_id);
      setPlaceDebug(response.result);
    }
    fetchData();
  }, [selectedPlace]);

  useEffect(() => {
    async function fetchData() {
      if (!placeId) {
        return;
      }
      const response = await debugSearch(placeId);
      setPlaceDebug(response.result);
    }
    fetchData();
  }, [placeId]);

  const getPlacePredictions = (input, callback) => {
    if (!input || !autocomplete.current) {
      return callback(null);
    }

    autocomplete.current.getPlacePredictions(
      {
        input: input,
        types: types ? [types] : null,
      },
      (predictions) => {
        if (!predictions) {
          return callback(null);
        }
        callback(
          predictions.map((prediction) => ({
            value: prediction.place_id,
            label: prediction.description,
            types: prediction.types,
          })),
        );
      },
    );
  };

  const callbackOnSelect = (places) => {
    setSelectedPlace(places[0]);
  };

  const onChangeWithCoords = (selected) => {
    if (selected) {
      geocoder.current.geocode({ placeId: selected.value }, callbackOnSelect);
    }
  };

  const debouncedGetPlacePredictions = debounce(getPlacePredictions, 500);
  const typesOptions = [
    {
      value: '(regions)',
      label: 'Regions - Country/State/City/locality',
    },
    {
      value: 'geocode',
      label: 'Any Level (excluding businesses)',
    },
    {
      value: 'establishment',
      label: 'establishments (ie, businesses)',
    },
    {
      value: '',
      label: 'anything',
    },
  ];

  const onSelectChange = useCallback((option) => setTypes(option), []);

  if (!selectedPlace && !placeId) {
    // Default to Bondi
    const bondiId = 'ChIJWTi8xgGyEmsRgK0yFmh9AQU';
    return <Redirect to={`/content/debug-search/${bondiId}`} />;
  }

  if (!loaded) {
    return null;
  }

  if (selectedPlace && selectedPlace.place_id !== placeId) {
    return <Redirect to={`/content/debug-search/${selectedPlace.place_id}`} />;
  }

  return (
    <>
      <Stack mt={1} direction="row" spacing={2}>
        <Box flexGrow={1}>
          <TextField value={types} onChange={(e) => onSelectChange(e.target.value)} fullWidth select>
            {typesOptions.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box flexGrow={3}>
          <AsyncAutocomplete
            loadOptions={debouncedGetPlacePredictions}
            onChange={onChangeWithCoords}
            placeholder="Search a city, country or region"
          />
        </Box>
      </Stack>

      <div>
        <p>
          Search results will include only offers inside the red box. For Luxury Escapes offers (NOT expedia offers),
          they'll be ordered by distance from the main red pin.
        </p>
        <p>
          For "administrative_area_level_1" and above (that means "for state or country level searches") we ignore the
          bounding box and just go from the address. So eg. for "NSW", we just find offers who's address state is NSW.
          We <strong>don't</strong> look for "anything in the{' '}
          <Link target="_blank" href="/content/debug-search/ChIJDUte93TLDWsRLZ_EIhGvgBc">
            bounding box of NSW
          </Link>
          " (which would include all of ACT and a decent chunk of victoria)
        </p>
        Known limitations:
        <ul>
          <li>Google won't let us know the exact shape of a region - only it's "bounding box"</li>
          <li>
            Google's places are not based on touristic value. So,{' '}
            <Link target="_blank" href="/content/debug-search/ChIJ38bhORjODGsR_X6295P3L1Y">
              Hunter Valley
            </Link>{' '}
            covers the whole Hunter Valley Basin, and isn't aware that tourists are interested in a small area around{' '}
            <Link target="_blank" href="/content/debug-search/ChIJp-uTKua1DGsR8EIzFmh9AQU">
              Pokolbin
            </Link>
          </li>
        </ul>
      </div>

      {placeDebug && (
        <>
          <SearchMap placeDebug={placeDebug} />

          <PageSubheader title={`Place ID ${placeDebug.placeId}`} />

          <pre>{JSON.stringify(placeDebug.place, null, 2)}</pre>
        </>
      )}
    </>
  );
}
