import { useMemo } from 'react';

import { SearchFilters } from '~/components/Cruises/pages/Home/components/Sailings/constants';

import useQuery from '~/hooks/useQuery';

export function useSailingsSearchFilter() {
  const query = useQuery();

  return useMemo(() => {
    const sailingDurationMin = query.get('sailingDurationMin') ? Number(query.get('sailingDurationMin')) : undefined;
    const sailingDurationMax = query.get('sailingDurationMax') ? Number(query.get('sailingDurationMax')) : undefined;
    const sailingExternalId = query.get('sailingExternalId') ? Number(query.get('sailingExternalId')) : undefined;
    const departurePortExternalIds = query.get('departurePortExternalIds')
      ? query
          .get('departurePortExternalIds')
          .split(',')
          .map((departure) => Number(departure))
      : [];
    const shipExternalId = query.get('shipExternalId') ? Number(query.get('shipExternalId')) : undefined;

    return {
      name: query.get('name') || undefined,
      status: (query.get('status') as 'ACTIVE' | 'INACTIVE') || undefined,
      offerId: query.get('offerId') || undefined,
      vendorId: query.get('vendorId') || undefined,
      sailingExternalId,
      sailingPlan: query.get('sailingPlan') || undefined,
      sailingDurationMin,
      sailingDurationMax,
      departureDateStart: query.get('departureDateStart') || undefined,
      departureDateEnd: query.get('departureDateEnd') || undefined,
      departurePortExternalIds,
      requiresApproval: (query.get('requiresApproval') as 'TRUE' | 'FALSE') || undefined,
      shipName: query.get('shipName') || undefined,
      shipExternalId,
    } as SearchFilters;
  }, [query]);
}
