import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { MarkdownPreview } from 'react-marked-markdown';

import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import { Box, Button, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';

import AIGeneratorButton, { AIContext } from '~/components/AIGenerator/AIGenerateButton';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';
import { TraverseObject } from '~/components/DebugModal/utils';

import { humanize } from '~/utils/humanize';
import isContentEmpty from '~/utils/isContentEmpty';

export interface CuratedFieldProps {
  initialValue: string | null;
  isSaving?: boolean;
  fieldName: string;
  referenceData: {
    [key: string]: any;
  };
  aiContext: AIContext;
  // allow for single property updating or multiple fields in a contiguous form
  isSingleProperty?: boolean;
  onChange?: (value: string) => void;
  showAIGeneratorButton?: boolean;
  customTitle?: string;
  markdownPreviewAPIData?: boolean;
}

export default function CuratedFieldPane({
  isSaving = false,
  initialValue,
  fieldName,
  referenceData,
  aiContext,
  isSingleProperty = true,
  onChange,
  showAIGeneratorButton = true,
  customTitle,
  markdownPreviewAPIData = false,
}: CuratedFieldProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState<boolean>(!isSingleProperty);
  const [showReference, setShowReference] = useState<boolean>(true);
  const [value, setValue] = useState<string | null>(initialValue);
  // The markdown preview component is a bit finicky and needs a key to re-render when the value changes externally
  const [aiGeneratedContentCount, setAiGeneratedContentCount] = useState<number>(0);

  const attemptSave = async () => {
    try {
      onChange && onChange(value);
      setIsEditing(false);
    } catch (err) {
      if (isSingleProperty) {
        console.warn(err);
      }
    }
  };

  const updateValue = useCallback(
    (value: string) => {
      setValue(value);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const chooseAiContent = useCallback(
    (content: string) => {
      updateValue(content);
      enqueueSnackbar('New content will be saved when you click the save button', { variant: 'info' });
      setAiGeneratedContentCount((currentState) => currentState + 1);
    },
    [enqueueSnackbar, updateValue],
  );

  return (
    <>
      <PageSubheader title={customTitle || humanize(fieldName)}>
        {isSingleProperty && (
          <IconButton onClick={() => setIsEditing(!isEditing)}>
            <ModeEditTwoToneIcon />
          </IconButton>
        )}
      </PageSubheader>
      {isSingleProperty && (
        <>
          {!isEditing && value && <MarkdownPreview value={value} />}
          {!isEditing && !value && (
            <Typography>
              <em>Not currently set</em>
            </Typography>
          )}
        </>
      )}
      {(isEditing || !isSingleProperty) && (
        <>
          <MarkdownEditor key={aiGeneratedContentCount} value={value} onChange={updateValue} />
          {showAIGeneratorButton && (
            <AIGeneratorButton
              context={aiContext}
              content={value || Object.values(referenceData).join('\n')}
              onChoose={chooseAiContent}
              buttonLabel={(value ? 'Rewrite' : 'Generate') + ' ' + humanize(fieldName)}
              sx={{ m: 2, p: 0 }}
            />
          )}
          {isSingleProperty && (
            <Box>
              <Button
                sx={{ m: 2 }}
                variant="outlined"
                onClick={() => {
                  updateValue(initialValue); // reset to original if cancelled
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
              <Button sx={{ m: 2 }} disabled={isSaving} variant="contained" onClick={() => attemptSave()}>
                Save
              </Button>
            </Box>
          )}
          {referenceData && Array.isArray(referenceData) && referenceData.length > 0 && (
            <Card sx={{ mb: 2 }} variant="outlined">
              <CardHeader
                title="Connection Data"
                action={
                  <Button onClick={() => setShowReference(!showReference)}>{showReference ? 'Hide' : 'Show'}</Button>
                }
              />
              {showReference && (
                <CardContent>
                  {Array.isArray(referenceData) && (
                    <>
                      <Typography sx={{ textTransform: 'capitalize' }}>
                        <h4>{fieldName}</h4>
                      </Typography>
                      {referenceData.map((item) => {
                        return (
                          <div key={item}>
                            <li>{item}</li>
                          </div>
                        );
                      })}
                    </>
                  )}
                </CardContent>
              )}
            </Card>
          )}
          {referenceData && !Array.isArray(referenceData) && Object.keys(referenceData).length > 0 && (
            <Card sx={{ mb: 2 }} variant="outlined">
              <CardHeader
                title="Data from API"
                action={
                  <Button onClick={() => setShowReference(!showReference)}>{showReference ? 'Hide' : 'Show'}</Button>
                }
              />
              {showReference && (
                <CardContent>
                  {referenceData &&
                    Object.keys(referenceData).length > 0 &&
                    Object.keys(referenceData).map((key) => {
                      return (
                        <div key={key}>
                          <h4>{humanize(key)}</h4>
                          {referenceData[key] && !isContentEmpty(referenceData[key]) && (
                            <>
                              {!markdownPreviewAPIData && <TraverseObject obj={referenceData[key]} />}
                              {markdownPreviewAPIData && <MarkdownPreview value={referenceData[key]} />}
                            </>
                          )}
                          {referenceData[key] && isContentEmpty(referenceData[key]) && (
                            <Typography>
                              <em>No reference data available</em>
                            </Typography>
                          )}
                        </div>
                      );
                    })}
                </CardContent>
              )}
            </Card>
          )}
        </>
      )}
    </>
  );
}
