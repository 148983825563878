import React, { useState } from 'react';

import { useHistory } from 'react-router';

import { Button, DialogActions, DialogContent } from '@mui/material';

import { getQuoteUpdate } from '~/services/OrdersService';
import { formatDateSlashes } from '~/services/TimeService';

import ErrorListDisplay from '../../../Common/ErrorListDisplay';

import type { InsuranceForm } from './StepForm';

export type PaymentData = {
  updateId: string;
  totalDiff: number;
  coveredAmount: number;
};

type Props = {
  order: App.Order;
  currentInsurance: App.InsuranceItem;
  updateData: InsuranceForm;
  totalTicketPrice: number;
  onContinue: (data: PaymentData) => void;
  onClose: () => void;
};

export default function StepConfirm(props: Props) {
  const { order, currentInsurance, updateData, totalTicketPrice } = props;

  const [processing, setProcessing] = useState(false);
  const [apiErrors, setApiErrors] = useState([]);
  const history = useHistory();

  const handleStep = async () => {
    try {
      setProcessing(true);

      const { result } = await getQuoteUpdate(order, {
        subscriptionId: currentInsurance.id_subscription,
        ...updateData,
      });

      if (result.total_price_diff > 0) {
        history.push(
          `/users/${order.fk_customer_id}/edit-orders/${currentInsurance.fk_order_id}/insurance/${currentInsurance.id_insurance_items}/insurance-information`,
          {
            updateId: result.update_id,
            amount: result.total_price_diff,
            ...updateData,
          },
        );
      } else {
        props.onContinue({
          coveredAmount: updateData.coveredAmount,
          updateId: result.update_id,
          totalDiff: result.total_price_diff,
        });
      }
    } catch (e) {
      setApiErrors([e.message]);
    } finally {
      setProcessing(false);
    }
  };

  const dateFormatter = (date) => {
    return formatDateSlashes(date);
  };

  return (
    <>
      <DialogContent>
        <p>
          <strong>Current product:</strong> <br />
          {currentInsurance.product_name}
        </p>

        {updateData.coveredAmount && updateData.coveredAmount !== totalTicketPrice && (
          <div className="mt-20">
            <p>
              <strong>Previous coverage amount:</strong> {totalTicketPrice}
              <br />
              <strong>New coverage amount:</strong> {updateData.coveredAmount}
            </p>
          </div>
        )}

        {updateData.startDate !== currentInsurance.start_date && (
          <div className="mt-20">
            <p>
              <strong>Previous start date:</strong> {dateFormatter(currentInsurance.start_date)}
              <br />
              <strong>New start date:</strong> {dateFormatter(updateData.startDate)}
            </p>
          </div>
        )}

        {updateData.endDate !== currentInsurance.end_date && (
          <div className="mt-20">
            <p>
              <strong>Previous end date:</strong> {dateFormatter(currentInsurance.end_date)}
              <br />
              <strong>New end date:</strong> {dateFormatter(updateData.endDate)}
            </p>
          </div>
        )}

        {apiErrors.length > 0 && <ErrorListDisplay messages={apiErrors} />}
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={handleStep} disabled={processing}>
          All good, get quote
        </Button>
        <Button onClick={props.onClose} disabled={processing}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}
