import React, { useEffect } from 'react';

import { Box, Button, Stack, TextField, Typography } from '@mui/material';

import useCurrentUser from '~/hooks/useCurrentUser';

import {
  FRAUD_AND_COMPLIANCE_AND_DISPUTE,
  LATE_CANCEL_GOODWILL,
  LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS,
  OPERATOR_CANCELLATION,
  REFUND_TEST_PURCHASE,
  STEP_SELECT_TYPE_OF_REFUND,
  STEP_SUMMARY,
  VARIANT_OF_REFUND_ALL,
  VARIANT_OF_REFUND_PARTIAL,
  VARIANT_OF_REFUND_PARTIAL_CALCULATED,
  VENDOR_REFUND_AGREES_NUMBER,
  VENDOR_REFUND_AGREES_PERCENTAGE,
} from '../../consts/refund';

import ExpiredLETourRefundContainer from './ExpiredLETourRefundContainer';
import FraudAndComplianceAndDisputeContainer from './FraudAndComplianceAndDisputeContainer';
import LateChangeOfMindContainer from './LateChangeOfMindContainer';
import VariantsContainer from './VariantsContainer';

interface AdditionalInfoProps {
  getReasonInfo: () => any;
  handleCheckedChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFlagChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlerOfCalculations: (handlers: any) => void;
  handleTmpChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  tmpValue: any; // replace 'any' with the actual type
  handleVariantChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setBackStep: (step: any) => void;
  stepHandler: (validate: any) => void;
  totalRefund: () => string;
  itemRefund: () => number;
  itemRefundOriginal: any; // replace 'any' with the actual type
  surchargeRefund: () => number;
  surchargeRefundOriginal: any; // replace 'any' with the actual type
  refund: any; // replace 'any' with the actual type
  surchargeInfo: { currency_code: string; price: number }; // replace 'any' with the actual type
  handleCommentChange: (comment: string) => void;
}

function AdditionalInfo(props: AdditionalInfoProps) {
  const reason = props.getReasonInfo();
  const { user } = useCurrentUser();

  useEffect(() => {
    if (props.refund.reason === REFUND_TEST_PURCHASE) {
      props.handleCommentChange(user?.fullName);
    }
    props.setBackStep(STEP_SELECT_TYPE_OF_REFUND);
  }, []);

  const getLabel = () => (
    <label>
      {props.refund.reason === REFUND_TEST_PURCHASE
        ? 'Name of agent processing refund:'
        : reason.key === LATE_CANCEL_GOODWILL || reason.key === OPERATOR_CANCELLATION
        ? 'Reason:'
        : "Customer's reason:"}
    </label>
  );

  const handler = () => {
    const { refund, handlerOfCalculations, surchargeInfo, tmpValue } = props;

    props.stepHandler(function (error, next) {
      if (!refund.comment) {
        const errorMsg =
          refund.reason === REFUND_TEST_PURCHASE ? "Agent's name must be filled" : "A Customer's reason must be filled";
        error(errorMsg);
        return;
      }
      if (!refund.source) {
        error('A source of fund must be selected');
        return;
      }
      if (!refund.ticket_id) {
        error('A Case ID must be filled');
        return;
      }
      if (!refund.variant_of_refund) {
        error('The refund type must be selected');
        return;
      }

      if (
        refund.has_surcharge &&
        refund.is_refunded_surcharge === null &&
        refund.variant_of_refund === VARIANT_OF_REFUND_ALL
      ) {
        error(`Should the surcharge ${surchargeInfo.currency_code} ${surchargeInfo.price} be refunded to customer?`);
        return;
      }
      if (
        refund.mark_cancelled === null &&
        (refund.variant_of_refund === VARIANT_OF_REFUND_ALL || refund.variant_of_refund === VARIANT_OF_REFUND_PARTIAL)
      ) {
        error('Should the booking be cancelled?');
        return;
      }

      const isExpiredLETourRefund = refund.reason === LATE_CANCEL_GOODWILL || refund.reason === OPERATOR_CANCELLATION;
      if (
        (refund.reason === LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS &&
          refund.variant_of_refund === VARIANT_OF_REFUND_ALL) ||
        isExpiredLETourRefund
      ) {
        handlerOfCalculations(['fee', 'surcharge']);
      } else if (
        refund.variant_of_refund === VARIANT_OF_REFUND_PARTIAL ||
        refund.variant_of_refund === VARIANT_OF_REFUND_PARTIAL_CALCULATED
      ) {
        if (refund.reason === LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS) {
          if (
            refund.refund_vendor_agrees_to === VENDOR_REFUND_AGREES_NUMBER &&
            (refund.number_of_nights <= 0 || refund.number_of_nights > refund.total_nights)
          ) {
            error('Number of nights cannot be zero');
            return;
          }

          if (
            refund.refund_vendor_agrees_to === VENDOR_REFUND_AGREES_PERCENTAGE &&
            (refund.percentage <= 0 || refund.percentage > 100)
          ) {
            error('Percentage cannot be zero');
            return;
          }

          if (tmpValue('amount') !== '' && Number(tmpValue('amount')) === 0) {
            error('Refund Amount must be more than 0');
            return;
          }

          if (!refund.variant_of_refund) {
            error('The refund type must be selected');
            return;
          }
        }

        handlerOfCalculations(['cash']);
      }

      next(STEP_SUMMARY);
    });
  };

  return (
    <Stack spacing={2}>
      <Box>
        <h4>{reason.key}</h4>
        <Typography>{reason.description}</Typography>
      </Box>
      <Stack spacing={2}>
        <Stack>
          {getLabel()}

          <TextField
            name="comment"
            id="comment"
            value={props.refund.comment}
            onChange={props.handleValueChange}
            placeholder={
              props.refund.reason === REFUND_TEST_PURCHASE
                ? 'Name required for auditing purposes'
                : 'Brief explanation of why the customer is requesting a refund'
            }
            minRows={2}
            multiline
          />
        </Stack>

        <Box>
          <TextField
            label="Case ID"
            name="ticket_id"
            value={props.refund.ticket_id}
            onChange={props.handleValueChange}
          />
        </Box>
      </Stack>

      {reason.can_select_variant && (
        <VariantsContainer
          handleFlagChange={props.handleFlagChange}
          handleTmpChange={props.handleTmpChange}
          tmpValue={props.tmpValue}
          handleValueChange={props.handleValueChange}
          handleVariantChange={props.handleVariantChange}
          totalRefund={props.totalRefund}
          itemRefund={props.itemRefund}
          itemRefundOriginal={props.itemRefundOriginal}
          surchargeInfo={props.surchargeInfo}
          refund={props.refund}
        />
      )}

      {reason.key === LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS && (
        <LateChangeOfMindContainer
          handleFlagChange={props.handleFlagChange}
          handleTmpChange={props.handleTmpChange}
          tmpValue={props.tmpValue}
          handleValueChange={props.handleValueChange}
          handleVariantChange={props.handleVariantChange}
          totalRefund={props.totalRefund}
          itemRefund={props.itemRefund}
          itemRefundOriginal={props.itemRefundOriginal}
          surchargeInfo={props.surchargeInfo}
          refund={props.refund}
        />
      )}

      {(reason.key === LATE_CANCEL_GOODWILL || reason.key === OPERATOR_CANCELLATION) && (
        <ExpiredLETourRefundContainer
          handleFlagChange={props.handleFlagChange}
          handleTmpChange={props.handleTmpChange}
          tmpValue={props.tmpValue}
          handleVariantChange={props.handleVariantChange}
          surchargeInfo={props.surchargeInfo}
          refund={props.refund}
        />
      )}

      {reason.key === FRAUD_AND_COMPLIANCE_AND_DISPUTE && (
        <FraudAndComplianceAndDisputeContainer
          handleFlagChange={props.handleFlagChange}
          handleValueChange={props.handleValueChange}
          refund={props.refund}
        />
      )}

      <Button variant="contained" className="T-submit" onClick={handler}>
        Continue
      </Button>
    </Stack>
  );
}

export default AdditionalInfo;
