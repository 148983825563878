import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import OffersService from '../../../services/OffersService';
import { reportError } from '../../../utils/reportError';
import ImagesForm from '../../Common/Forms/ImagesForm';

import CopyOfferImagesModal from './CopyOfferImagesModal';

export default class ImagesEditContainer extends React.Component {
  constructor(props) {
    super(props);

    const { match } = props;
    const { id_offer: offerId } = match.params;

    this.state = {
      isCopyModalVisible: false,
      images: [],
      offerDetails: {},
      offerId: offerId,
      isReady: false,
    };
  }

  fetchData = () => {
    Promise.all([OffersService.getImages(this.state.offerId), OffersService.getOffer(this.state.offerId)])
      .then((data) => {
        const mappedImages = data[0].result.map((image) =>
          Object.assign({}, image, {
            id: image.id_image,
          }),
        );

        this.setState({
          offerDetails: data[1].result,
          images: mappedImages,
          isReady: true,
        });
      })
      .catch((error) => {
        reportError(error);
      });
  };

  onAddImage = (cloudinaryId, filename) => {
    return OffersService.createImage(
      {
        id_cloudinary_external: cloudinaryId,
        filename: filename ? filename : undefined,
      },
      this.state.offerId,
    ).then((newImage) => {
      newImage.id = newImage.id_image;
      return newImage;
    });
  };

  onUpdateImages = (newImageList) => {
    const updatePayload = newImageList.map((image) => ({
      id_image: image.id,
      id_cloudinary_external: image.id_cloudinary_external,
      order: image.order,
      title: image.title,
    }));

    return OffersService.updateImages(updatePayload, this.state.offerId);
  };

  onUpdateImage = (image) => {
    const payload = {
      id_image: image.id,
      id_cloudinary_external: image.id_cloudinary_external,
      order: image.order,
      title: image.title,
    };

    return OffersService.updateImage(this.state.offerId, image.id, payload);
  };

  onDeleteImage = (imageId) => {
    return OffersService.deleteImage(this.state.offerId, imageId);
  };

  showCopyModal = () => {
    this.setState({
      isCopyModalVisible: true,
    });
  };

  copyOfferImagesData = () => {
    OffersService.getImages(this.state.offerId)
      .then((images) => {
        const mappedImages = images.result.map((image) =>
          Object.assign({}, image, {
            id: image.id_image,
          }),
        );

        this.setState({
          images: mappedImages,
          isReady: true,
        });

        this.hideCopyModal();
      })
      .catch((error) => {
        reportError(error);
      });
  };

  hideCopyModal = () => {
    this.setState({
      isCopyModalVisible: false,
    });
  };

  componentDidMount = () => {
    this.fetchData();
  };

  setAsNotReady = () => {
    this.setState({
      isReady: false,
    });
  };

  render() {
    const { offerId, images, isCopyModalVisible, isReady } = this.state;

    const formImages = images.map((i) => ({
      ...i,
      publicImageId: i.id_cloudinary_external,
    }));

    return (
      <div className="offer-edit container">
        <Helmet>
          <title>Offers | {this.state.offerDetails?.name || offerId} | Edit Offer Images</title>
        </Helmet>
        <Button component={Link} startIcon={<ChevronLeftIcon />} to={'/offers/' + offerId}>
          Return to offer
        </Button>
        <h1 className="page-header">
          Edit Offer Images
          <Button startIcon={<ContentCopyIcon size="large" />} onClick={this.showCopyModal} className="copy-button">
            <span className="sr-only">Copy Offer</span>
          </Button>
        </h1>
        {isReady && (
          <div>
            <ImagesForm
              images={formImages}
              onAddImage={this.onAddImage}
              onUpdateImages={this.onUpdateImages}
              onDeleteImage={this.onDeleteImage}
            />
            <CopyOfferImagesModal
              isCopyModalVisible={isCopyModalVisible}
              setAsNotReady={this.setAsNotReady}
              hideCopyModal={this.hideCopyModal}
              copyOfferImagesData={this.copyOfferImagesData}
              onAddImage={this.onAddImage}
              currentOfferStatus={this.state.offerDetails.status}
            />
          </div>
        )}
        {!isReady && <Spinner />}
      </div>
    );
  }
}
