import { CruisesContract, paths } from '@luxuryescapes/contract-svc-cruise';

import SearchService, { PutCruiseRecommendation } from '../SearchService';

import cruisesAPI from './CruisesApiService';

const resource = 'offers';
export type OfferResponse = CruisesContract.Response<CruisesContract.Offer>;
type OffersResponse = CruisesContract.Response<CruisesContract.OfferSummary[]>;
type RecommendationList = {
  offer_id: string;
  recommendation: number;
};
type RecommendationListResponse = CruisesContract.Response<RecommendationList[]>;

type OfferSummaryQueryParams = paths['/api/cruise/v1/offers/{offerId}/summary']['get']['parameters']['query'];
type OfferSummaryResponse =
  paths['/api/cruise/v1/offers/{offerId}/summary']['get']['responses']['200']['content']['application/json'];

const noCacheHeader = {
  'Cache-Control': 'no-cache',
};

const getList = async (): Promise<OffersResponse> => {
  const query: CruisesContract.OfferParams = {
    retainDynamicTags: true,
  };

  const params = {
    resource,
    queryString: JSON.stringify(query),
  };

  return await cruisesAPI.http.get(params, noCacheHeader);
};

const getListWithPagination = async (paginationParams: CruisesContract.OfferParams): Promise<OffersResponse> => {
  const query: CruisesContract.OfferParams = {
    retainDynamicTags: true,
  };

  const params = {
    resource,
    paginationParams,
    queryString: JSON.stringify(query),
  };

  return await cruisesAPI.http.get(params, noCacheHeader);
};

const getSummaryById = async (id: string): Promise<OfferSummaryResponse> => {
  const query: OfferSummaryQueryParams = {
    allowSoldOut: true,
    currencyCode: 'AUD',
  };

  const params = {
    resource: `${resource}/${id}/summary`,
    queryString: JSON.stringify(query),
  };

  return await cruisesAPI.http.get(params, noCacheHeader);
};

const getById = async (id: string): Promise<OfferResponse> => {
  const query: CruisesContract.OfferByIdParams = {
    retainDynamicTags: true,
  };

  const params = {
    resource: `${resource}/${id}`,
    queryString: JSON.stringify(query),
  };

  return await cruisesAPI.http.get(params, noCacheHeader);
};

type CurationByIdParams = {
  status: CruisesContract.OfferStatus;
  slug?: string;
  leExclusive?: boolean;
  disableDeposits?: boolean;
  showPricePerNight?: boolean;
  luxPremiumCollection?: boolean;
  editableSailingName?: string;
  editableSailingDescription?: string;
  heroImageId?: string;
  campaigns?: string[];
  leadDepartureId: string | null;
};

const setCurationById = async (id: string, params: CurationByIdParams): Promise<OfferResponse> => {
  return await cruisesAPI.http.post({
    resource: `${resource}/${id}/curation`,
    body: {
      slug: params.slug,
      status: params.status,
      leExclusive: params.leExclusive,
      displayName: params.editableSailingName,
      leVisibleDescription: params.editableSailingDescription,
      heroImageId: params.heroImageId,
      disableDeposits: params.disableDeposits,
      showPricePerNight: params.showPricePerNight,
      campaigns: params.campaigns,
      luxPremiumCollection: params.luxPremiumCollection,
      leadDepartureId: params.leadDepartureId,
    },
  });
};

const setSortOffers = async (params: PutCruiseRecommendation[]): Promise<OfferResponse> => {
  return await SearchService.putCruiseRecommendation(params);
};

const getSortedOffers = async (): Promise<RecommendationListResponse> => {
  return await SearchService.getCruisesRecommendation();
};

interface setOffers {
  id: string;
  status: string;
}

const setOffersToApproved = async (params: { offers: setOffers[] }): Promise<OfferResponse> => {
  return await cruisesAPI.http.post({
    resource: `${resource}/bulk-update`,
    body: params,
  });
};

const getDynamicOfferImages = async (
  offerId: string,
): Promise<CruisesContract.Response<CruisesContract.OfferImage[]>> => {
  const response = await cruisesAPI.http.get<CruisesContract.OfferImage[]>({
    resource: `${resource}/${offerId}/dynamic-images`,
  });

  return {
    ...response,
    result: response.result,
  };
};

const getOfferImages = async (offerId: string): Promise<CruisesContract.Response<CruisesContract.OfferImage[]>> => {
  const response = await cruisesAPI.http.get<CruisesContract.OfferImage[]>({
    resource: `${resource}/${offerId}/images`,
  });

  return {
    ...response,
    result: response.result,
  };
};

const createOfferImage = async (
  offerId: string,
  image: {
    imageId: string;
    order: number;
  },
): Promise<CruisesContract.OfferImage> => {
  const response = await cruisesAPI.http.post<CruisesContract.Response<CruisesContract.OfferImage>>({
    resource: `${resource}/${offerId}/images`,
    body: image,
  });
  return response.result;
};

const updateOfferImages = async (
  offerId: string,
  images: {
    idImage: string;
    idCloudinaryExternal: string;
    order: string;
  }[],
) => {
  await cruisesAPI.http.patch({
    resource: `${resource}/${offerId}/images`,
    body: { images },
  });
};

const deleteOfferImage = async (offerId: string, imageId: string) => {
  await cruisesAPI.http.delete({
    resource: `${resource}/${offerId}/images/${imageId}`,
  });
};

type RebuildOffer = {
  toOfferId: string;
  fromOfferId: string;
  processedSailings: {
    id: string;
    externalId: string;
    cruiseOfferId: string;
    name: string;
    departureDate: string;
    shipId: number;
    vendorId: number;
    status: string;
    sailingPlan: string | null;
    serialized: string;
    syncStatus: string;
    requiresApproval: boolean;
  }[];
};
type RebuildOfferResponse = CruisesContract.Response<RebuildOffer>;

const rebuildOffer = async (offerId: string): Promise<RebuildOfferResponse> => {
  return await cruisesAPI.http.post({
    resource: `${resource}/${offerId}/rebuild`,
  });
};

export default {
  getById,
  getSummaryById,
  getList,
  getListWithPagination,
  setCurationById,
  setSortOffers,
  getSortedOffers,
  setOffersToApproved,
  getOfferImages,
  createOfferImage,
  updateOfferImages,
  deleteOfferImage,
  getDynamicOfferImages,
  rebuildOffer,
};
