import qs from 'qs';

import { Request as RequestContract, Tour as ResponseContract } from '@luxuryescapes/contract-svc-tour';

import { json_headers, request } from './common';

interface InitialReqInstance {
  status: 'uninitiated';
}

interface PendingReqInstance<T> {
  status: 'pending';
  result?: T;
}

interface FailedReqInstance<T> {
  status: 'failed';
  error: T;
}

interface SucceededReqInstance<T> {
  status: 'succeeded';
  result?: T;
}

export type ReqInstance<R = unknown, E = unknown> =
  | InitialReqInstance
  | PendingReqInstance<R>
  | FailedReqInstance<E>
  | SucceededReqInstance<R>;

export type TourIndex = ResponseContract.TourIndex;

interface IIndexResponse {
  status: number;
  message: string;
  result: TourIndex;
}

interface ITourResponse {
  status: number;
  message: string;
  result: ResponseContract.Tour;
}

interface HttpResponse<T> {
  status: number;
  message: string;
  result: T;
}

export function basePath() {
  return window.configs.API_HOST + '/api/v2/tours';
}

export async function getTours(params: RequestContract.GetToursIndexQuery): Promise<IIndexResponse> {
  const url = `${basePath()}?${qs.stringify(params)}`;

  return request(url, {
    method: 'GET',
  });
}

export async function getTourById(id): Promise<ITourResponse> {
  const url = `${basePath()}/${id}`;
  return request(url, {
    method: 'GET',
  });
}

export async function updateTour(id, data): Promise<void> {
  const url = `${basePath()}/${id}`;
  const jsonData = JSON.stringify(data);

  return request(url, {
    method: 'PATCH',
    headers: json_headers,
    body: jsonData,
  });
}

export async function bulkUpdateDepositAmount(brand, data): Promise<void> {
  const url = `${basePath()}/deposits/${brand}`;
  const jsonData = JSON.stringify(data);

  return request(url, {
    method: 'PATCH',
    headers: json_headers,
    body: jsonData,
  });
}

export async function getBookingInfo(reservationId) {
  return request(basePath() + `/reservations/${reservationId}/snapshot`, {
    method: 'GET',
    headers: json_headers,
  });
}

export async function getCountriesVisitedList(): Promise<HttpResponse<{ countries: Array<string> }>> {
  return request(basePath() + `/filters/countries`, {
    method: 'GET',
    headers: json_headers,
  });
}

export function getTourCategoriesSuggestion(tourId: string) {
  return request(basePath() + `/${tourId}/categories/suggest`, {
    method: 'GET',
    headers: json_headers,
  });
}

export function downloadTourOptionPriceHistory(tourOptionId: string) {
  return request(basePath() + `/price-history/${tourOptionId}`, {
    method: 'GET',
    download: true,
  });
}
