import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@mui/material';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { LE_AGENT_HUB, ledBrands } from '../../../../consts/brands';
import type { Brand } from '../../../../consts/brands';
import OffersService from '../../../../services/OffersService';
import { buttonMessages, buttonStates } from '../../../Common/Forms/states/submitButton';
import ScheduleTableRow from '../common/ScheduleTableRow';
import { ScheduleUpdateResult } from '../common/types';
import { getLEDBrandName, updateResultToErrorMessage } from '../common/utils';

function scheduleMatchesBrand(schedule: App.Schedule, brand: Brand) {
  return schedule.brand === brand.value && schedule.region === brand.region.code;
}

function scheduleMatchesAnyLEDBrand(schedule: App.Schedule) {
  return ledBrands.some((brand) => scheduleMatchesBrand(schedule, brand));
}

interface Props {
  schedules: App.Schedule[];
  type: string;
  shouldMigrateSchedule: boolean;
  onSaveFinished: () => void;
  onDelete: (id: number) => void;
  onError: (error: Error) => void;
  onUpdateErrors: (error: string[]) => void;
  offerId: string;
}

interface ScheduleState {
  modified: boolean;
  schedule: App.Schedule;
}

async function updateSchedules(scheduleStates: ScheduleState[], offerId: string): Promise<ScheduleUpdateResult[]> {
  const scheduleStatesToUpdate = scheduleStates.filter((s) => s.modified);

  const updates = scheduleStatesToUpdate.map((s) => {
    const { id, start, end, region, brand, type } = s.schedule;
    const updatePayload = {
      start,
      end,
      region,
      brand,
      type,
    };
    return OffersService.updateSchedule(updatePayload, offerId, id);
  });

  const results = await Promise.allSettled(updates);

  return results.map((result, i) => ({
    label: getLEDBrandName(scheduleStatesToUpdate[i].schedule.brand),
    result,
  }));
}

function scheduleBrandNameCompare(a: App.Schedule, b: App.Schedule) {
  return a.brand.localeCompare(b.brand);
}

export default function LEDScheduleTable(props: Props) {
  const { schedules, type, shouldMigrateSchedule, onSaveFinished, onDelete, onUpdateErrors, offerId } = props;

  const [saveState, setSaveState] = useState(buttonStates.default);
  const [ledScheduleStates, setLedSchedules] = useState<ScheduleState[]>([]);

  const cleanState = useCallback(() => {
    const ledScheduleStates = schedules
      .filter((s) => s.type === type && scheduleMatchesAnyLEDBrand(s) && ![LE_AGENT_HUB].includes(s.brand))
      .sort(scheduleBrandNameCompare)
      .map((schedule) => ({
        modified: false,
        schedule: { ...schedule },
      }));
    setLedSchedules(ledScheduleStates);
  }, [schedules]);

  useEffect(cleanState, [schedules]);

  const onScheduleChange = useCallback(
    (schedule: App.Schedule) => {
      const newLeScheduleStates = ledScheduleStates.map((s) => {
        if (s.schedule.id === schedule.id) {
          return { modified: true, schedule };
        }
        return s;
      });
      setLedSchedules(newLeScheduleStates);
    },
    [ledScheduleStates, setLedSchedules],
  );

  const anyModified = ledScheduleStates.filter((s) => s.modified).length > 0;

  const saveChanges = useCallback(async () => {
    setSaveState(buttonStates.saving);

    const updateResults = await updateSchedules(ledScheduleStates, offerId);
    onSaveFinished();

    if (updateResults.some((r) => r.result.status === 'rejected')) {
      const updateErrorMessages = updateResults.map(updateResultToErrorMessage).filter((m) => m);
      onUpdateErrors(updateErrorMessages);
    }

    setSaveState(buttonStates.default);
  }, [ledScheduleStates, offerId, onUpdateErrors]);

  const saving = saveState === buttonStates.saving;

  return (
    <div>
      <table className="fp-schedule-table">
        <thead>
          <tr>
            <th>Brand</th>
            <th>Schedule</th>
          </tr>
        </thead>
        <tbody>
          {ledScheduleStates.map(({ schedule, modified }) => (
            <ScheduleTableRow
              label={getLEDBrandName(schedule.brand)}
              onChange={onScheduleChange}
              isModified={modified}
              key={schedule.id}
              schedule={schedule}
              type={type}
              disabled={shouldMigrateSchedule || saving}
              onDelete={() => onDelete(schedule.id)}
            />
          ))}
        </tbody>
      </table>

      <div className="fp-schedule-buttons">
        <Button onClick={cleanState} disabled={!anyModified || saving}>
          Clear changes
        </Button>
        <PermissionedComponent>
          <Button
            variant="contained"
            onClick={saveChanges}
            disabled={!anyModified || saveState !== buttonStates.default}
          >
            {buttonMessages[saveState]}
          </Button>
        </PermissionedComponent>
      </div>
    </div>
  );
}
