import React from 'react';

import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEditInputCell } from '@mui/x-data-grid';

import CopyableField from '~/components/Common/CopyableField';
import GridPagination from '~/components/Common/Elements/GridPagination';
import Spinner from '~/components/Common/Spinner';
import DebugModal from '~/components/DebugModal/DebugModal';
import dateFormatterDetailed from '~/components/Purchases/Home/formatters/dateFormatterDetailed';

import { DEFAULT_PAGE_SIZES } from '~/consts/filters';

import useQuery from '~/hooks/useQuery';

import { PromoUser } from '~/services/PromoService';

import PromoCodeUser from '../formatters/PromoCodeUser';
import usePromoUsers from '../hooks/usePromoUsers';

interface Props {
  promoId: string;
  initUserId?: string;
  initPage?: number;
  initLimit?: number;
  handleUserIdFilterSet?: (userId: string) => void;
}

const PromoUsers: React.FC<Props> = ({ promoId, initUserId, initPage = 1, initLimit = 10 }) => {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const query = useQuery();

  const isDevMode = !!query.get('isDev');

  const { promoUsers, totalRows, isLoading, promoUsersFilter, setPromoUsersFilter } = usePromoUsers({
    initPage,
    initLimit,
    initBrand: brand,
    initPromoId: promoId,
    initUserId,
  });

  const onPageChange = async (page, pageSize) => {
    setPromoUsersFilter({
      ...promoUsersFilter,
      page,
      limit: pageSize,
    });
  };

  const sharedOpts: Partial<GridColDef<PromoUser>> = {
    editable: false,
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    flex: 2,
  };

  const getColumns = (): GridColDef<PromoUser>[] => [
    {
      field: 'id_promo_code_user',
      headerName: 'ID',
      renderCell: (params) => <Typography>{params.row.id_promo_code_user}</Typography>,
      ...sharedOpts,
    },
    {
      field: 'created_at',
      headerName: 'Created',
      editable: true,
      renderCell: (params) => dateFormatterDetailed(params.row.created_at),
      ...sharedOpts,
    },
    {
      field: 'updated_at',
      headerName: 'Updated',
      editable: true,
      renderCell: (params) => dateFormatterDetailed(params.row.updated_at),
      ...sharedOpts,
    },
    {
      field: 'promo_code_user_type',
      headerName: 'Type',
      renderCell: (params) => <Typography>{params.row.promo_code_user_type}</Typography>,
      renderEditCell: (params) => <input value={params.row.promo_code_user_type}></input>,
      ...sharedOpts,
    },
    {
      field: 'external_ids',
      headerName: 'External Ids',
      renderCell: (params) => <Typography>{params.row.external_ids}</Typography>,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            min: 1,
          }}
          sx={{
            border: '1px solid',
            borderColor: 'rgb(190,190,190)',
            height: '83%',
            borderRadius: '4px',
            paddingLeft: '1rem',
          }}
          fullWidth
        />
      ),
      ...sharedOpts,
    },
    {
      field: 'user_id',
      headerName: 'User ID',
      renderCell: (params) => <Typography>{params.row.user_id}</Typography>,
      ...sharedOpts,
    },
    {
      field: 'remaining_uses',
      headerName: 'Remaining Uses',
      editable: true,
      renderCell: (params) => <Typography>{params.row.remaining_uses}</Typography>,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            min: 1,
          }}
          sx={{
            border: '1px solid',
            borderColor: 'rgb(190,190,190)',
            height: '83%',
            borderRadius: '4px',
            paddingLeft: '1rem',
          }}
          fullWidth
        />
      ),
      ...sharedOpts,
    },
    {
      field: 'expires_at',
      headerName: 'Expires',
      editable: true,
      renderCell: (params) => dateFormatterDetailed(params.row.expires_at),
      ...sharedOpts,
    },
    {
      field: 'modified_by',
      headerName: 'Modified By',
      renderCell: (params) => PromoCodeUser({ userId: params.row.modified_by }),
    },
    {
      field: 'debug',
      headerName: 'Raw Data',
      renderCell: (params) => (
        <CopyableField
          value={JSON.stringify(params.row, null, 4)}
          label={<DebugModal type="generic" data={params.row ?? { data: 'None' }} />}
        />
      ),
      ...sharedOpts,
    },
  ];

  return (
    <Box m={2}>
      {!isLoading ? (
        <DataGrid
          columns={getColumns()}
          autoHeight
          getRowId={(row) => row.id_promo_code_user}
          rowCount={totalRows}
          rows={promoUsers || []}
          pagination
          paginationMode="server"
          paginationModel={{ page: promoUsersFilter.page, pageSize: promoUsersFilter.limit }}
          slots={{ pagination: GridPagination }}
          pageSizeOptions={DEFAULT_PAGE_SIZES}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
          columnVisibilityModel={{
            id_promo_code_user: isDevMode,
          }}
          getRowHeight={() => 'auto'}
        />
      ) : (
        <Spinner size={36} />
      )}
    </Box>
  );
};

export default PromoUsers;
