import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Container, Menu, MenuItem, Stack } from '@mui/material';

import { CruiseOffersProvider } from '~/components/Cruises/context/useCruiseOffers';
import RateCodeCreationPage from '~/components/Cruises/pages/RateCodeCreation/RateCodeCreationPage';

import CruiseLines from '../CruiseLines/CruiseLinesPage';
import PortsPage from '../Ports/PortsPage';
import PromoBannerPage from '../PromoBanner/PromoBannerPage';
import PromotionManagementPage from '../Promotions/PromotionManagementPage';
import RateCodeManagementPage from '../RateCodeManagement/RateCodeManagementPage';
import ShipsPage from '../Ships/ShipsPage';

import BookingsPage from './components/Bookings/BookingsPage';
import CabinCategoriesPage from './components/CabinCategories/CabinCategoriesPage';
import CabinCategoriesGroupPage from './components/CabinCategoriesGroup/CabinCategoriesGroupPage';
import CruiseOfferList from './components/CruiseOffers/CruiseOfferList';
import CruiseSearch from './components/CruiseSearch/CruiseSearch';
import SailingPage from './components/Sailings/SailingPage';

type MenuItem = {
  name: string;
  href: string;
  component: React.FC | null;
  includeSearch?: boolean;
};

const CruiseOfferMenu: ReadonlyArray<MenuItem> = [
  { name: 'Flash Offers', href: '/tours/lists/le', component: null },
  { name: 'Cruisebank Offers', href: '/cruises/offers', component: CruiseOfferList, includeSearch: true },
  { name: 'Cruisebank Sailings', href: '/cruises/sailings', component: SailingPage },
] as const;

const CruiseCMSMenu: ReadonlyArray<MenuItem> = [
  { name: 'Cruise Lines', href: '/cruises/cruise-lines', component: CruiseLines },
  { name: 'Ships', href: '/cruises/ships', component: ShipsPage },
  { name: 'Cabin Categories', href: '/cruises/cabin-categories', component: CabinCategoriesPage },
  { name: 'Cabin Categories Groups', href: '/cruises/cabin-categories-groups', component: CabinCategoriesGroupPage },
  { name: 'Ports', href: '/cruises/ports', component: PortsPage },
] as const;

const RatesPromosMenu: ReadonlyArray<MenuItem> = [
  { name: 'Rate Code Management', href: '/cruises/rate-code-management', component: RateCodeManagementPage },
  { name: 'Rate Code Creation', href: '/cruises/rate-code-creation', component: RateCodeCreationPage },
  { name: 'Promotion Management', href: '/cruises/promotion-management', component: PromotionManagementPage },
  { name: 'Promo Banners', href: '/cruises/promo-banners', component: PromoBannerPage },
] as const;

const BookingsMenu: ReadonlyArray<MenuItem> = [
  { name: 'Logs', href: '/cruises/booking-logs', component: BookingsPage },
] as const;

const NavigationMenu = [
  { name: 'Offers', menu: CruiseOfferMenu },
  { name: 'Cruise CMS', menu: CruiseCMSMenu },
  { name: 'Rates & Promos', menu: RatesPromosMenu },
  { name: 'Bookings', menu: BookingsMenu },
];

const setDefaultItem = (locationPathname: string) => {
  const defaultItem = CruiseOfferMenu[1];

  const locationItem = NavigationMenu.flatMap((item) => item.menu).find((item) => item.href === locationPathname);

  return locationItem !== defaultItem ? locationItem : defaultItem;
};

const CruisesPage: React.FC = (): JSX.Element => {
  const location = useLocation();
  const defaultItem = setDefaultItem(location.pathname);
  const [currentItem, setCurrentItem] = React.useState(defaultItem);
  const [currentMenu, setCurrentMenu] = React.useState(null);
  const title = `CRUISE - ${currentItem.name}`;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleNavigation = (event: React.MouseEvent<HTMLElement>, menu: ReadonlyArray<MenuItem>) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(menu);
    setOpen(true);
  };
  const handleMenuClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleMenuClick = (item) => {
    setCurrentItem(item);
    handleMenuClose();
  };
  useEffect(() => {
    setCurrentItem(defaultItem);
  }, [defaultItem]);

  return (
    <CruiseOffersProvider>
      <Container maxWidth="xl">
        <Stack direction="row" spacing={1}>
          {NavigationMenu.map((item) => (
            <Button
              key={item.name}
              onClick={(event) => handleNavigation(event, item.menu)}
              endIcon={<KeyboardArrowDown />}
            >
              {item.name}
            </Button>
          ))}

          <Menu open={open} anchorEl={anchorEl} onClose={handleMenuClose}>
            {currentMenu &&
              currentMenu.map((item) => (
                <MenuItem key={item.name} onClick={() => handleMenuClick(item)} to={item.href} component={Link}>
                  {item.name}
                </MenuItem>
              ))}
          </Menu>
        </Stack>

        <Helmet>
          <title>{title}</title>
        </Helmet>

        {currentItem.includeSearch && <CruiseSearch />}

        <Box mt={3}>
          <Box component={currentItem?.component} />
        </Box>
      </Container>
    </CruiseOffersProvider>
  );
};

export default CruisesPage;
