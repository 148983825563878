import React from 'react';

import { Box } from '@mui/material';

import { ConnectionCancellationPolicies } from '@luxuryescapes/lib-refunds';

import { ITEM_STATUS_CANCELLED } from '~/consts/order';

import CancellationPolicy from './CancellationPolicy';
import ReservationInfoConnectionCancellationPolicies from './ReservationInfoConnectionCancellationPolicies';

type Props = {
  item: App.OrderItem;
  connectionCancellationPolicies?: ConnectionCancellationPolicies | null;
};

const ReservationInfoCancellationPolicies = ({ item, connectionCancellationPolicies }: Props) => {
  const cancellationPolicies = item.cancellation_policies;
  const noCancellationPolicies = !cancellationPolicies || (cancellationPolicies && cancellationPolicies.length === 0);

  const isItemCancelled = item.status === ITEM_STATUS_CANCELLED;

  if (noCancellationPolicies) {
    return <Box>None</Box>;
  }

  if (connectionCancellationPolicies) {
    return (
      <ReservationInfoConnectionCancellationPolicies
        isItemCancelled={isItemCancelled}
        connectionCancellationPolicies={connectionCancellationPolicies}
      />
    );
  }

  return (
    <Box>
      {cancellationPolicies.map((policy, index) => {
        return <CancellationPolicy policy={policy} key={index} isItemCancelled={isItemCancelled} />;
      })}
    </Box>
  );
};

export default ReservationInfoCancellationPolicies;
