import React, { useCallback, useEffect, useMemo } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { Box, Button, Container, Stack } from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import PageHeader from '~/components/Common/Elements/PageHeader';

import ReservationService from '~/services/ReservationService';

import { formatError } from '~/utils/errorFormatter';
import isContentEmpty from '~/utils/isContentEmpty';

import CuratedFieldPane from './CuratedFieldPane';
import cleanProperty from './helpers/cleanProperty';

const fetchProperty = (propertyId) => {
  return ReservationService.getProperty(propertyId);
};

export default function CuratedContentEditPage(props) {
  const { id_property: propertyId, id_vendor: vendorId } = useParams<{ id_property: string; id_vendor: string }>();

  const [property, setProperty] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = React.useState(false);
  const [propertyMeta, setPropertyMeta] = React.useState<Partial<Reservation.Property> | null>(null);
  const [updatedFields, setUpdatedFields] = React.useState<any>({});
  const [loading, setLoading] = React.useState(true);

  const saveDisabled = useMemo(() => {
    return isSaving || isContentEmpty(updatedFields);
  }, [isSaving, updatedFields]);

  useEffect(() => {
    fetchProperty(propertyId)
      .then((propFetch) => {
        setProperty(cleanProperty(propFetch.result));
        fetchMetaData();
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  }, [enqueueSnackbar, propertyId]);

  const doUpdate = useCallback(async () => {
    if (Object.values(updatedFields).length === 0) {
      return;
    }
    try {
      setIsSaving(true);
      if (propertyMeta) {
        await ReservationService.updatePropertyMeta(property.id, updatedFields);
      } else {
        await ReservationService.createPropertyMeta(property.id, updatedFields);
      }
      setIsSaving(false);
      enqueueSnackbar('Saved', {
        variant: 'success',
      });
      await fetchMetaData();
      setUpdatedFields({});
    } catch (err) {
      setIsSaving(false);
      enqueueSnackbar(formatError(err), {
        variant: 'error',
      });
    }
  }, [property, propertyMeta, updatedFields]);

  const fetchMetaData = async () => {
    try {
      const propertyMetaResponse = await ReservationService.getPropertyMeta(propertyId);

      setPropertyMeta(propertyMetaResponse.result);
    } catch (err) {
      // may not exist yet for this property
      if (err.status !== 404) {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      }
      setPropertyMeta(null);
    }
  };

  const updateDirtyFields = (fieldName: string, value: any) => {
    setUpdatedFields({ ...updatedFields, [fieldName]: value });
  };

  return (
    <Container maxWidth="xl">
      <PageHeader title="Edit property curated content" backButton={`/vendors/${vendorId}/properties/${propertyId}`} />
      {!loading && (
        <Stack gap={1}>
          <CuratedFieldPane
            fieldName="content_description"
            customTitle="Property Description"
            initialValue={property.content_description ?? ''}
            aiContext={{
              model: 'offer',
              field: 'description',
              propertyName: property.name,
            }}
            referenceData={{}}
            onChange={(value) => updateDirtyFields('content_description', value)}
            isSaving={isSaving}
            isSingleProperty={false}
          />
          <CuratedFieldPane
            fieldName="fine_print"
            initialValue={property.fine_print}
            aiContext={{
              model: 'offer',
              field: 'fine_print',
              propertyName: property.name,
            }}
            referenceData={{}}
            onChange={(value) => updateDirtyFields('fine_print', value)}
            isSaving={isSaving}
            isSingleProperty={false}
            showAIGeneratorButton={false}
          />
          <CuratedFieldPane
            fieldName="additional_description"
            initialValue={property.additional_description}
            aiContext={{
              model: 'offer',
              field: 'additional_description',
              propertyName: property.name,
            }}
            referenceData={{}}
            onChange={(value) => updateDirtyFields('additional_description', value)}
            isSaving={isSaving}
            isSingleProperty={false}
            showAIGeneratorButton={false}
          />
          <CuratedFieldPane
            fieldName="highlights_description"
            initialValue={property.highlights_description}
            aiContext={{
              model: 'offer',
              field: 'highlights_description',
              propertyName: property.name,
            }}
            referenceData={{}}
            onChange={(value) => updateDirtyFields('highlights_description', value)}
            isSaving={isSaving}
            isSingleProperty={false}
            showAIGeneratorButton={false}
          />
          <CuratedFieldPane
            fieldName="getting_there"
            initialValue={property.getting_there}
            aiContext={{
              model: 'offer',
              field: 'getting_there',
              propertyName: property.name,
            }}
            referenceData={{}}
            onChange={(value) => updateDirtyFields('getting_there', value)}
            isSaving={isSaving}
            isSingleProperty={false}
            showAIGeneratorButton={false}
          />
          <CuratedFieldPane
            fieldName="facilities_description"
            initialValue={property.facilities_description}
            aiContext={{
              model: 'offer',
              field: 'facilities_description',
              propertyName: property.name,
            }}
            referenceData={{}}
            onChange={(value) => updateDirtyFields('facilities_description', value)}
            isSaving={isSaving}
            isSingleProperty={false}
            showAIGeneratorButton={false}
          />

          <Box>
            <Button sx={{ m: 2 }} disabled={saveDisabled} variant="contained" onClick={() => doUpdate()}>
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Stack>
      )}
      {loading && <div>Loading...</div>}
    </Container>
  );
}
