import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import { Box, Container, Tab, Tabs } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import AtolReports from './AtolReports';
import CreditReservationList from './CreditReservationListContainer';
import FDAPage from './FDA/FDAPage';
import FlightsDashboard from './FlightDashboard';
import { FlightForceBundles } from './FlightForceBundles';
import MerchandisingFlightDeals from './MerchandisingFlightDeals';
import PendingReservations from './PendingReservations';

const tabsMap = {
  deals: {
    label: 'Deals',
    component: MerchandisingFlightDeals,
  },
  dashboard: {
    label: 'Dashboard',
    component: FlightsDashboard,
  },
  pending: {
    label: 'Pending',
    component: PendingReservations,
  },
  credit: {
    label: 'Credit Reservation',
    component: CreditReservationList,
  },
  'atol-reports': {
    label: 'ATOL Reports',
    component: AtolReports,
  },
  'force-bundles': {
    label: 'Force Bundles',
    component: FlightForceBundles,
  },
  fda: {
    label: 'Flights Configuration',
    component: FDAPage,
  },
};
const tabsList = Object.keys(tabsMap);

export const FlightsPageContainer = () => {
  const [currentTabKey, setCurrentTabKey] = useState(tabsList[0]);

  const currentTab = tabsMap[currentTabKey];
  const title = `Flights / ${currentTab.label}`;

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader title={title} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTabKey} onChange={(_, selectedTabKey) => setCurrentTabKey(selectedTabKey)}>
          {tabsList.map((tabKey) => (
            <Tab key={tabKey} value={tabKey} label={tabsMap[tabKey].label} />
          ))}
        </Tabs>
      </Box>

      <Box mt={4}>
        <Box component={currentTab.component} />
      </Box>
    </Container>
  );
};

export default FlightsPageContainer;
