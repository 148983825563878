import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';
import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER } from '~/consts/roles';

import { addDays, dateNowUtc, diffDays } from '~/services/TimeService';

import { humanize } from '~/utils/humanize';

import InsuranceSummary from '../../Common/Booking/InsuranceSummary';

import Log from './OrderItem/Log';
import UpdateInsurance from './UpdateInsurance';

function getCoolingOff(createdDate) {
  const date = addDays(21, dateNowUtc(new Date(createdDate)));
  // +1 to include start date
  const daysLeft = diffDays(date) + 1;
  if (daysLeft <= 0) {
    return 'Ended';
  }
  return daysLeft + ' Days Left';
}

function getRefundPermissionGroups(createdDate) {
  const daysPast = diffDays(dateNowUtc(), createdDate, 'days');
  return daysPast > 14 ? [ROLE_ADMIN_USER] : [ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER];
}

export default function OrderInsuranceItemList({
  order,
  items,
  showRefundModal,
  hasAllowedRefund,
  purchaseDate,
  refreshData,
  refunds,
}) {
  return (
    <Box mt={2}>
      <FoldableSection title="Travel insurance" initiallyExpanded>
        <Box className="T-Order-Insurance-Item-List">
          {items.map((item) => (
            <Grid container key={item.id_insurance_items} spacing={2}>
              <Grid xs={12} md={5}>
                <InsuranceSummary
                  name={item.product_name}
                  policyId={item.contract_number}
                  policyStartDate={item.start_date}
                  policyEndDate={item.end_date}
                  pds={item._links && item._links.pds ? item._links.pds.href : ''}
                  coolingOffMessage={getCoolingOff(purchaseDate)}
                  provider={item.provider}
                  coverAmount={item.cover_amount}
                  currencyCode={order.currency_code}
                />
              </Grid>

              <Grid xs={3} md={2}>
                <Typography fontWeight="bold" variant="h6">
                  ${item.total}
                </Typography>
              </Grid>

              <Grid xs={3} md={2}>
                <Typography fontWeight="bold" variant="h6">
                  {humanize(item.status)}
                </Typography>
              </Grid>

              {hasAllowedRefund && item.status !== 'cancelled' && (
                <PermissionedComponent requiredRoles={getRefundPermissionGroups(purchaseDate)}>
                  <Grid xs={2} md={1}>
                    <Button
                      onClick={() => {
                        showRefundModal({ itemId: item.id_insurance_items });
                      }}
                      className="T-issue-refund"
                      variant="text"
                    >
                      Issue refund
                    </Button>
                  </Grid>
                </PermissionedComponent>
              )}

              {item.provider === 'cover_genius' && item.status !== 'cancelled' && (
                <Grid xs={4} md={2}>
                  <UpdateInsurance order={order} currentInsurance={item} onSuccess={refreshData} refunds={refunds} />
                </Grid>
              )}

              <Grid xs={12}>
                <Log orderId={item.fk_order_id} itemId={item.id_insurance_items} />
              </Grid>
            </Grid>
          ))}
        </Box>
      </FoldableSection>
    </Box>
  );
}
