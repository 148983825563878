import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

import { getOrderDatesRequest } from '../../../services/OrdersService';
import Spinner from '../../Common/Spinner';

interface OrderDetailProps {
  orderId: string;
}

const typeFormatter = (cell) => {
  if (cell === 'bnbl') {
    return 'BNBL request';
  }
  return 'Change date request';
};

const dateFormatter = (cell) => {
  const date = new Date(cell);
  return formatDateOrdinalWithClock(date);
};

const renderStatus = (status) => {
  const statusMap = {
    pending: 'warning',
    approved: 'success',
    rejected: 'error',
    customer_booked: 'success',
    customer_cancelled: 'error',
  };
  const textMap = {
    pending: 'Request sent',
    approved: 'Request approved',
    rejected: 'Request rejected',
    customer_booked: 'Customer booked',
    customer_cancelled: 'Customer cancelled',
  };

  return <Alert severity={statusMap[status]}>{textMap[status]}</Alert>;
};

const columns: GridColDef[] = [
  {
    field: 'type',
    headerName: 'Type',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => typeFormatter(value),
    display: 'flex',
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => dateFormatter(value),
    display: 'flex',
  },
  {
    field: 'status',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    renderCell: (params) => {
      return <>{renderStatus(params.value)}</>;
    },
    display: 'flex',
  },
];

const OrderDetailBookingRequests: React.FC<OrderDetailProps> = ({ orderId }) => {
  const [datesRequests, setDatesRequests] = useState<any[]>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    getOrderDatesRequest(orderId)
      .then((data) => setDatesRequests(data))
      .catch(() => setError('Could not load Dates Requests'));
  }, [orderId]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  if (!datesRequests) {
    return <Spinner />;
  }
  if (datesRequests.length === 0) {
    return null;
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        sx={{
          backgroundColor: 'grey.200',
          height: '60px',
        }}
      >
        <Typography>Booking Requests</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DataGrid
          columns={columns}
          rows={datesRequests}
          getRowId={(row) => row.id_dates_request}
          autoHeight
          pageSizeOptions={[]}
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          getRowHeight={() => 'auto'}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderDetailBookingRequests;
