import React from 'react';

import { ROLE_ADMIN_USER } from '~/consts/roles';

import ErrorDisplay from '../components/Common/ErrorDisplay';

const adminPermissionError = {
  name: 'PermissionError',
  message: "You don't have the permissions for this operation",
};

/**
 *
 * @param {*} user the user to check if is admin
 * @returns {boolean} if the user is admin or not
 *
 * @example
 * isAdmin({
 *   roles: ['admin']
 * }
 */
export function isAdmin(user) {
  return user && user.roles && user.roles.includes(ROLE_ADMIN_USER);
}

export function errorMonitor(user) {
  if (!user || !user.roles || !user.roles.includes(ROLE_ADMIN_USER)) {
    throw adminPermissionError;
  }
}

export function errorDisplay(user) {
  if (!user || !user.roles) {
    return null;
  }
  if (!user.roles.includes(ROLE_ADMIN_USER)) {
    return <ErrorDisplay message={adminPermissionError.message} />;
  }
}
