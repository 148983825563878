import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { GetPromoUserFilters, PromoUser, getPromoUsers } from '~/services/PromoService';

type UsePromoUsersProps = {
  initPage?: number;
  initLimit?: number;
  initCodeName?: string;
  initUserId?: string;
  initPromoId?: string;
  initBrand?: string;
  initRegion?: string;
  initExpiresAfter?: string;
};

export default function usePromoUsers({
  initPage,
  initLimit,
  initUserId,
  initPromoId,
  initBrand = 'luxuryescapes',
  initRegion = 'AU',
  initExpiresAfter,
}: UsePromoUsersProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [promoUsers, setPromoUsers] = useState<Array<PromoUser>>([]);

  const [promoUsersFilter, setPromoUsersFilter] = useState<GetPromoUserFilters>({
    page: initPage ?? 0,
    limit: initLimit > 0 ? initLimit : 10,
    id_promo_code: initPromoId,
    user_id: initUserId,
    brand: initBrand as GetPromoUserFilters['brand'],
    region: initRegion as GetPromoUserFilters['region'],
    expires_after: initExpiresAfter,
  });

  const [totalRows, setTotalRows] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchPromoUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPromoUsers(promoUsersFilter);
      if (response.status === 200) {
        setPromoUsers(response.result.promoUsers.map((user, i) => ({ uniqueKey: i, ...user })));
        setTotalRows(Number(response.count));
      } else {
        enqueueSnackbar(response.message || 'Failed to fetch promo users', { variant: 'error' });
      }
    } catch (err) {
      if ('errors' in err && err.errors?.length > 0) {
        err.errors.forEach((e) => {
          enqueueSnackbar(`Error: ${e.path} "${e.value}" ${e.message}`, { variant: 'error', autoHideDuration: 5000 });
        });
      } else {
        enqueueSnackbar(`Error: ${JSON.stringify(err)}`, { variant: 'error', autoHideDuration: 5000 });
      }
    } finally {
      setLoading(false);
    }
  }, [promoUsersFilter, enqueueSnackbar]);

  useEffect(() => {
    fetchPromoUsers();
  }, [promoUsersFilter, fetchPromoUsers]);

  return {
    promoUsers,
    totalRows,
    isLoading,
    promoUsersFilter,
    setPromoUsersFilter,
    fetchPromoUsers,
  };
}
