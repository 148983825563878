import React from 'react';

import { Alert } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { getFlightDetailsByOrderAndItem, updateFlightDetails } from '~/services/OrdersService';

import OrderFlightItem from './Item';

interface Props {
  item: App.FlightItem;
  refreshData: () => void;
  showForceBundleAlert?: () => void;
  segmentIndex?: number;
}

interface State {
  flightDetails: any;
  error: string;
  successMessage: string;
}

export class OrderFlightItems extends React.Component<Props, State> {
  state = {
    flightDetails: null,
    error: null,
    successMessage: null,
  };

  componentDidMount() {
    this.getFlightDetails();
  }

  updateTravellerRefundStatus = async (orderId, itemId, payload) => {
    try {
      await updateFlightDetails(orderId, itemId, payload);
      this.setState({
        successMessage: `Successfully updated traveller refund status to be ${payload.data.refund_status}`,
      });
    } catch (error) {
      this.setState({
        error: `There has been an error updating traveller refund status. Please try again. Error message: ${error.message}`,
        successMessage: null,
      });
    }
    this.getFlightDetails();
  };

  getFlightDetails = async () => {
    try {
      const flightDetails = await getFlightDetailsByOrderAndItem(this.props.item.fk_order_id, this.props.item.id);
      this.setState({
        flightDetails,
      });
      const shouldShowAlert = this.props.showForceBundleAlert && flightDetails.force_bundle_deal_name;
      if (shouldShowAlert) {
        this.props.showForceBundleAlert();
      }
    } catch (error) {
      this.setState({
        error: `There has been an error retrieving the flight details. Please try again. Error message: ${error.message}`,
        successMessage: null,
      });
    }
  };

  render() {
    if (this.state.error) {
      return <ErrorDisplay message={this.state.error} />;
    }

    if (!this.state.flightDetails) {
      return <Spinner />;
    }

    return (
      <>
        {this.state.successMessage && (
          <Alert severity="success">
            <p>{this.state.successMessage}</p>
          </Alert>
        )}

        <OrderFlightItem
          orderId={this.props.item.fk_order_id}
          itemId={this.props.item.id}
          itemStatus={this.props.item.status}
          itemRefundStatus={this.props.item.refund_status}
          flightDetails={this.state.flightDetails}
          refreshData={this.props.refreshData}
          updateTravellerRefundStatus={this.updateTravellerRefundStatus}
          segmentIndex={this.props.segmentIndex}
        />
      </>
    );
  }
}

export default OrderFlightItems;
