import React, { useCallback, useEffect, useState } from 'react';

import currencyFormatter from 'currency-formatter';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { Link as NavLink } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { DEFAULT_EXPIRY_IN_MONTHS } from '~/consts/credits';

import { changeExpiryDate } from '~/services/PaymentsService';
import {
  addMonths,
  addYears,
  endOfDay,
  formatDateLongISO,
  formatDateLongMonthWithMeridiemNoSeconds,
  formatDateWeekDayShortMonth,
} from '~/services/TimeService';

import DateTimeWidget from '../Common/Elements/DateTimeWidget';
import GridPagination from '../Common/Elements/GridPagination';
import PageSubheader from '../Common/Elements/PageSubheader';
import CreditsForm from '../Common/Forms/CreditsForm';
import PermissionedComponent from '../Common/PermissionedComponent';
import { withTenant } from '../hoc';

type BasicUser = {
  id_member: string;
  fullName: string;
};

type Credits = {
  status: number;
  message: string;
  result: {
    collection: {
      id_credit: string;
      expires_at: string;
    }[];
  };
  metadata: {
    available_credit: number;
    total_available: number;
  };
};

type Props = {
  currency: string;
  user: App.User;
  idUser: string;
  currentPage: number;
  initiators: BasicUser[];
  tenant: App.Tenant;
  credits: Credits;
  creditTypes: unknown;
  creditExtensionTypes: { creditExtensionType: string }[];
  isLoading: boolean;
  onPageChange: (page: number) => void;
  rerender: () => void;
};

const CreditsPage: React.FC<Props & WithSnackbarProps> = (props) => {
  const {
    currency,
    user,
    idUser,
    currentPage,
    initiators,
    tenant,
    credits,
    creditTypes,
    isLoading,
    creditExtensionTypes,
    onPageChange,
    rerender,
    enqueueSnackbar,
  } = props;

  const currencyFormat = useCallback(
    (amount: number) => {
      return currencyFormatter.format(amount, {
        code: currency,
        precision: 2,
      });
    },
    [currency],
  );

  const getLatestDate = useCallback((dates: { expires_at: string }[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore because TS does't like dates and math
    return formatDateLongISO(Math.max(...dates.map((e) => new Date(e.expires_at))));
  }, []);

  const calculateExpiryDate = useCallback(() => {
    if (credits.result.collection.length > 0) {
      return getLatestDate(credits.result.collection);
    } else {
      return formatDateLongISO(addYears(1));
    }
  }, [credits.result.collection, getLatestDate]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [caseNumber, setCaseNumber] = useState<string>('');
  const [creditExtensionType, setCreditExtensionType] = useState<string>('');
  const [additionalContext, setAdditionalContext] = useState<string>('');
  const [creditsSubtitle, setCreditsSubtitle] = useState<string>('');
  const [expiresAt, setExpiresAt] = useState<string>(formatDateLongISO(endOfDay(addMonths(DEFAULT_EXPIRY_IN_MONTHS))));
  const [allExpiresAt, setAllExpiresAt] = useState<string>('');
  const [changeExpiryDateRequestState, setChangeExpiryDateRequestState] = useState<Utils.FetchingState>('idle');

  useEffect(() => {
    setCreditsSubtitle(currencyFormat(credits.metadata.available_credit));
    setAllExpiresAt(calculateExpiryDate());
  }, [calculateExpiryDate, credits, currencyFormat]);

  const creditFormat = useCallback(
    (value: string) => {
      const amount = parseFloat(value);
      return amount > 0 ? currencyFormat(amount) : '-';
    },
    [currencyFormat],
  );

  const debitFormat = useCallback(
    (value: string) => {
      const amount = parseFloat(value);
      return amount < 0 ? currencyFormat(Math.abs(amount)) : '-';
    },
    [currencyFormat],
  );

  const addedByLink = useCallback(
    (fk_user_id: string) => {
      const initiator = initiators.find((i) => i.id_member === fk_user_id);
      return (
        <Link component={NavLink} to={`/users/${fk_user_id}`}>
          {initiator ? initiator.fullName : 'Unknown'}
        </Link>
      );
    },
    [initiators],
  );

  const handleChangeExpiryDate = useCallback(
    async (event) => {
      event.preventDefault();
      setChangeExpiryDateRequestState('loading');
      const object = {
        expires_at: expiresAt,
        comments: additionalContext,
        fk_member: idUser,
        brand: tenant.brand,
        currency,
      };

      object.comments += (object.comments.length ? ', ' : '') + creditExtensionType + ', ' + caseNumber.toString();

      await changeExpiryDate(object);
      enqueueSnackbar('Expiry date updated', { variant: 'success' });
      setChangeExpiryDateRequestState('idle');
      setIsModalOpen(false);
      rerender();
    },
    [
      additionalContext,
      caseNumber,
      creditExtensionType,
      currency,
      enqueueSnackbar,
      expiresAt,
      idUser,
      rerender,
      tenant.brand,
    ],
  );

  const creditComments = useCallback(
    (comments: string, { fk_orders }: { fk_orders: string }) => (
      <Box overflow="hidden">
        <Typography whiteSpace="normal">{comments}</Typography>
        {fk_orders && (
          <Typography noWrap>
            Order:{' '}
            <Link component={NavLink} to={`/purchases/${fk_orders}`}>
              {fk_orders}
            </Link>
          </Typography>
        )}
      </Box>
    ),
    [],
  );

  const onSubmit = () => {
    handleChangeExpiryDate(event);
  };

  const onCancelModal = () => {
    setIsModalOpen(false);
  };

  const handleCaseNumberChange = (event) => {
    setCaseNumber(event.target.value);
  };

  const handleCommentChange = (event) => {
    setAdditionalContext(event.target.value);
  };

  const handleChangeCreditType = (event) => {
    setCreditExtensionType(event.target.value);
  };

  const toggleCreditExtensionModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: 'Date',
      sortable: false,
      width: 150,
      valueFormatter: (value) => {
        return formatDateWeekDayShortMonth(value);
      },
      display: 'flex',
    },
    {
      field: 'amount_credit',
      headerName: 'Credit',
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return creditFormat(params.row.amount);
      },
      display: 'flex',
    },
    {
      field: 'amount_debit',
      headerName: 'Debit',
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return debitFormat(params.row.amount);
      },
      display: 'flex',
    },
    {
      field: 'fk_added_by',
      headerName: 'Added by',
      sortable: false,
      width: 200,
      renderCell: (params) => addedByLink(params.value),
      display: 'flex',
    },
    {
      field: 'credit_type',
      headerName: 'Credit type',
      sortable: false,
      width: 180,
      display: 'flex',
    },
    {
      field: 'comments',
      headerName: 'Comment',
      sortable: false,
      flex: 1,
      renderCell: (params) => creditComments(params.value, params.row),
      display: 'flex',
    },
    {
      field: 'currency',
      headerName: 'Currency',
      display: 'flex',
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Users | {user.fullName} | Credits</title>
      </Helmet>
      <PageSubheader title="Add credits" />
      <CreditsForm
        credits={credits}
        creditTypes={creditTypes}
        currency={currency}
        id_user={idUser}
        rerender={rerender}
        updateAllExpiryDate={setAllExpiresAt}
      />
      {!isLoading && <PageSubheader title={`Total Credit: ${creditsSubtitle}`} />}
      <Box my={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack flexGrow={1} direction="row" spacing={1}>
            <Typography variant="h6">All credit expires at:</Typography>
            <Typography variant="h6" color="textSecondary" fontWeight="normal">
              {formatDateLongMonthWithMeridiemNoSeconds(allExpiresAt)}
            </Typography>
          </Stack>
          <PermissionedComponent>
            <Box>
              <Button
                type="submit"
                onClick={toggleCreditExtensionModal}
                variant="contained"
                disabled={changeExpiryDateRequestState === 'loading'}
              >
                Change expiry date
              </Button>
            </Box>
          </PermissionedComponent>
        </Stack>
      </Box>
      <DataGrid
        rows={credits.result.collection}
        rowCount={credits.metadata.total_available}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        loading={isLoading}
        paginationModel={{ page: currentPage - 1, pageSize: 10 }}
        paginationMode="server"
        pageSizeOptions={[10]}
        getRowId={(row) => row.id_credit}
        getRowHeight={() => 'auto'}
        onPaginationModelChange={({ page }) => onPageChange(page + 1)}
        slots={{ pagination: GridPagination }}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        autoHeight
      />
      <Dialog
        open={isModalOpen}
        onClose={onCancelModal}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="paper"
        fullWidth
        maxWidth="lg"
      >
        <form onSubmit={onSubmit}>
          <DialogTitle id="scroll-dialog-title">{'Credit Extension'}</DialogTitle>
          <DialogContent>
            <Stack direction="row" spacing={2} mt={2}>
              <Stack direction="column" spacing={2} mt={2} flexGrow={1} width={250}>
                <TextField
                  variant="outlined"
                  value={creditExtensionType}
                  onChange={handleChangeCreditType}
                  select
                  fullWidth
                  required
                >
                  {creditExtensionTypes.map((item, idx) => (
                    <MenuItem key={idx} value={item.creditExtensionType}>
                      {item.creditExtensionType}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction="column" spacing={2} mt={2} flexGrow={1}>
                <DateTimeWidget value={expiresAt} onChange={setExpiresAt} />
              </Stack>
              <Stack direction="column" spacing={2} mt={2} flexGrow={1}>
                <TextField
                  id="caseNumber"
                  label="Case number with approval"
                  placeholder="Case number with approval"
                  variant="outlined"
                  value={caseNumber}
                  onChange={handleCaseNumberChange}
                  inputProps={{
                    minLength: 2,
                  }}
                  fullWidth
                  required
                />
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2} mt={2}>
              <TextField
                label="Additional context"
                placeholder="Additional context"
                variant="outlined"
                value={additionalContext}
                onChange={handleCommentChange}
                rows={4}
                fullWidth
                multiline
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ marginRight: 2, marginBottom: 2 }}>
            <Button onClick={onCancelModal} color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default withTenant(withSnackbar(CreditsPage));
