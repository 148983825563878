/*
  It's a method duplicated from offer svc.
  The method is on both sides to ensure security and performance.
*/
import { getRegions } from '@luxuryescapes/lib-regions';

import { AVAILABILITY, ONLINE_PURCHASE } from '~/consts/schedule';

// Only old timeline uses 'world' keyword
const hasWorldRegion = (schedules) => schedules.some((schedule) => schedule.region === 'world');

// Schedules of the same type should share the same time range
const hasInconsistentTime = (schedules, type) => {
  const schedulesByType = schedules.filter((schedule) => schedule.type === type);
  const sample = schedulesByType[0];
  if (sample) {
    return schedulesByType.some((schedule) => schedule.start !== sample.start || schedule.end !== sample.end);
  }
  return false;
};

/*schedules created by new schedule module would be combinations of all brands and regions
    Statistic of region/brand combinations of each schedule type
    Mismatch on region/brand indicates it was built using old timeline
    e.g. {
      availability: {
        luxuryescapes: 29,
        cudotravel: 5  <-- mismatch on number of regions
      },
      online_purchase: {
        luxuryescapes: 29,
        cudotravel: 29,
      }
    }*/
const inValidBrandRegionCombination = (purchaseSchedules, brands) => {
  const scheduleBrandSumBase = {
    [AVAILABILITY]: {},
    [ONLINE_PURCHASE]: {},
  };

  const scheduleBrandSumResult = purchaseSchedules.reduce((scheduleBrandSum, schedule) => {
    if (!scheduleBrandSum[schedule.type][schedule.brand]) {
      scheduleBrandSum[schedule.type][schedule.brand] = 0;
    }
    scheduleBrandSum[schedule.type][schedule.brand] += 1;
    return scheduleBrandSum;
  }, scheduleBrandSumBase);

  const availabilityBrands = Object.keys(scheduleBrandSumResult[AVAILABILITY]);
  const onlinePurchaseBrands = Object.keys(scheduleBrandSumResult[ONLINE_PURCHASE]);

  if (
    (availabilityBrands.length && availabilityBrands.length !== brands.length) ||
    (onlinePurchaseBrands.length && availabilityBrands.length !== onlinePurchaseBrands.length)
  ) {
    return true;
  }

  for (const brand of availabilityBrands) {
    if (
      (scheduleBrandSumResult[AVAILABILITY][brand] !== scheduleBrandSumResult[ONLINE_PURCHASE][brand] &&
        scheduleBrandSumResult[ONLINE_PURCHASE][brand]) ||
      scheduleBrandSumResult[AVAILABILITY][brand] < getRegions(brand)?.length
    ) {
      return true;
    }
  }
  return false;
};

/*
  Migrate offer data created by old timeline to be consistent with new schedule
  Online purchase and availibility should be all combinations of brand and region.
  The number of brand depends on when the schedules were created, but it should be
  the same between online_purchase and availability if created by new schedule component.
  non-LE brand's list_visibility should match the corresponding region.
*/
function shouldMigrateScheduleData(schedules, brands) {
  const purchaseSchedules = schedules.filter((schedule) => [AVAILABILITY, ONLINE_PURCHASE].includes(schedule.type));

  if (!purchaseSchedules.length) {
    return false;
  }

  return (
    hasWorldRegion(purchaseSchedules) ||
    hasInconsistentTime(schedules, AVAILABILITY) ||
    hasInconsistentTime(schedules, ONLINE_PURCHASE) ||
    inValidBrandRegionCombination(purchaseSchedules, brands)
  );
}

export default shouldMigrateScheduleData;
