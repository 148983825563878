import React from 'react';

import { useSnackbar } from 'notistack';

import { Button, Dialog, DialogTitle, Stack, Typography } from '@mui/material';

import { deleteAffiliation } from '~/services/AgentHub/AgentService';

export interface DeleteAffiliationModalData {
  id: string | null;
  name: string;
  open: boolean;
}

interface Props {
  isOpen: boolean;
  onClose: ({ refetch }: { refetch: boolean }) => void;
  data: DeleteAffiliationModalData;
}

export default function DeleteAffiliationModal({ isOpen, onClose, data }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  async function handleDeleteAffiliation(id: string) {
    try {
      await deleteAffiliation(id);
      onClose({ refetch: true });
    } catch (err) {
      enqueueSnackbar(err?.message || 'there was an error deleting this affiliation', { variant: 'error' });
    }
  }

  return (
    <Dialog open={isOpen} fullWidth onClose={() => onClose({ refetch: false })}>
      <DialogTitle>Delete affiliation</DialogTitle>
      <Stack direction={'row'} alignItems={'center'} padding={3} pt={0}>
        <Stack spacing={2}>
          <Typography variant="body1">
            Are you sure you want to delete the <b>"{data?.name}"</b> affiliation?
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'flex-end'} gap={2} padding={3} pt={0}>
        <Button onClick={() => onClose({ refetch: false })} variant="outlined">
          No
        </Button>
        <Button variant="contained" onClick={() => handleDeleteAffiliation(data.id)}>
          Yes
        </Button>
      </Stack>
    </Dialog>
  );
}
