import React, { useEffect, useState } from 'react';

import { Paper } from '@mui/material';

import { getFlightDeals } from '~/services/FlightsService';

import FlightDealsForm from '../Common/Forms/FlightDealsForm';

import MerchandisingFlightDealsTable from './MerchandisingFlightDealsTable';
import { FLIGHT_DEALS_LIST_ATTRIBUTES } from './constants';

export const MerchandisingFlightDeals = () => {
  const sizePerPage = 20;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tableData, setTableData] = useState({ rows: [], count: 0 });
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');

  const fetchTableData = async (searchString) => {
    try {
      const response = await getFlightDeals({
        page: page || 1,
        pageSize: sizePerPage,
        attributes: FLIGHT_DEALS_LIST_ATTRIBUTES,
        searchString: searchString,
      });

      setLoading(false);
      setError(null);
      setTableData(response.result);
    } catch (err) {
      setLoading(false);
      setError(err.message);
      setTableData({ rows: [], count: 0 });
    }
  };

  const searchHandler = async () => {
    setError(null);
    await fetchTableData(searchString);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const handleDelete = () => {
    fetchTableData(searchString);
  };

  useEffect(() => {
    async function fetchFromAPI() {
      setLoading(true);
      setError(null);
      await fetchTableData(null);
    }

    fetchFromAPI();
  }, [page]);

  if (error) {
    return <div className="alert alert-danger">Failed to flight deals data: {error}</div>;
  }

  return (
    <Paper className="branded-panel">
      <FlightDealsForm fetchTableData={fetchTableData} />
      <MerchandisingFlightDealsTable
        isLoading={loading}
        onPageChange={onPageChange}
        page={page}
        records={tableData.rows}
        searchHandler={searchHandler}
        setSearchString={setSearchString}
        total={tableData.count}
        onDelete={handleDelete}
      />
    </Paper>
  );
};

export default MerchandisingFlightDeals;
