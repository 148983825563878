import { array, bool, number, object, string } from 'yup';

import { OFFER_TYPE_BUNDLE_AND_SAVE, OFFER_TYPE_RENTAL } from '~/consts/offerTypes';

const offerFormSchema = object({
  additional_description: string().label('Additional description').nullable(),
  booking_type: string().when('status', ([status], schema) =>
    status === 'content-approved' ? schema.required() : schema,
  ),
  bundled_with_flights_only: bool().label('Bundle with flights only').nullable(),
  campaigns: array().label('Campaign Tags').of(string().required()).nullable(),
  description: string()
    .label('Description')
    .when(['status', 'type'], ([status, type], schema) =>
      status === 'content-approved' && type !== OFFER_TYPE_RENTAL ? schema.required() : schema,
    )
    .nullable(),
  disable_best_price_guarantee: bool().label('Disable Best Price Guarantee'),
  disable_deposit: bool().label('Disable deposit'),
  deposit_thresholds: object({
    number_of_days: number().label('Deposit number of days'),
    percentage: number().label('Deposit percentage'),
  }),
  facilities: string().label('Facilities'),
  fine_print: string().label('Fine print'),
  flight_destination_port: string().label('Flight destination port').nullable(),
  flight_regions: array().label('Regions that can sell flights').of(string().required()),
  flights_cache_disabled: bool().label('Disable flight cache for this offer'),
  flights_enabled: bool().label('Flights enabled'),
  flights_max_arrival_time: string()
    .label('Latest flight arrival time of departing flight')
    .matches(/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/)
    .nullable(),
  flights_min_returning_departure_time: string()
    .label('Earliest flight departure time of returning flight')
    .matches(/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/)
    .nullable(),
  flights_warning_enabled: bool().label('Display Flights Warning Banner for Hotel + Flight Bookings'),
  getting_there: string()
    .label('Getting there/Locations')
    .when(['status', 'type'], ([status, type], schema) =>
      status === 'content-approved' && type !== OFFER_TYPE_BUNDLE_AND_SAVE && type !== OFFER_TYPE_RENTAL
        ? schema.required()
        : schema,
    ),
  highlights: string().label('Highlights').nullable(),
  holiday_types: array().label('Holiday Type Tags').of(string().required()).nullable(),
  ignore_vendor_email_notifications: bool().label('Turn off email notifications'),
  is_enable_deposit_thresholds: bool().label('Set deposit thresholds'),
  is_partner_property: bool().label('Display as Partner Property'),
  is_search_by_name_hidden: bool().label('Hidden by name'),
  is_search_hidden: bool().label('Hidden by destination'),
  is_discount_pill_hidden: bool().label('Hide discount pill'),
  location: string()
    .label('Name of property or Tour location')
    .when(['status', 'type'], ([status, type], schema) =>
      status === 'content-approved' && type !== OFFER_TYPE_BUNDLE_AND_SAVE && type !== OFFER_TYPE_RENTAL
        ? schema.required()
        : schema,
    )
    .nullable(),
  locations: array()
    .of(string().label('Location tag').required('Location tag cannot be empty'))
    .label('Location Tags')
    .nullable(),
  locations_visited: array()
    .label('Locations visited')
    .of(
      object({
        country: string().required(),
        cityOfrPlaceName: string().nullable(),
      }),
    )
    .nullable(),
  name: string()
    .label('Headline')
    .when('status', ([status], schema) => (status === 'content-approved' ? schema.required() : schema)),
  name_override_for_edm: string().label('Name override for EDM').nullable(),
  no_index: bool().label('Noindex (prevent search engine crawling)'),
  offer_inclusions: array()
    .label('Offer tile inclusions')
    .of(
      object({
        text: string().label('Tile inclusions text').required('Tile inclusion text cannot be empty'),
        is_highlighted: bool(),
        category: string().label('Tile inclusions category'),
        icon: string().label('Tile inclusions icon'),
      }),
    ),
  offer_inclusions_short: string().label('Offer inclusions summary').nullable(),
  offer_tile_inclusion_heading: string().label('Offer tile inclusion heading').nullable(),
  start_location: string().label('Start location').nullable(),
  end_location: string().label('End location').nullable(),
  vendor_name: string().label('Vendor name').nullable(),
  vendor_vehicle: string().label('Vendor vehicle').nullable(),
  cabin_types: string().label('Cabin types').nullable(),
  partnerships: array()
    .label('Partnerships')
    .of(
      object({
        code: string().label('Partnership code').required('Partnership code cannot be empty'),
        upsell_text: string().nullable(),
      }),
    ),
  preheader: string().label('Email preheader').nullable(),
  sale_unit: string().label('Sale unit').max(6),
  schedule_change_robot_enabled: bool().label('Enable schedule change robot'),
  slug: string()
    .label('Slug')
    .matches(/^[a-z0-9\\-]+$/, { excludeEmptyString: true })
    .max(128)
    .nullable(),
  staff_discount_enabled: bool().label('Staff discount enabled'),
  status: string().label('Status'),
  subject_line: string().label('Email subject line').nullable(),
  surcharge_paid_direct_to_vendor: bool().label('Surcharges paid directly to vendor'),
  tour_flight_details_required: bool().label('Are LE Tour Flight Details Required'),
  type: string().label('Type').required(),
  vendor_booking_email: string()
    .email()
    .when(['status', 'type'], ([status, type], schema) =>
      status === 'content-approved' && type !== OFFER_TYPE_BUNDLE_AND_SAVE ? schema.required() : schema,
    ),
  whitelisted_carrier_codes: array().label('Included carriers for bundle').of(string().required()).nullable(),
  offer_bundles: array()
    .label('Offer bundle')
    .of(string().required())
    .when('type', ([type], schema) =>
      type === OFFER_TYPE_BUNDLE_AND_SAVE
        ? schema
            .required()
            .min(2, 'Must be exactly 2 bundle offers attached')
            .max(2, 'Must be exactly 2 bundle offers attached')
        : schema,
    ),
});

export default offerFormSchema;
