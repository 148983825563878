import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box, Button, TextField } from '@mui/material';

import { updateConciergeNote } from '~/services/TripPlannerService';

interface Props {
  tripItem: App.TripItemDetailsBasic;
  tripId: string;
  onSuccess: () => void;
}

function ConciergeNote({ tripId, tripItem, onSuccess }: Props) {
  const [currentNote, setCurrentNote] = useState(tripItem.notes || '');
  const [isSaving, setIsSaving] = useState(false);
  const isModified = currentNote !== tripItem.notes;

  const { enqueueSnackbar } = useSnackbar();

  const handleSaveClick = async () => {
    setIsSaving(true);
    try {
      const response = await updateConciergeNote(tripId, tripItem.id, currentNote);
      if (response.status === 200) {
        enqueueSnackbar('Note updated successfully', { variant: 'success' });
        await onSuccess();
      }
    } catch (err) {
      enqueueSnackbar('Failed to update note', { variant: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancelClick = () => {
    setCurrentNote(tripItem.notes);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentNote(event.target.value);
  };

  return (
    <Box>
      <TextField
        label="Concierge Note"
        multiline
        value={currentNote}
        onChange={handleChange}
        variant="outlined"
        fullWidth
      />
      <Box mt={2} display="flex" justifyContent="flex-end" gap={1}>
        {isModified && !isSaving && (
          <Button variant="contained" color="error" onClick={handleCancelClick} disabled={!isModified}>
            Cancel
          </Button>
        )}

        <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={!isModified || isSaving}>
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default ConciergeNote;
