import React, { useMemo } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Box, Container, Tab, Tabs } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import tenants from '~/consts/tenant';

import AffiliationList from '../LeAgentHub/components/AffiliationList';
import AgencyList from '../LeAgentHub/components/AgencyList';
import AgencySubmissions from '../LeAgentHub/components/AgencySubmissions';
import StoplistPage from '../Stoplist/StoplistPage';

import UsersPage from './UsersSearchPage';

const TAB_USERS = 'users-search';
const TAB_AGENCY_SUBMISSION = 'agency-submission';
const TAB_AGENCIES = 'agencies';
const TAB_AFFILIATIONS = 'affiliations';

type Tab = {
  label: string;
  pageTitle: string;
  component: React.ComponentType;
  tenants?: Set<string>;
};

const TABS_MAP = {
  [TAB_USERS]: {
    label: 'Search',
    pageTitle: 'Users Search',
    component: UsersPage,
  } as Tab,
  'stop-list': {
    label: 'Stoplist',
    pageTitle: 'Stoplist',
    component: StoplistPage,
  } as Tab,
  [TAB_AGENCY_SUBMISSION]: {
    label: 'Agency Submission',
    pageTitle: 'Agency Submission',
    component: AgencySubmissions,
    tenants: new Set([tenants.leagenthub.value]),
  } as Tab,
  [TAB_AGENCIES]: {
    label: 'Agencies',
    pageTitle: 'Agencies',
    component: AgencyList,
    tenants: new Set([tenants.leagenthub.value]),
  } as Tab,
  [TAB_AFFILIATIONS]: {
    label: 'Affiliations',
    pageTitle: 'Affiliations',
    component: AffiliationList,
    tenants: new Set([tenants.leagenthub.value]),
  },
} as const;

type TabKey = keyof typeof TABS_MAP;

export default function UsersPageContainer() {
  const { tab = TAB_USERS } = useParams<{ tab?: TabKey }>();
  const currentTab = TABS_MAP[tab];

  const brand = useSelector((state: App.State) => state.tenant);

  const allTabs = useMemo(() => {
    return Object.keys(TABS_MAP).filter((key) => !TABS_MAP[key].tenants || TABS_MAP[key].tenants.has(brand.value));
  }, [brand]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{`Users / ${currentTab.label}`}</title>
      </Helmet>

      <PageHeader title={currentTab.pageTitle} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab}>
          {allTabs.map((tab) => (
            <Tab
              key={tab}
              value={tab}
              label={TABS_MAP[tab].label}
              to={`/users-list/${tab}`}
              component={Link}
              sx={{
                alignItems: 'start',
                '&.Mui-selected': {
                  backgroundColor: 'aliceblue',
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      <Box pt={4}>
        <Box component={currentTab.component} />
      </Box>
    </Container>
  );
}
