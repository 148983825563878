import React from 'react';

import { Controller } from 'react-hook-form';

import ModeIcon from '@mui/icons-material/Mode';
import { FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import Input from './Input';

export default function PackageInclusionItem({ onInputChange, getValues, control, name, index }) {
  return (
    <Grid
      container
      columns={24}
      direction={'row'}
      spacing={2}
      mt={0}
      alignItems="center"
      wrap="nowrap"
      sx={{ '& > .MuiGrid-item': { paddingTop: 0 } }}
    >
      <Grid item>
        <IconButton
          target="_blank"
          href={`/vendors/${getValues(`${name}.${index}.vendor_salesforce_id`)}/inclusions?inclusionId=${getValues(
            `${name}.${index}.inclusion_id`,
          )}`}
          size="large"
          color="primary"
          aria-label="Edit Asset"
        >
          <ModeIcon />
        </IconButton>
      </Grid>
      <Grid item xs={6} lg={4}>
        <Input
          control={control}
          name={`${name}.${index}.inclusion.category.name`}
          muiLabel="Category"
          disabled={getValues(`${name}.${index}.inclusion.is_hidden`)}
          fullWidth
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs>
        <Input
          control={control}
          name={`${name}.${index}.inclusion.name`}
          muiLabel="Inclusion"
          disabled={getValues(`${name}.${index}.inclusion.is_hidden`)}
          fullWidth
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={9} lg={7}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
          <Typography variant="body1">Style:</Typography>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl
                disabled={getValues(`${name}.${index}.inclusion.is_hidden`)}
                error={!!fieldState.error}
                fullWidth
                sx={{ position: 'relative' }}
              >
                <RadioGroup
                  {...field}
                  row
                  value={field.value || ''}
                  onChange={(event) => onInputChange(event, 'style', index)}
                >
                  <FormControlLabel value="null" control={<Radio />} label="No style" />
                  <FormControlLabel value={'highlighted'} control={<Radio />} label={'Highlighted'} />
                  <FormControlLabel value={'bonus'} control={<Radio />} label={'Bonus'} />
                </RadioGroup>
              </FormControl>
            )}
            name={`${name}.${index}.style`}
            control={control}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
