import QueryString from 'qs';

import { actions, definitions } from '@luxuryescapes/contract-svc-agent';

import { CommissionRulesConditionTypes } from '~/consts/agentHub';

import { request } from '../common';

type GetAgenciesResponse = actions['agencyList']['responses']['200']['content']['application/json'];
type GetAgentsResponse = actions['getUsers']['responses']['200']['content']['application/json'];

type ListAffiliationsResponse = actions['affiliationList']['responses']['200']['content']['application/json'];
type GetAffiliationResponse = actions['affiliationGet']['responses']['200']['content']['application/json'];
type UpdateAffiliationResponse = actions['affiliationUpdate']['responses']['200']['content']['application/json'];

export type CreateCommissionRuleParams = actions['createCommission']['parameters']['body']['createCommission'];
type CreateCommissionRuleResponse = actions['createCommission']['responses']['200']['content']['application/json'];

type getCommissionRulesResponse = actions['getCommissionList']['responses']['200']['content']['application/json'];
type EditCommissionRuleParams = actions['updateCommission']['parameters']['body']['payload'];

export type EditAgentHubAgencyParams = actions['agencyUpdate']['parameters']['body']['agencyUpdateSchema'];
export type EditagentHubAgencyResponse = definitions['agencySchema'];

type GetAvailableAffiliationsResponse =
  actions['affiliationListAvailable']['responses']['200']['content']['application/json'];

export interface AgentHubAgency {
  id: string;
  name: string;
  businessCodeType: string;
  businessCodeValue: string;
  address: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  affiliation: string;
}

export interface AgentHubAffiliation {
  region: string;
  name: string;
}

export interface AgentHubAgent {
  id: string;
  name: string;
  phone: string;
  email: string;
  affiliation: string;
  customerId?: string;
  agencyId?: string;
  eoiSubmissionId?: string;
  agencyName?: string;
  status: string;
  verifiedAt: string;
}

export interface AgentHubCommissionRule {
  id: string;
  description: string;
  commissionPercentage: number;
  commissionType: string;
  startDate: string;
  endDate: string;
  regions: Array<string>;
  rules: {
    [key in CommissionRulesConditionTypes]?: Array<string>;
  };
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

interface AgentHubList<T> {
  total: number;
  rows: Array<T>;
}

type AffiliationList = AgentHubList<AgentHubAffiliation>;
type AgentHubAgencyList = AgentHubList<AgentHubAgency>;
type AgentHubAgentList = AgentHubList<AgentHubAgent>;

export function basePath() {
  return window.configs.API_HOST + '/api/agent';
}

interface ListEOIParams {
  pageSize: number;
  page: number;
  status?: string;
}

interface ListAgencyParams {
  pageSize: number;
  page: number;
}

interface ListUserParams {
  pageSize: number;
  page: number;
  agencyId?: string;
}

export async function listEOI(params: ListEOIParams): Promise<any> {
  const { result } = await request(`${basePath()}/eoi?${QueryString.stringify(params)}`, { method: 'GET' });
  return result;
}

export async function getEOI(id: string): Promise<any> {
  const { result } = await request(`${basePath()}/eoi/${id}`, { method: 'GET' });
  return result;
}

export async function updateEOIStatus(id: string, status: 'approved' | 'rejected'): Promise<any> {
  const requestBody = {
    status,
  };

  const { result } = await request(`${basePath()}/eoi/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(requestBody),
  });
  return result;
}

export async function listAffiliations(params): Promise<AffiliationList> {
  const { result }: ListAffiliationsResponse = await request(
    `${basePath()}/affiliation?${QueryString.stringify(params)}`,
    { method: 'GET' },
  );
  return result;
}

export async function listAgencies(params: ListAgencyParams): Promise<AgentHubAgencyList> {
  const { result }: GetAgenciesResponse = await request(`${basePath()}/agency?${QueryString.stringify(params)}`, {
    method: 'GET',
  });

  const agenciesResult: AgentHubAgencyList = {
    total: result.total,
    rows: result.rows.map((agency) => ({
      id: agency.id,
      name: agency.name,
      businessCodeType: agency.businessCodeType,
      businessCodeValue: agency.businessCodeValue,
      address: agency.address,
      city: agency.city,
      state: agency.state,
      postcode: agency.postcode,
      country: agency.country,
      affiliation: agency.affiliation,
    })),
  };

  return agenciesResult;
}

export async function listUsers(params: ListUserParams): Promise<AgentHubAgentList> {
  const { result }: GetAgentsResponse = await request(`${basePath()}/user?${QueryString.stringify(params)}`, {
    method: 'GET',
  });

  const agentsResult: AgentHubAgentList = {
    total: result.total,
    rows: result.rows.map((agent) => ({
      id: agent.id,
      name: agent.name,
      phone: agent.phone,
      email: agent.email,
      affiliation: agent.affiliation,
      customerId: agent.customerId,
      agencyId: agent.agencyId,
      eoiSubmissionId: agent.eoiSubmissionId,
      agencyName: agent.agencyName,
      status: agent.status,
      verifiedAt: agent.verifiedAt,
    })),
  };

  return agentsResult;
}

export async function getAgency(id: string): Promise<any> {
  const { result } = await request(`${basePath()}/agency/${id}`, { method: 'GET' });
  return result;
}

export async function getAffiliation(id: string): Promise<AgentHubAffiliation> {
  const { result }: GetAffiliationResponse = await request(`${basePath()}/affiliation/${id}`, { method: 'GET' });
  return result;
}

export async function getAffiliationList(): Promise<Array<AgentHubAffiliation>> {
  const { result }: GetAvailableAffiliationsResponse = await request(`${basePath()}/available-affiliations`, {
    method: 'GET',
  });

  return result;
}

export async function createAffiliation(data: AgentHubAffiliation): Promise<AgentHubAffiliation> {
  const { result }: GetAffiliationResponse = await request(`${basePath()}/affiliation`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return result;
}

export async function deleteAffiliation(id: string): Promise<void> {
  await request(`${basePath()}/affiliation/${id}`, { method: 'DELETE' });
}

export async function updateAffiliation(id: string, data: AgentHubAffiliation): Promise<AgentHubAffiliation> {
  const { result }: UpdateAffiliationResponse = await request(`${basePath()}/affiliation/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return result;
}

export async function updateAgency(id: string, data: EditAgentHubAgencyParams): Promise<EditagentHubAgencyResponse> {
  const { result } = await request(`${basePath()}/agency/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return result;
}

export async function listUserEmailLogs(id: string, params): Promise<any> {
  const { result } = await request(`${basePath()}/user/${id}/email-logs?${QueryString.stringify(params)}`, {
    method: 'GET',
  });
  return result;
}

export async function resendActivationEmail(id: string): Promise<boolean> {
  const { result } = await request(`${basePath()}/user/${id}/resend-activation-email`, {
    method: 'POST',
  });
  return result;
}

export async function getUser(id: string): Promise<any> {
  const { result } = await request(`${basePath()}/user/${id}`, {
    method: 'GET',
  });
  return result;
}

export async function createCommissionRule(params: CreateCommissionRuleParams): Promise<CreateCommissionRuleResponse> {
  const { result } = await request(`${basePath()}/commission`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return result;
}

export async function getCommissionRules(): Promise<Array<AgentHubCommissionRule>> {
  const res: getCommissionRulesResponse = await request(`${basePath()}/commission`, { method: 'GET' });

  const rules = res.result.rows.map((rule) => {
    return {
      id: rule.id,
      description: rule.description,
      commissionPercentage: rule.commissionPercentage,
      commissionType: rule.type,
      startDate: rule.startDate,
      endDate: rule.endDate,
      regions: rule.regions,
      rules: rule.rules,
      createdAt: rule.createdAt,
      updatedAt: rule.updatedAt,
      createdBy: rule.createdBy,
      updatedBy: rule.updatedBy,
    };
  });

  return rules;
}

export async function updateCommissionRule(commissionId: string, data: EditCommissionRuleParams) {
  await request(`${basePath()}/commission/${commissionId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
export async function deleteCommissionRule(id: string) {
  await request(`${basePath()}/commission/${id}`, { method: 'DELETE' });
}
