import React from 'react';

import { Typography } from '@mui/material';

const statusFormatter = (value: string, status: string) => {
  if (status === 'cancelled') {
    return (
      <Typography component="span" sx={{ textDecoration: 'line-through' }}>
        {value}
      </Typography>
    );
  }

  return <Typography component="span">{value}</Typography>;
};

export default statusFormatter;
