import React, { useMemo } from 'react';

import currencyFormatter from 'currency-formatter';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';

import { OFFER_FACING_NAMES } from '~/consts/offerTypes';

import { formatDateSlashes } from '~/services/TimeService';
import { customerPortalHost, salesforceHost } from '~/services/common';

import getCustomerPortalLinkForOffer from '~/utils/getCustomerPortalLinkForOffer';

const getOfferPageUrl = (tenant, path) => {
  return customerPortalHost({ value: tenant }) + path;
};

const customerPortals = {
  'Luxury Escapes': 'lux',
  'Scoopon Travel': 'scoopon',
  'Cudo Travel': 'cudo',
  'TreatMe Travel': 'treatme',
  'Deals Travel': 'deals',
  'Kogan Travel': 'kogantravel',
  'Le Business Traveller': 'lebusinesstraveller',
  'Le Agent Hub': 'leagenthub',
};

const dateFormatter = (cell) => {
  if (!cell) {
    return '-';
  }
  return formatDateSlashes(cell);
};

interface Props {
  offer: any;
  offerPayments: any;
  offerPerformance: any;
  totalErrorPayments: {
    amount: number;
    currency: string;
  };
  isLedExperience: boolean;
}

export default function OverviewPane(props: Props) {
  const { totalErrorPayments: errorPayments, offer, isLedExperience } = props;

  const {
    currency: totalRevenueCurrency,
    totalRevenueRaw: totalRevenue,
    totalRevenuePending,
    totalRevenueConfirmed,
  } = props.offerPerformance;

  const offerPayments = useMemo(() => (props.offerPayments ? props.offerPayments.result : []), [props.offerPayments]);

  const { totalPaid, currency: totalPaidCurrency } = useMemo(() => {
    const totalPaidToVendor = offerPayments.reduce(
      (previousValue, currentValue) => (parseFloat(previousValue) + parseFloat(currentValue.cost_amount)).toFixed(2),
      0,
    );

    if (totalPaidToVendor == 0) {
      return { totalPaid: 0, currency: 'AUD' };
    }

    const currencyInWhichVendorWasPaid = offerPayments[0].cost_currency;
    return {
      totalPaid: totalPaidToVendor,
      currency: currencyInWhichVendorWasPaid,
    };
  }, [offerPayments]);

  const holdback = totalRevenue - totalPaid;

  const path = getCustomerPortalLinkForOffer(offer, isLedExperience);

  return (
    <div>
      <div>
        <DefinitionTitle>Title</DefinitionTitle>
        <Typography variant="h5" textTransform="uppercase">
          {props.offer.result.name}
        </Typography>
      </div>

      <Grid container spacing={2} mt={2}>
        <Grid xs={12} md={6} lg={3}>
          <DefinitionTitle>Offer Type</DefinitionTitle>
          <DefinitionText>{OFFER_FACING_NAMES[props.offer.result.type]}</DefinitionText>
        </Grid>

        <Grid xs={12} md={6} lg={3}>
          <DefinitionTitle>Vendor Id</DefinitionTitle>
          <DefinitionText>
            <Link to={`/vendors/${props.offer.result.vendor_account_id}`}>{props.offer.result.vendor_account_id}</Link>
          </DefinitionText>
        </Grid>

        <Grid xs={12} md={6} lg={3}>
          <DefinitionTitle>Total Payable</DefinitionTitle>
          <DefinitionText>
            {totalRevenue
              ? currencyFormatter.format(totalRevenue, {
                  code: totalRevenueCurrency,
                })
              : 0}
          </DefinitionText>
        </Grid>

        <Grid xs={12} md={6} lg={3}>
          <DefinitionTitle>Holdback</DefinitionTitle>
          <DefinitionText color={holdback < 0 ? 'red' : undefined}>
            {holdback
              ? currencyFormatter.format(holdback, {
                  code: totalRevenueCurrency,
                })
              : 0}
          </DefinitionText>
        </Grid>

        <Grid xs={12} md={6} lg={3}>
          <DefinitionTitle>Surcharges paid directly to vendor</DefinitionTitle>
          <DefinitionText>{props.offer.result.surcharge_paid_direct_to_vendor ? 'Yes' : 'No'}</DefinitionText>
        </Grid>

        <Grid xs={12} md={6} lg={3}>
          <DefinitionTitle>Total Revenue Confirmed</DefinitionTitle>
          <DefinitionText>
            {totalRevenueConfirmed
              ? currencyFormatter.format(totalRevenueConfirmed, {
                  code: totalRevenueCurrency,
                })
              : 0}
          </DefinitionText>
        </Grid>

        <Grid xs={12} md={6} lg={3}>
          <DefinitionTitle>Total Revenue Pending</DefinitionTitle>
          <DefinitionText>
            {totalRevenuePending
              ? currencyFormatter.format(totalRevenuePending, {
                  code: totalRevenueCurrency,
                })
              : 0}
          </DefinitionText>
        </Grid>

        <Grid xs={12} md={6} lg={3}>
          <DefinitionTitle>Total Paid</DefinitionTitle>
          <DefinitionText>
            {totalPaid
              ? currencyFormatter.format(totalPaid, {
                  code: totalPaidCurrency,
                })
              : 0}
          </DefinitionText>
        </Grid>

        <Grid xs={12} md={6} lg={3}>
          <DefinitionTitle>Slug: {offer.result.slug}</DefinitionTitle>
          <DefinitionText>
            <ul className="list-unstyled">
              {Object.entries(customerPortals).map(([name, slug]) => (
                <li key={slug}>
                  <a href={getOfferPageUrl(slug, path)} target="_blank" rel="noreferrer">
                    View On {name}
                  </a>
                </li>
              ))}
              <li>
                <a href={`${salesforceHost}/${offer.result.id_salesforce_external}`} target="_blank" rel="noreferrer">
                  View On Salesforce
                </a>
              </li>
            </ul>
          </DefinitionText>
        </Grid>

        <Grid xs={12} md={6} lg={3}>
          <DefinitionTitle>Travel To Date</DefinitionTitle>
          <DefinitionText>{dateFormatter(props.offer.result.travel_to_date)}</DefinitionText>
        </Grid>

        <Grid xs={12} md={6} lg={3}></Grid>

        <Grid xs={12} md={6} lg={3}>
          <DefinitionTitle>Total Error Payments</DefinitionTitle>
          <DefinitionText>
            {errorPayments ? currencyFormatter.format(errorPayments.amount, { code: errorPayments.currency }) : 0}
          </DefinitionText>
        </Grid>
      </Grid>
    </div>
  );
}
