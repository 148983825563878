export const ROLE_ADMIN_USER = 'admin-user';
export const ROLE_EMPLOYEE_USER = 'employee';
export const ROLE_COORDINATOR = 'hotel-coordinator';
export const ROLE_LERE_ADMIN = 'lere-admin';
export const ROLE_LERE_USER = 'lere-user';
export const ROLE_EXPERIENCES_COORDINATOR = 'experiences-coordinator';
export const ROLE_PRIZE_ADMIN_USER = 'prize_administrator';
export const ROLE_PROMO_ADMIN_USER = 'promo-admin';
export const ROLE_LESA_ADMIN = 'support-assistant-admin';
export const ROLE_HOTEL_COORDINATOR = 'hotel-coordinator';
export const ROLE_CRUISE_COORDINATOR = 'cruise-coordinator';
export const ROLE_TOUR_COORDINATOR = 'tour-coordinator';
export const ROLE_HERO_PLANNER_ADMIN = 'hero-planner-admin';
export const ROLE_HERO_PLANNER_VIEWER = 'hero-planner-viewer';
