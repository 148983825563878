import React, { useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import FlagIcon from '@mui/icons-material/Flag';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridPagination, GridSortModel } from '@mui/x-data-grid';

import { ChatModal } from '~/components/SupportAssistant/components/ChatModal';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

import userUtils from '~/utils/userUtils';

import { ChatQuality } from '../../types/Chat';

import { useGetChatTopics } from './hooks/useGetChatTopics';
import { useSearchChatsParams } from './hooks/useSearchChatsParams';
import { useSearchChatsQuery } from './hooks/useSearchChatsQuery';
import { useSelectChatById } from './hooks/useSelectChatById';

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    disableColumnMenu: true,
    minWidth: 100,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'status',
    headerName: 'Status',
    disableColumnMenu: true,
    minWidth: 50,
    flex: 0,
    cellClassName: (params: GridCellParams) =>
      clsx('chat', {
        active: params.row.status === 'ACTIVE',
      }),
    display: 'flex',
  },
  {
    field: 'quality',
    headerName: 'Quality',
    disableColumnMenu: true,
    minWidth: 50,
    flex: 0,
    cellClassName: (params: GridCellParams) =>
      clsx('chat', {
        ['low-quality']: params.row.quality === ChatQuality.LOW,
        ['high-quality']: params.row.quality === ChatQuality.HIGH,
      }),
    display: 'flex',
  },
  {
    field: 'followUp',
    headerName: 'Follow Up',
    disableColumnMenu: true,
    minWidth: 50,
    flex: 0,
    cellClassName: (params: GridCellParams) =>
      clsx('chat', {
        active: params.row.followUp?.status === 'ACTIVE',
      }),
    renderCell: (params: GridCellParams) => (
      <>
        {params.value && (
          <Stack direction="row" alignItems="center">
            {params.row.followUp?.team}
            <Tooltip title={params.row.followUp?.message}>
              <IconButton size="small" color="info">
                {params.row.followUp?.status === 'ACTIVE' ? (
                  <QuestionMarkIcon fontSize="inherit" />
                ) : (
                  <CheckIcon fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </>
    ),
    display: 'flex',
  },
  {
    field: 'customerId',
    renderCell: (params: GridCellParams) => (
      <>
        {params.value && (
          <Link
            target="_blank"
            color={userUtils.isEmployeeEmail(params.row.customer?.email) ? '#ff0000' : '#000'}
            href={`/users/${params.row.customer?.id_member}`}
          >
            <Typography variant="body2">{params.row.customer?.full_name}</Typography>
            <Typography variant="body2">{params.row.customer?.email}</Typography>
          </Link>
        )}
      </>
    ),
    headerName: 'User',
    disableColumnMenu: true,
    sortable: false,
    minWidth: 150,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'topics',
    headerName: 'Topics',
    disableColumnMenu: true,
    minWidth: 150,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'createdAt',
    valueFormatter: (value) => formatDateOrdinalWithClock(new Date(value as string)),
    headerName: 'Created at',
    disableColumnMenu: true,
    minWidth: 75,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'updatedAt',
    valueFormatter: (value) => formatDateOrdinalWithClock(new Date(value as string)),
    headerName: 'Updated at',
    disableColumnMenu: true,
    minWidth: 75,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'hasDeadEnd',
    headerName: 'Dead end',
    disableColumnMenu: true,
    minWidth: 80,
    flex: 1,
    renderCell: (params: GridCellParams) => (params.value ? <FlagIcon color="error" /> : ''),
    display: 'flex',
  },
  {
    field: 'agentType',
    headerName: 'Agent type',
    disableColumnMenu: true,
    minWidth: 80,
    flex: 1,
    cellClassName: (params: GridCellParams) =>
      clsx('chat', {
        agent: params.row.agentType === 'AGENT',
      }),
    display: 'flex',
  },
];

const ChatHistoryPage: React.FC = (): JSX.Element => {
  const [messagesModalOpen, setMessagesModalOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const searchQuery = params.get('search');
  const selectedChatIdQuery = params.get('chat');
  const pageQuery = parseInt(params.get('page'));

  const [queryString, setQueryString] = useState<string | undefined>(searchQuery || undefined);

  const {
    setters: {
      setFollowUpOnly,
      setAgentChatsOnly,
      setChatTopicsFilter,
      setCustomersOnly,
      setFilterByDeadEnd,
      setHasMessagesOnly,
      setPage,
      setSearchString,
      setSortModel,
    },
    searchParams,
  } = useSearchChatsParams({ searchString: queryString });

  const {
    chats,
    chatsWithCustomerDetails,
    total,
    searchChatsQuery,
    loading: loadingSearchChats,
  } = useSearchChatsQuery();

  const { setSelectedChatId, selectedChat, loading: loadingFetchChatById } = useSelectChatById();

  const { chatTopics: allChatTopics, loading: loadingAllChatTopics } = useGetChatTopics();

  const loading = loadingSearchChats || loadingFetchChatById || loadingAllChatTopics;

  const messagesModalClose = useCallback(() => {
    if (selectedChatIdQuery) {
      params.delete('chat');
      history.replace({
        search: params.toString(),
      });
    }
    setSelectedChatId(null);
    searchChatsQuery(searchParams);
  }, [history, params, searchChatsQuery, searchParams, selectedChatIdQuery, setSelectedChatId]);

  const handleSortModelChange = (sortModel: GridSortModel) => {
    // sortModel will always have 1 item because non-PRO datagrid has only 1 sort at the same time
    setSortModel({
      field: sortModel?.[0]?.field,
      sort: sortModel?.[0]?.sort,
    });
  };

  const onRowClickHandler = useCallback(
    (chat) => {
      setSelectedChatId(chat?.id);
      setMessagesModalOpen(true);
      params.set('chat', chat?.id);
      history.replace({
        search: params.toString(),
      });
    },
    [history, params, setSelectedChatId],
  );

  const onCellClickHandler = (params) => {
    if (params.field !== 'customerId' || params.row.customerId === null) {
      onRowClickHandler(params.row);
    }
  };

  const onPageChange = useCallback(
    (page) => {
      if (!isNaN(page)) {
        params.set('page', page);
        history.replace({
          search: params.toString(),
        });
      }
    },
    [history, params],
  );

  const updateQuery = useCallback(
    (e) => {
      if (e.keyCode !== 13 && e.target.name !== 'chat-search-submit') {
        return;
      }

      params.set('search', queryString);
      history.push({
        search: params.toString(),
      });
    },
    [history, params, queryString],
  );

  const handleTopicFilterChange = useCallback(
    (event: SelectChangeEvent<typeof searchParams.chatTopicsFilter>) => {
      const { target } = event;
      setChatTopicsFilter(typeof target.value === 'string' ? target.value.split(',') : target.value);
    },
    [searchParams, setChatTopicsFilter],
  );

  useEffect(() => {
    if (!isNaN(pageQuery)) {
      setPage(pageQuery);
    }
  }, [pageQuery, setPage]);

  useEffect(() => {
    setSearchString(searchQuery);
  }, [searchQuery, setSearchString]);

  useEffect(() => {
    const chat = chats.find((chat) => chat.id === selectedChatIdQuery);
    setSelectedChatId(chat?.id || selectedChatIdQuery);
    setMessagesModalOpen(!!(chat?.id || selectedChatIdQuery));
  }, [chats, setSelectedChatId, selectedChatIdQuery]);

  useEffect(() => {
    searchChatsQuery(searchParams);
  }, [searchChatsQuery, searchParams]);

  const onSearchStringChange = useCallback(
    (e: { target: { value: React.SetStateAction<string> } }) => {
      setQueryString(e.target.value);
    },
    [setQueryString],
  );

  return (
    <Box>
      <Stack direction="column" spacing={1}>
        <Typography variant="h4">Search chat history</Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            variant="outlined"
            id="ChatSearch"
            data-testid="ChatSearch"
            type="text"
            label="Search query"
            placeholder="Chat ID / Customer ID / Chat Title"
            onChange={onSearchStringChange}
            value={queryString}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={updateQuery} name="chat-search-submit">
            Search
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center">
          <FormControl sx={{ width: 500, mr: 2 }}>
            <InputLabel id="select-chat-topic-label">Chat Topics</InputLabel>
            <Select
              labelId="select-chat-topic-label"
              multiple
              value={searchParams.chatTopicsFilter}
              onChange={handleTopicFilterChange}
              input={<OutlinedInput label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {allChatTopics.map((topic) => (
                <MenuItem key={topic} value={topic}>
                  {topic}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={searchParams.filterByDeadEnd}
                onChange={(event) => setFilterByDeadEnd(event.target.checked)}
              />
            }
            label="Show only dead end chats"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={searchParams.customersOnly}
                onChange={(event) => setCustomersOnly(event.target.checked)}
              />
            }
            label="Customers only"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={searchParams.agentChatsOnly}
                onChange={(event) => setAgentChatsOnly(event.target.checked)}
              />
            }
            label="Agent chats"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={searchParams.followUpOnly}
                onChange={(event) => setFollowUpOnly(event.target.checked)}
              />
            }
            label="Follow up only"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={searchParams.hasMessagesOnly}
                onChange={(event) => setHasMessagesOnly(event.target.checked)}
              />
            }
            label="Hide Empty Chats"
          />
        </Stack>
        <Box
          sx={{
            '& .chat.active': {
              color: (theme) => theme.palette.success.light,
              fontWeight: '600',
            },
            '& .chat.low-quality': {
              color: (theme) => theme.palette.error.light,
              fontWeight: '600',
            },
            '& .chat.high-quality': {
              color: (theme) => theme.palette.success.light,
              fontWeight: '600',
            },
            '& .chat.agent': {
              color: (theme) => theme.palette.success.light,
              fontWeight: '600',
            },
          }}
        >
          <DataGrid
            autoHeight
            columns={columns}
            getRowHeight={() => 'auto'}
            density="compact"
            loading={loading}
            onPaginationModelChange={({ page }) => onPageChange(page)}
            onSortModelChange={handleSortModelChange}
            onCellClick={(params) => onCellClickHandler(params)}
            pageSizeOptions={[searchParams.limit]}
            paginationMode="server"
            paginationModel={{ pageSize: searchParams.limit, page: searchParams.page }}
            rowCount={total}
            rows={chatsWithCustomerDetails || []}
            slots={{
              loadingOverlay: LinearProgress,
              pagination: GridPagination,
            }}
            sortingMode="server"
            sortModel={[searchParams.sortModel]}
          />
        </Box>
      </Stack>
      <ChatModal
        open={messagesModalOpen}
        onClose={messagesModalClose}
        loading={loading}
        chat={selectedChat}
        allChatTopics={allChatTopics}
      />
    </Box>
  );
};

export default ChatHistoryPage;
