import React from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

export default function VccBalanceUpdateToggle(props: { handleFlagChange: () => void; update_vcc_balance: boolean }) {
  return (
    <FormControl>
      <FormLabel>Should the VCC balance be updated?</FormLabel>
      <RadioGroup
        row
        aria-labelledby="update-vcc-balance-label"
        name="update_vcc_balance"
        value={props.update_vcc_balance === false ? '0' : '1'}
        onChange={props.handleFlagChange}
      >
        <FormControlLabel value="1" control={<Radio />} label="Yes" />
        <FormControlLabel value="0" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
}
