import React from 'react';

import { Box, Button, Card, CardActions, CardMedia, FormGroup, FormHelperText, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

interface Props {
  onChange: (value: string) => void;
  previousValue?: string;
  value?: string;
}

const VimeoWidget = (props: Props) => (
  <Box>
    <TextField
      type="text"
      variant="outlined"
      fullWidth
      value={props.value || ''}
      onChange={(event) => props.onChange(event.target.value || '')}
    />
    {props.previousValue && <FormHelperText>The previous value used: {props.previousValue}</FormHelperText>}
    <FormHelperText>Just add the numbers after the .com part of the url, eg:</FormHelperText>
    <img src="/assets/vimeo_help.png" style={{ width: '300px', marginBottom: '15px' }} />
    {props.value && (
      <FormGroup>
        <Grid sm={6}>
          <Card sx={{ maxWidth: 560 }}>
            <CardMedia
              sx={{ width: 560 }}
              component="iframe"
              src={`https://player.vimeo.com/video/${props.value}`}
              height="315"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
            <CardActions>
              <Button color="error" variant="contained" onClick={() => props.onChange('')}>
                Delete
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </FormGroup>
    )}
  </Box>
);

export default VimeoWidget;
