import * as libRegions from '@luxuryescapes/lib-regions';

export const allRegions = [...libRegions.getRegions(), { code: 'world', name: 'world' }];

export const asiaRegions = [
  'China',
  'Hong Kong',
  'India',
  'Indonesia',
  'Israel',
  'Japan',
  'Korea',
  'Macau',
  'Malaysia',
  'Philippines',
  'Qatar',
  'Saudi Arabia',
  'Singapore',
  'Taiwan',
  'Thailand',
  'United Arab Emirates',
  'Vietnam',
];

export const asiaRegionCodes = libRegions
  .getRegions()
  .filter((region) => asiaRegions.includes(region.name))
  .map((region) => region.code);

export const getNonAsiaRegionCodes = libRegions
  .getRegions()
  .filter((region) => !asiaRegions.includes(region.name))
  .map((region) => region.code);
