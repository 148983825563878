import React, { useEffect, useState } from 'react';

import cn from 'clsx';
import { useSnackbar } from 'notistack';

import { Button, Link } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { TripPlannerTripItemTypes } from '~/consts/tripPlanner';

import { addExperienceRecommendationToTrip, getExperienceRecommendations } from '~/services/TripPlannerService';

import { TripPlannerDataGrid } from '../TripPlannerDataGrid';

interface Props {
  isConciergeTrip: boolean;
  tripId: string;
  tripItems: App.TripItemDetailsBasic[];
  onAddSuccess: () => void;
}

function ExperiencesRecommendation({ tripId, isConciergeTrip, tripItems, onAddSuccess }: Props) {
  const [experiences, setExperiences] = useState<App.ExperienceRecommendation[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      // fetch
      if (isConciergeTrip) {
        const experiences = await getExperienceRecommendations(tripId);
        const tripItemsExperienceCodes = new Set(
          tripItems.filter((item) => item.type === TripPlannerTripItemTypes.EXPERIENCE).map((item) => item.code),
        );

        if (experiences.status === 200) {
          setExperiences(
            experiences.result.experiences.filter(
              (experience: App.ExperienceRecommendation) => !tripItemsExperienceCodes.has(experience.id),
            ),
          );
        } else {
          enqueueSnackbar('Failed to get experience recommendations', { variant: 'error' });
        }
      }
    })();
  }, [enqueueSnackbar, isConciergeTrip, tripId, tripItems]);

  const handleAddExperienceToTrip = async (experienceId: string) => {
    const res = await addExperienceRecommendationToTrip(tripId, experienceId);
    if (res.status === 200) {
      enqueueSnackbar('Experience added to trip', { variant: 'success' });
      onAddSuccess();
    } else {
      enqueueSnackbar('Failed to add experience to trip', { variant: 'error' });
    }
  };

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Name', width: 500 },
    {
      field: 'id',
      headerName: 'Offer',
      renderCell: (params: GridRenderCellParams) => (
        <Link
          color="primary"
          rel="noreferrer"
          target="_blank"
          href={`${window.configs['LUX_CUSTOMER_PORTAL']}/experience/${params.row.id}`}
          underline="hover"
        >
          {params.row.id}
        </Link>
      ),
      width: 250,
    },
    {
      headerName: '',
      field: 'Add',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Button variant="contained" color="primary" onClick={() => handleAddExperienceToTrip(params.row.id)}>
          Add to trip
        </Button>
      ),
      display: 'flex',
      align: 'right',
    },
  ];

  return (
    <TripPlannerDataGrid
      rows={experiences}
      columns={columns}
      rowCount={experiences.length}
      loading={!experiences}
      localeText={{
        noRowsLabel: 'No recommendations found',
      }}
      hideFooterPagination
      getRowHeight={() => 'auto'}
      className={cn({ 'no-trip-items': experiences.length === 0 })}
    />
  );
}

export default ExperiencesRecommendation;
