import React from 'react';

import { Paper, Table, TableBody, TableContainer, TableHead } from '@mui/material';

import { SearchExperienceHead } from '~/components/Content/SearchRanking/SearchExperienceHead';
import { SearchHotelHead } from '~/components/Content/SearchRanking/SearchHotelHead';
import SearchHotelRow from '~/components/Content/SearchRanking/SearchHotelRow';
import { EvVariant } from '~/components/Content/SearchRanking/SearchRankingPage';
import { SearchTourHead } from '~/components/Content/SearchRanking/SearchTourHead';
import SearchTourRow from '~/components/Content/SearchRanking/SearchTourRow';
import { SearchUnifiedHead } from '~/components/Content/SearchRanking/SearchUnifiedHead';
import SearchUnifiedRow from '~/components/Content/SearchRanking/SearchUnifiedRow';

import { HotelSearchList, OrderOffer, TourSearchList, UnifiedSearchList } from '~/services/SearchService';

import { SearchCruiseHead } from './SearchCruiseHead';
import SearchCruiseRow from './SearchCruiseRow';

interface CommonProps {
  region: string;
  segments: App.Segment[];
  listOffers: OrderOffer[];
  variant: EvVariant;
}

interface HotelTableProps extends CommonProps {
  vertical: 'hotel';
  offers: HotelSearchList;
}

interface TourTableProps extends CommonProps {
  vertical: 'tour';
  offers: TourSearchList;
}

interface CruiseTableProps extends CommonProps {
  vertical: 'cruise';
  offers: UnifiedSearchList;
}

interface ExperienceTableProps extends CommonProps {
  vertical: 'experience';
  offers: UnifiedSearchList;
}

interface UnifiedTableProps extends CommonProps {
  vertical: 'all' | 'ultra lux';
  offers: UnifiedSearchList;
}

export type SearchRankingTableProps =
  | HotelTableProps
  | TourTableProps
  | CruiseTableProps
  | ExperienceTableProps
  | UnifiedTableProps;

function SearchRankingTable(props: SearchRankingTableProps) {
  const { vertical, region, variant, listOffers, segments } = props;
  const listedOfferIds = new Set(listOffers.map((offer) => offer.bk));
  const isUnifiedTable = vertical === 'all' || vertical === 'ultra lux' || vertical === 'experience';
  const isUnifiedHead = vertical === 'all' || vertical === 'ultra lux';
  return (
    <Paper sx={{ marginTop: 2 }} variant="outlined">
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            {vertical === 'hotel' && <SearchHotelHead segments={segments} />}
            {vertical === 'tour' && <SearchTourHead />}
            {vertical === 'cruise' && <SearchCruiseHead />}
            {vertical === 'experience' && <SearchExperienceHead />}
            {isUnifiedHead && <SearchUnifiedHead />}
          </TableHead>
          <TableBody>
            {props.vertical === 'hotel' &&
              props.offers.map((offer) => (
                <SearchHotelRow
                  key={offer.id}
                  offerId={offer.id}
                  region={region}
                  segments={segments}
                  variant={variant}
                />
              ))}
            {props.vertical === 'tour' &&
              props.offers.map((offer) => (
                <SearchTourRow
                  key={offer}
                  offerId={offer}
                  region={region}
                  variant={variant}
                  isManuallySorted={listedOfferIds.has(offer)}
                />
              ))}
            {props.vertical === 'cruise' &&
              props.offers.map((offer) => (
                <SearchCruiseRow
                  key={offer.bk}
                  offer={offer}
                  region={region}
                  variant={variant}
                  isManuallySorted={listedOfferIds.has(offer.bk)}
                />
              ))}
            {isUnifiedTable &&
              props.offers.map((offer) => (
                <SearchUnifiedRow
                  key={offer.bk}
                  offer={offer}
                  region={region}
                  variant={variant}
                  vertical={vertical}
                  isManuallySorted={listedOfferIds.has(offer.bk)}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default SearchRankingTable;
