import React from 'react';

import { useLocation } from 'react-router';

import { confirmInsuranceUpdate } from '~/services/OrdersService';

import Payment from '../../../Common/Payment';

type Props = {
  order: App.Order;
  insurance: App.InsuranceItem;
};

export class InsuranceUpdateError extends Error {}
const errorMessage = `An error has occurred during payment. Please return to the order summary page and check if payment has been processed before trying again. If further assistance is required please escalate to a team leader or report as a bug following normal process.`;

export const StepPayment = function ({ order }: Props) {
  const handleOrder = () => order;

  const location = useLocation<{
    coveredAmount: number;
  }>();
  const updateData = location.state;

  const handleAfterPayment = async (cart: App.Cart /*, orderId: string*/) => {
    try {
      await confirmInsuranceUpdate(order, {
        updateId: cart.insurance.quoteId,
        coverAmount: updateData.coveredAmount,
        transactionKey: cart.transactionKey,
      });
    } catch (error) {
      console.warn('Insurance update error:', error);
      throw new InsuranceUpdateError(errorMessage);
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Payment handleOrder={handleOrder} onAfterPayment={handleAfterPayment} />
  );
};
