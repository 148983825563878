import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Box, Link, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import useQuery from '~/hooks/useQuery';

import { formatDateLongMonthWithMeridiem, formatDateShort } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';

import { PromoTypesAndUnknown, getLogsByPromo } from '../../services/PromoService';
import CopyableField from '../Common/CopyableField';
import ErrorDisplay from '../Common/ErrorDisplay';
import Spinner from '../Common/Spinner';
import UserName from '../Common/UserName';
import DebugModal from '../DebugModal/DebugModal';
import PromoTesterLink from '../Promo/PromoTester/PromoTesterLink';
import { PromoCodeName } from '../Promo/formatters/PromoCodeNameFormatter';
import PromoCodeUser from '../Promo/formatters/PromoCodeUser';
import PromoLogResultsFormatter from '../Promo/formatters/promoLogResultsFormatter';

import { PromoItemResultTableCell } from './PromoItemResultTableCell';

const sharedAttrs: Pick<GridColDef, 'sortable' | 'display' | 'flex'> = {
  sortable: false,
  display: 'flex',
  flex: 1,
};

type GetColumnsProps = {
  meta: definitions['PromoMeta'];
};

const getColumns = ({ meta }: GetColumnsProps): GridColDef[] => [
  {
    field: 'created_at',
    valueFormatter: (value) => formatDateShort(value),
    renderCell: (params) => (
      <Box title={formatDateLongMonthWithMeridiem(params.row.created_at)}>{formatDateShort(params.row.created_at)}</Box>
    ),
    headerName: 'Logged at',
    ...sharedAttrs,
    flex: 0,
  },
  {
    field: 'code_name',
    renderCell: (params) => PromoCodeName({ codeName: params.row.code_name }),
    headerName: 'Code',
    ...sharedAttrs,
  },
  {
    field: 'Used By',
    renderCell: (params) => PromoCodeUser({ userId: params.row.used_by }),
    ...sharedAttrs,
  },
  {
    field: 'amount',
    valueFormatter: (value) => formatDateShort(value),
    renderCell: (params) => (
      <Stack>
        <Box component="span" color="green">
          {currencyFormatter(params.row.currency ?? 'AUD', params.row.amount)}
        </Box>
      </Stack>
    ),
    headerName: 'Amount',
    ...sharedAttrs,
  },
  {
    field: 'order',
    headerName: 'Order',
    renderCell: (params) => (
      <Stack>
        <Box>
          <Link noWrap href={`/purchases/${params.row.fk_orders}`} title={params.row.fk_orders}>
            {params.row.fk_orders}
          </Link>
        </Box>
      </Stack>
    ),
    ...sharedAttrs,
    flex: 2,
  },
  {
    field: 'validation',
    headerName: 'Amount Validation',
    renderCell: (params) => PromoLogResultsFormatter(params.row),
    ...sharedAttrs,
  },
  {
    field: 'details',
    headerName: 'Details',
    description: 'Details of the promo applying to the order - PromoDiscount, ItemPricing, categoryBK, productBK etc',
    sortable: false,
    renderCell: (params) => {
      if (!params.row.details) {
        return (
          <Typography title="No details? This is unexpected for recent promos and can represent a problem">
            No Log details
          </Typography>
        );
      }

      if (params.row.details.discountItems.length == 0) {
        return (
          <Typography title="No discount items on promo_log. This is unexpected for recent promos and can represent a problem">
            No Log Discount Items
          </Typography>
        );
      }
      // When the promo_code_name (the code_name of the promo we're viewing), doesn't match the code_name on the log, its a child usage
      const showChildPromoLink = params.row.parent_id_promo_code != null;

      return (
        <Stack direction="column" sx={{ minWidth: '50rem' }}>
          <PromoItemResultTableCell
            discountItems={params.row.details.discountItems}
            promo_type={params.row.promo_type}
            currency={params.row.currency}
            meta={meta}
          />
          <div>
            {showChildPromoLink && params.row.promo_code_name === 'ALL' && (
              <Link target="_blank" href={`/promos/${encodeURIComponent(params.row.parent_id_promo_code)}`}>
                [child of {params.row.parent_id_promo_code}]
              </Link>
            )}
            {showChildPromoLink && params.row.promo_code_name !== 'ALL' && (
              <Link target="_blank" href={`/promos/code/${encodeURIComponent(params.row.promo_code_name)}`}>
                [child of {params.row.promo_code_name}]
              </Link>
            )}
          </div>
        </Stack>
      );
    },
    ...sharedAttrs,
    flex: 4,
  },
  {
    field: 'debug',
    headerName: 'Debug',
    renderCell: (params) => (
      <Box>
        <CopyableField
          value={JSON.stringify(params.row, null, 4)}
          label={<DebugModal type="generic" data={params.row ?? { data: 'None' }} />}
        />
      </Box>
    ),
    ...sharedAttrs,
  },
  {
    field: 'reSimulate',
    headerName: 'Re-Simulate Promo',
    renderCell: (params) =>
      PromoTesterLink({
        codeName: params.row.code_name,
        orderId: params.row.fk_orders,
      }),
    ...sharedAttrs,
    flex: 0,
  },
];

interface PromoLog {
  amount: string;
  code_name: string;
  currency: string;
  email: string;
  created_at: Date;
  fk_orders: string;
  promo_type: string;
  promo_value: number;
  used_by: string;
  details: string;
  parent_id_promo_code: string;
  /**
   * This is the code_name of the promo_code that we're currently viewing
   */
  promo_code_name: string;
}
interface PromoLogs {
  [index: number]: PromoLog;
}

interface Props {
  promo?: {
    code_name: string;
    promo_type: PromoTypesAndUnknown;
  };
  userIdFilter?: string;
  meta: definitions['PromoMeta'];
}

const PromoLogs = ({ promo, userIdFilter, meta }: Props) => {
  const [isLogsLoading, setLogsLoading] = useState(true);
  const [hasErrorToFetchLogs, setHasErrorsToFetchLogs] = useState(false);
  const [promoLogs, setPromoLogs] = useState<PromoLogs[]>();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const query = useQuery();
  const isDevMode = query.get('dev') == 'true';

  useEffect(() => {
    const fetchLogsData = async () => {
      if (!promo) {
        return;
      }
      setLogsLoading(true);
      try {
        const promoLogs = await getLogsByPromo(promo.code_name, brand);
        setPromoLogs(
          promoLogs.result
            .filter((r) => r.used_by == userIdFilter || userIdFilter == '' || userIdFilter == null)
            .map((r) => ({
              ...r,
              promo_code_name: promo.code_name,
              promo_type: promo.promo_type,
            })),
        );
        setHasErrorsToFetchLogs(false);
      } catch {
        setHasErrorsToFetchLogs(true);
      }
      setLogsLoading(false);
    };
    fetchLogsData();
  }, [brand, promo, userIdFilter]);

  return (
    <>
      {userIdFilter && (
        <Typography>
          ({`filtered to `}
          <UserName asLink userId={userIdFilter} />)
        </Typography>
      )}
      {!isLogsLoading && (
        <DataGrid
          columns={getColumns({ meta: meta })}
          autoHeight
          getRowId={(row) => row.fk_orders}
          rows={promoLogs || []}
          pageSizeOptions={[10]}
          initialState={{ pagination: { paginationModel: { pageSize: 10, page: 0 } } }}
          getRowHeight={() => 'auto'}
        />
      )}

      {isLogsLoading && hasErrorToFetchLogs && <ErrorDisplay message="Occurred an error while fetching usages." />}

      {isLogsLoading && !hasErrorToFetchLogs && <Spinner size={36} />}
    </>
  );
};

export default PromoLogs;
