export default function loadScript(src, attributes = {}) {
  return new Promise(function (resolve, reject) {
    if (document == undefined) {
      return reject();
    }

    if (scriptAlreadyLoaded(src, attributes)) {
      resolve();
      return;
    }

    let script = document.createElement('script');
    script.src = src;
    for (let [key, value] of Object.entries(attributes)) {
      script.setAttribute(key, value);
    }
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

function scriptAlreadyLoaded(src, attributes) {
  return Array.from(document.querySelectorAll('script')).some((script) => scriptMatches(script, src, attributes));
}

function scriptMatches(script, src, attributes) {
  return script.src == src && Object.keys(attributes).every((key) => script.getAttribute(key) == attributes[key]);
}
