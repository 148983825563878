import { json_headers, request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/recommendation/';
}

export async function sendTPFMTestEmail(emailVariant: string, emailRecipient: string, useRecipientData: boolean) {
  return request(`${basePath()}tpfm_operations/tpfm_test_trigger`, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({
      email_variant: emailVariant,
      email_recipient: emailRecipient,
      use_recipient_data: useRecipientData,
    }),
  });
}
