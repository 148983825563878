import request from 'superagent';

import ApiError from '~/types/ApiError';

import ReactStormpath from '../stormpath';

const host = window.configs.IMAGE_HOST;
const fieldKey = 'file';

const getAuthToken = () => {
  let token = ReactStormpath.getAccessToken();
  if (!token) {
    throw new ApiError({
      message: 'You are not logged in.',
      name: 'AuthorizationError',
    });
  }

  return token;
};

export function uploadImage(file) {
  return request.post(host).withCredentials().auth(getAuthToken(), { type: 'bearer' }).field(fieldKey, file);
}

export function copyImage(id) {
  return request.post(host).withCredentials().auth(getAuthToken(), { type: 'bearer' }).field(fieldKey, `${host}/${id}`);
}

// TODO find out why this is not working
export function deleteImage(id) {
  return request.delete(`${host}/${id}`).withCredentials().auth(getAuthToken(), { type: 'bearer' });
}

export const ALLOWED_IMAGE_MIMETYPES = ['image/jpeg', 'image/png', 'image/svg+xml'];
