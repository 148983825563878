import React, { useEffect, useMemo, useState } from 'react';

import { Circle, GoogleMap, Marker, Rectangle } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '600px',
};

export default function SearchMap({ placeDebug }) {
  const { place } = placeDebug;
  const [map, setMap] = useState();
  const center = place.geometry.location;
  const { viewport } = place.geometry;

  const bounds = useMemo(
    () => ({
      north: viewport.northeast.lat,
      east: viewport.northeast.lng,
      south: viewport.southwest.lat,
      west: viewport.southwest.lng,
    }),
    [viewport],
  );

  const onLoad = React.useCallback((map1) => {
    setMap(map1);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (!map) {
      return;
    }
    map.fitBounds(bounds);
  }, [placeDebug, map, bounds]);

  if (!window.google) {
    return <div>Maps JS is not loaded</div>;
  }

  if (!placeDebug) {
    return <div>Select a place</div>;
  }

  return (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={4} onLoad={onLoad} onUnmount={onUnmount}>
      <Circle
        center={placeDebug.circle.center}
        radius={placeDebug.circle.radius}
        options={{
          strokeColor: '#FFFF00',
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: '#FFFF00',
          fillOpacity: 0.1,
        }}
      />
      <Marker position={place.geometry.location} />
      <Rectangle
        bounds={bounds}
        options={{
          strokeColor: '#FF0000',
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: '#FF0000',
          fillOpacity: 0.05,
        }}
      />
    </GoogleMap>
  );
}
