import React from 'react';

import { Stack, Typography } from '@mui/material';

type OrderItem = App.OrderItem | App.CustomOfferOrderItem;
type Props = { item: OrderItem };

const getStatusColor = (item: OrderItem) => {
  const isCustomOfferItem = (item as App.CustomOfferOrderItem).fk_custom_offer_id !== undefined;

  if (item.status === 'cancelled') {
    return 'red';
  } else if ((item as App.OrderItem).on_hold) {
    return 'yellow';
  } else if ((item as App.OrderItem).reservation_made || (isCustomOfferItem && item.status == 'completed')) {
    return 'green';
  }

  return 'orange';
};

const getStatusText = (item: OrderItem) => {
  const isCustomOfferItem = (item as App.CustomOfferOrderItem).fk_custom_offer_id !== undefined;

  if (isCustomOfferItem && item.status === 'created') {
    const payment = (item as App.CustomOfferOrderItem)?.custom_offer?.payment_metadata;
    if (payment?.payment_type === 'full_payment' || payment?.customer_deposit_payment_made) {
      return 'Pending full payment';
    } else {
      return 'Pending deposit payment';
    }
  }

  if (item.status === 'cancelled') {
    return 'Cancelled';
  } else if ((item as App.OrderItem).on_hold) {
    return 'On hold';
  } else if ((item as App.OrderItem).reservation_made || (isCustomOfferItem && item.status == 'completed')) {
    return 'Booked';
  } else {
    return 'Pending date';
  }
};

export default function ItemStatus({ item }: Props) {
  return (
    <Stack direction="row" spacing={1}>
      <Typography>Status:</Typography>
      <Typography color={getStatusColor(item)}>{getStatusText(item)}</Typography>
    </Stack>
  );
}
