import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Container } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import { CREDIT_EXTENSION_TYPES } from '~/consts/credits';

import { getCreditTypes, getCredits } from '~/services/PaymentsService';
import UsersService from '~/services/UsersService';

import { parseSearchString } from '~/utils/url';

import ErrorDisplay from '../Common/ErrorDisplay';
import Spinner from '../Common/Spinner';

import CreditsCurrencySelector from './CreditsCurrencySelector';
import CreditsPage from './CreditsPage';

type Props = RouteComponentProps<{ id_user: string }>;

const regions = libRegions.getRegions();

export default function CreditsContainer({ match, location }: Props) {
  const { id_user: userId } = match.params;
  const qsString = location.search;

  // const tenant = useSelector((state: App.State) => state.tenant);
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [creditTypes, setCreditTypes] = useState([]);
  const [creditExtensionTypes, setCreditExtensionTypes] = useState([]);
  const [credits, setCredits] = useState([]);
  const [user, setUser] = useState<App.User | null>(null);
  const [initiators, setInitiators] = useState([]);
  const [error, setError] = useState<string | null>(null);
  const [isInitialLoad, setInitialLoad] = useState(true);

  const currency = useMemo<string>(() => {
    const { currency } = parseSearchString(qsString);
    return (currency as string) || 'AUD';
  }, [qsString]);

  const handlePageChange = useCallback((page) => setCurrentPage(page), []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const creditTypes = getCreditTypes(userId);
      const creditExtensionTypes = CREDIT_EXTENSION_TYPES;

      const [credits, user] = await Promise.all([
        getCredits(userId, currency, brand, currentPage),
        UsersService.getUser(userId),
      ]);

      const initiators = await Promise.all(
        [...new Set(credits.result.collection.map((item) => item.fk_added_by))].map((id) => UsersService.getUser(id)),
      );

      setCreditExtensionTypes(creditExtensionTypes);
      setCreditTypes(creditTypes);
      setCredits(credits);
      setInitiators(initiators);
      setUser(user);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
      setInitialLoad(false);
    }
  }, [brand, currency, currentPage, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container maxWidth="xl">
      <CreditsCurrencySelector user={user} regions={regions} currency={currency as string} />

      {isInitialLoad && !user && <Spinner />}

      {error && <ErrorDisplay message={error} />}

      {!isInitialLoad && user && (
        <CreditsPage
          creditExtensionTypes={creditExtensionTypes}
          creditTypes={creditTypes}
          credits={credits}
          initiators={initiators}
          user={user}
          idUser={userId}
          currency={currency}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          isLoading={isLoading}
          rerender={fetchData}
        />
      )}
    </Container>
  );
}
