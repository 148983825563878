import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import * as ES from '~/services/ExperiencesService';

import ExperienceItem from './item';

interface ExperienceListProps {
  tenant: App.Tenant;
  initialCount?: number;
  hasAllowedRefund: boolean;
  experiences: App.ExperienceItem[];
  showRefundModal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, experienceItem: ES.ExperienceItem) => void;
  orderBrand: string;
}

export default function ExperiencesList(props: ExperienceListProps) {
  const { tenant, experiences, initialCount = 1, showRefundModal, hasAllowedRefund, orderBrand } = props;

  const [offers, setOffers] = useState<ES.ExperienceOffers | null>([]);
  const [bookingDetails, setBookingDetails] = useState<ES.BookingDetails[] | null>([]);

  const getOfferById = async (ids: string[]) => {
    const expOffers = [];
    for (const expId of ids) {
      const res = await ES.getExperienceOfferById({
        id: expId,
        brand: tenant.brand,
        curationData: true,
      });
      if (!res?.errors) expOffers.push(res?.result);
    }
    if (expOffers.length > 0) setOffers(expOffers);
  };

  const matchOfferWithItem = (expItemId: string): ES.ExperienceOffer | null => {
    if (offers?.length === 0 || !expItemId) return null;
    return offers.find((expOffer) => expOffer.id === expItemId);
  };

  const matchBookingDetailsWithItem = (expItemId: string) => {
    return bookingDetails?.find((booking) => booking.experienceItemId === expItemId);
  };

  const getBookingDetails = async (expItemIds: string) => {
    const bookingDetails = await ES.getBookingDetails(expItemIds);
    setBookingDetails(bookingDetails?.result as ES.BookingDetails[]);
  };

  useEffect(() => {
    const mapExperiencesIds = experiences.map((exp) => exp.provider_offer_id);
    const noDuplicatedIds = [...new Set(mapExperiencesIds)];
    getOfferById(noDuplicatedIds);

    const experienceItemIDs = [...new Set(experiences.map((exp) => exp.id))].join(',');
    getBookingDetails(experienceItemIDs);
  }, [experiences]);

  return (
    <Box>
      {experiences.map((expItem, i) => (
        <ExperienceItem
          key={expItem.id}
          count={initialCount + i}
          experienceItem={expItem}
          showRefundModal={showRefundModal}
          hasAllowedRefund={hasAllowedRefund}
          experienceOffer={matchOfferWithItem(expItem.provider_offer_id)}
          orderBrand={orderBrand}
          experienceBookingDetail={matchBookingDetailsWithItem(expItem.id)}
        />
      ))}
    </Box>
  );
}
