export function getAllBaggageV2(traveller, direction: 'departing' | 'returning'): TravellerBaggage[] {
  return (
    traveller?.baggage
      .filter((baggage) => {
        const baggageExists = !!baggage && baggage.baggage_type;
        const departingCount = baggage.departing_count && direction === 'departing';
        const returningCount = baggage.returning_count && direction === 'returning';
        return baggageExists && (departingCount || returningCount);
      })
      .map((baggage) => ({
        id: baggage.baggage_type?.split('-')[0],
        departing: {
          count: direction === 'departing' ? baggage?.departing_count ?? 0 : 0,
        },
        returning: {
          count: direction === 'returning' ? baggage?.returning_count ?? 0 : 0,
        },
      })) ?? []
  );
}
