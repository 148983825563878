import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';

import { Alert, Box, Button, FormControl, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { getBusiness, patchBusiness } from '~/services/BusinessTraveller/BusinessTravellerService';

interface Params {
  businessId: string;
  tab: string;
}

const emptyBusiness: App.Business = {
  country: '',
  employeeCount: '',
  id: '',
  industry: '',
  name: '',
  phone: '',
};

const industries = {
  accommodation: 'Accommodation and food services',
  administration: 'Administrative and support services',
  agriculture: 'Agriculture, forestry and fishing',
  arts: 'Arts and recreation services',
  construction: 'Construction',
  education: 'Education and training',
  finance: 'Financial and insurance services',
  health: 'Health care and social assistance',
  manufacturing: 'Manufacturing',
  mining: 'Mining',
  other: 'Other',
  publicadmin: 'Public administration and safety',
  realestate: 'Rental, hiring and real estate services',
  retail: 'Retail or wholesale trade',
  science: 'Professional, scientific and technical services',
  telecommunications: 'Information media and telecommunications',
  transport: 'Transport, postal and warehousing',
  utilities: 'Electricity, gas, water and waste services',
};

const businessIdentifierMap = {
  australian_business_number: 'ABN',
};

export default function BusinessTab() {
  const [business, setBusiness] = useState<App.Business>(emptyBusiness);
  const [fetchBusinessError, setFetchBusinessError] = useState(false);
  const { businessId } = useParams<Params>();
  const { enqueueSnackbar } = useSnackbar();

  async function fetchBusiness() {
    try {
      const result = await getBusiness(businessId);
      setBusiness(result);
    } catch (e) {
      setFetchBusinessError(true);
      console.error(e);
    }
  }

  function onChange(e) {
    const { name, value } = e.target;

    setBusiness((prevState) => ({ ...prevState, [name]: value }));
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      await patchBusiness(businessId, business);
    } catch (e) {
      enqueueSnackbar(`Error patching business [${JSON.stringify(e, null, 2)}]`, {
        variant: 'error',
      });
    }
    return;
  }

  useEffect(() => {
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={2} sx={{ wordWrap: 'anywhere' }}>
        {fetchBusinessError && <Alert severity="error">Error fetching business with id {businessId}</Alert>}
        <Grid2 container gap={2} spacing={2}>
          <Grid2 alignItems="center" container flexDirection="row" xs={12}>
            <Grid2 xs={1}>
              <Typography>Name:</Typography>
            </Grid2>
            <Grid2 xs={5}>
              <FormControl fullWidth>
                <OutlinedInput
                  id="business_name"
                  label="Name"
                  name="name"
                  notched={false}
                  onChange={onChange}
                  placeholder="Enter name of business"
                  value={business.name}
                />
              </FormControl>
            </Grid2>
          </Grid2>
          <Grid2 alignItems="center" container flexDirection="row" xs={12}>
            <Grid2 xs={1}>
              <Typography>ID:</Typography>
            </Grid2>
            <Grid2>
              <Typography>{business.id}</Typography>
            </Grid2>
          </Grid2>
          <Grid2 alignItems="center" container flexDirection="row" xs={12}>
            <Grid2 xs={1}>
              <Typography>Phone Number:</Typography>
            </Grid2>
            <Grid2 xs={5}>
              <FormControl fullWidth>
                <OutlinedInput
                  id="business_phone"
                  label="Phone Number"
                  name="phone"
                  notched={false}
                  onChange={onChange}
                  placeholder="Enter phone number of business"
                  value={business.phone}
                />
              </FormControl>
            </Grid2>
          </Grid2>
          <Grid2 alignItems="center" container flexDirection="row" xs={12}>
            <Grid2 xs={1}>
              <Typography>Industry:</Typography>
            </Grid2>
            <Grid2 xs={5}>
              <FormControl fullWidth>
                <Select
                  value={business.industry}
                  id="business_industry"
                  label="Industry"
                  name="industry"
                  notched={false}
                  onChange={onChange}
                >
                  {Object.keys(industries).map((industry) => (
                    <MenuItem key={industry} value={industry}>
                      {industries[industry]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>
          <Grid2 alignItems="center" container flexDirection="row" xs={12}>
            <Grid2 xs={1}>
              <Typography>Number of employees:</Typography>
            </Grid2>
            <Grid2>
              <Typography>{business.employeeCount}</Typography>
            </Grid2>
          </Grid2>
          <Grid2 alignItems="center" container flexDirection="row" xs={12}>
            <Grid2 xs={1}>
              <Typography>{businessIdentifierMap[business.businessIdentifierType]}:</Typography>
            </Grid2>
            <Grid2>
              <FormControl fullWidth>
                <OutlinedInput
                  id="business_identifier"
                  name="businessIdentifier"
                  notched={false}
                  onChange={onChange}
                  placeholder={`Enter ${businessIdentifierMap[business.businessIdentifierType]}`}
                  value={business.businessIdentifier}
                />
              </FormControl>
            </Grid2>
          </Grid2>
        </Grid2>
        <Box display="flex" justifyContent="flex-start">
          <Button type="submit" variant="contained">
            Save
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
