import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridEditInputCell,
  GridEditSingleSelectCell,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';

import { definitions } from '@luxuryescapes/contract-svc-promo';
import { Region, getRegions } from '@luxuryescapes/lib-regions';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useQuery from '~/hooks/useQuery';

import {
  InternalPromoWithDiscounts,
  createOrUpdatePromoDiscount,
  deletePromoDiscount,
  getProductTypeOptions,
  getPromoDiscountById,
} from '~/services/PromoService';

import { sortBy } from '~/utils/arrayUtils';
import currencyFormatter from '~/utils/currencyFormatter';
import { getCurrencyCodeByRegionCode } from '~/utils/getCurrencyCodeByRegionCode';

import MultiProductOptionSelect from './MultiProductOptionSelect';

const REGION_SORT_ORDER = [
  'Australia',
  'New Zealand',
  'United States',
  'United Kingdom',
  'Singapore',
  'India',
  'Germany',
  'Italy',
  'Canada',
  'China',
  'France',
  'Hong Kong',
  'Indonesia',
  'Ireland',
  'Israel',
  'Japan',
  'Korea',
  'Macau',
  'Malaysia',
  'Netherlands',
  'Philippines',
  'Qatar',
  'Russia',
  'Saudi Arabia',
  'South Africa',
  'Spain',
  'Taiwan',
  'Thailand',
  'United Arab Emirates',
  'Vietnam',
];

const sortedRegions = sortBy(
  getRegions(),
  (region) => {
    const regionOrder = REGION_SORT_ORDER.indexOf(region.name);
    if (regionOrder === -1) {
      // Put it at the bottom of the list if it's not in the desired order
      return 999;
    }
    return regionOrder;
  },
  'asc',
);

interface Props {
  promoCode: InternalPromoWithDiscounts;
  parentPromo?: InternalPromoWithDiscounts | null;
  isPromoAddDiscountView?: boolean;
  setIsDiscountReady?: Dispatch<SetStateAction<boolean>>;
  promoType: string;
  promoDiscountData: App.PromoDiscount[];
  setPromoDiscountData: (discounts: App.PromoDiscount[]) => void;
  promoProductTypes: definitions['PromoMeta']['promo_product_types'];
}

function DiscountObjectForm({
  promoCode,
  parentPromo,
  setIsDiscountReady,
  promoType,
  promoDiscountData,
  setPromoDiscountData,
  promoProductTypes,
}: Props) {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const query = useQuery();
  // showAllProducts of true will show every possible product type option in the products type dropdown (including 'preferToHide' options)
  const showAllProducts = !!query.get('showAllProducts');
  const [hiddenProductOptions, setHiddenProductOptions] = useState<string[]>([]);

  const getPromoDiscountData = useCallback(async () => {
    if (promoCode) {
      const promoDiscountData = await getPromoDiscountById(promoCode.id_promo_code, brand);
      const refinedDiscountData = promoDiscountData.result.promoDiscounts.map((discountObject) => ({
        ...discountObject,
        id: discountObject.id_promo_code_discount,
      }));
      setPromoDiscountData(refinedDiscountData);
    }
  }, [brand, promoCode, setPromoDiscountData]);

  const handleAddDiscount = () => {
    const id = uuid();
    setPromoDiscountData([
      ...promoDiscountData,
      {
        id,
        id_promo_code: promoCode?.id_promo_code,
        id_promo_code_discount: id,
        products: [],
        region: '',
        subscription_item_discount_type: 'none',
        isNew: true,
      } as unknown as App.PromoDiscount,
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'region' },
    }));
    setOnlyEditingColumnsVisible();
  };

  useEffect(() => {
    let hiddenFields: Array<string> = [];

    getProductTypeOptions(promoProductTypes).forEach((option) => {
      const matchingProductOptions = promoDiscountData.filter(
        (pd) => showAllProducts || pd.products.includes(option.value as definitions['Item Product BK Options']),
      );
      if (option?.preferToHide && matchingProductOptions.length === 0) {
        hiddenFields.push(option.value);
      } else {
        hiddenFields = hiddenFields.filter((hf) => hf !== option.value);
      }
    });
    setHiddenProductOptions(hiddenFields);
  }, [query, promoDiscountData, showAllProducts]);

  const handleCancelDiscount = useCallback(
    (params) => {
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      const editedRow = promoDiscountData.find((row) => row.id_promo_code_discount === params.id);
      if (editedRow?.isNew) {
        setPromoDiscountData(promoDiscountData.filter((row) => row.id_promo_code_discount !== params.id));
      }
      setOnlyViewingColumnsVisible();
    },
    [promoDiscountData, rowModesModel, setPromoDiscountData],
  );

  const handleSaveDiscount = (params) => {
    setRowModesModel({
      ...rowModesModel,
      [params.id]: { mode: GridRowModes.View },
    });
    setOnlyViewingColumnsVisible();
  };

  const handleEditDiscount = (params) => {
    setRowModesModel({
      ...rowModesModel,
      [params.id]: { mode: GridRowModes.Edit, fieldToFocus: 'region' },
    });
    setOnlyEditingColumnsVisible();
  };

  const validateFields = (payload) => {
    const { subscription_item_discount_type, subscription_item_discount_value } = payload;

    if (subscription_item_discount_type !== 'none' && subscription_item_discount_value > 0) {
      if (subscription_item_discount_type === 'percentage' && subscription_item_discount_value > 100) {
        throw new Error('Subscription item discount percentage must be less than or equal to 100.');
      } else {
        if (subscription_item_discount_value == 0) {
          throw new Error('Subscription item discount value must be greater than 0.');
        }
      }
    }
  };

  const handleDeletePromoDiscount = async (params) => {
    if (!promoCode) {
      setPromoDiscountData(promoDiscountData.filter((row) => row.id_promo_code_discount !== params.id));
      return;
    }

    let response;
    try {
      response = await deletePromoDiscount(params.id_promo_code, params.id_promo_code_discount);

      if (response.status == 200) {
        enqueueSnackbar('Discount deleted successfully', {
          variant: 'success',
        });
        setPromoDiscountData(promoDiscountData.filter((row) => row.id_promo_code_discount !== params.id));
      } else {
        enqueueSnackbar(
          `Error deleting discount - ${'message' in response.body ? response.body.message : ''} - ${JSON.stringify(
            response.body,
          )}`,
          {
            variant: 'error',
          },
        );
      }
    } catch (err) {
      enqueueSnackbar(`Error deleting discount - ${JSON.stringify({ err, response }, null, 4)}`, {
        variant: 'error',
      });
    }
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false } as App.PromoDiscount;
    try {
      const payload = {
        id_promo_code: newRow.id_promo_code,
        id_promo_code_discount: newRow.id_promo_code_discount,
        discount_value: newRow.discount_value,
        max_discount: newRow.max_discount,
        min_spend: newRow.min_spend,
        products: updatedRow.products,
        subscription_item_discount_value: +newRow.subscription_item_discount_value,
        subscription_item_discount_type: newRow.subscription_item_discount_type,
        region: newRow.region,
      };

      validateFields(payload);
      // promoCode exists means this is not the create form -> update form.
      // We should only create or update promo discount when it is update form.
      // Promo Discount in the create form will be created only when promo code has been created.
      if (promoCode) {
        await createOrUpdatePromoDiscount(payload);
      }
      setPromoDiscountData(
        promoDiscountData.map((row) =>
          row.id_promo_code_discount === newRow.id_promo_code_discount ? updatedRow : row,
        ),
      );
      return updatedRow;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        `processRowUpdate - ${newRow.id_promo_code_discount} - error\n${err.message}\n${err.stack}\n\n ${JSON.stringify(
          newRow,
        )}`,
      );
      setRowModesModel({ ...rowModesModel, [newRow.id_promo_code_discount]: { mode: GridRowModes.Edit } });
      setOnlyEditingColumnsVisible();
      enqueueSnackbar(err?.message, {
        autoHideDuration: 5000,
      });
    }
  };

  const getProductBKOption = (
    optionValue: string,
    promoProductTypes: definitions['PromoMeta']['promo_product_types'],
  ) => {
    const match = getProductTypeOptions(promoProductTypes).filter((o) => o.value === optionValue);
    if (match && match.length > 0) {
      return {
        label: match[0].label,
        value: match[0].value,
      };
    } else {
      console.error('Fallback productBKOptions used, no display name found for productBK: ' + optionValue);
      return {
        label: optionValue,
        value: optionValue,
      };
    }
  };

  const sharedOpts: Partial<GridColDef> = {
    editable: true,
    sortable: true,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    flex: 1,
  };

  const SHOW_PRODUCT_LABELS_THRESHOLD = 3;
  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...sharedOpts,
        field: 'region',
        headerName: 'Region',
        flex: 1,
        type: 'singleSelect',
        valueOptions: sortedRegions,
        getOptionLabel: (region: Region) => region.name,
        getOptionValue: (region: Region) => region.code,
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'products',
        headerName: 'Product Type',
        align: 'left',
        flex: 2,
        styles: { maxHeight: '500px' },
        renderCell: (params) => (
          <Box
            key={`${params.id}_${params.tabIndex}`}
            title={`${
              params.row.products.length == 0
                ? 'None'
                : `${params.row.products
                    .map((p) => getProductBKOption(p, promoProductTypes))
                    .map((o) => `${o.label}`)
                    .join('\n')}`
            }`}
          >
            {'row' in params ? (
              params.row.products.length > 0 && params.row.products.length <= SHOW_PRODUCT_LABELS_THRESHOLD ? (
                params.row.products
                  .map((p) => getProductBKOption(p, promoProductTypes))
                  .map((p) => <Typography key={`${p.id}_${p.value}`}>{p.label}</Typography>)
              ) : (
                <Typography>
                  {params.row.products.length == 0 ? `None` : `${params.row.products.length} Products Selected`}
                </Typography>
              )
            ) : (
              <Typography>None</Typography>
            )}
          </Box>
        ),
        renderEditCell: (params) => {
          return (
            <MultiProductOptionSelect
              id={params.id}
              field="products"
              value={params.row.products}
              filterFields={hiddenProductOptions}
              fullWidth
              promoProductTypes={promoProductTypes}
            />
          );
        },
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'discount_value',
        headerName: `${promoType === 'percentage' ? 'Percentage' : 'Fixed'} Discount Value`,
        type: 'number',
        renderEditCell: (params) => (
          <GridEditInputCell
            {...params}
            inputProps={{
              min: 1,
            }}
            sx={{
              border: '1px solid',
              borderColor: 'rgb(190,190,190)',
              height: '83%',
              borderRadius: '4px',
            }}
            fullWidth
          />
        ),
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'max_discount',
        headerName: 'Max Discount',
        type: 'number',
        title: 'Discount for subscription items - does not apply to subscription item discounts',
        renderEditCell: (params) => (
          <GridEditInputCell
            {...params}
            inputProps={{
              min: 0,
              onKeyPress: (e) => {
                if (e.key === '-') {
                  e.preventDefault();
                }
              },
            }}
            sx={{
              border: '1px solid',
              borderColor: 'rgb(190,190,190)',
              height: '83%',
              borderRadius: '4px',
            }}
          />
        ),
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'min_spend',
        headerName: 'Min Spend',
        type: 'number',
        renderEditCell: (params) => (
          <GridEditInputCell
            {...params}
            inputProps={{
              min: 0,
              onKeyPress: (e) => {
                if (e.key === '-') {
                  e.preventDefault();
                }
              },
            }}
            sx={{
              border: '1px solid',
              borderColor: 'rgb(190,190,190)',
              height: '83%',
              borderRadius: '4px',
            }}
          />
        ),
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'subscription_item_discount_value',
        headerName: 'Subscription Discount',
        title: 'Discount for subscription items - will not apply contribute to max discount checks',
        type: 'number',
        renderCell: (params) => {
          if (
            params.row.subscription_item_discount_type === 'none' ||
            params.row.subscription_item_discount_value == 0
          ) {
            return '';
          }
          if (params.row.subscription_item_discount_type === 'percentage') {
            return `${params.row.subscription_item_discount_value}%`;
          }

          return currencyFormatter(
            getCurrencyCodeByRegionCode(params.row.region) ?? 'AUD',
            params.row.subscription_item_discount_value,
          );
        },
        renderEditCell: (params) => (
          <GridEditInputCell
            {...params}
            inputProps={{
              min: 1,
            }}
            sx={{
              border: '1px solid',
              borderColor: 'rgb(190,190,190)',
              height: '83%',
              borderRadius: '4px',
              paddingLeft: '1rem',
            }}
            fullWidth
          />
        ),
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'subscription_item_discount_type',
        headerName: 'Subscription Type',
        type: 'singleSelect',
        valueOptions: [
          { value: 'percentage', label: 'Percentage' },
          { value: 'fixed_amount', label: 'Fixed Amount' },
          { value: 'none', label: 'None' },
        ],

        renderEditCell: (params) => (
          <GridEditSingleSelectCell
            {...params}
            sx={{
              border: '1px solid',
              borderColor: 'rgb(190,190,190)',
              height: '83%',
              borderRadius: '4px',
              marginRight: '10px',
            }}
            fullWidth
          />
        ),
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
      {
        ...sharedOpts,
        flex: 2,
        editable: false,
        field: 'action',
        headerName: 'Action',
        renderCell: (params) => {
          if (rowModesModel[params.id]?.mode === GridRowModes.Edit) {
            return (
              <ButtonGroup variant="contained" size="small" disableElevation>
                <Button key={`${params.id}-save`} onClick={() => handleSaveDiscount(params)}>
                  Save
                </Button>
                <Button key={`${params.id}-cancel`} onClick={() => handleCancelDiscount(params)}>
                  Cancel
                </Button>
                {!params.row.isNew && (
                  <Button
                    key={`${params.id}-delete`}
                    color="error"
                    onClick={() => {
                      if (confirm('Are you sure you want to delete this discount row?')) {
                        handleDeletePromoDiscount(params.row);
                      }
                    }}
                  >
                    Delete
                  </Button>
                )}
              </ButtonGroup>
            );
          }
          return (
            <ButtonGroup variant="text" size="small">
              <Button key={`${params.id}-edit`} onClick={() => handleEditDiscount(params)}>
                Edit
              </Button>
            </ButtonGroup>
          );
        },
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
    ],
    [
      sharedOpts,
      promoType,
      hiddenProductOptions,
      rowModesModel,
      promoCode,
      parentPromo?.promo_category,
      handleSaveDiscount,
      handleCancelDiscount,
      handleEditDiscount,
      setPromoDiscountData,
      promoDiscountData,
    ],
  );

  useEffect(() => {
    getPromoDiscountData();
  }, [getPromoDiscountData]);

  useEffect(() => {
    const isUpdateDiscount = Object.values(rowModesModel).reduce(
      (prev, cur) => prev || cur?.mode == GridRowModes.Edit,
      false,
    );
    const isDiscountReady = promoDiscountData.some((discount) => discount.region && discount.products.length > 0);
    setIsDiscountReady(!isUpdateDiscount && isDiscountReady);

    const hasMaxDiscountOrIsPercentagePromo =
      promoType === 'percentage' || promoDiscountData.some((d) => d.max_discount);

    setColumnVisibilityModel((prev) => ({
      ...prev,
      max_discount: hasMaxDiscountOrIsPercentagePromo,
    }));
  }, [promoDiscountData, promoType, rowModesModel, setIsDiscountReady]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    subscription_item_discount_type: false,
  });

  const setOnlyEditingColumnsVisible = () => {
    setColumnVisibilityModel((prev) => ({
      ...prev,
      subscription_item_discount_type: true,
    }));
  };

  const setOnlyViewingColumnsVisible = () => {
    setColumnVisibilityModel((prev) => ({
      ...prev,
      subscription_item_discount_type: false,
    }));
  };

  const handleEditStop = () => {
    setOnlyViewingColumnsVisible();
  };

  return (
    <Box>
      <PageSubheader title="Add discount">
        <Stack direction="row">
          <Button onClick={handleAddDiscount} variant="contained" size="small" startIcon={<AddIcon />}>
            Add Discount
          </Button>
        </Stack>
      </PageSubheader>
      <Box mt={3}>
        <DataGrid
          columns={columns}
          editMode="row"
          autoHeight
          rows={promoDiscountData}
          rowModesModel={rowModesModel}
          initialState={{ pagination: { paginationModel: { pageSize: 10, page: 0 } } }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => {
            enqueueSnackbar(error.message, { autoHideDuration: 5000 });
          }}
          columnVisibilityModel={columnVisibilityModel}
          onRowEditStop={handleEditStop}
          getRowHeight={() => 'auto'}
        />
      </Box>
    </Box>
  );
}

export default DiscountObjectForm;
