import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { Container } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';
import * as libRegions from '@luxuryescapes/lib-regions';

import { getCurrentSubscriptionPeriod, hasActiveOrInactiveSubscription } from '~/components/Membership/utils';

import { getCustomerMembershipSubscriptions } from '~/services/MembershipService';
import { getOrderDetail, getOrderItemBySubscriptionPeriodId } from '~/services/OrdersService';
import UsersService from '~/services/UsersService';

import featureToggle from '~/utils/featureToggle';

import ErrorDisplay from '../Common/ErrorDisplay';
import Spinner from '../Common/Spinner';
import { LUXURY_PLUS } from '../Membership/constants';

import UserDetails from './UserDetails';

type Props = RouteComponentProps<{ user_email: string }>;

const regions = libRegions.getRegions();

export default function UserPage(props: Props) {
  const tenant = useSelector((state: App.State) => state.tenant);

  const { match } = props;
  const { brand } = tenant;
  const { user_email: userIdOrEmail } = match.params;

  const [user, setUser] = useState<App.User | null>(null);
  const [schema, setSchema] = useState({});
  const [tenancy, setTenancy] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [subscriptions, setSubscriptions] = useState<App.MembershipSubscriptions | null>(null);
  const [currentSubscriptionOrderItem, setCurrentSubscriptionOrderItem] = useState<Order.SubscriptionItem | null>(null);
  const [currentSubscriptionOrder, setCurrentSubscriptionOrder] = useState<App.Order | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const handleUpdate = useCallback((user: App.User) => {
    setUser(user);
  }, []);

  const fetchSubscriptions = useCallback(
    async (userId: string): Promise<App.MembershipSubscriptions | null> => {
      try {
        const { result } = await getCustomerMembershipSubscriptions({ customerId: userId });
        return result;
      } catch (error) {
        featureToggle.availableToShow('SHOW_SUBSCRIPTIONS') &&
          error.status !== 404 &&
          enqueueSnackbar(`There was an error fetching user ${LUXURY_PLUS.PROGRAM_NAME} subscription`, {
            variant: 'error',
          });
        return null;
      }
    },
    [enqueueSnackbar],
  );

  const fetchCurrentSubscriptionOrderItem = useCallback(
    async (subscriptionPeriodId: string): Promise<Order.SubscriptionItem | null> => {
      try {
        return await getOrderItemBySubscriptionPeriodId(subscriptionPeriodId);
      } catch (error) {
        featureToggle.availableToShow('SHOW_SUBSCRIPTIONS') &&
          error.status !== 404 &&
          enqueueSnackbar(`There was an error fetching user ${LUXURY_PLUS.PROGRAM_NAME} subscription order item`, {
            variant: 'error',
          });
        return null;
      }
    },
    [enqueueSnackbar],
  );

  const fetchOrder = useCallback(async (orderId: string): Promise<App.Order | null> => {
    try {
      return await getOrderDetail(orderId);
    } catch (error) {
      setError(error.message);
      return null;
    }
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const user: App.User = await UsersService.getUser(userIdOrEmail, { brand });

      const [schema, tenancy, subscriptions] = await Promise.all([
        UsersService.getUpdateSchema({ id_member: user.id_member }, brand),
        UsersService.getUserTenancy(user.email),
        fetchSubscriptions(user.id_member),
      ]);

      let currentSubscriptionOrderItem = null;
      let currentSubscriptionOrder = null;

      if (hasActiveOrInactiveSubscription(subscriptions)) {
        const currentSubscriptionPeriod = getCurrentSubscriptionPeriod(subscriptions);
        if (currentSubscriptionPeriod) {
          currentSubscriptionOrderItem = await fetchCurrentSubscriptionOrderItem(currentSubscriptionPeriod.id);
        }
      }

      if (currentSubscriptionOrderItem) {
        currentSubscriptionOrder = await fetchOrder(currentSubscriptionOrderItem.fk_order_id);
      }

      setUser(user);
      setSchema(schema);
      setTenancy(tenancy);
      setSubscriptions(subscriptions);
      setCurrentSubscriptionOrderItem(currentSubscriptionOrderItem);
      setCurrentSubscriptionOrder(currentSubscriptionOrder);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [brand, fetchCurrentSubscriptionOrderItem, fetchOrder, fetchSubscriptions, userIdOrEmail]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container maxWidth="xl">
      {isLoading && <Spinner />}

      {error && <ErrorDisplay message={error} />}

      {!isLoading && user && (
        <UserDetails
          user={user}
          schema={schema}
          tenant={tenant}
          tenancy={tenancy}
          regions={regions}
          update={handleUpdate}
          subscriptions={subscriptions}
          currentSubscriptionOrderItem={currentSubscriptionOrderItem}
          currentSubscriptionOrder={currentSubscriptionOrder}
          refreshData={fetchData}
        />
      )}
    </Container>
  );
}
