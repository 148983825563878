import React from 'react';

import { Box, Button, Chip, Stack, TextField } from '@mui/material';

interface Props {
  handleChange: (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  country: string;
  adminLevelOne: string;
  locality: string;
  sublocality: string;
  colloquialArea: string;
  lat: string;
  lng: string;
  placeId: string;
  level: string;
  onSave: (_: unknown) => void;
  onRemove: (primaryKeyId: string) => void;
  popularList: {
    locationAlert: { value: string };
    placeId: string;
  }[];
}

export default function PopularDestination(props: Props) {
  const {
    handleChange,
    country,
    adminLevelOne,
    locality,
    sublocality,
    colloquialArea,
    lat,
    lng,
    placeId,
    level,
    onSave,
    onRemove,
    popularList,
  } = props;

  const handleOnClick = () => {
    onSave({
      place_id: placeId,
      google_result: {
        continent: '',
        country,
        administrative_area_level_1: adminLevelOne,
        locality: locality,
        colloquial_area: colloquialArea,
      },
      location_alert: {
        level,
        value: sublocality,
        geocode: {
          lng,
          lat,
        },
      },
      brand: 'luxuryescapes',
    });
  };

  return (
    <div className="popular-destination-form">
      <Stack mt={2} direction="row" spacing={2} alignItems="center">
        <Box flexGrow={1}>
          <TextField
            id="formControlTextSublocality"
            label="Destination Name"
            value={sublocality}
            onChange={handleChange('sublocality')}
            fullWidth
          />
        </Box>

        <Box>
          <Button type="button" variant="contained" onClick={handleOnClick}>
            Save Destination
          </Button>
        </Box>
      </Stack>

      <Stack mt={4} p={2} bgcolor="Background" direction="row" spacing={2}>
        {popularList.map((p, index) => (
          <Chip
            key={`${p.placeId}-${index}`}
            variant="outlined"
            color="primary"
            label={p.locationAlert.value}
            onDelete={() => onRemove(p.placeId)}
            size="medium"
            sx={{ backgroundColor: 'white' }}
          />
        ))}
      </Stack>
    </div>
  );
}
