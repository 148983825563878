import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { EXP_PROVIDER_REZDY_PREFIX, PROVIDER_OPTIONS } from '~/consts/experiences';
import { OPERATOR_CANCELLATION } from '~/consts/refund';

import { cancelOrder, getExperienceRefund, resendConfirmationEmail } from '~/services/OrdersService';

import generateTransactionKey from '~/utils/generateTransactionKey';

import List from './list';

interface ExperiencesListProps {
  hasAllowedRefund: boolean;
  showRefundModal: (params: { itemId?: string }) => void;
  order: App.Order;
  refreshData: () => void;
}

const defaultRequestState = { loading: false, error: null };

export default function ExperiencesList(props: ExperiencesListProps) {
  const { hasAllowedRefund, showRefundModal, order, refreshData } = props;

  const tenant = useSelector((state: App.State) => state.tenant);

  const { enqueueSnackbar } = useSnackbar();

  const [refundMessage, setRefundMessage] = useState(null);
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [experienceItem, setExperienceItem] = useState(null);
  const [resendExperiencesLoading, setResendExperiencesLoading] = useState(false);
  const [refundDataRequest, setRefundDataRequest] = useState(defaultRequestState);
  const [refundaAllItemsRequest, setRefundAllItemsRequest] = useState(defaultRequestState);

  const onResendExperienceConfirmationEmail = useCallback(
    async (event) => {
      event.stopPropagation();
      setResendExperiencesLoading(true);

      const orderId = !order.items.length ? order.experience_items[0].fk_order_id : order.id_orders;
      // to send offerId for standalone purchases
      const offerId = !order.items.length ? order.experience_items[0].provider_offer_id : undefined;

      try {
        await resendConfirmationEmail(orderId, offerId);
        enqueueSnackbar('Confirmation Email resented', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setResendExperiencesLoading(false);
      }
    },
    [enqueueSnackbar, order],
  );

  const canResendExperienceEmail = order.experience_items.some((item) => item.status !== 'cancelled');
  const allItemsCancelled = !order.experience_items.some((item) => item.status !== 'cancelled');
  const hasOnlyRezdyItems = !order.experience_items.some((item) => item.provider !== EXP_PROVIDER_REZDY_PREFIX);
  const onlyRefundAllOrderItems = hasAllowedRefund && hasOnlyRezdyItems;
  const showRefundAllOrderItemsButton =
    onlyRefundAllOrderItems &&
    !order.experience_items.some((item) => item.provider_offer_id !== order.experience_items[0].provider_offer_id);

  const handleCloseExperienceRefundModal = () => setRefundModalOpen(false);

  const showRefundAllProviderItemsModal = (experienceItem = null) => {
    const item = experienceItem
      ? order.experience_items.find((item) => item.provider_offer_id === experienceItem.provider_offer_id)
      : order.experience_items[0];
    const provider = PROVIDER_OPTIONS.find((provider) => provider.value === item.provider);

    const modalMessage = (
      <>
        <Typography color="red" fontWeight="bold">
          All {provider?.name} tickets for the offer <i>{item.title}</i> will be cancelled
        </Typography>
        This action will respect the cancellation policy of the provider.
        <br />
        {item?.cancellation_policies?.refundPolicies?.length ? (
          <>
            Refund policies:
            <br />
            {item.cancellation_policies.refundPolicies.map((policy, index) => (
              <p key={index}>{policy.periodLabel}</p>
            ))}
          </>
        ) : null}
      </>
    );
    setRefundMessage(modalMessage);
    setRefundModalOpen(true);
  };

  const showRefundItemModal = async (experienceItem) => {
    setRefundModalOpen(true);
    setRefundDataRequest((prev) => ({ ...prev, loading: true }));

    let refundData;

    try {
      refundData = await getExperienceRefund(experienceItem.id);
    } catch (error) {
      setRefundDataRequest({ error: error?.message, loading: false });
    } finally {
      setRefundDataRequest((prev) => ({ ...prev, loading: false }));
    }

    const provider = PROVIDER_OPTIONS.find((provider) => provider.value === experienceItem?.provider);

    let amount;
    let modalMessage;

    if (refundData) {
      amount = refundData?.result[experienceItem.id].refundAmount ?? 0;
      modalMessage = (
        <>
          The maximum refundable amount for this booking at this moment is ${amount}. This amount is calculated in
          real-time using vendor’s cancellation policy from
          {`${provider.name}`} provider.
          <br />
          <b>You'll have the option to override this amount and refund an amount of your choice on the next step.</b>
        </>
      );
    } else {
      amount = experienceItem.total;
      modalMessage = (
        <>
          Impossible to determine maximum refund amount from refund policy. Agent to make a final decision on the amount
          to be refunded.
        </>
      );
    }
    setRefundMessage(modalMessage);
  };

  const showRefundExperienceModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, experienceItem = null) => {
    e.stopPropagation();

    setExperienceItem(experienceItem);
    setRefundDataRequest(defaultRequestState);
    setRefundAllItemsRequest(defaultRequestState);

    const noIndividualRefundItemProvider = experienceItem?.provider === EXP_PROVIDER_REZDY_PREFIX;

    if (onlyRefundAllOrderItems || noIndividualRefundItemProvider) {
      showRefundAllProviderItemsModal(experienceItem);
    } else {
      showRefundItemModal(experienceItem);
    }
  };

  const refundAllOrderItems = async (experienceItem = null) => {
    setRefundAllItemsRequest((prev) => ({ ...prev, loading: true }));

    const orderItems = experienceItem
      ? order.experience_items.filter((item) => item.provider_offer_id === experienceItem.provider_offer_id)
      : order.experience_items;

    try {
      await Promise.all(
        orderItems.map(async (item) => {
          const { result } = await getExperienceRefund(item.id);
          return cancelOrder(
            item.fk_order_id,
            item.id,
            buildItemToRefund({ ...item, refundFee: result[item.id].refundFee }),
          );
        }),
      );
      refreshData();
    } catch (error) {
      setRefundAllItemsRequest({ error: error?.message, loading: false });
    } finally {
      setRefundAllItemsRequest((prev) => ({ ...prev, loading: false }));
    }
  };

  const awareWithExperienceRefundConditions = () => {
    const noIndividualRefundItemProvider = experienceItem?.provider === EXP_PROVIDER_REZDY_PREFIX;
    if (!experienceItem || noIndividualRefundItemProvider) {
      refundAllOrderItems(experienceItem);
    } else {
      handleCloseExperienceRefundModal();
      showRefundModal({ itemId: experienceItem.id });
    }
  };

  return (
    <Box mt={2}>
      <FoldableSection
        title="Experiences"
        actions={
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            {showRefundAllOrderItemsButton && !allItemsCancelled && (
              <div>
                <Button variant="text" size="small" onClick={showRefundExperienceModal}>
                  Issue Refund
                </Button>
              </div>
            )}
            {canResendExperienceEmail && (
              <div>
                <Button
                  disabled={resendExperiencesLoading}
                  variant="text"
                  size="small"
                  onClick={onResendExperienceConfirmationEmail}
                  startIcon={resendExperiencesLoading ? <CircularProgress size={24} /> : null}
                >
                  Re-send confirmation email
                </Button>
              </div>
            )}
          </Stack>
        }
        initiallyExpanded
      >
        <List
          tenant={tenant}
          experiences={order.experience_items}
          initialCount={order.addon_items.length + 1}
          hasAllowedRefund={showRefundAllOrderItemsButton ? false : hasAllowedRefund}
          showRefundModal={showRefundExperienceModal}
          orderBrand={order.brand}
        />

        <Dialog open={refundModalOpen} onClose={handleCloseExperienceRefundModal}>
          <DialogTitle>Experience Refund Warning</DialogTitle>

          {(refundDataRequest.loading || refundaAllItemsRequest.loading) && <Spinner />}
          {(refundDataRequest.error || refundaAllItemsRequest.error) && (
            <>
              <DialogContent>
                <ErrorDisplay message={refundDataRequest.error || refundaAllItemsRequest.error} />
              </DialogContent>

              <DialogActions>
                <Button variant="text" onClick={handleCloseExperienceRefundModal}>
                  Close
                </Button>
              </DialogActions>
            </>
          )}

          {refundMessage &&
            !refundDataRequest.loading &&
            !refundaAllItemsRequest.loading &&
            !refundDataRequest.error &&
            !refundaAllItemsRequest.error && (
              <>
                <DialogContent>
                  <DialogContentText>{refundMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="text" onClick={handleCloseExperienceRefundModal}>
                    Close
                  </Button>
                  <Button variant="contained" onClick={awareWithExperienceRefundConditions}>
                    I'm aware
                  </Button>
                </DialogActions>
              </>
            )}
        </Dialog>
      </FoldableSection>
    </Box>
  );
}

const buildItemToRefund = (item) => ({
  transaction_key: generateTransactionKey(),
  reason: OPERATOR_CANCELLATION,
  refund_method: 'Back To Original',
  amount: item.total,
  comment: 'Admin refund',
  ticket_id: 'N/A',
  currency_code: item.currency,
  mark_cancelled: true,
  send_refund_notification: false,
  accounting_metadata: [
    {
      source: 'Default',
      cash_amount: item.total,
      accounting_amount: item.total,
      charge_component_key: item.id,
      refund_fee: item.refundFee,
    },
  ],
});
