import { json_headers, request } from './common';

function postRequest(path, data) {
  return request(path, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: json_headers,
  });
}

function getRequest(path) {
  return request(path, {
    method: 'GET',
    headers: json_headers,
  });
}

function deleteRequest(path) {
  return request(path, {
    method: 'DELETE',
    headers: json_headers,
  });
}

function basePath() {
  return window.configs.API_HOST + '/api/search/popular-place';
}

function destinationAlertPath(brand, isoCode) {
  return window.configs.API_HOST + `/api/search/popular-places?brand=${brand}&region=${isoCode}`;
}

export function createPopularDestination(data) {
  return postRequest(basePath(), data);
}

export function deletePopularDestination(id) {
  return deleteRequest(`${basePath()}/${id}`);
}

export function getDestinationAlertList(brand, isoCode) {
  return getRequest(destinationAlertPath(brand, isoCode));
}

export function debug(placeId) {
  const path = window.configs.API_HOST + `/api/search/debug?place_id=${placeId}`;
  return getRequest(path);
}
