import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import VimeoWidget from '~/components/Common/Forms/widgets/VimeoWidget';
import Spinner from '~/components/Common/Spinner';

import OffersService from '~/services/OffersService';

import { reportError } from '~/utils/reportError';

interface RouteParams {
  id_offer: string;
}

export default function VideosEditContainer() {
  const { id_offer: offerId } = useParams<RouteParams>();

  const [offerDetails, setOfferDetails] = useState(null);
  const [leVimeoId, setLeVimeoId] = useState(null);
  const [whitelabelVimeoId, setWhitelabelVimeoId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    try {
      const { result } = await OffersService.getOffer(offerId);

      setOfferDetails(result);
      setLeVimeoId(result.videos.luxuryescapes.vimeo_id);
      setWhitelabelVimeoId(result.videos.whitelabel.vimeo_id);
      setIsLoading(false);
    } catch (err) {
      reportError(err);
    }
  };

  const saveData = async () => {
    setIsSubmitting(true);

    try {
      const { result } = await OffersService.updateOffer(
        {
          type: offerDetails.type,
          videos: {
            luxuryescapes: { vimeo_id: leVimeoId || '' },
            whitelabel: { vimeo_id: whitelabelVimeoId || '' },
          },
        },
        offerId,
      );

      setOfferDetails(result);
      enqueueSnackbar(`Successfully saved videos`, { variant: 'success' });
    } catch (err) {
      reportError(err);
      enqueueSnackbar(`"Failed to save videos`, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [offerId]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Offers | {offerDetails?.name || offerId} | Edit Offer Videos</title>
      </Helmet>
      <Button variant="outlined" component={Link} to={'/offers/' + offerId}>
        <ChevronLeftIcon /> Return to offer
      </Button>
      <PageHeader title="Edit Offer Videos" />
      {!isLoading && (
        <>
          <h3>Vimeo video (Luxury Escapes)</h3>
          <VimeoWidget value={leVimeoId} onChange={setLeVimeoId} />
          <h3>Vimeo video (Whitelabel Escapes)</h3>
          <VimeoWidget value={whitelabelVimeoId} onChange={setWhitelabelVimeoId} />
          <Button sx={{ width: 100 }} onClick={saveData} variant="contained" disabled={isSubmitting}>
            {isSubmitting ? <Spinner size={20} /> : 'Save'}
          </Button>
        </>
      )}
      {isLoading && <Spinner />}
    </Container>
  );
}
