/**
 * Determine if the user's tenancy is compatible with a given site tenant
 *
 * Roughly translated from svc-auth: https://github.com/lux-group/svc-auth/blob/master/src/lib/tenant.js#L2-L30
 *
 * e.g. If the site tenant is currently set to 'cudo', lux tenant accounts are allowed to be used
 * @param {App.TenantValues} siteTenant The value of the current site tenant
 * @param {string} userTenant The tenant value of the user
 * @returns {boolean}
 */
const validUserTenantForSiteTenant = (siteTenant: App.TenantValues, userTenant: string) => {
  const luxCompatibleTenants = ['lux', 'cudo', 'deals', 'treatme', 'lebusinesstraveller'];

  if (luxCompatibleTenants.includes(siteTenant)) {
    return luxCompatibleTenants.includes(userTenant);
  }

  return siteTenant === userTenant;
};

export { validUserTenantForSiteTenant };
