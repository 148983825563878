import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { Add, Save } from '@mui/icons-material';
import { Alert, Box, Button, Divider, Typography } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Spinner from '~/components/Common/Spinner';

import { setCancellationModalSettings } from '~/services/SupportService';

import { CancellationModalSettingsRuleInputs } from './CancellationModalSettingsRuleInputs';
import { fetchInitialValues, formatRuleData, ruleInitialState } from './utils';

const CancellationModalSettingsContainer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const formMethods = useForm({
    // ideally this is an async function that calls fetchInitialValues, but it
    // doesn't work for useFieldArray. So set it as an empty array and perform
    // setting of initial values via a useEffect
    defaultValues: {
      rules: [],
    },
  });

  const {
    fields: rules,
    append,
    remove,
  } = useFieldArray({
    control: formMethods.control,
    name: 'rules',
  });

  const handleAddRule = () => {
    append(ruleInitialState);
  };

  const handleDeleteRule = (index) => {
    remove(index);
  };

  useEffect(() => {
    fetchInitialValues().then((rules) => {
      formMethods.reset({ rules });
    });
  }, []);

  const onSubmit = async (formData) => {
    try {
      setIsLoading(true);
      const formattedRulesData = formData.rules.map((rule) => formatRuleData(rule));
      await setCancellationModalSettings({
        rules: formattedRulesData,
      });
      enqueueSnackbar('Settings saved', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Something went wrong processing this update: ${e.message}`, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const hasErrors = Object.keys(formMethods.formState.errors).length > 0;

  return (
    <Box>
      <PageSubheader title="Cancellation Modal">
        <Box>
          <Button startIcon={<Add />} variant="contained" onClick={handleAddRule}>
            Add rule
          </Button>
        </Box>
      </PageSubheader>
      <Box>
        <Typography>
          If an order complies with the criteria listed below, self service cancellation will be blocked and the
          customer will be directed to call our customer sales team
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Note: time inputs are in 24h format AEST</Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>
          Note on days until cancellation expires: Self-service cancellation will be allowed if the remaining days until
          the customer's cancellation period are less than or equal to the value specified on the field
        </Typography>
      </Box>
      {hasErrors && (
        <Alert severity="warning" sx={{ my: 2 }}>
          <Typography>Please fix all errors on the form and try again</Typography>
        </Alert>
      )}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {rules.length === 0 && (
            <Box sx={{ my: 2 }}>
              <Typography>
                No rules configured. Please add a rule using the button at the top right of this page to configure a
                rule.
              </Typography>
            </Box>
          )}
          {rules.map((rule, index) => (
            <>
              <CancellationModalSettingsRuleInputs
                key={`cancellation-modal-settings-rule-inputs-${index}`}
                handleDelete={handleDeleteRule}
                index={index}
                isLoading={isLoading}
              />
              <Divider />
            </>
          ))}
          <Box sx={{ mt: 4 }}>
            <Button type="submit" startIcon={<Save />} variant="contained" disabled={isLoading}>
              {isLoading ? <Spinner size={30} /> : 'Save cancellation modal settings'}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CancellationModalSettingsContainer;
