import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Dialog, DialogContent, IconButton, Stack } from '@mui/material';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';
import { ErrorPaymentsUpdateForm } from '~/components/Finance/OfferDetail/ErrorPaymentsUpdateForm';

import VendorsService from '~/services/VendorsService';

export default function ErrorPaymentsActions(params) {
  const { enqueueSnackbar } = useSnackbar();

  const { row } = params;

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const updateRow = (updatedRow: any) => {
    row.onErrorPaymentUpdated(updatedRow);
    setShowUpdateModal(false);
  };

  const deleteRow = async () => {
    try {
      await VendorsService.deleteErrorPayment({ id_adjustment: row.id_adjustment });
      enqueueSnackbar(`Payment deleted`, { variant: 'success' });
      row.onErrorPaymentDeleted(row.id_adjustment);
    } catch (err) {
      enqueueSnackbar(`Can't delete error payment: ${err.message}`, { variant: 'error' });
    }
  };

  return (
    <Stack direction="row">
      <IconButton onClick={() => setShowUpdateModal(true)}>
        <ModeEditIcon fontSize="medium" />
      </IconButton>
      <ConfirmButton asIcon onConfirm={deleteRow}>
        <DeleteIcon fontSize="medium" />
      </ConfirmButton>

      <Dialog open={showUpdateModal} onClose={() => setShowUpdateModal(false)}>
        <DialogContent>
          <ErrorPaymentsUpdateForm
            schema={row.schema}
            id_adjustment={row.id_adjustment}
            date_of_adjustment={row.date_of_adjustment}
            fk_offer={row.fk_offer}
            fk_vendor={row.fk_vendor}
            currency={row.currency}
            amount={row.amount}
            description={row.description}
            onErrorPaymentUpdated={updateRow}
          />
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
