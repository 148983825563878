import { LE_BUSINESS_TRAVELLER } from './businessTraveller';

export interface Brand {
  title: string;
  value: string;
  region: {
    code: string;
    name: string;
  };
}

export const LE_AGENT_HUB = 'leagenthub';
export const LUXURY_ESCAPES = 'luxuryescapes';
export const KOGAN_TRAVEL = 'kogantravel';
export const ZOOM_ZOOM = 'zoomzoom';
export const SCOOPON = 'scoopontravel';
export const CUDO = 'cudotravel';
export const TREAT_ME = 'treatmetravel';
export const DEALS = 'dealstravel';

export const WHITE_LABELS = [KOGAN_TRAVEL, ZOOM_ZOOM, SCOOPON, CUDO, TREAT_ME, DEALS];

export const brands: Brand[] = [
  {
    title: 'Luxury Escapes',
    value: LUXURY_ESCAPES,
    region: { code: 'AU', name: 'Australia' },
  },
  {
    title: 'LE Business Traveller',
    value: 'lebusinesstraveller',
    region: { code: 'AU', name: 'Australia' },
  },
  {
    title: 'Scoopon',
    value: SCOOPON,
    region: { code: 'AU', name: 'Australia' },
  },
  {
    title: 'Cudo',
    value: CUDO,
    region: { code: 'AU', name: 'Australia' },
  },
  {
    title: 'Deals',
    value: DEALS,
    region: { code: 'AU', name: 'Australia' },
  },
  {
    title: 'Treat Me',
    value: TREAT_ME,
    region: { code: 'NZ', name: 'New Zealand' },
  },
  {
    title: 'Kogan Travel',
    value: KOGAN_TRAVEL,
    region: { code: 'AU', name: 'Australia' },
  },
  {
    title: 'LE Agent Hub',
    value: LE_AGENT_HUB,
    region: { code: 'AU', name: 'Australia' },
  },
];

if (window.configs.ZOOMZOOM_ENABLED === 'true') {
  brands.push({
    title: 'Zoom Zoom',
    value: ZOOM_ZOOM,
    region: { code: 'KR', name: 'Korea' },
  });
}

export const isMultiRegionSchedule = (brand: string) =>
  [LUXURY_ESCAPES, LE_AGENT_HUB, LE_BUSINESS_TRAVELLER].includes(brand);

export const isUnusedBrand = (brand: string) => [ZOOM_ZOOM, KOGAN_TRAVEL].includes(brand);

export const ledBrands = brands.filter((brand) => !isMultiRegionSchedule(brand.value));
