import React from 'react';

import { Box, Chip } from '@mui/material';

type OrderTagProps = {
  item: App.AnyItem;
  items?: App.AnyItem[];
};

export const OrderTag: React.FC<OrderTagProps> = (props) => {
  const { item, items } = props;
  let label, displayedId;

  const downgradeTo = items?.find((i) => i.fk_downgraded_from_id === item.id);
  const downgradeFrom = items?.find((i) => i.id === item.fk_downgraded_from_id);

  const getLabel = (downgrade_type, downgradeTo, downgradeFrom) => {
    if (downgradeTo) {
      if (downgrade_type === 'bnbl') return 'Converted to BNBL';
      if (downgrade_type === 'package_change') return 'Upgraded to';
      if (['change_dates_bedbank', 'change_dates_tactical_ao_hotel'].includes(downgrade_type))
        return 'Changed dates to';
    } else if (downgradeFrom) {
      if (downgrade_type === 'bnbl') return 'BNBL converted from';
      if (downgrade_type === 'package_change') return 'Upgraded from';
      if (['change_dates_bedbank', 'change_dates_tactical_ao_hotel'].includes(downgrade_type))
        return 'Changed dates from';
    }
  };

  if (downgradeTo) {
    displayedId = downgradeTo?.booking_number;
    label = getLabel(item.downgrade_type, downgradeTo, null);
  } else if (downgradeFrom) {
    displayedId = downgradeFrom?.booking_number;
    label = getLabel(item.downgrade_type, null, downgradeFrom);
  }

  if (label) {
    return (
      <Box>
        <Chip label={`${label} ${displayedId}`} color="info" />
      </Box>
    );
  }

  return null;
};
