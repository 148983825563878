import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

const RefundItemsReminderModal = ({
  show,
  onClose,
  hasCruises,
  hasExperiences,
  hasInsurance,
  hasFlights,
  hasCarHire,
}) => {
  return (
    <Dialog open={show} onClose={onClose}>
      <DialogContent>
        <DialogContentText>This order also contains the following items:</DialogContentText>

        <List>
          {hasInsurance && (
            <ListItem>
              <ListItemText
                primary="Insurance"
                secondary={
                  <>
                    If you are rebooking a different package for this customer you can modify the amount of insurance
                    coverage by scrolling down to the TRAVEL INSURANCE section and clicking{' '}
                    <strong>Update details</strong>.
                    <br />
                    Otherwise, you can refund and cancel the insurance policy by clicking <strong>Issue refund</strong>.
                  </>
                }
              />
            </ListItem>
          )}
          {hasExperiences && (
            <ListItem>
              <ListItemText primary="Experience(s)" secondary={<>You may need to cancel and/or issue a refund</>} />
            </ListItem>
          )}
          {hasCruises && (
            <ListItem>
              <ListItemText primary="Cruise(s)" secondary={<>You may need to cancel and/or issue a refund</>} />
            </ListItem>
          )}
          {hasFlights && (
            <ListItem>
              <ListItemText
                primary="Flight(s)"
                secondary={
                  <>
                    <div>Remember to advise customer to speak to Flight Support to cancel/rebook flights.</div>
                    <div className="mt-10">
                      <strong>Note: </strong>
                      Airline and/or LE fees may apply to cancel/rebook. Existing flights must be cancelled at least
                      12hrs before departure to avoid NOSHOW.
                    </div>
                  </>
                }
              />
            </ListItem>
          )}
          {hasCarHire && (
            <ListItem>
              <ListItemText primary="Car Hire" secondary={<>You may need to cancel and/or issue a refund</>} />
            </ListItem>
          )}
        </List>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefundItemsReminderModal;
