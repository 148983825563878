import React from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { Helmet } from 'react-helmet';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import generateRandomString from '../../../utils/generateRandomString';
import ErrorListDisplay from '../../Common/ErrorListDisplay';
import RoomTypeForm from '../../Common/Forms/RoomTypeForm';

export default class RoomTypesEdit extends React.Component {
  constructor(props) {
    super(props);
    this.vendorId = props.vendorId;
    this.schema = props.schema;
    this.property = props.property;

    this.handleAddRoomType = this.handleAddRoomType.bind(this);
    this.handleRemoveRoomType = this.handleRemoveRoomType.bind(this);
    this.updateRoomType = this.updateRoomType.bind(this);
    this.handleAddAmenityOption = this.handleAddAmenityOption.bind(this);
    this.handleCopyAttributes = this.handleCopyAttributes.bind(this);

    const amenityOptions = props.amenityOptionsData.map((item) => item.name);

    const roomTypeOffers = new Map(
      props.roomTypes.map((roomType) => {
        return [
          roomType.id,
          props.offers.filter((offer) => offer.packages.some((pkg) => pkg.fk_room_type_id === roomType.id)),
        ];
      }),
    );

    this.state = {
      roomTypes: props.roomTypes,
      amenityOptions,
      roomTypesExpanded: [...Array(props.roomTypes.length).fill(false)],
      roomTypeOffers: roomTypeOffers,
      saveAll: false,
      saveAllCounter: -1,
      saveAllFailed: false,
    };
  }

  handleAddRoomType() {
    this.setState((prevState) => {
      const roomType = {
        property_id: this.property.id,
        amenities: [],
        images: [],
        _id: generateRandomString(),
      };

      return {
        roomTypes: [...prevState.roomTypes, roomType],
      };
    });
  }

  handleRemoveRoomType(formKey) {
    this.setState((prevState) => {
      const newRoomTypes = prevState.roomTypes.filter((roomType) => {
        return roomType._id !== formKey;
      });

      return {
        roomTypes: newRoomTypes,
      };
    });
  }

  handleAddAmenityOption(amenityName) {
    this.setState((prevState) => {
      return {
        amenityOptions: [...prevState.amenityOptions, amenityName],
      };
    });
  }

  findRoomTypeIndex(_id) {
    return this.state.roomTypes.findIndex((roomType) => roomType._id === _id);
  }

  handleCopyAttributes(sourceRoomInternalId, destRoomInternalId, attributes) {
    const sourceRoomIndex = this.findRoomTypeIndex(sourceRoomInternalId);
    const destRoomIndex = this.findRoomTypeIndex(destRoomInternalId);

    const sourceRoomType = cloneDeep(this.state.roomTypes[sourceRoomIndex]);
    const destRoomType = cloneDeep(this.state.roomTypes[destRoomIndex]);

    attributes.forEach((attribute) => {
      destRoomType[attribute] = sourceRoomType[attribute];
    });

    // We generate a new _id, so rich-text fields will
    //   re-mount once this room type's key changes.
    this.updateRoomType(destRoomType, true);
  }

  updateRoomType(roomType, generateNewInternalId = false) {
    const roomTypeIndex = this.findRoomTypeIndex(roomType._id);

    this.setState((prevState) => ({
      roomTypes: [
        ...prevState.roomTypes.slice(0, roomTypeIndex),
        {
          ...roomType,
          _id: generateNewInternalId ? generateRandomString() : roomType._id,
        },
        ...prevState.roomTypes.slice(roomTypeIndex + 1),
      ],
    }));
  }

  toggleAllPackages = () => {
    const roomTypesExpanded = this.state.roomTypesExpanded.fill(!this.isAllExpanded());
    this.setState({ roomTypesExpanded });
  };

  toggleRoomType = (index) => {
    const newState = this.state;
    newState.roomTypesExpanded[index] = !newState.roomTypesExpanded[index];
    this.setState(newState);
  };

  isAllExpanded = () => {
    return this.state.roomTypesExpanded.some((isExpanded) => isExpanded);
  };

  onRoomTypeSaveAllProgress = (isFailed = false) => {
    if (isFailed) {
      this.setState({ saveAllFailed: true });
    }
    if (this.state.saveAllCounter !== -1) {
      this.setState({ saveAllCounter: this.state.saveAllCounter + 1 });
    }
  };

  render() {
    const { roomTypes, amenityOptions, saveAllCounter } = this.state;
    const { vendor } = this.props;
    const saveAllButtonState =
      saveAllCounter < 0 ? 'default' : saveAllCounter === roomTypes.length ? 'saved' : 'saving';
    return (
      <Container maxWidth="xl">
        <Helmet>
          <title>Vendors | {vendor?.name || this.vendorId} | Edit Room Types</title>
        </Helmet>

        <PageHeader
          title={`Edit room types (${roomTypes.length})`}
          backButton={`/vendors/${this.vendorId}/properties/${this.property.id}`}
        />

        {roomTypes.length > 1 && (
          <Box>
            <Button variant="text" onClick={this.toggleAllPackages}>
              {this.isAllExpanded() ? 'Collapse All Options' : 'Expand All Options'}
            </Button>
          </Box>
        )}

        <Box>
          {roomTypes.map((roomType, index) => (
            <Accordion
              variant="outlined"
              key={roomType._id}
              expanded={this.state.roomTypesExpanded[index]}
              onChange={() => this.toggleRoomType(index)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box flexGrow={1}>
                  <Typography variant="h6">{roomType.name}</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} mx={2}>
                  {this.state.roomTypeOffers.get(roomType.id)?.map((offer) => (
                    <>
                      <Typography variant="body2" color="textSecondary" key={offer.id_salesforce_external}>
                        {offer.opportunity_name}
                      </Typography>
                      <Divider orientation="vertical" />
                    </>
                  ))}
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                <RoomTypeForm
                  formKey={roomType._id}
                  roomType={roomType}
                  schema={this.schema}
                  onRemoveRoomType={this.handleRemoveRoomType}
                  onEditRoomType={this.updateRoomType}
                  amenityOptions={amenityOptions}
                  onAddAmenityOption={this.handleAddAmenityOption}
                  onCopyAttributes={this.handleCopyAttributes}
                  roomTypeNames={roomTypes.map((rt) => ({
                    value: rt._id,
                    label: rt.name,
                  }))}
                  vendorId={this.vendorId}
                  property={this.property}
                  channelManager={this.props.channelManager}
                  // Save all
                  saveAll={this.state.saveAll}
                  onSaveCompleted={() => this.onRoomTypeSaveAllProgress()}
                  onSaveFailed={() => this.onRoomTypeSaveAllProgress(true)}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <div className={'button-container right'}>
          <Button
            variant="contained"
            onClick={() => {
              this.setState((prevState) => ({
                saveAll: !prevState.saveAll,
                saveAllCounter: 0,
              }));
            }}
            disabled={saveAllButtonState === 'saving'}
          >
            {(() => {
              switch (saveAllButtonState) {
                case 'default':
                  return 'Save All';
                case 'saving':
                  return `Saving... ${saveAllCounter}/${roomTypes.length}`;
                case 'saved':
                  return 'All Saved';
              }
            })()}
          </Button>
        </div>

        <Box>
          <Button variant="contained" onClick={this.handleAddRoomType} startIcon={<AddIcon />}>
            Add
          </Button>
        </Box>

        <Dialog open={this.state.saveAllFailed} onClose={() => this.setState({ saveAllFailed: false })}>
          <DialogContent>
            <ErrorListDisplay messages={["Some of the packages wasn't saved."]} />
          </DialogContent>
        </Dialog>
      </Container>
    );
  }
}
