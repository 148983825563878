import React from 'react';

import fileDownload from 'react-file-download';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import { Container } from '@mui/material';

import { property as propertyGlobals } from '@luxuryescapes/lib-global';

import PageHeader from '~/components/Common/Elements/PageHeader';

import BedbankService from '../../../services/BedbankService';
import OffersService from '../../../services/OffersService';
import { getTaxesAndFeesPDF } from '../../../services/PDFService';
import ReservationService from '../../../services/ReservationService';
import VendorsService from '../../../services/VendorsService';
import { reportError } from '../../../utils/reportError';
import Spinner from '../../Common/Spinner';

import PropertyPage from './PropertyPage';
import RentalPropertyForm from './RentalPropertyForm';
import cleanProperty from './helpers/cleanProperty';

class PropertyPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.handlePropertyUpdate = this.handlePropertyUpdate.bind(this);
    this.state = {
      property: {},
      vendor: {},
      ratePlans: [],
      vendorId: null,
      fetching: true,
      saving: false,
    };
  }

  async fetchData(propertyId, vendorId) {
    const [property, vendor, ratePlans, offers] = await Promise.all([
      ReservationService.getProperty(propertyId),
      VendorsService.getVendorById(vendorId),
      ReservationService.getRatePlans(vendorId),
      OffersService.getOffers({
        queryString: vendorId,
        limit: 250,
      }),
    ]);

    if (property.result.parent_property_id) {
      const parent = await ReservationService.getProperty(property.result.parent_property_id);
      if (parent && parent.result) {
        property.result.parentProperty = parent.result;
      }
    }

    if (property.result.bedbank_property_id) {
      const bedbank = await BedbankService.getPropertyById(property.result.bedbank_property_id);
      if (bedbank && bedbank.result) {
        property.result.bedbankProperty = bedbank.result;
      }
    }

    return {
      property: property.result,
      vendor: vendor.result,
      ratePlans: ratePlans.result,
      offers: offers.result,
    };
  }

  downloadTaxesAndFees = async () => {
    const { property } = this.state;
    this.setState({
      downloadInProgress: true,
      downloadFailed: false,
    });

    try {
      const propertyName = property.name || property.id;
      const { taxes_and_fees, commissionable_taxes_and_fees, taxes_and_fees_content } = property;
      const pdf = await getTaxesAndFeesPDF({
        taxes: {
          taxes_and_fees,
          commissionable_taxes_and_fees,
          taxes_and_fees_content,
        },
        propertyName,
      });
      const fileName = `LuxuryEscapes-Taxes-And-Fees-${property.id}.pdf`;

      await fileDownload(pdf, fileName);
    } catch (error) {
      reportError(error);
      this.setState({
        downloadFailed: true,
        downloadInProgress: false,
      });
    } finally {
      this.setState({
        downloadInProgress: false,
      });
    }
  };

  async componentDidMount() {
    const { match } = this.props;
    const { id_vendor: vendorId, id_property: propertyId } = match.params;

    try {
      const { property, vendor, ratePlans, offers } = await this.fetchData(propertyId, vendorId);

      this.setState({
        fetching: false,
        property,
        vendor,
        ratePlans,
        vendorId,
        offers,
      });
    } catch (error) {
      reportError(error);
    }
  }

  async handlePropertyUpdate(changes) {
    const property = this.state.property;
    const propertyId = property.id;
    const { match } = this.props;
    const { id_vendor: vendorId } = match.params;
    const updatedProperty = cleanProperty({ ...property, ...changes });

    this.setState({
      saving: true,
    });

    try {
      await ReservationService.updateProperty(updatedProperty, propertyId);
      const { property, vendor, ratePlans, offers } = await this.fetchData(propertyId, vendorId);

      this.setState({
        saving: false,
        fetching: false,
        property,
        vendor,
        ratePlans,
        vendorId,
        offers,
      });
    } catch (error) {
      reportError(error);
      this.setState({
        saving: false,
      });
    }
  }

  render() {
    if (this.state.fetching) {
      return <Spinner />;
    }

    const { property, vendor, vendorId } = this.state;

    const pageTitle = `Vendors | ${vendor.name} | ${property.name}`;

    return (
      <Container maxWidth="xl" className="property-edit-form">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <PageHeader title={pageTitle} backButton={`/vendors/${vendorId}`} />

        {this.state.property.type !== propertyGlobals.RENTAL_TYPE && (
          <PropertyPage
            property={this.state.property}
            ratePlans={this.state.ratePlans}
            vendorId={this.state.vendorId}
            downloadInProgress={this.state.downloadInProgress}
            downloadFailed={this.state.downloadFailed}
            offers={this.state.offers}
            downloadTaxesAndFees={this.downloadTaxesAndFees}
          />
        )}

        {this.state.property.type === propertyGlobals.RENTAL_TYPE && (
          <RentalPropertyForm
            property={this.state.property}
            ratePlans={this.state.ratePlans}
            vendorId={this.state.vendorId}
            vendorCurrencyCode={this.state.vendor.currencyisocode}
            downloadInProgress={this.state.downloadInProgress}
            downloadFailed={this.state.downloadFailed}
            offers={this.state.offers}
            downloadTaxesAndFees={this.downloadTaxesAndFees}
            updateProperty={this.handlePropertyUpdate}
            isPropertyUpdating={this.state.saving}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(PropertyPageContainer);
