const knownPlurals = {
  child: 'children',
};

export function countTravellers(travellers: App.OrderTourTraveller[]) {
  const travellersCount = {};

  for (let i = 0; i < travellers.length; ++i) {
    travellersCount[travellers[i].type] = (travellersCount[travellers[i].type] || 0) + 1;
  }

  return travellersCount;
}

export function formatTravellers(travellers: App.OrderTourTraveller[], isTitleCase = false) {
  const travellersCount = countTravellers(travellers);

  const travellerTypes = Object.keys(travellersCount);

  const travellerTypesStr = travellerTypes.map((type) =>
    isTitleCase
      ? toTitleCase(pluralizeToString(type, travellersCount[type]) || '')
      : pluralizeToString(type, travellersCount[type]) || '',
  );

  return travellerTypesStr.join(', ');
}

export function pluralizeToString(singular: string, count: number) {
  let text = singular;
  if (count !== 1) {
    text = getPlural(singular, count);
  }
  return `${count} ${text}`;
}

export function getPlural(singular: string, count?: number) {
  if (count <= 1) {
    return singular;
  }

  const knownPlural = knownPlurals[singular.toLowerCase()];
  if (knownPlural) {
    const startsWithCapital = singular.length > 0 && singular[0] === singular[0].toUpperCase();
    return startsWithCapital ? capitalise(knownPlural) : knownPlural;
  } else {
    return `${singular}s`;
  }
}

export function capitalise(str = '') {
  if (str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
}

export function toTitleCase(str = '') {
  return str
    .split(' ')
    .map((word) => capitalise(word))
    .join(' ');
}

export function formatTourV2SnapshotRoom(room: App.OrderTourItemRooms) {
  const { adultCount, childCount } = room;
  return `${adultCount ? pluralizeToString('adult', adultCount) : ''}${
    childCount ? `, ${pluralizeToString('child', childCount)} }` : ''
  }, Special Request: ${room.specialRequests}`;
}

export function getToursSellingRegions() {
  return window.configs.TOUR_V2_SELLING_REGIONS.split(',');
}
