import React, { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';
import { Helmet } from 'react-helmet';

import { Close, Done, HourglassBottom, HourglassDisabled, HourglassFull, PanTool } from '@mui/icons-material';
import { Box, Grid, Link, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import DebugModal from '~/components/DebugModal/DebugModal';
import { withTenant } from '~/components/hoc';

import useQuery from '~/hooks/useQuery';

import { getReferralLogs } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';
import dateFormatter from '~/utils/dateFormatter';

interface Props {
  tenant: App.Tenant;
}

const sharedOpts: Partial<GridColDef> = {
  editable: false,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  flex: 1,
};

// this value is to be replaced with value from referral config in svc-promo
const MAX_ATTEMPTS_ALLOWED = 101;

const columns: GridColDef[] = [
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params) => {
      if (params.row.log_status == 'redeemed') {
        return (
          <Box title={`Referral event complete ${params.row.updated_at}`}>
            <Done />
          </Box>
        );
      }
      if (!('referee_order' in params.row) || !('status' in params.row.referee_order)) {
        return (
          <Box title={`Could not get current order from the orderId on referral log`}>
            <Close />
          </Box>
        );
      }

      if (params.row.referee_order.status !== 'completed') {
        return (
          <Box title={`Order Status is not complete [${params.row.referee_order.status}]`}>
            <PanTool />
          </Box>
        );
      }

      if (params.row.processing_attempts > MAX_ATTEMPTS_ALLOWED) {
        return (
          <Box title={`Too many processing attempts: ${params.row.processing_attempts}`}>
            <HourglassDisabled />
          </Box>
        );
      }

      if (new Date(params.row.available_by) < new Date()) {
        return (
          <Box title={`Referral is waiting for job to run`}>
            <HourglassFull />
          </Box>
        );
      } else {
        return (
          <Box title={`Referral is still waiting for cooldown to expire`}>
            <HourglassBottom />
          </Box>
        );
      }
    },
    ...sharedOpts,
    flex: 0.3,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    editable: false,
    renderCell: (params) => dateFormatter(params.row.created_at),
    ...sharedOpts,
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    editable: false,
    renderCell: (params) => dateFormatter(params.row.updated_at),
    ...sharedOpts,
  },
  {
    field: 'log_status',
    headerName: 'Log Status',
    ...sharedOpts,
  },
  {
    field: 'referrer_email_sent',
    headerName: 'Email Sent',
    ...sharedOpts,
  },
  {
    field: 'processing_attempts',
    headerName: 'Processing Attempts',
    ...sharedOpts,
  },
  {
    field: 'earn_amount',
    headerName: 'Earn Amount',
    renderCell: (params) =>
      params.row.currency_applied_in ? currencyFormatter(params.row.currency_applied_in, params.row.earn_amount) : null,
    ...sharedOpts,
  },
  {
    field: 'available_by',
    headerName: 'Available By',
    renderCell: (params) => dateFormatter(params.row.available_by),
    ...sharedOpts,
  },
  {
    field: 'referee_order_region',
    headerName: 'Region',
    ...sharedOpts,
  },
  {
    field: 'order',
    headerName: 'Order',
    display: 'flex',
    renderCell: (params) => (
      <Stack direction="column">
        {params.row.referree_order_id ? (
          <Link href={`/purchases/${params.row.referree_order_id}`} target="_blank">
            Referrer Order
          </Link>
        ) : (
          'No Referree OrderId?'
        )}
        {params.row.id_promo_code ? (
          <Link href={`/promos/${params.row.id_promo_code}`} target="_blank">
            Referree Promo
          </Link>
        ) : (
          'No Promo Code?'
        )}
      </Stack>
    ),
    ...sharedOpts,
  },
  {
    field: 'source',
    headerName: 'Source (Referrer)',
    renderCell: (params) => (
      <Stack direction="column">
        <Link href={`/users/${params.row.referrer_user_id}`}>{params.row.referrer_user_id}</Link>
      </Stack>
    ),
    ...sharedOpts,
  },
  {
    field: 'target',
    headerName: 'Target (Referee)',
    renderCell: (params) => (
      <Stack direction="column">
        <Link href={`/users/${params.row.referree_user_id}`}>
          {params.row.first_name ?? params.row.referree_user_id}
        </Link>
      </Stack>
    ),
    ...sharedOpts,
  },
  {
    field: 'debug',
    headerName: 'Raw',
    renderCell: (params) => <DebugModal type="generic" data={params.row ?? { data: 'None' }} />,
    ...sharedOpts,
  },
];

const PAGE_SIZE = 10;

function ReferralLogs({ tenant }: Props) {
  const query = useQuery();
  const [dataState, setData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>(query.get('q') || query.get('userId') || '');
  const [logStatus, setLogStatus] = useState<string>(query.get('referral_log_status') || 'pending_cooldown');
  const [pageNum, setPageNum] = useState<number>(Number(query.get('page_num')) || 0);
  const [resultTotal, setResultTotal] = useState<number>(0);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const { result } = await getReferralLogs(tenant?.brand, userId, logStatus, pageNum, PAGE_SIZE);
    const { logs, total } = result;
    setData(logs ?? []);
    setResultTotal(Number(total));
    setIsLoading(false);
  }, [pageNum, logStatus, tenant?.brand, userId]);

  useEffect(() => {
    const delayedFetchData = debounce(fetchData, 500);
    delayedFetchData();

    return () => {
      delayedFetchData.cancel();
    };
  }, [fetchData]);

  const dataRows = dataState.map((row, index) => ({
    id: index,
    ...row,
  }));

  const onPageChange = (page: number) => {
    setPageNum(page);
    query.set('page_num', page.toString());
    window.history.pushState({}, '', `${window.location.pathname}?${query.toString()}`);
  };

  return (
    <div>
      <Helmet>
        <title>Referral Logs</title>
      </Helmet>
      <PageSubheader title={`Search Referral Logs [${dataState?.length} of ${resultTotal}]`} />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <TextField
            fullWidth
            autoFocus
            type="text"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            label="Enter User ID"
            placeholder="Enter user ID"
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            fullWidth
            type="text"
            value={logStatus}
            onChange={(e) => setLogStatus(e.target.value)}
            label="Enter Referral Log Status"
            placeholder="Enter referral log status"
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <DataGrid
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page)}
          loading={isLoading}
          rows={dataRows}
          columns={columns}
          autoHeight
          rowCount={resultTotal}
          paginationMode="server"
          paginationModel={{ page: +pageNum ?? 10, pageSize: +PAGE_SIZE }}
        />
      </Box>
    </div>
  );
}

export default withTenant(ReferralLogs);
