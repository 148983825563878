import React, { useState } from 'react';

import { Button, Stack, TextField, Typography } from '@mui/material';

import { CommissionRulesConditionTypes, RuleCondition, ruleConditionsToTextMap } from '~/consts/agentHub';

interface Props {
  setCreatedConditions: (condition: RuleCondition) => void;
  conditionType: CommissionRulesConditionTypes;
}

function AgentHubCommissionsTextInput(props: Props) {
  const { setCreatedConditions, conditionType } = props;
  const [conditionValue, setConditionValue] = useState<string>('');

  function handleSaveRule() {
    const splitValues = conditionValue.split(',');

    setCreatedConditions({
      type: conditionType,
      value: splitValues.map((value) => {
        const trimmedValue = value.trim();
        return { value: trimmedValue, label: trimmedValue };
      }),
    });
  }

  return (
    <Stack gap={2}>
      <div>
        <TextField
          fullWidth
          value={conditionValue}
          onChange={(event) => setConditionValue(event.target.value)}
          label={ruleConditionsToTextMap.get(conditionType)}
          placeholder={ruleConditionsToTextMap.get(conditionType)}
        />
        <Typography variant="caption" color="textSecondary">
          You can paste multiple {conditionType} separated by commas
        </Typography>
      </div>

      <Button fullWidth onClick={handleSaveRule} variant="contained">
        Save Condition
      </Button>
    </Stack>
  );
}

export default AgentHubCommissionsTextInput;
