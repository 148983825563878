import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { getDayJs } from '~/services/TimeService';
import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerSchedulesGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/schedules/country-groups/{countryGroup}']['get'];

function mapHeroPlannerSchedulesListItem(
  external: Utils.ArrayElement<
    HeroPlannerSchedulesGetContract['responses']['200']['content']['application/json']['result']
  >,
): CustomerCommunication.HeroPlannerSchedulesListItem {
  return {
    id: external.scheduleId,
    sendDate: getDayJs(external.identifiers.sendDate),
    countryGroupId: external.identifiers.countryGroup,
    countryId: external.identifiers.country,
    stateId: external.identifiers.state,
    cityId: external.identifiers.city,
    segmentId: external.identifiers.segment,
    cadenceId: external.identifiers.cadence,
    membershipId: external.identifiers.loyalty,

    emailSubjectLine: external.data.emailSubjectLine,
    emailPreHeader: external.data.emailPreheader,
    emailStatus: external.data.emailStatus,
    emailBannerId: external.data.emailBannerId,
    emailHeroDeals: external.data.emailHeroDeals,
    isApprovedForTpfm: external.data.isApprovedForTpfm,
    pushTitle: external.data.pushTitle,
    pushSubtitle: external.data.pushSubtitle,
    pushMessage: external.data.pushMessage,
    pushLink: external.data.pushLink,
    pushStatus: external.data.pushStatus,
    pushImageId: external.data.pushImageId,
  };
}

function getSchedulesListUrl(countryGroup: string, countryId?: string, countryState?: string) {
  if (countryId && countryState) {
    return makeCustomerCommunicationV1URL(
      `/todays-escapes-schedule/hero-planner/schedules/country-groups/${countryGroup}/countries/${countryId}/states/${countryState}`,
    );
  }

  return makeCustomerCommunicationV1URL(
    `/todays-escapes-schedule/hero-planner/schedules/country-groups/${countryGroup}`,
  );
}

async function requestGetHeroPlannerSchedulesList(
  brand: string,
  /**
   * YYYY-MM-DD
   */
  startDate: string,
  /**
   * YYYY-MM-DD
   */
  endDate: string,
  countryGroupId: string,
  countryId?: string,
  countryStateId?: string,
) {
  if (!brand) throw 'missing brand';
  if (!countryGroupId) throw 'missing countryGroup';
  if (!startDate) throw 'missing startDate';
  if (!endDate) throw 'missing endDate';

  const url = getSchedulesListUrl(countryGroupId, countryId, countryStateId);
  url.searchParams.set('brand', brand);
  url.searchParams.set('startDate', startDate);
  url.searchParams.set('endDate', endDate);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: HeroPlannerSchedulesGetContract['responses']['200']['content']['application/json']) => {
      return response.result.map(mapHeroPlannerSchedulesListItem);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerSchedulesList;
