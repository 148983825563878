import React from 'react';

import { Button, Typography } from '@mui/material';

import { humanize } from '~/utils/humanize';

type EmailType = 'customer' | 'vendor';

interface Props {
  emailType: EmailType;
  resendEmail: (offerId: string, offerType?: string) => void;
  resendEmailToAgent: (offerId: string, offerType?: string) => void;
  offerId?: string;
  offerType?: string;
  isCustomerEmailDisabled: boolean;
  isAgentEmailDisabled: boolean;
}

export default function ResendEmail(props: Props) {
  const {
    emailType,
    resendEmail,
    resendEmailToAgent,
    isCustomerEmailDisabled,
    isAgentEmailDisabled,
    offerId,
    offerType,
  } = props;

  const handleResendEmail = (event) => {
    event.stopPropagation();
    resendEmail(offerId, offerType);
  };

  const handleResendEmailToAgent = (event) => {
    event.stopPropagation();
    resendEmailToAgent(offerId, offerType);
  };

  return (
    <>
      <Typography color="black">Resend {emailType} booking email to:</Typography>

      <Button variant="text" size="small" onClick={handleResendEmail} disabled={isCustomerEmailDisabled}>
        {humanize(emailType)}
      </Button>

      <Button variant="text" size="small" onClick={handleResendEmailToAgent} disabled={isAgentEmailDisabled}>
        Agent
      </Button>
    </>
  );
}
