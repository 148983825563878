import React from 'react';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const layoverTime = (layover) => {
  if (!layover) return '-';
  const { hours, minutes } = layover;
  if (hours || minutes) return `${hours}h ${minutes}m`;
  return '-';
};

const checkFareFeatureIncluded = (fareFamily, featureType) => {
  const selectedFareFeature = fareFamily?.fareFeatures.find((feature) => feature.type === featureType);
  return selectedFareFeature?.status === 'INCLUDED';
};

const checkBaggageIncluded = (fareFamily) => {
  return checkFareFeatureIncluded(fareFamily, 'checked_baggage');
};

export default function OrderFlightItemFlights({ flightDetails, fareFamily, segmentIndex }) {
  const departingFlights = flightDetails.journey.journeyFares.fares[0].flightGroups[0].flights;
  // not all flights have a returning leg
  const returningFlights = flightDetails.journey.journeyFares.fares[1]?.flightGroups[0].flights || [];
  const mixedFlights = [...departingFlights, ...returningFlights];

  let allFlights = mixedFlights;

  if (typeof segmentIndex === 'number') {
    allFlights = segmentIndex === 0 ? departingFlights : returningFlights;
  }

  return (
    <Box mt={5} mb={7}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Flight No</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Layover</TableCell>
              <TableCell>Departure</TableCell>
              <TableCell>Arrival</TableCell>
              <TableCell>Baggage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allFlights.map((flight) => (
              <TableRow key={`${flight.departingDate}-${flight.departingTime}`}>
                <TableCell component="th" scope="row">
                  {flight.flightNumber}
                </TableCell>
                <TableCell component="th" scope="row">
                  <strong>{flight.departureAirport}</strong>
                  <br />
                  {flight.departureAirportName}, {flight.departureCountry}
                </TableCell>
                <TableCell component="th" scope="row">
                  <strong>{flight.arrivalAirport}</strong>
                  <br />
                  {flight.arrivalAirportName}, {flight.arrivalCountry}
                </TableCell>
                <TableCell>{layoverTime(flight.stopoverTime)}</TableCell>
                <TableCell>
                  <strong>{flight.departingTime}</strong>
                  <br />
                  {flight.departingDate}
                </TableCell>
                <TableCell>
                  <strong>{flight.arrivalTime}</strong>
                  <br />
                  {flight.arrivalDate}
                </TableCell>
                <TableCell>
                  <strong>Carry on:</strong> {flight.carryOnBaggageOptions?.[0]?.description}
                  <br />
                  <strong>Check in:</strong>
                  {fareFamily && checkBaggageIncluded(fareFamily)
                    ? 'Checked Bag included.'
                    : flight.checkedBaggageOptions?.[0]?.description}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
