import { useCallback, useEffect, useState } from 'react';

import { JSONSchema7Definition } from 'json-schema';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';

import { basePath } from '~/services/ToursService';
import { request } from '~/services/common';

import { ArrayItemCopyDefinition } from './ArrayItemCopyAndReplaceModal';
import { ImagePreviewDefinition } from './ImagePreviewModal';
import { ParentSiblingArrayCopyDefinition } from './ParentSiblingArrayCopyModal';
import { cloneFormData } from './formUtils';

export interface ExtendedRJSFormContext {
  triggerSiblingParentArrayCopy: (definition: {
    fieldDepth: string[];
    fieldTitleProperty?: string;
    canCopyArrayItemsFromSimilar?: string;
    uncopyableProperties?: string[];
    properties: Record<string, JSONSchema7Definition>;
  }) => void;
  triggerArrayItemCopy: (definition: {
    currentItemIndex: number;
    fieldDepth: string[];
    fieldTitleProperty?: string;
    uncopyableProperties?: string[];
  }) => void;
  triggerImagePreview: (definition: ImagePreviewDefinition) => void;
  pushToFormDataArrayUpdateQueue: (fieldDepth: string[], items: unknown[]) => void;
  handleInclusionItemsGenerate: (tourOptionIndex: number) => void;
  tourId: string;
}

interface ExtendedRJSFormHook<T> {
  formData: T;
  setFormData: (formData: T) => void;
  formContext: ExtendedRJSFormContext;
  parentSiblingArrayCopyDefinition?: ParentSiblingArrayCopyDefinition;
  handleParentSiblingArrayCopyDismiss: () => void;
  handleParentSiblingArrayCopy: (targetDepth: string[], items: unknown[]) => void;
  arrayItemCopyDefinition?: ArrayItemCopyDefinition;
  handleArrayItemCopyDismiss: () => void;
  handleArrayItemCopy: (targetDepth: string[], item: unknown) => void;
  imagePreviewDefinition: ImagePreviewDefinition;
  handleImagePreviewDismiss: () => void;
}

export default function useExtendedRJSForm<T = unknown>(initialFormData: T): ExtendedRJSFormHook<T> {
  const [formData, setFormData] = useState<T>(initialFormData);
  const [formDataArrayPushQueue, setFormDataArrayPushQueue] = useState<{
    fieldDepth: string[];
    items: unknown[];
  }>(null);
  const [formDataUpdateQueue, setFormDataUpdateQueue] = useState<{
    fieldDepth: string[];
    value: unknown;
  }>(null);

  useEffect(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  useEffect(() => {
    if (formDataArrayPushQueue) {
      const { fieldDepth, items } = formDataArrayPushQueue;

      const formDataCopy = cloneDeep(formData);
      const target = get(formDataCopy, fieldDepth);
      cloneFormData(items).forEach((item) => {
        target.push(item);
      });
      setFormData(formDataCopy);
      setFormDataArrayPushQueue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataArrayPushQueue]);

  useEffect(() => {
    if (formDataUpdateQueue) {
      const { fieldDepth, value } = formDataUpdateQueue;
      setFormData((currentFormData) => {
        const formDataCopy = cloneDeep(currentFormData);
        set(formDataCopy as object, fieldDepth, cloneFormData(value));

        return formDataCopy;
      });
      setFormDataUpdateQueue(null);
    }
  }, [formDataUpdateQueue]);

  const pushToFormDataArrayUpdateQueue = (fieldDepth: string[], items: unknown[]) => {
    setFormDataArrayPushQueue({ fieldDepth, items });
  };

  // PARENT SIBLING ARRAY COPY
  const [parentSiblingArrayCopyDefinition, setParentSiblingArrayCopyDefinition] =
    useState<ParentSiblingArrayCopyDefinition>(undefined);

  const triggerSiblingParentArrayCopy = useCallback<ExtendedRJSFormContext['triggerSiblingParentArrayCopy']>(
    ({ fieldDepth, uncopyableProperties, canCopyArrayItemsFromSimilar, fieldTitleProperty, properties }) => {
      setParentSiblingArrayCopyDefinition({
        fieldDepth,
        parentTitleProperty: fieldTitleProperty,
        canCopyArrayItemsFromSimilar,
        uncopyableProperties,
        properties,
      });
    },
    [],
  );

  const handleParentSiblingArrayCopy = useCallback<ExtendedRJSFormHook<T>['handleParentSiblingArrayCopy']>(
    (targetDepth, items) => {
      const formDataCopy = cloneDeep(formData);
      const target = get(formDataCopy, targetDepth);
      items.forEach((item) => {
        target.push(item);
      });
      setFormData(formDataCopy);

      setParentSiblingArrayCopyDefinition(undefined);
    },
    [formData],
  );

  const handleInclusionItemsGenerate = useCallback<ExtendedRJSFormContext['handleInclusionItemsGenerate']>(
    async (tourOptionIndex: number) => {
      const formDataCopy = cloneDeep(formData);
      const tourOptionId = (formDataCopy as any).tourOptions[tourOptionIndex].id;
      const existingInclusions = (formDataCopy as any).tourOptions[tourOptionIndex].inclusions;
      if (existingInclusions === null || existingInclusions === undefined || existingInclusions.length === 0) {
        throw new Error('No existing highlights & inclusions to generate from');
      }
      const response = await request(`${basePath()}/ai/tourOption/${tourOptionId}/inclusionItems`, {
        method: 'GET',
      });
      (formDataCopy as any).tourOptions[tourOptionIndex].inclusionItems.push(...response.result);
      setFormData(formDataCopy);
    },
    [formData],
  );

  const handleParentSiblingArrayCopyDismiss = useCallback(() => {
    setParentSiblingArrayCopyDefinition(undefined);
  }, []);

  // ARRAY ITEM COPY
  const [arrayItemCopyDefinition, setArrayItemCopyDefinition] = useState<ArrayItemCopyDefinition>(undefined);
  const triggerArrayItemCopy = useCallback<ExtendedRJSFormContext['triggerArrayItemCopy']>(
    ({ currentItemIndex, fieldDepth, uncopyableProperties, fieldTitleProperty }) => {
      setArrayItemCopyDefinition({
        currentItemIndex,
        fieldDepth,
        itemTitleProperty: fieldTitleProperty,
        uncopyableProperties,
      });
    },
    [],
  );

  const handleArrayItemCopy = useCallback<ExtendedRJSFormHook<T>['handleArrayItemCopy']>(
    (targetDepth, item) => {
      const formDataCopy = cloneDeep(formData);
      set(formDataCopy as object, targetDepth, cloneFormData(item));
      setFormData(formDataCopy);
      setArrayItemCopyDefinition(undefined);
    },
    [formData],
  );

  const handleArrayItemCopyDismiss = useCallback(() => {
    setArrayItemCopyDefinition(undefined);
  }, []);

  // IMAGE PREVIEW
  const [imagePreviewDefinition, setImagePreviewDefinition] = useState<ImagePreviewDefinition | undefined>(undefined);

  const handleImagePreviewDismiss = useCallback(() => {
    setImagePreviewDefinition(undefined);
  }, []);

  const triggerImagePreview = useCallback((definition: ImagePreviewDefinition) => {
    setImagePreviewDefinition(definition);
  }, []);

  return {
    formData,
    setFormData,
    parentSiblingArrayCopyDefinition,
    handleParentSiblingArrayCopy,
    handleParentSiblingArrayCopyDismiss,
    arrayItemCopyDefinition,
    handleArrayItemCopy,
    handleArrayItemCopyDismiss,
    imagePreviewDefinition,
    handleImagePreviewDismiss,
    formContext: {
      triggerSiblingParentArrayCopy,
      triggerArrayItemCopy,
      triggerImagePreview,
      pushToFormDataArrayUpdateQueue,
      handleInclusionItemsGenerate,
      tourId: (formData as any)?.tour?.id,
    },
  };
}
