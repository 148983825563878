import React from 'react';

import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';

import { getProductTypeOptions } from '~/services/PromoService';

function MultiProductOptionSelect(props) {
  const { id, value, field, filterFields, promoProductTypes } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  const productTypeOptions = getProductTypeOptions(promoProductTypes, filterFields).map((opt) => ({
    isChecked: value.includes(opt.value),
    label: opt.label,
    value: opt.value,
  }));

  const renderValue = (selected: string[]) => {
    if (selected.length == 1) {
      const match = productTypeOptions.find((opt) => opt.value === selected[0]);
      return match.label;
    } else {
      return `${selected.length} Products Selected`;
    }
  };

  return (
    <Select
      labelId="products"
      id="products"
      multiple
      value={value}
      placeholder="Select Products"
      onChange={handleValueChange}
      renderValue={(selected) => renderValue(selected)}
      fullWidth
    >
      {productTypeOptions.map((opt, i) => (
        <MenuItem key={`${i}_${opt.value}`} value={opt.value}>
          <Checkbox checked={opt.isChecked} />
          <ListItemText primary={opt.label} />
        </MenuItem>
      ))}
    </Select>
  );
}

export default MultiProductOptionSelect;
