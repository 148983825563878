import React, { useMemo, useState } from 'react';

import { NavLink } from 'react-router-dom';

import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FlightIcon from '@mui/icons-material/Flight';
import HotelIcon from '@mui/icons-material/Hotel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import TourIcon from '@mui/icons-material/Tour';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';

import {
  ROLE_ADMIN_USER,
  ROLE_CRUISE_COORDINATOR,
  ROLE_EMPLOYEE_USER,
  ROLE_EXPERIENCES_COORDINATOR,
  ROLE_HOTEL_COORDINATOR,
  ROLE_TOUR_COORDINATOR,
} from '~/consts/roles';

import useCurrentUser from '~/hooks/useCurrentUser';

import { intersection } from '~/utils/sets';

const ProductsMenu = [
  {
    name: 'Offers',
    href: '/offers',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_HOTEL_COORDINATOR]),
    icon: <HotelIcon />,
  },
  {
    name: 'Bedbank',
    href: '/bedbank/properties',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_HOTEL_COORDINATOR]),
    icon: <BedroomParentIcon />,
  },
  {
    name: 'Tours',
    href: '/tours',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_TOUR_COORDINATOR]),
    icon: <TourIcon />,
  },
  {
    name: 'Flights',
    href: '/flights',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]),
    icon: <FlightIcon />,
  },
  {
    name: 'Experiences',
    href: '/experiences',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EXPERIENCES_COORDINATOR]),
    icon: <LocalActivityIcon />,
  },
  {
    name: 'Cruises',
    href: '/cruises/offers',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_CRUISE_COORDINATOR]),
    icon: <DirectionsBoatIcon />,
  },
  {
    name: 'TripPlanner',
    href: '/trip-planner',
    requiredRoles: new Set([ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]),
    icon: <FavoriteIcon />,
  },
] as const;

export default function ProductsDropdown() {
  const { user: currentUser } = useCurrentUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const currentUserRoles = useMemo(() => (currentUser ? new Set(currentUser.roles) : new Set()), [currentUser]);
  const links = useMemo(
    () => ProductsMenu.filter((item) => intersection(currentUserRoles, item.requiredRoles ?? new Set()).size),
    [currentUserRoles],
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!currentUser) return null;

  return (
    <div>
      <Button onClick={handleClick} color="inherit" endIcon={<KeyboardArrowDownIcon />}>
        Products
      </Button>

      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {links.map((item) => (
          <MenuItem key={item.name} onClick={handleClose} to={item.href} component={NavLink}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
