import React, { useState } from 'react';

import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';

import ErrorListDisplay from '~/components/Common/ErrorListDisplay';
import Spinner from '~/components/Common/Spinner';

import { sendGDPRRequestNotification } from '~/services/NotificationService';
import { getCredits } from '~/services/PaymentsService';

import { reportError } from '~/utils/reportError';

interface Props {
  isGDPRRequestModalVisible: boolean;
  hideGDPRRequestModal: () => void;
  memberId: string;
  email: string;
  currencyCode: string;
  brand: string;
}

export default function GRPRRequestModal(props: Props) {
  const [hasError, setHasError] = useState(false);
  const [errors, setErrors] = useState('');
  const [updating, setUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [caseNumber, setCaseNumber] = useState('');
  const [accepted, setAccepted] = useState(false);

  const hideGDPRRequestModal = () => {
    setHasError(false);
    setErrors('');
    setUpdating(false);
    setAccepted(false);
    setUpdated(false);
    setCaseNumber('');
    props.hideGDPRRequestModal();
  };

  const gdprRequest = async () => {
    setUpdating(true);
    const { memberId, brand, currencyCode } = props;
    const credits = await getCredits(memberId, currencyCode, brand);
    try {
      if (credits.result.available_credit > 0) {
        throw new Error(
          `Unable to proceed. All active credit must first be removed. Currently ${credits.result.available_credit} ${currencyCode} credits`,
        );
      }
      await sendGDPRRequestNotification({ customer_id: memberId, brand, case_number: caseNumber });
      setUpdated(true);
    } catch (error) {
      setHasError(true);
      setErrors(error.errors || [error.message || 'Some Error Occurred']);
      reportError(error);
    }
    setUpdating(false);
  };

  return (
    <Dialog open={props.isGDPRRequestModalVisible} onClose={hideGDPRRequestModal}>
      <DialogTitle>GDPR request</DialogTitle>

      <DialogContent>
        <Typography variant="body2">
          This will trigger an email to the data protection team to request deletion of this user’s account. Has the
          user requested this?
        </Typography>

        {hasError && <ErrorListDisplay messages={errors} />}

        {updated && (
          <Alert severity="success">
            <p>Email sent to data protection team to request deletion of this user’s account.</p>
          </Alert>
        )}

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={accepted}
                onChange={(e) => {
                  setAccepted(e.target.checked);
                }}
              />
            }
            label="Yes they have"
          />
          <TextField
            label="Case Number"
            value={caseNumber}
            required
            onChange={(e) => {
              setCaseNumber(e.target.value);
            }}
          />
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={hideGDPRRequestModal} disabled={updating}>
          Close
        </Button>
        {!updated && (
          <Button
            variant="contained"
            onClick={gdprRequest}
            disabled={updating || !accepted || caseNumber.trim() === ''}
          >
            Send request {updating && <Spinner size={15} inline={true} />}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
