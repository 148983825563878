import React, { useState } from 'react';

import { Button, DialogActions, DialogContent } from '@mui/material';

import { confirmInsuranceUpdate } from '~/services/OrdersService';

import generateTransactionKey from '../../../../utils/generateTransactionKey';
import ErrorListDisplay from '../../../Common/ErrorListDisplay';

import type { PaymentData } from './StepConfirm';

type Props = {
  currentInsurance: App.InsuranceItem;
  order: App.Order;
  paymentData: PaymentData;
  onContinue: () => void;
  onClose: () => void;
};

export default function StepResult(props: Props) {
  const { currentInsurance, paymentData, order } = props;
  const [processing, setProcessing] = useState(false);
  const [apiErrors, setApiErrors] = useState([]);

  const handleStep = async () => {
    setProcessing(true);
    try {
      await confirmInsuranceUpdate(order, {
        updateId: paymentData.updateId,
        coverAmount: paymentData.coveredAmount,
        transactionKey: generateTransactionKey(),
      });
      props.onContinue();
    } catch (e) {
      setApiErrors([e.message]);
    }
  };

  return (
    <>
      <DialogContent>
        <p>
          <strong>Current product:</strong> <br />
          {currentInsurance.product_name}
        </p>

        <p className="mt-20">
          <strong>Total amount to pay:</strong> {paymentData.totalDiff}
        </p>
        {apiErrors.length > 0 && <ErrorListDisplay messages={apiErrors} />}
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={handleStep} disabled={processing}>
          Update insurance
        </Button>
        <Button onClick={props.onClose} disabled={processing}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}
