export default function currencyFormatter(
  currencyCode = 'AUD',
  value: number,
  maximumFractionDigits = 0,
  currencyDisplay = 'symbol',
) {
  return new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits,
    currencyDisplay,
  }).format(value);
}
