import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { operations } from '@luxuryescapes/contract-svc-promo';

import useCurrentTenant from '~/hooks/useCurrentTenant';

import { debugPromoByOrderID } from '~/services/PromoService';

type PromoError = {
  code: string;
  message: string;
};

type UseOrderDiscountsProps = {
  initOrderId?: string;
  initCodeName?: string;
};

type OrderDiscountFilter = {
  codeName: string;
  orderId: string;
  brand: string;
};

export default function usePromoSimulator({ initOrderId, initCodeName }: UseOrderDiscountsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [orderDiscount, setOrderDiscounts] =
    useState<operations['calculateDiscountByOrderId']['responses']['200']['content']['application/json']['result']>(
      null,
    );
  const [promoCodeErrors, setPromoCodeErrors] = useState<Array<PromoError>>([]);
  const { tenant } = useCurrentTenant();

  const [isLoading, setLoading] = useState(false);

  const reSimulateDiscountEvent = useCallback(
    async ({ codeName, orderId, brand }: OrderDiscountFilter) => {
      setLoading(true);
      try {
        const response = await debugPromoByOrderID(codeName, orderId, brand);
        if (response.status === 200) {
          setOrderDiscounts(response.result);
          setPromoCodeErrors([]);
        } else {
          setOrderDiscounts(null);
          setPromoCodeErrors(response);
        }
      } catch (err) {
        if ('errors' in err && err.errors?.length > 0) {
          setPromoCodeErrors(err.errors);
          setOrderDiscounts(null);
        } else {
          setPromoCodeErrors([err]);
          enqueueSnackbar(`Error1: ${JSON.stringify(err)}`, { variant: 'error', autoHideDuration: 5000 });
        }
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    if (initOrderId && initCodeName) {
      reSimulateDiscountEvent({ codeName: initCodeName, orderId: initOrderId, brand: tenant.brand });
    }
  }, [reSimulateDiscountEvent, initOrderId, initCodeName, enqueueSnackbar]);

  return {
    orderDiscount,
    isLoading,
    promoCodeErrors,
    reSimulateDiscountEvent,
  };
}
