import React, { useCallback, useEffect, useMemo, useState } from 'react';

import requestGetHeroPlannerBanners from '~/queries/customerCommunication/requestGetHeroPlannerBanners';

import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material';

import { RequestStatus } from '~/consts/requestConstants';

import { isRequestPending, isRequestRejected } from '~/utils/requestUtils';

interface Props {
  name: string;
  defaultValue?: string;
}

function ScheduleContentBannerImageInput(props: Props) {
  const { defaultValue = '', name } = props;
  const [value, setValue] = useState<string | undefined>(defaultValue);
  const [req, setReq] = useState<Utils.RequestState<Array<CustomerCommunication.HeroPlannerBanner>, string>>({
    status: RequestStatus.INITIAL,
  });
  const request = useCallback(async () => {
    setReq((currReq) => ({
      status: RequestStatus.PENDING,
      params: undefined,
      result: currReq.result,
    }));

    try {
      const result = await requestGetHeroPlannerBanners();
      setReq({
        status: RequestStatus.FULFILLED,
        params: undefined,
        result,
      });
    } catch (error) {
      setReq({
        status: RequestStatus.REJECTED,
        params: undefined,
        error,
      });
    }
  }, []);

  useEffect(() => {
    request();
  }, [request]);

  const selectedBanner = useMemo<CustomerCommunication.HeroPlannerBanner>(() => {
    return req.result?.find((b) => b.id === value);
  }, [req.result, value]);

  const handleChange = useCallback((event: SelectChangeEvent<string>) => {
    setValue(event.target.value);
  }, []);

  const initialBannerImage = useMemo<CustomerCommunication.HeroPlannerBanner | undefined>(() => {
    if (!req.result?.find((i) => i.id === defaultValue)) return { id: defaultValue, name: defaultValue, url: '' };
  }, [defaultValue, req.result]);

  return (
    <Stack direction="column" gap={2}>
      <FormControl variant="standard" sx={{ position: 'relative' }} fullWidth>
        <InputLabel>Banner Image</InputLabel>
        <Select
          name={name}
          value={value}
          onChange={handleChange}
          label="Banner Image ID"
          required={false}
          variant="standard"
          disabled={isRequestPending(req)}
          error={isRequestRejected(req)}
        >
          {!!initialBannerImage && (
            <MenuItem value={initialBannerImage.id}>
              <i>{initialBannerImage.name}</i>
            </MenuItem>
          )}
          {req.result?.map((banner) => (
            <MenuItem key={banner.id} value={banner.id}>
              {banner.id}
            </MenuItem>
          ))}
        </Select>
        {isRequestPending(req) && <LinearProgress />}
        {isRequestRejected(req) && (
          <Alert
            severity="error"
            action={
              <Button size="small" color="inherit" onClick={request}>
                Retry
              </Button>
            }
          >
            <AlertTitle>Could not fetch banners.</AlertTitle>
            {req.error}
          </Alert>
        )}
      </FormControl>
      <Card elevation={2} sx={{ height: 320 }}>
        {!selectedBanner && <ImageNotSupportedIcon sx={{ m: 1 }} />}
        {!!selectedBanner && (
          <Tooltip placement="top" title="Click to open the image in a new tab" followCursor>
            <CardActionArea sx={{ height: '100%' }} href={selectedBanner.url} target="_blank">
              <CardMedia
                key={selectedBanner.url}
                component="img"
                src={selectedBanner.url}
                alt="banner image preview"
                sx={{ height: '100%', objectFit: 'contain' }}
              />
            </CardActionArea>
          </Tooltip>
        )}
      </Card>
    </Stack>
  );
}

export default ScheduleContentBannerImageInput;
