import qs from 'qs';

import { request } from './common';

function getBaseURL() {
  return `${window.configs.API_HOST}/api/carts`;
}

export async function getCarts(type, page = 1, limit = 10, brand) {
  const baseURL = getBaseURL();
  const queryParams = {
    type,
    page,
    limit,
    brand,
  };

  const url = `${baseURL}?${qs.stringify(queryParams)}`;

  return request(url);
}

export async function getUserQuotes(customerId: string, page = 1, limit = 10) {
  const baseURL = getBaseURL();
  const queryParams = {
    page,
    limit,
  };

  const url = `${baseURL}/quotes/${customerId}?${qs.stringify(queryParams)}`;

  return request(url, {
    method: 'GET',
  });
}
