import React from 'react';

import { Box, Stack } from '@mui/material';

import { VARIANT_OF_REFUND_ALL, VARIANT_OF_REFUND_PARTIAL } from '../../consts/refund';

import CustomRefundVariant from './CustomRefundVariant';
import FullRefundVariant from './FullRefundVariant';

export default class LateChangeOfMindContainer extends React.Component {
  render() {
    return (
      <Box id="LateChangeOfMindContainer">
        <Stack direction="row" spacing={2}>
          <Box>
            <input
              type="radio"
              key={VARIANT_OF_REFUND_ALL}
              name="variant_of_refund"
              value={VARIANT_OF_REFUND_ALL}
              onChange={this.props.handleVariantChange}
              checked={this.props.refund.variant_of_refund === VARIANT_OF_REFUND_ALL}
            />
            <label>Full refund with Fee</label>
          </Box>
          <Box>
            <input
              type="radio"
              key={VARIANT_OF_REFUND_PARTIAL}
              name="variant_of_refund"
              value={VARIANT_OF_REFUND_PARTIAL}
              onChange={this.props.handleVariantChange}
              checked={this.props.refund.variant_of_refund === VARIANT_OF_REFUND_PARTIAL}
              disabled={this.props.refund.missing_cost_price_data}
            />
            <label>Custom Refund</label>
            {this.props.refund.missing_cost_price_data && (
              <div className="text-danger">Cost price data is missing, unable to perform a custom refund</div>
            )}
          </Box>
        </Stack>

        {this.props.refund.variant_of_refund == VARIANT_OF_REFUND_ALL && (
          <FullRefundVariant
            handleFlagChange={this.props.handleFlagChange}
            surchargeInfo={this.props.surchargeInfo}
            handleTmpChange={this.props.handleTmpChange}
            tmpValue={this.props.tmpValue}
            isActive={this.props.refund.variant_of_refund == VARIANT_OF_REFUND_ALL}
            refund={this.props.refund}
            with_fee={true}
          />
        )}

        {this.props.refund.variant_of_refund == VARIANT_OF_REFUND_PARTIAL && (
          <CustomRefundVariant
            handleValueChange={this.props.handleValueChange}
            handleFlagChange={this.props.handleFlagChange}
            handleTmpChange={this.props.handleTmpChange}
            tmpValue={this.props.tmpValue}
            itemRefund={this.props.itemRefund}
            itemRefundOriginal={this.props.itemRefundOriginal}
            isActive={this.props.refund.variant_of_refund == VARIANT_OF_REFUND_PARTIAL}
            refund={this.props.refund}
          />
        )}
      </Box>
    );
  }
}
