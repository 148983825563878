import React from 'react';

import Form from '@rjsf/mui';
import { RJSFSchema, TemplatesType } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import currencyFormatter from 'currency-formatter';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { Link } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';
import * as libRegions from '@luxuryescapes/lib-regions';

import { buttonMessages, buttonStates } from '~/components/Common/Forms/states/muiSubmitButton';

import {
  ROOM_RATE_FLASH_TYPE,
  ROOM_RATE_LAST_MINUTE_TYPE,
  ROOM_RATE_LPC_TYPE,
  ROOM_RATE_RENTAL_TYPE,
} from '~/consts/ratePlans';

import { getItemCountForOffer } from '~/services/OrdersService';

import { DYNAMIC_ALWAYS_ON, DYNAMIC_LAST_MINUTE, FLASH_FLEXI_NIGHTS } from '../../../consts/campaignTypes';
import {
  HOTEL_OFFER_TYPES,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE_HOTEL,
  OFFER_TYPE_RENTAL,
  OFFER_TYPE_TACTICAL_AO_HOTEL,
  OFFER_TYPE_TOUR,
  TOUR_OFFER_TYPES,
} from '../../../consts/offerTypes';
import { postHandpickedExperiencesForPackage } from '../../../services/ExperiencesService';
import InclusionsService from '../../../services/InclusionsService';
import PackagesService from '../../../services/PackagesService';
import isE2ETestOffer from '../../../utils/isE2ETestOffer';
import { reportError } from '../../../utils/reportError';
import { ManifestStatuses } from '../../Common/Modals/ManifestPackagesModal';
import CopyToPackageModal from '../../Offers/Edit/CopyToPackageModal';
import ExtendedArrayFieldTemplate from '../../Tours/TourDetails/RJSF/ExtendedArrayFieldTemplate';
import CopyableField from '../CopyableField';
import ErrorListDisplay from '../ErrorListDisplay';
import SetRegionModal from '../Modals/SetRegionModal';

import { savePackageInclusion } from './InclusionsAssetForm/utils';
import PackageInclusionsField from './fields/PackageInclusionsField';
import { addValuesToSchema } from './helpers/schemaPopulator';
import CustomCheckboxWidget from './widgets/CustomCheckboxWidget';
import CustomSelectFieldWidget from './widgets/CustomSelectFieldWidget';
import CustomTextFieldWidget from './widgets/CustomTextFieldWidget';
import MarkdownEditor from './widgets/MarkdownEditor';
import PackageOptionsWidget from './widgets/PackageOptionsWidget';
import ReactHookFormAdapter from './widgets/ReactHookFormAdapter';

export const PackageFormContext = React.createContext({
  vendorId: '',
  propertyData: [],
  packageData: {} as App.AccommodationPackage & App.TourPackage,
  mapRoomRateToRatePlan: (propertyData, fk_property_id, value) => ({} as App.RoomRate),
});

interface Props {
  allPackagesData: Array<App.AccommodationPackage & App.TourPackage>;
  allSave: boolean;
  campaignType: string;
  iterateIndex: number;
  key: string;
  manifestPackagesListener: {
    clicked: boolean;
    type: ManifestStatuses;
  };
  offerName: string;
  offerType: string;
  onChange: () => void;
  onSaveComplete: () => void;
  onSaveFailed: () => void;
  savePackage: (data: App.AccommodationPackage & App.TourPackage) => void;
  packageData: App.AccommodationPackage & App.TourPackage;
  propertyData: Reservation.Property[];
  schema: any;
  settingUpdates: string[];
  tourData: Reservation.Tour[];
  vendorId: string;
  updateInclusionsOnPackages: () => void;
  packageInclusions: Record<string, App.PackageInclusion[]>;
  experiences: Array<any>;
  packageExperiences: string[];
}

interface State {
  errors: App.ErrorMessage[];
  apiErrors: string[];
  packageData: App.AccommodationPackage & App.TourPackage;
  schema: any;
  saveState: any;
  modalIsOpen: boolean;
  isCopyToModalVisible: boolean;
  copyToState: string;
  copyToError: string[];
  packageExperiences: string[];
}

const WIDGETS = {
  CheckboxWidget: CustomCheckboxWidget,
  TextWidget: CustomTextFieldWidget,
  SelectWidget: CustomSelectFieldWidget,
};

const FIELDS = {
  PackageInclusionsField: PackageInclusionsField,
};

const TEMPLATES: Partial<TemplatesType<unknown, RJSFSchema>> = {
  ArrayFieldTemplate: ExtendedArrayFieldTemplate,
};

const MarkdownEditorWidget = (props) => {
  return (
    <Box>
      <MarkdownEditor {...props} />
    </Box>
  );
};

// eslint-disable-next-line react-prefer-function-component/react-prefer-function-component
export default class PackageForm extends React.PureComponent<Props, State> {
  schema: any;
  allPackagesData: Array<App.AccommodationPackage | App.TourPackage>;
  packageData: App.AccommodationPackage & App.TourPackage;
  propertyData: Reservation.Property[];
  readOnly: any;
  hiddenWidgets: any;
  settableSchema: any;
  rentalSchema: any;
  uiSchema: any;

  constructor(props) {
    super(props);
    //Remove all null values as requried by react-jsonschema-form
    Object.keys(props.packageData).forEach((key) => props.packageData[key] == null && delete props.packageData[key]);

    this.schema = this.props.schema;
    this.packageData = props.packageData;
    this.propertyData = props.propertyData;

    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.injectRoomType = this.injectRoomType.bind(this);
    this.setRegions = this.setRegions.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showCopyToModal = this.showCopyToModal.bind(this);
    this.closeCopyToModal = this.closeCopyToModal.bind(this);
    this.onCopyToPackageSubmit = this.onCopyToPackageSubmit.bind(this);
    this.handleCopyPackageResponse = this.handleCopyPackageResponse.bind(this);
    this.setButtonToDefault = this.setButtonToDefault.bind(this);

    this.readOnly = {
      currency_code: { 'ui:disabled': true },
      price: { 'ui:disabled': true },
      value: { 'ui:disabled': true },
      number_of_nights: { 'ui:disabled': true },
      number_of_days: { 'ui:disabled': true },
      max_extra_nights: { 'ui:disabled': true },
      bundle_max_extra_nights: { 'ui:disabled': true },
      addons: { 'ui:disabled': true },
      deal_option_name: { 'ui:disabled': true },
    };

    if (this.props.offerType === OFFER_TYPE_TACTICAL_AO_HOTEL) {
      delete this.readOnly.number_of_nights;
      delete this.readOnly.max_extra_nights;
      delete this.readOnly.bundle_max_extra_nights;
    }

    this.hiddenWidgets = {
      fk_property_id: { 'ui:widget': 'hidden' },
    };

    let package_bonus_inclusions = {};

    if (this.props.offerType === OFFER_TYPE_TACTICAL_AO_HOTEL) {
      package_bonus_inclusions = {
        items: {
          content: {
            'ui:widget': MarkdownEditorWidget,
          },
        },
      };
    }

    this.settableSchema = {
      partnerships: {
        items: {
          bonus_points: {
            'ui:emptyValue': null,
          },
          bonus_description: {
            'ui:emptyValue': null,
          },
        },
      },
      campaign_code: {
        'ui:emptyValue': '',
      },
      inclusions: {
        'ui:widget': ReactHookFormAdapter,
        'ui:options': {
          ReactHookFormComponent: PackageInclusionsField,
          props: {
            onPackageDataUpdate: this.props.updateInclusionsOnPackages,
          },
        },
      },
      status: {
        'ui:options': {
          showLabel: true,
        },
      },
      package_bonus_inclusions,
      description:
        this.props.offerType === OFFER_TYPE_HOTEL && !this.props.packageData.description
          ? { 'ui:widget': 'hidden' }
          : { 'ui:widget': MarkdownEditorWidget },
      inclusion_highlights: { 'ui:widget': MarkdownEditorWidget },
      regions: {
        classNames: 'hidden-form-field',
        'ui:disabled': true,
      },
      room_occupancy: {
        'ui:emptyValue': '',
        'ui:help': 'ex. Twin Share',
      },
    };

    this.rentalSchema = {};
    if (this.props.offerType === OFFER_TYPE_RENTAL) {
      this.rentalSchema = {
        deal_option_name: {
          'ui:widget': 'hidden',
        },
        name: {
          'ui:widget': 'hidden',
        },
        description: {
          'ui:widget': 'hidden',
        },
        campaign_code: {
          'ui:widget': 'hidden',
        },
        inclusion_highlights: {
          'ui:widget': 'hidden',
        },
        fk_tour_id: {
          'ui:widget': 'hidden',
        },
        fk_tour_option_id: {
          'ui:widget': 'hidden',
        },
        fk_property_id: {
          'ui:widget': 'hidden',
        },
        regions: {
          'ui:widget': 'hidden',
        },
        sort_order: {
          'ui:widget': 'hidden',
        },
        room_occupancy: {
          'ui:widget': 'hidden',
        },
        flexible_nights: {
          'ui:widget': 'hidden',
        },
        allow_buy_now_book_later: {
          'ui:widget': 'hidden',
        },
        allow_dates_request: {
          'ui:widget': 'hidden',
        },
        currency_code: {
          'ui:widget': 'hidden',
        },
        price: {
          'ui:widget': 'hidden',
        },
        value: {
          'ui:widget': 'hidden',
        },
        package_bonus_inclusions: {
          'ui:widget': 'hidden',
        },
        number_of_nights: {
          'ui:widget': 'hidden',
        },
        max_extra_nights: {
          'ui:widget': 'hidden',
        },
        package_options: {
          'ui:widget': 'hidden',
        },
      };
    }

    this.uiSchema = {
      ...this.readOnly,
      ...this.hiddenWidgets,
      ...this.settableSchema,
      ...this.rentalSchema,
    };

    let schema = this.schema;
    if (HOTEL_OFFER_TYPES.includes(this.props.offerType)) {
      //Inject property, room type and room rate data to the schema
      schema = this.injectProperties(this.schema, props.propertyData);
      delete schema.properties.fk_tour_id;
      delete schema.properties.fk_tour_option_id;

      const property = this.props.propertyData?.find((property) => {
        if (property.id === props.packageData.fk_property_id) {
          return property;
        }
      });

      if (!props.packageData.fk_property_id) {
        this.readOnly.fk_room_type_id = { 'ui:disabled': true };
      } else {
        schema = this.injectRoomType(property, schema);
      }

      schema = this.injectPackagesBonusInclusion(
        this.props.offerType,
        props.packageData.max_extra_nights,
        props.packageData.number_of_nights,
        schema,
      );

      if (!props.packageData.fk_property_id && !props.packageData.fk_room_type_id) {
        this.readOnly.fk_room_rate_id = { 'ui:disabled': true };
      } else {
        schema = this.injectRoomRate(property, props.packageData.fk_room_type_id, schema);
        schema = this.injectPackageOptions(this.props.offerType, schema, property, props.packageData.fk_room_type_id);
      }

      // Generate links for room rates / types
      this.schema.properties.package_options.items.properties.fk_room_rate_id = {
        ...this.schema.properties.package_options.items.properties['fk_room_rate_id'],
        title: this.schema.properties.fk_room_rate_id.title,
      };
      this.uiSchema = this.injectLinksToUi(this.props.packageData);
    }

    if (TOUR_OFFER_TYPES.includes(this.props.offerType)) {
      //Inject tour and tour option data to the schema
      schema = this.injectTours(this.schema, props.tourData);
      delete schema.properties.fk_property_id;
      delete schema.properties.fk_room_type_id;
      delete schema.properties.fk_room_rate_id;

      if (!props.packageData.fk_tour_id) {
        this.readOnly.fk_tour_option_id = { 'ui:disabled': true };
      } else {
        schema = this.injectTourOption(props.packageData.fk_tour_id, schema);
      }
    }
    if (props.packageData.flexible_nights === undefined) {
      props.packageData.flexible_nights = [DYNAMIC_ALWAYS_ON, DYNAMIC_LAST_MINUTE, FLASH_FLEXI_NIGHTS].includes(
        props.campaignType,
      );
    }

    this.state = {
      errors: [],
      apiErrors: [],
      packageData: this.injectInclusions(props.packageData, props.packageInclusions),
      schema: schema,
      saveState: buttonStates.default,
      modalIsOpen: false,
      isCopyToModalVisible: false,
      copyToState: buttonStates.default,
      copyToError: [],
      packageExperiences: props.packageExperiences,
    };
  }

  injectInclusions(packageData, packageInclusionsData = {}) {
    const packageId = packageData?.id_salesforce_external;
    return {
      ...packageData,
      inclusions: packageInclusionsData?.[packageId] ?? [],
    };
  }

  filterRoomRatesByOfferType(roomRates) {
    return roomRates.filter((rate) => {
      switch (this.props.offerType) {
        case OFFER_TYPE_HOTEL:
          return rate.rate_plan.product_type === ROOM_RATE_FLASH_TYPE;
        case OFFER_TYPE_TACTICAL_AO_HOTEL:
          return rate.rate_plan.product_type === ROOM_RATE_LPC_TYPE;
        case OFFER_TYPE_LAST_MINUTE_HOTEL:
          return rate.rate_plan.product_type === ROOM_RATE_LAST_MINUTE_TYPE;
        case OFFER_TYPE_RENTAL:
          return rate.rate_plan.product_type === ROOM_RATE_RENTAL_TYPE;
        default:
          return false;
      }
    });
  }

  searchRoomTypeByIteratorNumber(roomTypeIds, roomTypeNames, property) {
    let foundRoomTypeId, foundRoomTypeName;

    // first try to use Room type by order accordingly component iterator (this.props.iterateIndex)
    for (let x = this.props.iterateIndex; x < roomTypeIds.length; x++) {
      foundRoomTypeId = roomTypeIds[x];
      foundRoomTypeName = roomTypeNames[x];

      // check whether current Room type has at least one Rate plan, otherwise try next one
      const roomType = property?.room_types.find((roomType) => {
        if (roomType.id === roomTypeIds[x]) {
          return roomType;
        }
      });
      if (roomType && roomType.room_rates_count && this.filterRoomRatesByOfferType(roomType.room_rates).length) {
        break;
      }
    }

    return { foundRoomTypeId, foundRoomTypeName };
  }

  searchRoomTypeByDealName(roomTypeIds, roomTypeNames) {
    let foundRoomTypeId, foundRoomTypeName;

    for (let y = 0; y < roomTypeNames.length; y++) {
      if (new RegExp(roomTypeNames[y], 'i').test(this.state.packageData.deal_option_name)) {
        foundRoomTypeId = roomTypeIds[y];
        foundRoomTypeName = roomTypeNames[y];
      }
    }

    return { foundRoomTypeId, foundRoomTypeName };
  }

  searchRoomType(property) {
    const roomTypeIds = this.state.schema.properties.fk_room_type_id.enum || [];
    const roomTypeNames = this.state.schema.properties.fk_room_type_id.enumNames || [];

    if (ManifestStatuses.BY_TYPE_ORDER === this.props.manifestPackagesListener.type && property) {
      return this.searchRoomTypeByIteratorNumber(roomTypeIds, roomTypeNames, property);
    } else if (ManifestStatuses.BY_MAPPING === this.props.manifestPackagesListener.type) {
      return this.searchRoomTypeByDealName(roomTypeIds, roomTypeNames);
    }
  }

  populatePackageOptionFieldsAutomatically() {
    if (HOTEL_OFFER_TYPES.includes(this.props.offerType)) {
      const property = this.props.propertyData?.find((property) => {
        if (property.id === this.props.packageData.fk_property_id) {
          return property;
        }
      });
      const { foundRoomTypeId, foundRoomTypeName } = this.searchRoomType(property);

      if (foundRoomTypeId) {
        let newSchema = JSON.parse(JSON.stringify(this.state.schema));

        newSchema = this.injectRoomRate(property, foundRoomTypeId, newSchema);

        newSchema = this.injectPackageOptions(this.props.offerType, newSchema, property, foundRoomTypeId);

        let package_options = [];
        const roomType = property.room_types.find((roomType) => roomType.id === foundRoomTypeId);
        if (roomType?.room_rates) {
          package_options = this.filterRoomRatesByOfferType(roomType.room_rates).map((room_rate) => {
            return {
              fk_room_rate_id: room_rate.id,
              name: room_rate.rate_plan.name,
            };
          });
        }

        this.setState({
          packageData: {
            ...this.state.packageData,
            name: foundRoomTypeName,
            fk_room_type_id: foundRoomTypeId,
            fk_room_rate_id: newSchema.properties.fk_room_rate_id.enum[0],
            package_options,
            room_occupancy: 'Two Adults',
            flexible_nights: true,
            status: 'draft',
            regions: ['world'],
            number_of_nights: this.state.packageData.number_of_nights ? this.state.packageData.number_of_nights : 1,
            max_extra_nights: this.state.packageData.max_extra_nights ? this.state.packageData.max_extra_nights : 29,
          },
          schema: newSchema,
        });
      }
    }
  }

  showCopyToModal() {
    this.setState({ isCopyToModalVisible: true });
  }

  closeCopyToModal() {
    this.setState({
      isCopyToModalVisible: false,
      copyToError: [],
      copyToState: buttonStates.default,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.packageData.fk_property_id !== prevProps.packageData.fk_property_id) {
      const idOffer = this.props.packageData.offer_id_salesforce_external;
      const idPackage = this.props.packageData.le_package_id ?? this.props.packageData.id_salesforce_external;
      const payload = {
        ...this.props.packageData,
      };
      delete payload.fk_room_rate_id;
      delete payload.fk_room_type_id;
      const submitData = JSON.parse(JSON.stringify(payload));
      PackagesService.updatePackage(submitData, idOffer, idPackage);

      let newSchema = JSON.parse(JSON.stringify(this.state.schema));
      if (HOTEL_OFFER_TYPES.includes(this.props.offerType)) {
        // Inject property, room type and room rate data to the schema
        // this.readOnly.fk_property_id = { 'ui:disabled': true };

        const property = this.props.propertyData?.find((property) => {
          if (property.id === this.props.packageData.fk_property_id) {
            return property;
          }
        });

        if (this.props.packageData.fk_property_id) {
          newSchema = this.injectRoomType(property, newSchema);
        }

        if (this.props.packageData.fk_property_id && !this.props.packageData.fk_room_type_id) {
          newSchema = this.injectRoomRate(property, this.props.packageData.fk_room_type_id, newSchema);
        }
      }

      const updatedPackageData = {
        ...this.state.packageData,
        fk_property_id: this.props.packageData.fk_property_id,
        fk_room_type_id: this.props.packageData.fk_room_type_id,
        fk_room_rate_id: this.props.packageData.fk_room_rate_id,
      };

      this.setState({
        packageData: updatedPackageData,
        schema: newSchema,
      });
    }

    // when 'Generate Packages' button is being clicked
    if (this.props.manifestPackagesListener.clicked !== prevProps.manifestPackagesListener.clicked) {
      this.populatePackageOptionFieldsAutomatically();
    }

    if (this.props.allSave !== prevProps.allSave) {
      this.onSubmit({
        formData: {
          ...this.state.packageData,
        },
      });
    }

    if (this.props.settingUpdates !== prevProps.settingUpdates) {
      const updates = {};
      for (const settingUpdate of this.props.settingUpdates) {
        updates[settingUpdate] = this.props.packageData[settingUpdate];
      }
      if (Object.keys(updates).length > 0) {
        this.setState({
          ...this.state,
          packageData: {
            ...this.state.packageData,
            ...updates,
          },
        });
      }

      this.onSubmit({
        formData: {
          ...this.state.packageData,
          ...updates,
        },
      });
    }
    const inclusionsUpdated = !isEqual(
      this.props.packageInclusions?.[this.props.packageData.id_salesforce_external],
      prevProps.packageInclusions?.[this.props.packageData.id_salesforce_external],
    );
    if (inclusionsUpdated) {
      this.setState({
        ...this.state,
        packageData: this.injectInclusions(this.state.packageData, this.props.packageInclusions),
      });
    }
  }

  debouncedOnChange = debounce(this.onChange.bind(this), 1000);

  onChange(edit) {
    const newState: any = {
      packageData: edit.formData,
    };
    if (this.state.saveState !== buttonStates.default) {
      newState.saveState = buttonStates.default;
    }

    // Required to redraw the form as the js objects no longer have the same reference
    let schema = JSON.parse(JSON.stringify(this.state.schema));

    const property = this.props.propertyData?.find((property) => {
      if (property.id === edit.formData.fk_property_id) {
        return property;
      }
    });

    if (edit.formData.fk_property_id) {
      schema = this.injectRoomType(property, schema);
      newState.schema = schema;

      if (edit.formData.package_bonus_inclusions.length) {
        schema = this.injectPackagesBonusInclusion(
          this.props.offerType,
          edit.formData.max_extra_nights,
          edit.formData.number_of_nights,
          schema,
        );

        edit.formData.package_bonus_inclusions.forEach((inclusion) => {
          if (inclusion.from_nights < edit.formData.number_of_nights) {
            inclusion.from_nights = edit.formData.number_of_nights;
          }

          const to_nights_threshold = edit.formData.number_of_nights + edit.formData.max_extra_nights;
          if (inclusion.to_nights > to_nights_threshold) {
            inclusion.to_nights = to_nights_threshold;
          }
          return inclusion;
        });
      }
    }

    if (edit.formData.fk_property_id && edit.formData.fk_room_type_id) {
      schema = this.injectRoomRate(property, edit.formData.fk_room_type_id, schema);
      schema = this.injectPackageOptions(this.props.offerType, schema, property, edit.formData.fk_room_type_id);
      newState.schema = schema;
    }

    if (edit.formData.fk_tour_id) {
      schema = this.injectTourOption(edit.formData.fk_tour_id, schema);
      newState.schema = schema;
    }

    this.uiSchema = this.injectLinksToUi(newState.packageData);

    this.props.onChange();

    this.setState(newState);
  }

  onTestOfferSubmit = (form) => {
    if (confirm('Are you sure? Editing test offers can break the E2E tests!')) {
      this.onSubmit(form);
    }
  };

  async onSubmit(form) {
    this.setState({
      saveState: buttonStates.saving,
    });
    let confirmUpdate = true;
    const { offerType } = this.props;

    const offerHasItems = await getItemCountForOffer(form.formData.offer_id_salesforce_external);
    if (offerHasItems.numItems) {
      if ([OFFER_TYPE_HOTEL, OFFER_TYPE_TACTICAL_AO_HOTEL].includes(offerType)) {
        if (
          this.packageData.fk_room_type_id !== form.formData.fk_room_type_id ||
          form.formData.fk_room_rate_id !== this.packageData.fk_room_rate_id
        )
          confirmUpdate = confirm(
            'This offer has purchases. Changing the rate plan or room type is likely to cause problems for customers or have other unintended consequences. Only proceed if you have consulted team leaders and a member of the tech or product team.',
          );
      }
      if (offerType === OFFER_TYPE_TOUR) {
        if (
          this.packageData.fk_tour_option_id !== form.formData.fk_tour_option_id ||
          this.packageData.fk_tour_id !== form.formData.fk_tour_id
        )
          confirmUpdate = confirm(
            'This offer has purchases. Changing the tour or tour option is likely to cause problems for customers or have other unintended consequences. Only proceed if you have consulted team leaders and a member of the tech or product team.',
          );
      }
    }
    if (confirmUpdate === false) {
      return;
    }
    form.errors = [];
    form.errorSchema = {};
    this.setState({
      packageData: form.formData,
    });

    const idPackage = form.formData.le_package_id ?? form.formData.id_salesforce_external;
    const idOffer = form.formData.offer_id_salesforce_external;
    const submitData = JSON.parse(JSON.stringify(form.formData));

    const packageInclusions = form.formData.inclusions.map(savePackageInclusion);
    delete submitData.inclusions;

    try {
      await InclusionsService.updatePackageInclusion({
        package_inclusions: packageInclusions,
      });
    } catch (e) {
      const apiErrors = [];
      if (e.name === 'ValidationError') {
        e.errors.forEach((err) => {
          apiErrors.push(`inclusion update error: '${err.path}' - ${err.message}`);
        });
      }
      this.setState({
        saveState: buttonStates.failed,
        apiErrors,
      });
      this.props.onSaveFailed();
      reportError(e);
      return;
    }

    if (offerType === OFFER_TYPE_RENTAL) {
      try {
        await postHandpickedExperiencesForPackage({
          packageId: idPackage,
          override: true,
          payload: (this.state.packageExperiences ?? []).map((experienceId: string) => {
            return {
              packageId: idPackage,
              experienceId: experienceId,
            };
          }),
        });
      } catch (e) {
        const apiErrors = [];
        if (e.name === 'ValidationError') {
          e.errors.forEach((err) => {
            apiErrors.push(`package experiences update error: '${err.path}' - ${err.message}`);
          });
        }
        this.setState({
          saveState: buttonStates.failed,
          apiErrors,
        });
        this.props.onSaveFailed();
        reportError(e);
        return;
      }
    }

    Object.keys(this.readOnly).map(function (prop) {
      delete submitData[prop];
    });

    // Clean up field if empty
    if (Object.prototype.hasOwnProperty.call(submitData, 'fk_room_rate_id') && !submitData.fk_room_rate_id) {
      delete submitData.fk_room_rate_id;
    }

    if (Object.prototype.hasOwnProperty.call(submitData, 'fk_room_type_id') && !submitData.fk_room_type_id) {
      delete submitData.fk_room_type_id;
    }

    PackagesService.updatePackage(submitData, idOffer, idPackage)
      .then((response) => {
        this.setState({ saveState: buttonStates.saved, apiErrors: [] });
        this.props.savePackage(response.result);
        this.props.onSaveComplete();
      })
      .catch((e) => {
        const apiErrors = [];
        if (e.name === 'ValidationError') {
          e.errors.forEach((err) => {
            apiErrors.push(err.message);
          });
        }
        this.setState({
          saveState: buttonStates.failed,
          apiErrors,
        });
        this.props.onSaveFailed();
        reportError(e);
      });
    this.setState({ saveState: buttonStates.saved, apiErrors: [] });
  }

  setButtonToDefault() {
    this.setState({ copyToState: buttonStates.default });
  }

  async onCopyToPackageSubmit(selectedPackages, selectedSchema) {
    this.setState({ copyToState: buttonStates.saving, copyToError: [] });

    const pkgData = this.state.packageData;
    const allPackageData = this.props.allPackagesData;

    const idOffer = pkgData.offer_id_salesforce_external;
    const selectedFields = selectedSchema.map((s) => s.value);

    const allCopyPackagesPromises = selectedPackages.map((item) => {
      // find the copy to package data
      const targetPackageData = allPackageData.find(
        (data) => (data.le_package_id ?? data.id_salesforce_external) === item.value,
      );
      // get the need to be copied data based on the current packageData
      const needData = pick(this.state.packageData, selectedFields);

      // generate the copy to data/ update data
      const copyToData = { ...targetPackageData, ...needData };

      return PackagesService.updatePackage(JSON.parse(JSON.stringify(copyToData)), idOffer, item.value).catch(
        (error) => {
          return error;
        },
      );
    });

    const copyPackageResponse = await Promise.all(allCopyPackagesPromises);

    this.handleCopyPackageResponse(copyPackageResponse);
  }

  handleCopyPackageResponse(copyPackageResponse) {
    const erroredUpdates = copyPackageResponse.filter((r) => r.errors);
    if (erroredUpdates.length === 0) {
      this.setState({ copyToState: buttonStates.saved, copyToError: [] });
      // refresh page to see the updated packages
      setTimeout(() => location.reload(), 2000);
    } else {
      const errorsResponseMessage = erroredUpdates.map((item) => item.errors[0].message);
      this.setState({
        copyToState: buttonStates.failed,
        copyToError: [...errorsResponseMessage],
      });
    }
  }

  injectTours(packageSchema, tours) {
    return addValuesToSchema('fk_tour_id', tours, packageSchema);
  }

  injectTourOption(tourId, packageSchema) {
    const tour = this.props.tourData.find((tour) => {
      if (tour.id === tourId) {
        return tour;
      }
    });

    if (tour && tour.tour_options_count) {
      packageSchema = addValuesToSchema('fk_tour_option_id', tour.tour_options, packageSchema);
      delete this.readOnly.fk_tour_option_id;
    }

    return packageSchema;
  }

  injectProperties(packageSchema, properties) {
    return addValuesToSchema('fk_property_id', properties, packageSchema);
  }

  injectRoomType(property, packageSchema) {
    if (property && property.room_types_count) {
      packageSchema = addValuesToSchema('fk_room_type_id', property.room_types, packageSchema);
      delete this.readOnly.fk_room_type_id;
    }

    return packageSchema;
  }

  injectRoomRate(property, roomTypeId, packageSchema) {
    if (!property) {
      return packageSchema;
    }

    const roomType = property.room_types.find((roomType) => {
      if (roomType.id === roomTypeId) {
        return roomType;
      }
    });

    if (roomType && roomType.room_rates_count) {
      const roomRates = this.filterRoomRatesByOfferType(roomType.room_rates);

      packageSchema = addValuesToSchema(
        'fk_room_rate_id',
        roomRates.map((r) => ({
          id: r.id,
          name: r.rate_plan.name,
        })),
        packageSchema,
      );
      delete this.readOnly.fk_room_rate_id;
    }

    return packageSchema;
  }

  injectPackagesBonusInclusion(offerType, maxExtraNights = 0, numberOfNights, packageSchema) {
    if (offerType === OFFER_TYPE_TACTICAL_AO_HOTEL) {
      // create the array range of nights:
      // minNight = numberOfnights;
      // maxNights = maxExtraNights + numberOfNights
      const stayNightsRange = Array.from(new Array(maxExtraNights + 1), (_, i) => i + numberOfNights);

      const values = stayNightsRange.reduce(
        (acc, value) => {
          acc.enum.push(value);
          acc.enumNames.push(value + ' Nights');
          return acc;
        },
        {
          enum: [],
          enumNames: [],
        },
      );

      return {
        ...packageSchema,
        properties: {
          ...packageSchema.properties,
          package_bonus_inclusions: {
            ...packageSchema.properties['package_bonus_inclusions'],
            items: {
              ...packageSchema.properties['package_bonus_inclusions'].items,
              properties: {
                ...packageSchema.properties['package_bonus_inclusions'].items.properties,
                from_nights: {
                  ...packageSchema.properties['package_bonus_inclusions'].items.properties['from_nights'],
                  enum: values.enum,
                  enumNames: values.enumNames,
                },
                to_nights: {
                  ...packageSchema.properties['package_bonus_inclusions'].items.properties['to_nights'],
                  enum: values.enum,
                  enumNames: values.enumNames,
                },
              },
            },
          },
        },
      };
    }
    return packageSchema;
  }

  injectPackageOptions(offerType, packageSchema, property, roomTypeId) {
    if (!HOTEL_OFFER_TYPES.includes(offerType)) {
      return packageSchema;
    }

    if (!property) {
      return packageSchema;
    }

    const roomType = property.room_types.find((roomType) => {
      if (roomType.id === roomTypeId) {
        return roomType;
      }
    });

    if (!roomType || !roomType.room_rates_count) {
      return packageSchema;
    }

    const roomRates = this.filterRoomRatesByOfferType(roomType.room_rates);

    const values = roomRates
      .map((r) => ({
        id: r.id,
        name: r.rate_plan.name + (r.rate_plan.status === 'hidden' ? ' [hidden]' : ''),
      }))
      .reduce(
        (acc, value) => {
          acc.enum.push(value.id);
          acc.enumNames.push(value.name);

          return acc;
        },
        {
          enum: [],
          enumNames: [],
        },
      );

    return {
      ...packageSchema,
      properties: {
        ...packageSchema.properties,
        package_options: {
          ...packageSchema.properties['package_options'],
          items: {
            ...packageSchema.properties['package_options'].items,
            properties: {
              ...packageSchema.properties['package_options'].items.properties,
              fk_room_rate_id: {
                ...packageSchema.properties['package_options'].items.properties['fk_room_rate_id'],
                title: 'Rate Plan',
                enum: values.enum,
                enumNames: values.enumNames,
              },
            },
          },
        },
      },
    };
  }

  mapRoomRateToRatePlan(propertyData, fk_property_id, fk_room_rate_id): App.RoomRate {
    const findRatePlan = (room_types, index) => {
      if (index >= 0) {
        const roomRate = room_types[index].room_rates.find((room_rate) => room_rate.id === fk_room_rate_id);
        return roomRate ? roomRate : findRatePlan(room_types, index - 1);
      }
    };
    const property = propertyData?.find((property) => {
      if (property.id === fk_property_id) {
        return property;
      }
    });

    return findRatePlan(property?.room_types, property?.room_types.length - 1);
  }

  injectLinksToUi(packageData) {
    const uiSchema = {
      ...this.uiSchema,
      fk_room_type_id: {
        ...this.uiSchema.fk_room_type_id,
        'ui:description':
          packageData.fk_room_type_id &&
          this.generateLinkToEditRoomTypes(this.props.vendorId, this.props.packageData.fk_property_id),
        'ui:options': {
          showLabel: true,
        },
      },
    };
    const currentPlanItem = this.mapRoomRateToRatePlan(
      this.props.propertyData,
      packageData.fk_property_id,
      packageData.fk_room_rate_id,
    );
    if (currentPlanItem) {
      return {
        ...uiSchema,
        fk_room_rate_id: {
          ...uiSchema.fk_room_rate_id,
          'ui:description':
            packageData.fk_room_rate_id &&
            this.generateLinkToRatePlan(this.props.vendorId, currentPlanItem.rate_plan.id),
          'ui:options': {
            showLabel: true,
          },
        },
        package_options: {
          ...this.uiSchema.package_options,
          items: {
            fk_room_rate_id: {
              'ui:widget': PackageOptionsWidget,
            },
          },
          'ui:options': {
            showLabel: true,
          },
        },
      };
    }
    return uiSchema;
  }

  validate(formData, errors) {
    this.state.errors.forEach((error) => errors.addError(error.message));

    if (formData.package_options && formData.package_options.length > 0) {
      // Restrict saving duplicated Package Options Room Rates
      const packageOptsIds = [...new Set(formData.package_options.map((o) => o.fk_room_rate_id))];

      if (formData.package_options.length !== packageOptsIds.length) {
        errors.package_options.addError('Please check the rate plans added to avoid duplicated mappings');
      }
    }

    return errors;
  }

  getRegionNameFromCode(code) {
    const region = libRegions.getRegionByCode(code, 'luxuryescapes');
    if (region) {
      return region.name;
    } else {
      return code;
    }
  }

  setSelectedExperiences(experiences) {
    if (!Array.isArray(experiences)) {
      experiences = [experiences];
    }
    this.setState({ packageExperiences: experiences });
  }

  setRegions(regions) {
    this.setState((prevState) => {
      const updatedPackageData = {
        ...prevState.packageData,
        regions: regions,
      };

      return {
        packageData: updatedPackageData,
        modalIsOpen: false,
      };
    });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  showModal() {
    this.setState({ modalIsOpen: true });
  }

  generateLinkToRatePlan(vendorId, ratePlanId) {
    return <Link to={'/vendors/' + vendorId + '/rate-plans/' + ratePlanId}>(show rate plan)</Link>;
  }

  generateLinkToEditRoomTypes(vendorId, propertyId) {
    return (
      <Link to={'/vendors/' + vendorId + '/properties/' + propertyId + '/edit-room-types/'}>(show room types)</Link>
    );
  }

  generateLinkToAvailabilityRates(packageData, vendorId) {
    if (packageData?.fk_property_id && packageData?.fk_room_type_id && packageData?.fk_room_rate_id && vendorId) {
      return (
        <Link
          to={
            '/vendors/' +
            vendorId +
            '/properties/' +
            packageData.fk_property_id +
            '/room-types/' +
            packageData.fk_room_type_id +
            '/room-rates/' +
            packageData.fk_room_rate_id
          }
          target="_blank"
        >
          View Availability &amp; Rates&nbsp;
          <span className="glyphicon glyphicon-new-window" aria-hidden="true"></span>
        </Link>
      );
    }
    return 'Missing Data To Get Availability & Rates';
  }

  handleDescriptionSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.uiSchema.description = event.target.checked
      ? { 'ui:widget': MarkdownEditorWidget }
      : { 'ui:widget': 'hidden' };

    // ugly and discouraged, but we will refactor this whole component soon
    // and move to modern approach so this will do for now
    this.forceUpdate();
  };

  priceRow = (prices) => {
    return prices.map((packagePriceData) => (
      <span className="package-prices-item" key={packagePriceData.currency_code}>
        <div className="h5">{packagePriceData.currency_code}</div>
        <dl>
          <dt className="text-muted small">Price:</dt>
          <dd>
            {currencyFormatter.format(packagePriceData.price, {
              code: packagePriceData.currency_code,
            })}
          </dd>
          <dt className="text-muted small">Value:</dt>
          <dd>
            {currencyFormatter.format(packagePriceData.value, {
              code: packagePriceData.currency_code,
            })}
          </dd>
        </dl>
      </span>
    ));
  };

  render() {
    const originalPriceRow = this.state.packageData.prices && this.priceRow(this.state.packageData.prices);
    const bundlePriceRow = this.state.packageData.bundle_prices && this.priceRow(this.state.packageData.bundle_prices);
    const selectedExperiences = this.state.packageExperiences ? this.state.packageExperiences[0] : undefined;

    let apiErrors = null;
    if (this.state.apiErrors && this.state.apiErrors.length) {
      apiErrors = <ErrorListDisplay messages={this.state.apiErrors} />;
    }

    const showE2EOfferWarning = isE2ETestOffer(this.props.offerName);
    const onFormSubmit = showE2EOfferWarning ? this.onTestOfferSubmit : this.onSubmit;

    const packageFormContext = {
      packageData: this.state.packageData,
      vendorId: this.props.vendorId,
      propertyData: this.props.propertyData,
      mapRoomRateToRatePlan: this.mapRoomRateToRatePlan,
    };

    const experiencesOptions = this.props.experiences
      .map((i) => ({
        label: `${i.title.trim()} (ID ${i.providerId})`,
        value: i.providerId,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <div className="package-edit">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton onClick={this.showCopyToModal} size="large" color="primary" aria-label="Copy Offer">
              <ContentCopyIcon />
            </IconButton>
            {this.state.packageData &&
              this.generateLinkToAvailabilityRates(this.props.packageData, this.props.vendorId)}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
            <strong>IDs</strong>
            <CopyableField value={this.state.packageData.le_package_id} />
            <CopyableField value={this.state.packageData.id_salesforce_external} />
          </Box>
          {this.props.offerType === OFFER_TYPE_HOTEL && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={this.uiSchema.description['ui:widget'] === MarkdownEditorWidget}
                disabled={!!this.state.packageData.description}
                onChange={this.handleDescriptionSwitchChange}
                size="small"
              />
              <Typography variant="body2">Description</Typography>
            </Box>
          )}
        </Box>

        <PackageFormContext.Provider value={packageFormContext}>
          <Form
            schema={this.state.schema}
            formData={this.state.packageData}
            showErrorList={false}
            liveValidate={true}
            customValidate={this.validate}
            validator={validator}
            onSubmit={onFormSubmit}
            className="package-form"
            onChange={this.debouncedOnChange}
            uiSchema={{ ...this.uiSchema }}
            templates={TEMPLATES}
            fields={FIELDS}
            widgets={WIDGETS}
          >
            <CopyToPackageModal
              isOpen={this.state.isCopyToModalVisible}
              onHide={this.closeCopyToModal}
              packageData={this.state.packageData}
              uiSchema={{ ...this.uiSchema }}
              schema={this.state.schema}
              allPackagesData={this.props.allPackagesData}
              onCopyToPackageSubmit={this.onCopyToPackageSubmit}
              apiErrors={this.state.copyToError}
              copyToState={this.state.copyToState}
              setButtonToDefault={this.setButtonToDefault}
            />
            {this.props.offerType !== OFFER_TYPE_RENTAL && (
              <>
                <div className="regions-tag-container">
                  <div>
                    <label className="control-label">Package Regions</label>
                  </div>
                  <div>
                    <ul className="region-tags-list">
                      {this.state.packageData.regions.length === libRegions.getRegions().length ? (
                        <li key="world">world</li>
                      ) : (
                        this.state.packageData.regions.map((region) => (
                          <li key={region}>{this.getRegionNameFromCode(region)}</li>
                        ))
                      )}
                    </ul>
                  </div>
                  <div>
                    <Button variant="contained" onClick={this.showModal}>
                      Set Regions
                    </Button>
                  </div>
                </div>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Box fontWeight="700" component="label">
                      Available in Currency
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="package-prices">{originalPriceRow}</div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Box fontWeight="700" component="label">
                      Available in Currency (Bundle & Save)
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="package-prices">{bundlePriceRow}</div>
                  </AccordionDetails>
                </Accordion>
                {apiErrors}
                <div className="button-container">
                  <Button
                    data-cy="package-form-submit-button"
                    variant="contained"
                    type="submit"
                    color={this.state.saveState}
                  >
                    {buttonMessages[this.state.saveState]}
                  </Button>
                </div>
              </>
            )}
            {this.props.offerType === OFFER_TYPE_RENTAL && (
              <>
                <div className="experiences-tag-container">
                  <Autocomplete
                    options={[...experiencesOptions.map((type) => type.value)]}
                    onChange={(_, values) => {
                      this.setSelectedExperiences(values);
                    }}
                    limitTags={5}
                    value={selectedExperiences}
                    renderInput={(params) => <TextField {...params} label="Select Experiences" />}
                    getOptionLabel={(option) =>
                      experiencesOptions.find((type) => type.value === option)?.label || option
                    }
                    sx={{ mt: 2 }}
                  />
                </div>
              </>
            )}
          </Form>
        </PackageFormContext.Provider>
        <SetRegionModal
          open={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          onSet={this.setRegions}
          regions={this.state.packageData.regions}
          name="Package Regions"
          label="Tag all regions that you want this package to be visible in"
        />
      </div>
    );
  }
}
